import React from 'react'
import red from '../../assets/images/red1.jpg'
function Accessdenied() {
  return (
    <div style={{height:'100%', width:'100%',marginTop:'100px'}} className="d-flex flex-column justify-content-center align-items-center">
        <img src={red} alt="Access denied" height={250} width={320}/>
        <h3 style={{fontSize:'40px'}} className="pb-3">Access Denied</h3>
        <h3>You don't have permission to view this resource.</h3>
    </div>
  )
}

export default Accessdenied