
import { Button, Modal } from 'react-bootstrap'

function Deletewarning({ show, handleClose, handleDelete,message,title,actionBtnLabel,closeBtnLabel }) {
    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <button
                    className='sbuttonbgcolor btn btn-sm'
                    onClick={handleClose}
                >
                    {closeBtnLabel}
                </button>
                <button
                    className='pbuttonbgcolor btn btn-sm'
                    onClick={handleDelete}
                >
                    {actionBtnLabel}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default Deletewarning