
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import axios from 'axios';
import { Button, Form, InputGroup } from 'react-bootstrap'
// import '../../User/userList.css'
import '../dashboard.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Chart as chartJS, registerables } from 'chart.js';
import { dashboard } from '../../../controllers/dashboard';
import { UserContext } from '../../common/UserContext';
import { useQuery } from 'react-query';
import Loader from '../../common/Loader';
import config from '../../../config.json';
import { toastify } from '../../common/notification';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import lodash from 'lodash'
import Select from 'react-select';
const url = config['baseHost_backend'];

function Filter({ data,
    setFilter,
    setFilteredROIReport,
    defaultSelectedFiltervalue,
    setDefaultSelectedFilterValue,
    years,
    globalSellingValue,
    setGlobalSellingValue,
    setGlobalSubscriptionfee,
    setSelectedDealerID,
    selectedDealerID,
    noDataDisable
}: any) {
    const { authToken, dealerID, corporateID, user_first_name, type, subscriptionFee } = useContext(UserContext);
    const [activeYear, setActiveYear] = useState(new Date());
    const userInfo = {}
    const [yearsM, setYearsM] = useState<any>(null)
    const selectFilter = useRef<any>(null);
    const selectFilter1 = useRef<any>(null);
    const [frontTotal, setFrontTotal] = useState<any>(0)
    const [filter2Value, setFilter2value] = useState<any>(null)
    const [selectedYear, setSelectedYear] = useState<any>(null)
    const yearRef = useRef<any>(null)
    const [selectedDealer, setSelectedDealer] = useState<any>(null)
    const [tempselectedDealer, setTempSelectedDealer] = useState<any>(null)
    const [dealers, setDealers] = useState<any>(null)
    const getQuarter = (date = new Date()) => {
        const quarter = Math.floor(date.getMonth() / 3 + 1);
        switch (quarter) {
            case 1:
                return "Q1 - [Jan-Feb-Mar]"
            case 2:
                return "Q2 - [Apr-May-Jun]"
            case 3:
                return "Q3 - [Jul-Aug-Sep]"
            case 4:
                return "Q4 - [Oct-Nov-Dec]"
            default:
                break;
        }
    }

    useEffect(() => {
        if (data && data?.body?.data) {
            // selectFilter.current.focus()
            const temp = [{ label: "All", value: "null" }] as any
            

            const map = new Map();
            if (data?.body?.data?.inventory?.data) {
                for (const item of data?.body?.data?.inventory?.data) {
                    if (!map.has(item.Dealer_id)) {
                        map.set(item.Dealer_id, true);    // set any value to Map
                        temp.push({
                            label: item.Dealer_name,
                            value: item.Dealer_id
                        });
                    }
                }
            } else {
                for (const item of data?.body?.data?.roi?.data) {
                    if (!map.has(item.Dealer_id)) {
                        map.set(item.Dealer_id, true);    // set any value to Map
                        temp.push({
                            label: item.Dealer_name,
                            value: item.Dealer_id
                        });
                    }
                }
            }
            let sorted = temp.sort((a, b) => a.label.trim().toLowerCase() > b.label.trim().toLowerCase() ? 1 : -1)
            setDealers(sorted)
            
        }
    }, [data])
    

    var getDaysInMonth = function (month: any, year: any) {
        return new Date(year, month, 0).getDate();
    };

    useEffect(() => {
        if (years) {
            let temp = [] as any;
            years?.map((yr) => {
                temp.push({ label: yr, value: yr })
            })
            setYearsM(temp)
        }
    }, [years])


    const handleYearChange = (e: any) => {
        yearRef.current.selectedIndex = 0;
        setFilter2value(null)
        setSelectedYear(e)
        if (e === "null") {
            setFilteredROIReport(null)
        }
    }
    const handleFilter2change = (e: any) => {
        setFilter2value(e)
        const annual = [{ data: [] }] as any;
        
        if (e !== "null") {
            if (e === "Annual") {
                setGlobalSellingValue(0)
                setGlobalSubscriptionfee(0)
                setFilter2value(e);
                let cTotal = 0;
                let pTotal = 0;

                const yearFilterd = data?.body?.data?.inventory?.data?.forEach((obj: any) => {
                    let condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["Date"]).getFullYear() === Number(selectedYear) :
                        new Date(obj["Date"]).getFullYear() === Number(selectedYear) && obj?.Dealer_id === selectedDealer

                    if (condition) {
                        annual[0].data.push(obj);
                        cTotal += obj["Cost"] ? obj["Cost"] : 0;
                        pTotal += obj["Price"] ? obj["Price"] : 0;
                        annual[0].totalCost = cTotal;
                        annual[0].totalPrice = pTotal;
                    }
                })
                
                setFilteredROIReport(annual)
            } else if (e === "Quarterly") {
                defaultQuaterlyDataPopulate()
            } else if (e === "Monthly") {
                defaultMonthlyDataPopulate()
            }
        }
    }

    const handleByAnuualFilterchange = (e: any) => {
        const h1Data = [{ data: [] }] as any;
        const h2Data = [{ data: [] }] as any;
        const allData = [{ data: [] }] as any;
        const firstHalfYearStart = new Date(`01 Jan ${selectedYear}`)
        const firstHalfYearEnd = new Date(`${getDaysInMonth(selectedYear, 6)} Jun ${selectedYear}`)

        const secondHalfYearStart = new Date(`01 Jul ${selectedYear}`)
        const secondHalfYearEnd = new Date(`${getDaysInMonth(selectedYear, 12)} Dec ${selectedYear}`)

        
        

        if (e === "H1") {
            setGlobalSellingValue(0)
            setGlobalSubscriptionfee(0)
            let cTotal = 0;
            let pTotal = 0;
            const h1Filterd = data?.body?.data?.inventory?.data?.forEach((obj: any) => {
                let condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["Date"]) >= firstHalfYearStart && new Date(obj["Date"]) <= firstHalfYearEnd :
                    new Date(obj["Date"]) >= firstHalfYearStart && new Date(obj["Date"]) <= firstHalfYearEnd && obj?.Dealer_id === selectedDealer
                if (condition) {
                    h1Data[0].data.push(obj)
                    cTotal += obj["Cost"] ? obj["Cost"] : 0;
                    pTotal += obj["Price"] ? obj["Price"] : 0;
                    h1Data[0].totalCost = cTotal;
                    h1Data[0].totalPrice = pTotal;
                }
            })
            
            setFilteredROIReport(h1Data)
        } else if (e === "H2") {
            setGlobalSellingValue(0)
            setGlobalSubscriptionfee(0)
            let cTotal = 0;
            let pTotal = 0;
            const h2Filterd = data?.body?.data?.inventory?.data?.forEach((obj: any) => {
                let condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["Date"]) > secondHalfYearStart && new Date(obj["Date"]) <= secondHalfYearEnd :
                    new Date(obj["Date"]) > secondHalfYearStart && new Date(obj["Date"]) <= secondHalfYearEnd && obj?.Dealer_id === selectedDealer
                if (condition) {
                    h2Data[0].data.push(obj)
                    cTotal += obj["Cost"] ? obj["Cost"] : 0;
                    pTotal += obj["Price"] ? obj["Price"] : 0;
                    h2Data[0].totalCost = cTotal;
                    h2Data[0].totalPrice = pTotal;
                }
            })
            
            setFilteredROIReport(h2Data)
        } else {
            setGlobalSellingValue(0)
            setGlobalSubscriptionfee(0)
            let cTotal = 0;
            let pTotal = 0;
            // All
            const allData2 = data?.body?.data?.inventory?.data?.forEach((obj: any) => {
                let condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["Date"]).getFullYear() === Number(selectedYear) :
                    new Date(obj["Date"]).getFullYear() === Number(selectedYear) && obj?.Dealer_id === selectedDealer
                if (condition) {
                    allData[0].data.push(obj)
                    cTotal += obj["Cost"] ? obj["Cost"] : 0;
                    pTotal += obj["Price"] ? obj["Price"] : 0;
                    allData[0].totalCost = cTotal;
                    allData[0].totalPrice = pTotal;
                }
            })
            
            setFilteredROIReport(allData)
        }
    }

    const handleQuarterlyChange = (e: any) => {
        //limit the data to selected Year
        const yearFilterd = data?.body?.data?.inventory?.data?.filter((obj: any) => {
            return new Date(obj["Date"]).getFullYear() === Number(selectedYear)
        })

        if (e !== 'all') {
            setGlobalSellingValue(0)
            setGlobalSubscriptionfee(0)
            let cTotal = 0;
            let pTotal = 0;
            const QuarterlyData = [{ data: [] }] as any
            const data = yearFilterd?.forEach((obj: any) => {
                let date = new Date(new Date(obj["Date"]))
                let quarter = Math.floor(date.getMonth() / 3 + 1)
                let condition = (selectedDealer === "null" || selectedDealer === null) ? Number(quarter) === Number(e) :
                    Number(quarter) === Number(e) && obj?.Dealer_id === selectedDealer
                if (condition) {
                    QuarterlyData[0].data.push(obj)
                    cTotal += obj["Cost"] ? obj["Cost"] : 0;
                    pTotal += obj["Price"] ? obj["Price"] : 0;
                    QuarterlyData[0].totalCost = cTotal;
                    QuarterlyData[0].totalPrice = pTotal;
                }
                setFilteredROIReport(QuarterlyData)
            })
            // 


        } else if (e === "all") {
            defaultQuaterlyDataPopulate()
        }
    }

    const defaultQuaterlyDataPopulate = () => {
        setGlobalSellingValue(0)
        setGlobalSubscriptionfee(0)
        let cTotal = 0;
        let pTotal = 0;
        const QuarterlyData = [] as any;
        let condition;
        const yearFilterd = data?.body?.data?.inventory?.data?.filter((obj: any) => {
            condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["Date"]).getFullYear() === Number(selectedYear) :
                new Date(obj["Date"]).getFullYear() === Number(selectedYear) && obj?.Dealer_id === selectedDealer
            return condition
        })
        yearFilterd?.map((data1: any) => {
            const Quarter = getQuarter(new Date(data1["Date"]));
            if (QuarterlyData.find((obj: any) => obj.quarter === Quarter)) {
                let index = QuarterlyData.findIndex((obj: any) => obj.quarter === Quarter);
                QuarterlyData[index].data.push(data1)
                cTotal += data1["Cost"] ? data1["Cost"] : 0;
                pTotal += data1["Price"] ? data1["Price"] : 0;
                QuarterlyData[index].totalCost = QuarterlyData[index].totalCost + cTotal;
                QuarterlyData[index].totalPrice = QuarterlyData[index].totalPrice + pTotal;
            }
            else {
                QuarterlyData.push({ "quarter": Quarter, "data": [data1], "totalCost": data1["Cost"] || 0, "totalPrice": data1["Price"] || 0 })
            }
        })
        
        setFilteredROIReport(QuarterlyData)
    }

    const defaultMonthlyDataPopulate = () => {
        setGlobalSellingValue(0)
        setGlobalSubscriptionfee(0)
        let cTotal = 0;
        let pTotal = 0;
        const monthlyData = [] as any;
        let condition;
        const yearFilterd = data?.body?.data?.inventory?.data?.filter((obj: any) => {
            return condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["Date"]).getFullYear() === Number(selectedYear) :
                new Date(obj["Date"]).getFullYear() === Number(selectedYear) && obj?.Dealer_id === selectedDealer
        })

        yearFilterd?.forEach((obj: any) => {
            

            const month = moment(new Date(obj["Date"])).format('LL').split(" ")[0]
            if (monthlyData?.find((obj: any) => obj.month === month)) {
                let index = monthlyData.findIndex((obj: any) => obj.month === month);
                monthlyData[index].data.push(obj)
                cTotal += obj["Cost"] ? obj["Cost"] : 0;
                pTotal += obj["Price"] ? obj["Price"] : 0;
                monthlyData[index].totalCost = monthlyData[index].totalCost + cTotal;
                monthlyData[index].totalPrice = monthlyData[index].totalPrice + pTotal;
            }
            else {
                monthlyData.push({ "month": month, "data": [obj], "totalCost": obj["Cost"] || 0, "totalPrice": obj["Price"] || 0 })
            }
        })
        
        setFilteredROIReport(monthlyData)
    }
    

    const handleMonthlyFilterChange = (e: any) => {
        setGlobalSellingValue(0)
        setGlobalSubscriptionfee(0)
        let cTotal = 0;
        let pTotal = 0;
        const yearFilterd = data?.body?.data?.inventory?.data?.filter((obj: any) => {
            return new Date(obj["Date"]).getFullYear() === Number(selectedYear)
        })
        if (e === "all") {
            defaultMonthlyDataPopulate();
        } else {

            const monthData = [{ data: [] }] as any
            const data = yearFilterd?.forEach((obj: any) => {
                let month = moment(new Date(obj["Date"])).format('LL').split(" ")[0]
                let condition = (selectedDealer === "null" || selectedDealer === null) ? month === e :
                    month === e && obj?.Dealer_id === selectedDealer
                if (condition) {
                    monthData[0].data.push(obj)
                    cTotal += obj["Cost"] ? obj["Cost"] : 0;
                    pTotal += obj["Price"] ? obj["Price"] : 0;
                    monthData[0].totalCost = cTotal;
                    monthData[0].totalPrice = pTotal;
                }
                // setFilteredROIReport(QuarterlyData)
            })
            
            setFilteredROIReport(monthData)

        }
    }
    const handleDealerSelectedview = (did, dname) => {
        
        setTempSelectedDealer({ label: dname, value: did })
        setSelectedDealer(did)
        selectFilter1.current.selectedIndex = 0;
        yearRef.current.selectedIndex = 0;
    }

    const dv = {
        label: user_first_name,
        value: dealerID
    }
    let annualValues = [
        { label: "Annual", value: "Annual" },
        { label: "Bi-Annual", value: "Bi-Annual" },
        { label: "Quarterly", value: "Quarterly" },
        { label: "Monthly", value: "Monthly" }
    ]

    let BiannualValues = [
        { label: "All", value: "all" },
        { label: "H1 [Jan - Jun]", value: "H1" },
        { label: "H2 [Jul - Dec]", value: "H2" },
    ]
    let quarterlyValues = [
        { label: "All", value: 'all' },
        { label: "Q1 [Jan - Mar]", value: 1 },
        { label: "Q2 [Apr - Jun]", value: 2 },
        { label: "Q3 [Jul - Sep]", value: 3 },
        { label: "Q4 [Oct - Dec]", value: 4 }
    ]

    let monthlyValue = [
        { label: "All", value: "all" },
        { label: "Jan", value: "January" },
        { label: "Feb", value: "February" },
        { label: "Mar", value: "March" },
        { label: "Apr", value: "April" },
        { label: "May", value: "May" },
        { label: "Jun", value: "June" },
        { label: "Jul", value: "July" },
        { label: "Aug", value: "August" },
        { label: "Sep", value: "September" },
        { label: "Oct", value: "October" },
        { label: "Nov", value: "November" },
        { label: "Dec", value: "December" },
    ]

    return (
        <div className='mt-auto mb-auto resp-width-r' >
            <div className=''>
                <div className='componentWrapper d-flex justify-content-left gap-3 p-3'>
                    <div className="header">
                        View filters
                    </div>
                    <div className="input-select-search" style={{ width: '200px' }}>
                        <span className='filter-control-lable'>Select dealer</span>
                        <Select closeOnSelect={true}
                            isDisabled={type === "dealer"}
                            value={type === "dealer" ? dv : tempselectedDealer}
                            isSearchable={true}
                            defaultValue="All"
                            options={dealers && dealers}
                            onChange={(opt) => { handleDealerSelectedview(opt.value, opt.label) }}
                        ></Select>
                    </div>
                    {/* <Form.Select
                        onChange={handleYearChange}
                        ref={selectFilter1}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        aria-describedby='basic-addon1'
                    >
                        <option value="null">Select</option>
                        {years?.map((year: any) => (
                            <option value={year}>{year}</option>
                        ))}
                    </Form.Select> */}
                    <div className="input-select-search" style={{ width: '200px' }}>
                        <span className='filter-control-lable'>Select year</span>
                        <Select closeOnSelect={true}
                            // ref={compRef}
                            ref={selectFilter1}
                            isSearchable={true}
                            defaultValue="All"
                            options={yearsM && yearsM}
                            onChange={(opt) => { handleYearChange(opt.value) }}
                        ></Select>
                    </div>

                    {/* <Form.Select
                        ref={yearRef}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        onChange={handleFilter2change}
                        aria-describedby='basic-addon1'
                    >
                        <option value="null">Select</option>
                        <option value="Annual">Annual</option>
                        <option value="Bi-Annual">Bi-Annual</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Monthly">Monthly</option>
                    </Form.Select> */}
                    <div className="input-select-search" style={{ width: '200px' }}>
                        <span className='filter-control-lable'>Select report type</span>
                        <Select closeOnSelect={true}
                            ref={yearRef}
                            isSearchable={true}
                            options={annualValues}
                            onChange={(opt) => { handleFilter2change(opt.value) }}
                        ></Select>
                    </div>

                    {/* {filter2Value === "Bi-Annual" && <Form.Select
                        ref={selectFilter}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        onChange={handleByAnuualFilterchange}
                        aria-describedby='basic-addon1'
                    >
                        <option value="all">All</option>
                        <option value="H1">H1 [Jan - Jun]</option>
                        <option value="H2">H2 [Jul - Dec]</option>
                    </Form.Select>} */}
                    {filter2Value === "Bi-Annual" && <div className="input-select-search" style={{ width: '200px' }}>
                        <span className='filter-control-lable'>Select annual type</span>
                        <Select closeOnSelect={true}
                            ref={selectFilter}
                            isSearchable={true}
                            defaultValue={{ label: "All", value: 'all' }}
                            options={BiannualValues}
                            onChange={(opt) => { handleByAnuualFilterchange(opt.value) }}
                        ></Select>

                    </div>}

                    {/* {filter2Value === "Quarterly" && (<Form.Select
                        ref={selectFilter}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        onChange={handleQuarterlyChange}
                        aria-describedby='basic-addon1'
                    >
                        <option value="all">All</option>
                        <option value={1}>Q1 [Jan - Mar]</option>
                        <option value={2}>Q2 [Apr - Jun]</option>
                        <option value={3}>Q3 [Jul - Sep]</option>
                        <option value={4}>Q4 [Oct - Dec]</option>
                    </Form.Select>)} */}
                    {filter2Value === "Quarterly" && <div className="input-select-search" style={{ width: '200px' }}>
                        <span className='filter-control-lable'>Select quarter</span>
                        <Select closeOnSelect={true}
                            defaultValue={{ label: "All", value: 'all' }}
                            ref={selectFilter}
                            isSearchable={true}
                            options={quarterlyValues}
                            onChange={(opt) => { handleQuarterlyChange(opt.value) }}
                        ></Select>

                    </div>}


                    {/* {filter2Value === "Monthly" && <Form.Select
                        ref={selectFilter}
                        onChange={handleMonthlyFilterChange}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        aria-describedby='basic-addon1'
                    >
                        <option value="all">All</option>
                        <option value="January">Jan</option>
                        <option value="February">Feb</option>
                        <option value="March">Mar</option>
                        <option value="April">Apr</option>
                        <option value="May">May</option>
                        <option value="June">Jun</option>
                        <option value="July">Jul</option>
                        <option value="August">Aug</option>
                        <option value="September">Sep</option>
                        <option value="October">Oct</option>
                        <option value="November">Nov</option>
                        <option value="December">Dec</option>
                    </Form.Select>} */}
                    {filter2Value === "Monthly" && <div className="input-select-search" style={{ width: '200px' }}>
                        <span className='filter-control-lable'>Select month</span>
                        <Select closeOnSelect={true}
                            ref={selectFilter}
                            isSearchable={true}
                            defaultValue={{ label: "All", value: 'all' }}
                            options={monthlyValue}
                            onChange={(opt) => { handleMonthlyFilterChange(opt.value) }}
                        ></Select>
                    </div>}

                </div>
            </div>
        </div>

    )

}
export default Filter