import React, { useEffect, useState } from "react";
import Smstemplate from "./SMS/Smstemplate";
import { Form } from "react-bootstrap";
import plusbtn from "../../assets/images/plus.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import config from "../../config.json";
import LoadingBar from "../common/LoadingBar";
import { Tab, Tabs } from "@mui/material";
import Emailtemplate from "./Email/Emailtemplate";
function Template() {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const location: any = useLocation();
  const [tabs, setTabs] = useState<any>(
    Number(localStorage.getItem("Template_TAB")) || 0
  );
  const [categoryForDropdown, setCategoryDropdown] = useState<any>(null);
  const [categoryForEmailDropdown, setCategoryForEmailDropdown] =
    useState<any>(null);
  const fetchTemplates = async () => {
    let res = await axios.get(
      config.baseHost_backend + "/fetch-sms-templates",
      {
        headers: { Authorization: `${localStorage.getItem("$AuthToken")}` },
      }
    );
    return res?.data?.body;
  };

  const fetchEmailTemplates = async () => {
    let res = await axios.post(
      config.baseHost_backend + "/email-template",
      { type: "fetch" },
      {
        headers: { Authorization: `${localStorage.getItem("$AuthToken")}` },
      }
    );
    return res?.data?.body;
  };

  const { data, isLoading, refetch } = useQuery("templates", fetchTemplates);
  const {
    data: emailTemplates,
    isLoading: isEmailTemplateLoading,
    refetch: emailRefetch,
  } = useQuery("Emailtemplates", fetchEmailTemplates);

  useEffect(() => {
    if (location?.state?.dataChanged) {
      refetch();
      emailRefetch();
    }
  }, [location?.state?.dataChanged, refetch]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabs(newValue);
    localStorage.setItem("Template_TAB", newValue.toString());
  };

  return (
    <div className="">
      {<LoadingBar isActive={isLoading} />}
      <Tabs value={tabs} onChange={handleTabChange}>
        <Tab
          label="SMS"
          style={{
            backgroundColor: tabs === 0 ? "#343a4024" : "#ffff",
            color: "black",
          }}
        ></Tab>
        <Tab
          label="Email"
          style={{
            backgroundColor: tabs === 1 ? "#343a4024" : "#ffff",
            color: "black",
          }}
        ></Tab>
      </Tabs>
      {tabs === 0 && (
        <>
          <div className="d-flex justify-content-between p-3">
            <span className="mt-auto mb-auto">SMS Templates</span>
            <div>
              <button
                className="btn btn-sm pbuttonbgcolor mr-2"
                onClick={() => {
                  history.push({
                    pathname: "/template/sms/create",
                    state: { categories: categoryForDropdown },
                  });
                }}
              >
                <img src={plusbtn} alt="add template" /> Create template
              </button>
              <button
                className="btn btn-sm pbuttonbgcolor"
                onClick={() => {
                  history.push({
                    pathname: "/merge-field/create",
                    state: { categories: categoryForDropdown },
                  });
                }}
              >
                <img src={plusbtn} alt="add template" /> Create merge-field
              </button>
            </div>
          </div>
          <hr className="m-0 p-0" />
          <div className="p-3">
            <Smstemplate
              data={data && data}
              setCategoryDropdown={setCategoryDropdown}
              refetch={refetch}
              isLoading={isLoading}
            />
          </div>
        </>
      )}
      {tabs === 1 && (
        <>
          <>
            <div className="d-flex justify-content-between p-3">
              <span className="mt-auto mb-auto">Email Templates</span>
              <div>
                <button
                  className="btn btn-sm pbuttonbgcolor mr-2"
                  onClick={() => {
                    history.push({
                      pathname: "/template/email/create",
                      state: { emailCategories: categoryForEmailDropdown },
                    });
                  }}
                >
                  <img src={plusbtn} alt="add template" /> Create template
                </button>
                <button
                  className="btn btn-sm pbuttonbgcolor"
                  onClick={() => {
                    history.push({
                      pathname: "/merge-field/create",
                      state: { categories: categoryForDropdown },
                    });
                  }}
                >
                  <img src={plusbtn} alt="add template" /> Create merge-field
                </button>
              </div>
            </div>
            <hr className="m-0 p-0" />
            <div className="p-3">
              <Emailtemplate
                refetch={emailRefetch}
                data={emailTemplates}
                loading={isEmailTemplateLoading}
                setCategoryForEmailDropdown={setCategoryForEmailDropdown}
              />
            </div>
          </>
        </>
      )}
    </div>
  );
}

export default Template;
