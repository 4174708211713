import React, { useContext, useEffect, useRef, useState } from 'react'
import { Form, Image, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import leftArrow from '../../assets/images/leftArrow.svg';
import { UserContext } from '../common/UserContext';
import axios from 'axios';
import config from '../../config.json'
import { toastify } from '../common/notification';
import { merger_fields } from '../../controller';
import _ from 'lodash'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from '../common/Loader';
import LoadingBar from '../common/LoadingBar';
function Createtemplate() {
    const history = useHistory();
    const location: any = useLocation();
    const textArea = useRef<any>(null)
    const [messageBody, setMessageBody] = useState<any>("");
    const [mf,setMf]=useState<any>(null)
    const [loading, setLoading] = useState<any>(false);
    const [selectedTemplateCategory, setSelectedTemplateCategory] = useState<any>("");
    const [enteredTemplateCategory, setEnteredTemplateCategory] = useState<any>("");
    const [tempName, setTempName] = useState<any>("")
    const reactQuillref: any = useRef<any>(null);
    const { email } = useContext<any>(UserContext)
    let token: any = localStorage.getItem('$AuthToken');
    const handleBack = () => {
        history.push({
            pathname: '/template',
            state: {
                dataChanged: true
            }
        })
    }

    useEffect(() => {
        const fetchMergeFields = async () => {
            let body = {
                function: "fetchAll"
            }

            let resp = await axios.post(config.baseHost_backend + "/mergefield", body, {
                headers: {
                    Authorization: token
                },
            })
            // console.log("merge fields api", resp)
            setMf(resp?.data?.body)
        }
        fetchMergeFields();
 
    })

    const handleChange = (e) => {
        // setMessageBody((prev)=>prev+`{{${e.target.value}}}`)}
        if (e.target.value !== "null") {
            const selectedOption = e.target.selectedOptions[0];
            const objectString = selectedOption.getAttribute('data-object');
            const object = JSON.parse(objectString);
            const textarea = textArea.current;
            const { selectionStart, selectionEnd } = textarea;
            const fieldValue = `[${object?.value}]`;
            setMessageBody((prevValue) =>
                prevValue.slice(0, selectionStart) + fieldValue + prevValue.slice(selectionEnd)
            );
            textarea.focus();
            textarea.setSelectionRange(selectionStart + fieldValue.length, selectionStart + fieldValue.length);
        }
    }

    const handleSave = (e) => {
        e.preventDefault();

        let body={} as any;
        if(location?.state?.edit){
                body={
                    type: "update",
                    template_name: tempName,
                    content: messageBody,
                    _id:location?.state?.editData?._id
                }
        }else{
            body = {
                template_category: selectedTemplateCategory === "new" ? enteredTemplateCategory : selectedTemplateCategory,
                template_name: tempName,
                content: messageBody,
                createdBy: email,
            }
        }
      
        if ((!body.template_category || body.template_category === "null") && !location?.state?.edit) {
            return toastify('failure', "Template category cannot be empty")
        }

        if (!tempName) {
            return toastify('failure', "Template name cannot be empty")
        }
        if (!messageBody) {
            return toastify('failure', "Message body cannot be empty")
        }

        setLoading(true)
        axios.post(config.baseHost_backend + "/save-sms-template", body, {
            headers: {
                Authorization: token
            },
        }).then((res: any) => {
            if (res?.data?.statusCode === 200) {
                toastify('success',location?.state?.edit ? "SMS Template updated successfully." : "SMS Template created successfully.")
                setLoading(false)
                history.push({
                    pathname: "/template",
                    state: {
                        dataChanged: true
                    }
                })
            } else {
                setLoading(false)
                toastify('failure', "Unable to create the template.")
            }

        }).catch((err) => {
            setLoading(false)
            toastify('failure', `${err.message}`)
        })

    }

    useEffect(() => {
        if (location?.state?.edit) {
          console.log("location?.state", location?.state);
          setTempName(location?.state?.editData?.template_name);
          setMessageBody(location?.state?.editData?.content);
   
        }
      }, [location?.state]);

    return (
        <React.Fragment>
            <div className='p-3'>
                <Image
                    onClick={() => {
                        handleBack();
                    }}
                    className='pointer'
                    src={leftArrow}
                />
                <span className='ml-2'>{location?.state?.edit ? "Edit" : "Create"} SMS template</span>
            </div>
            <hr className='m-0 p-0' />
            {<LoadingBar isActive={loading} />}
            <div className='p-3'>
                <Form>
                    <Row>
                        {!location?.state?.edit && <Form.Group>
                            <Form.Label className='text'>{selectedTemplateCategory !== "new" ? "Select template category" : "Enter template category"}<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                            {selectedTemplateCategory !== "new" && <select name="" id="" className='form-select mt-auto mb-auto bg-light p-3' onChange={(e) => { setSelectedTemplateCategory(e.target.value) }}>
                                <option value="null">Select template category</option>
                                {_.uniq(location?.state?.categories)?.map((cat: any) => (
                                    <option value={cat}>{cat}</option>
                                ))}
                                <option value="new">ADD NEW CATEGORY</option>
                            </select>}
                            {selectedTemplateCategory === "new" && <input type="text" className='form-control p-3 bg-light' placeholder='Enter template category' onChange={(e) => { setEnteredTemplateCategory(e.target.value) }} />}
                        </Form.Group>}
                        <Form.Group className='mt-2'>
                            <Form.Label className='text'>Template name<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                            <input value={tempName} type="text" className='form-control p-3 bg-light' placeholder='Enter template name' onChange={(e) => { setTempName(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className='mt-4'>
                            {/* <div className='d-flex justify-content-between mb-1'> */}
                            {/* <Form.Label className='text mt-auto'>Message body<span className='text-danger h6 font-weight-bold'>*</span></Form.Label> */}
                            <select name="" id="" className='form-select mt-auto mb-auto w-25' onChange={handleChange} style={{ float: 'right' }}>
                                <option value="null">Select merge field</option>
                                {mf && mf?.sort((a, b) => a?.label?.toLocaleLowerCase() > b?.label?.toLocaleLowerCase() ? 1 : -1)?.map((mf) => (
                                    <option value={mf?.value} data-object={JSON.stringify(mf)}>{mf?.label}</option>
                                ))}
                            </select>
                            {/* </div> */}
                            <textarea ref={textArea} className='form-control bg-light p-2 m-0' rows={10} value={messageBody} onChange={(e) => { setMessageBody(e.target.value) }} />
                            {/* <div style={{ fontWeight: 'normal' }}>
                                <ReactQuill ref={reactQuillref} value={messageBody} onChange={setMessageBody} placeholder='Type here...' theme="snow" />
                            </div> */}
                        </Form.Group>
                        <Form.Group>
                            <button type='submit' className='btn btn-sm pbuttonbgcolor mt-3' onClick={handleSave}>{location?.state?.edit ? "Update template" : "Save template"}</button>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </React.Fragment >
    )
}

export default Createtemplate