/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Col, Row, Form, Button } from 'react-bootstrap';
import './Leads.css';
import { UserContext } from '../common/UserContext';
import NoDataFound from '../common/NoDataFound';
import { FilterLeads } from '../Filters/FilterLeads';
import { useQuery } from 'react-query';
import { leadFilterDetailsApi, leadListApi } from '../../controllers/leads';
import ReactPaginate from 'react-paginate';
import LoadingBar from '../common/LoadingBar';
import { Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import filter from '../../assets/images/filter.png'
import { accessRoles } from '../../controller';
import _ from 'lodash'
import Addlead from '../common/Addlead';
import Leadtable from './Leadtable';
import config from '../../config.json'
import axios from 'axios';
const Leads = ({ setUser }: any) => {
    const [custPage, setCustPage] = React.useState<any>("");
    const [page, setPage] = React.useState<any>(Number(localStorage.getItem("CRM_PAGE")) || 1);
    // const [page, setPage] = React.useState<any>(Number(custPage) || 1);
    
    const [toggle, setToggle] = React.useState<any>('lead');
    const [limit] = React.useState(10);
    const [reset, setReset] = useState<any>(true)
    const [QualificationStatus, setQualificationStatus] = useState<any>([])
    // const [sort, setSort] = useState<any>("null")
    const [filterQuery, setFilterQuery] = React.useState<any>();
    const { authToken, type, dealerID, corporateID, role } = useContext(UserContext);
    console.log("vv",typeof(localStorage.getItem("CRM_PAGE")));
    

    const [userList, setUserList] = React.useState([]);
    const [dealerList, setDealerList] = React.useState<any>([]);
    const [dealerListForTable, setDealerListFoeTable] = React.useState<any>([]);
    const [leadStatus, setLeadStatus] = React.useState<any>([]);
    const [opportunitiesStatus, setOpportunitiesStatus] = React.useState<any>([]);
    const history = useHistory();
    const [loading, setLoading] = React.useState<any>(false)
    const [rowPerPage, setRowperPage] = React.useState(10);
    const [show, setShow] = useState(false)
    const [resetForm, setResetForm] = React.useState<boolean>(false)
    const [dataChanged, setDataChanged] = useState<any>(null)
    // const [headerList, setHeaderList] = useState<any>(accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowSeeLastContactedDatetime") ? ["Count", "Name", "Dealership", "Email", "Mobile", "Status", "Owner", "Last contacted date", "Lead processed date"] : ["Count", "Name", "Dealership", "Email", "Mobile", "Status", "Owner", "Message sent date", "Customer response date", "Lead processed date"])
    const [headerList, setHeaderList] = useState<any>([])
    const [verHeaderList, setVerHeaderList] = useState<any>()

    const curr_table_head=localStorage.getItem("CRM_TABLE_COLUMN");
    const sortType=localStorage.getItem("CRM_SORT");
    const [MSDSort, setMSDSort] = useState<any>(curr_table_head==="msgsentdate" ? sortType : null);
    const [CRDsort, setCRDSort] = useState<any>(curr_table_head==="custrespdate" ? sortType : null);
    const [LCDsort, setLCDsort] = useState<any>(curr_table_head==="lastcontacteddate" ? sortType : null);
    const [LPDsort, setLPDsort] = useState<any>(curr_table_head==="leadprocessed" ? sortType : null);
    //API for setting dropdown values
    const { data: filterDetails, isLoading: isFilterDetailsLoading } = useQuery(
        'filterDetails',
        () => leadFilterDetailsApi(authToken, type, dealerID || corporateID, true),
    );

    useEffect(() => {
        let tmp = ["Count", "Name", "Dealership", "Email", "Mobile", "Status"];

        if (accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowSeeLastContactedDatetime")) {
            tmp.push("Last contacted date")
        }
        if (accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowSeeMesgSentDate")) {
            tmp.push("Message sent date")
        }
        if (accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowSeeCustomerResponseDate")) {
            tmp.push("Customer response date")
        }
        if (accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowSeeLeadProcessedDate")) {
            tmp.push("Lead processed date")
        }
        setHeaderList(tmp);
        setVerHeaderList(tmp)
    }, [])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        localStorage.removeItem("filterQuery")
        localStorage.removeItem("toggle")
        localStorage.removeItem('sr')
        localStorage.removeItem('CRDsort')
        localStorage.removeItem('LCDsort')
        localStorage.removeItem('LPDsort')
        localStorage.removeItem("LEAD_PAGE_URL")

    }, [])

    localStorage.removeItem("TIMER")
    useEffect(() => {
        if (filterDetails) {
            console.log("filterDetails",filterDetails);
            
            const userList = filterDetails.body.user_detail.map(
                (user: { _id: string; first_name: string; last_name: string }) => ({
                    key: user._id,
                    value: user.first_name + ' ' + user.last_name,
                })
            );

            const dealerList = filterDetails.body.dealer.map(
                (dealer: { dealer_id: string; dealer_name: string }) => ({
                    key: dealer.dealer_id,
                    value: dealer.dealer_name,
                })
            );

            setLeadStatus(filterDetails?.body?.status);
            console.log("org status", filterDetails.body.status);
            let Qarray = [] as any;
            filterDetails?.body?.status.filter((s) => s.label === "Opportunities")[0]?.options?.forEach((df) => {
                Qarray.push(df.value)
            });
            console.log("Qarray",Qarray);
            
            setQualificationStatus(Qarray)
            setUserList(userList);
            setDealerListFoeTable(dealerList)
            setDealerList(type === "dealer" ? [{ "test": 123, "test1": 356 }] : dealerList);
            // setOpportunitiesStatus(opportunitiesStatusList);
        }
    }, [filterDetails, toggle]);

    useEffect(() => {
        setDataChanged("changed")
    })

    let initialLoad = {
        status: ["All"],
        reset: true,
    }

    //API hits when first load and filters applied
    let loc_d: any = localStorage.getItem("FILTERS_OBJ")
    let parsed: any = JSON.parse(loc_d)

    //new api
    const fetchLeads = async (fnType) => {
        let resp = await axios.post(config.baseHost_backend + "/fetch-all-leads", {
            pages_to_skip: page - 1,
            limit_per_page: limit,
            ...parsed || initialLoad,
            type: type,
            functionType: fnType,
            ID: dealerID || corporateID,
            sort: MSDSort,
            CRDsort,
            LCDsort,
            LPDsort
        }, {
            headers: {
                Authorization: authToken
            }
        });
        return resp
    }

    const s3upload = async (fnType) => {
        let resp = await axios.post(config.baseHost_backend + "/download-file", {
            pages_to_skip: page - 1,
            limit_per_page: limit,
            ...parsed || initialLoad,
            type: type,
            functionType: fnType,
            ID: dealerID || corporateID,
            sort: MSDSort,
            CRDsort,
            LCDsort,
            LPDsort
        }, {
            headers: {
                Authorization: authToken
            }
        });
        return resp
    }

    const { data: allleads, isLoading: isLeadLoading, isRefetching, isFetching, } = useQuery([`fetch-leads`,page, reset, CRDsort, LCDsort, MSDSort,LPDsort, parsed], () => fetchLeads(null))
    const { data: leadCount, isLoading:isLeadCountLoading } = useQuery(['fetch-leads-count', parsed, page], () => fetchLeads("getCount"))
    const { data: csvUploaddata } = useQuery(['csv-upload', parsed, page], () => s3upload("leadUpload"))

    useEffect(() => {
        if (localStorage.getItem('TOGGLE')) {
            localStorage.getItem('TOGGLE') === "opportunities" ? setToggle('opportunities') : setToggle('lead');
        }
    }, [localStorage.getItem('TOGGLE')])

    const handlePageClick = (Selected: any) => {
        // setPageNumber(Selected.selected)
        setPage(Selected.selected + 1);
        localStorage.setItem("CRM_PAGE", Selected.selected + 1)
    }

    const headers = verHeaderList

    const handleChange = (event: SelectChangeEvent<typeof headerList>) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setHeaderList(headerList.length === headers.length ? ["Count", "Name", "Dealership"] : headers);
            return;
        }
        setHeaderList(value);
    };

    // useEffect(()=>{
    //    custPage && setPage(Number(custPage))
    // },[custPage])




    const isAllSelected =
        headers?.length > 0 && headerList?.length === headers?.length;

    const handleModelclose = () => {
        setShow(false)
    }

    console.log("Page", page);
    
    return (
        <div className='containerBox'>
            {
                <>
                    <div className='pad-button card-title ml-4 mt-3 mb-0 d-flex justify-content-between'>
                        <span>Qualification & Opportunities</span>
                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("createDemoLead") && <button className='btn btn-sm pbuttonbgcolor mr-4' onClick={() => { setShow(true) }}>Add lead</button>}
                    </div>
                    {isFilterDetailsLoading ? <LoadingBar isActive={true} /> :
                        <Row className='spacing-1'>
                            <FilterLeads
                                resetForm={resetForm}
                                setResetForm={setResetForm}
                                setPage={setPage}
                                setReset={setReset}
                                // setReset={setReset}
                                setUser={setUser}
                                setLoading={setLoading}
                                dealerList={dealerList && dealerList}
                                userList={userList && userList}
                                statusList={toggle === 'lead' ? leadStatus : opportunitiesStatus}
                                filterLeads={setFilterQuery}
                                toggle={toggle}
                                filterQuery={filterQuery}
                                totalLeads={leadCount?.data?.body?.config?.totalLeads || 0}
                                reset={reset}
                                csvExportPayload={parsed || initialLoad}
                                QualificationStatus={QualificationStatus}
                            // resetFilters={resetFilters}
                            />
                        </Row>}
                    {/* <hr className='divider-line1' /> */}
                    <Row className='spacing-1'>
                        {
                            !parsed ? <div className='bg-white py-4'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <img src={filter} alt="Filter" height={170} className='ml-auto mr-auto' />
                                    <h2 className='spacing-1' style={{ fontWeight: 'bold' }}>Please select the filter to view the data.</h2>
                                </div>
                            </div> :
                                !(isLeadLoading && isLeadCountLoading) && !allleads?.data?.body?.length ? (
                                    <NoDataFound height={""} width={""} message1='No results Found' message2='Try different filters or reset the filters' img="" />
                                ) : (
                                    <div className='lead-container-1'>
                                        <div className='d-flex flex-row '>
                                            <div className='mt-auto mb-auto'>
                                                <span style={{ fontWeight: 'lighter' }}>Total count <span style={{ fontWeight: 'bold' }}>{leadCount?.data?.body?.config?.totalLeads || 0}</span></span>
                                            </div>
                                            <div className='header-selecter mt-auto mb-auto'>
                                                <Select
                                                    size="small"
                                                    // labelId="demo-multiple-checkbox-label"
                                                    style={{ backgroundColor: 'white' }}
                                                    input={<OutlinedInput label="Tag" />}
                                                    className="w-100 mb-2"
                                                    value={headerList && headerList}
                                                    multiple

                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                    renderValue={(selected) => "Select header"}
                                                >
                                                    <MenuItem value="all">
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                checked={isAllSelected}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Select All"
                                                        />
                                                    </MenuItem>
                                                    {verHeaderList?.map((hl, indx) => (

                                                        <MenuItem key={indx} value={hl} disabled={hl === "Count" || hl === "Name" || hl === "Dealership"} >
                                                            <Checkbox disabled={hl === "Count" || hl === "Name" || hl === "Dealership"} checked={headerList.indexOf(hl) > -1} />
                                                            <ListItemText primary={hl} />
                                                        </MenuItem>


                                                    ))
                                                    }
                                                </Select>
                                            </div>
                                        </div>

                                        <Leadtable
                                            data={allleads?.data?.body && allleads?.data?.body}
                                            page={page}
                                            headerList={headerList}
                                            setMSDSort={setMSDSort}
                                            setCRDSort={setCRDSort}
                                            setLCDsort={setLCDsort}
                                            setLPDsort={setLPDsort}
                                            CRDsort={CRDsort}
                                            LCDsort={LCDsort}
                                            MSDSort={MSDSort}
                                            LPDsort={LPDsort}
                                            QualificationStatus={QualificationStatus}
                                            leadStatus={leadStatus}
                                        />
                                    </div>

                                )}
                        {leadCount?.data?.body?.config?.totalLeads && parsed ?
                            <>
                                <Col style={{ float: "right", width: 'auto' }} lg={5} >
                                    <ReactPaginate
                                        nextLabel=">>"
                                        onPageChange={handlePageClick}
                                        pageCount={leadCount?.data?.body?.config?.totalLeads ? leadCount?.data?.body?.config?.totalLeads / limit : 0}
                                        previousLabel="<<"
                                        containerClassName='pagination'
                                        pageClassName={'pagination'}
                                        // pageClassName='page-item'
                                        pageLinkClassName='page-link'
                                        previousClassName='page-item'
                                        previousLinkClassName='page-link'
                                        nextClassName='page-item'
                                        nextLinkClassName='page-link'
                                        breakLabel='...'
                                        breakLinkClassName='page-link'
                                        activeClassName='active'
                                        breakClassName='page-item'
                                        forcePage={page - 1}
                                    />

                                </Col>
                                <Col className=''>
                                    <div className='d-flex'>
                                        <input type="number" name="" id="" value={custPage} style={{ width: '60px' }} className="p-1 form-control cust-page-input bg-light" placeholder='##' onChange={(e) => { setCustPage(e.target.value) }} />
                                        <button className='cust-page-btn pbuttonbgcolor btn btn-sm' onClick={(e) => { custPage && setPage(Number(custPage)); setCustPage("");localStorage.setItem("CRM_PAGE",custPage) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg></button>
                                    </div>
                                </Col>
                            </>: ""}
                            
                    </Row>
                    <Addlead
                        show={show}
                        handlelose={handleModelclose}
                        // handleAdd={handleAdd}
                        dealerList={dealerList && dealerList}
                        statusList={leadStatus}
                    />
                    {parsed && (isLeadLoading && isLeadCountLoading) ? <LoadingBar isActive={true} /> : ""}
                </>
            }
        </div>
    );
};
export default Leads;
