import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router";
import { useMutation, useQuery } from "react-query";
import {
  Col,
  Row,
  Modal,
  Card,
  Form,
  Button,
  Table,
  Tab,
  Nav,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import dailpad from "../../assets/images/dialpad3.png";
import { UserContext } from "../common/UserContext";
import {
  leadDetailsApi,
  leadFilterDetailsApi,
  leadUpdateApi,
} from "../../controllers/leads";
import { dateformater, timeformater } from "../common/dateFormat";
import addbtn from "../../assets/images/plus.svg";
import Loader from "../common/Loader";
import { toastify } from "../common/notification";
import AdditionalLeadInfo from "./additionalLeadInfo";
import plus from "../../assets/images/plus.svg";
import plusBlack from "../../assets/images/plusblack.svg";
import LeadPersonalDetails, { Dealer } from "./leadPersonalDetails";
import { dealerListApi } from "../../controllers/dealers";
import leftArrow from "../../assets/images/leftArrow.svg";
import phoneicon from "../../assets/images/phoneicon.png";
import active from "../../assets/images/circle.svg";
import Appointment from "./Appointment";
import notes from "../../assets/images/notes.png";
import { Grid, Menu } from "@mui/material";
import { leadStage } from "../common/globalValues";
import moment from "moment";
import Sms from "./SMS/Sms";
import Calllist from "../common/Calllist";
import Dialpad from "./Call/Dialpad";
import DealerInfo from "../Dealer/dealerInfo";
import states from "../common/states";
import Outlookogin from "../Mails/Outlookogin";
import Warningmodal from "../common/Warningmodal";
import NoDataFound from "../common/NoDataFound";
import axios from "axios";
import config from "../../config.json";
import LoadingBar from "../common/LoadingBar";
import Reusabletabs from "../common/Reusabletabs";
import Notes from "../common/Notes";
import Createtaskmodal from "../common/Createtaskmodal";
import Notessearch from "../common/Notessearch";
import Tasks from "../common/Tasks";
import { accessRoles } from "../../controller";
import UnsubscribeIcon from '@mui/icons-material/MarkAsUnread';
import { deleteNotification } from "../common/Notificationpopup"

const LeadDetails = () => {
  const { id }: any = useParams();
  const {
    authToken,
    id: currentUserId,
    role,
    type,
    dealerID,
    corporateID,
    email,
    user_first_name,
    user_last_name,
  } = useContext(UserContext);
  const { state }: any = useLocation();
  console.log("state", state);

  const location: any = useLocation();
  // const [open, setOpen] = useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = React.useState<any>(1);
  const [lead, setLead] = useState<any>(null);
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false);
  const [showSecondaryNoModal, setShowSecondaryNoModal] = useState<any>(false);
  const [showWarning, setShowWarning] = useState<any>(false);
  const [warningHeading, setWarningHeading] = useState<any>("");
  const [warningBody, setWarningBody] = useState<any>("");
  const [actionbtnLabel, setActionBtnLabel] = useState<any>("");
  const [idlebtnLabel, setIdleBtnLabel] = useState<any>("");
  const [showNoTwilionumber, setShowNoTwilioNumber] = useState(false);
  const [datachanged, setDataChanged] = useState(false);
  const [pastDate, setPastDate] = useState<boolean>(false);
  const [selectedPastDate, setSelectedPastDate] = useState<any>("");
  const [noteSearchVal, setNoteSearchVal] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [dealer, setDealer] = useState<Dealer | undefined>();
  const [openNote, setOpenNote] = useState<boolean>(false);
  const [isLeadLoading, setIsLeadLoading] = useState<boolean>(true);
  const [isDealerLoading, setIsDealerLoading] = useState<boolean>(true);
  const [noteType, setNoteType] = useState<any>(null);
  const [isFilterDetailsLoading, setIsFilterDetailsLoading] =
    useState<boolean>(true);
  const [note, setNote] = useState<string>("");
  const [statuses, setStatuses] = useState<any>([]);
  const [dealerDetails, setDealerDetails] = React.useState<any>(null);
  const [callLogs, setCallLogs] = React.useState<any>(null);
  const history = useHistory();
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [secNo, setSecNo] = React.useState<any>("");
  const [action, setAction] = useState<any>(null);
  const [defaultActiveKey, setDefaultActiveKey] = useState<any>("second");
  const [changed, setchanged] = useState<any>(null);
  const [longAddress, setLongAddress] = useState<any>([]);
  const [smstemplate, setSmsTemplate] = useState<any>(null);
  const [dialerAlreadyVisited, setDialerAlreadyVisited] = useState(false);
  const [leadDetails, setleadDetails] = useState<any>(null);
  let initialTime = { hours: 0, minutes: 0, seconds: 0 };
  let savedTime: any = localStorage.getItem("TIMER");
  let storageTime = JSON.parse(savedTime);
  const [time, setTime] = useState(storageTime || initialTime);
  const [isActive, setIsActive] = useState(true);
  const [stopTimer, setStopTimer] = useState(false);
  const [notes1, setNotes] = useState<any>(null);
  const [NotiList, setNotiList] = useState<string[]>([])

  const fetchDataa = () => {
    leadDetailsApi(authToken, id)
      .then((res) => {
        console.log("res", res);

        setLead(res.body.data);
        setNotes(res?.body?.data?.notes?.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)));
        setleadDetails(res);
        // refetchDealer();
        // refetchFilters();
        setIsLeadLoading(false);
      })
      .catch((err) => { });
  };

  const fetchSmsNotifications = async () => {
    let body = {
      limit_per_page: 10,
      pages_to_skip: page - 1,
    };
    let url: any = config.baseHost_backend;

    let resp = await axios.post(url + "/notification/unread-messages",
      body,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    console.log("list of sms notifications ", resp?.data, resp?.data)

    let sms_leads = resp.data?.body?.unReadMessagesLeads.map((leadInfoNoti) => leadInfoNoti?.lead_id)

    // let sms_lead = resp?.data?.body?.unReadMessagesLeads ? ((resp.data?.body?.unReadMessagesLeads.filter( (onelead) => onelead.lead_id === lead?._id).length > 0 ) ? true : false) : false
    // console.log("unreadlead",resp?.data?.body?.unReadMessagesLeads)
    // console.log("lead id" )
    // console.log("lead is in notification", sms_lead)
    // setShowDeleteIcon(sms_lead)
    // setNotiList(resp?.data)


    let pricingResp = await axios.post(url + "/notification/pricing",
      body,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    console.log("list of price notifications ", pricingResp?.data?.body?.pricingLeads.length, pricingResp?.data)
    let price_leads = pricingResp.data?.body?.pricingLeads.length > 0 ? pricingResp.data?.body?.pricingLeads.map((leadInfoNoti) => leadInfoNoti?.lead_id) : []
    console.log("noti lead ids", [...sms_leads, ...price_leads])
    setNotiList([...sms_leads, ...price_leads])
    // return resp?.data;
  };

  const handleClick = (event: any) => {
    if (!dealerDetails?.twilioPhone) {
      setWarningBody(
        "Twilio phone number is not mapped for this dealer. Please map it to send SMS.."
      );
      setWarningHeading("Twilio phone number is not mapped!");
      setIdleBtnLabel("OK");
      setShowWarning(true);
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setDataChanged(true);
    setAnchorEl(null);

    setTimeout(() => {
      refetchallhistory();
    }, 3000);
  };

  useEffect(() => {
    setDataChanged(false);
  }, []);

  useEffect(() => {
    fetchSmsNotifications();

    setInterval(fetchSmsNotifications, 7000);
  }, []);

  useEffect(() => {
    fetchDataa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refetch() {
    fetchDataa();
  }

  useEffect(() => {
    setchanged("changed");
  }, [changed]);

  React.useEffect(() => {
    if (isActive) {
      console.log("ABK");

      setTimeout(() => {
        const newSeconds = time.seconds + 1;
        const newMinutes = time.minutes + Math.floor(newSeconds / 60);
        const newHours = time.hours + Math.floor(newMinutes / 60);
        setTime({
          hours: newHours,
          minutes: newMinutes % 60,
          seconds: newSeconds % 60,
        });
        localStorage.setItem("TIMER", JSON.stringify(time));
      }, 1000);
    }
  }, [isActive, time, time.hours, time.minutes, time.seconds]);

  // useEffect(() => {
  //   if (state?.lead) {
  //     //if it's lead
  //     setStatuses([
  //       { label: "Not Interested", value: "customer_declined" },
  //       { label: "Dropped", value: "dropped" },
  //     ]);
  //   } else {
  //     //qualification
  //     setStatuses([
  //       { label: "Buyback", value: "buyBack" },
  //       { label: "Buyback & Sold", value: "buyBackAndSold" },
  //       { label: "Dropped", value: "dropped" },
  //     ]);
  //   }
  // }, [state?.lead]);

  useEffect(() => {
    setStatuses([
      { label: "Buyback", value: "buyBack" },
      { label: "Buyback & Sold", value: "buyBackAndSold" },
      { label: "Showed", value: "showed" },
      { label: "No show", value: "no_show" },
      { label: "Sold", value: "sold" },
      // { label: "Appointment", value: "appointment" },
      // { label: "Appointment Cancelled", value: "appointment_cancelled" },
      { label: "Not Interested", value: "customer_declined" },
      { label: "Customer Stop", value: "customer_rejected" },
      // { label: "No response", value: "bot_msg_sent" },
      // { label: "Bot working", value: "bot_working" },
      // { label: "Filtered Leads", value: "Filtered_Leads" },
      // { label: "Bot Service Protection", value: "bot_service_protection" },
      { label: "Service Appointment Sent", value: "service_appointment" },
      // {
      //   label: "Service Appointment Filtered Leads",
      //   value: "service_appointment_filtered_leads",
      // },
      { label: "Interested", value: "new" },
      { label: "Service Interested", value: "Service_Interested" },
      {
        label: "Service Appointment Interested",
        value: "service_appointment_interested",
      },
      { label: "Working/Follow-up", value: "working_followup" },
    ]);
  }, [state?.lead]);

  //called when back button is clicked
  window.onpopstate = function (event) {
    event.preventDefault();
    setIsActive(false);
    // This function will be called when the browser's back button is clicked
    // You can call your desired function here
    console.log("Back button clicked!", lead, dealerDetails);

    let payload = {
      API_TYPE: "UPDATE_CRM_TIMER",
      id: currentUserId,
      leadID: lead?._id,
      leadDetails: lead?.customer_details,
      dealerID: lead?.dealer_id,
      dealerName: lead?.dealer_name,
      leadStatus: lead?.status,
      timezone: lead?.timezone || dealerDetails?.timezone,
      timer: time,
    };

    axios
      .put(`${config.baseHost_backend}/update-user`, payload, {
        headers: {
          Authorization: data.authToken,
        },
      })
      .then((res) => {
        console.log("Timer resp", res);
      })
      .catch(() => {
        console.log("error");
      });
  };

  useEffect(() => {
    if (leadDetails) {
      setLead(leadDetails?.body?.data);
      setDealerDetails(leadDetails?.body?.dealerDetails);
      // setIsLeadLoading(false);
      setSmsTemplate(leadDetails?.body?.smsTemplates);
      // console.log("bbb", leadDetails?.body?.data.messages);
      // let sample = leadDetails?.body?.data.messages
      // console.log("sample", sample.sort((v, n) => new Date(v.time) > new Date(n.time) ? -1 : 1));

      leadDetails?.body?.data?.messages?.reverse(); //Reversing the Text message array
      leadDetails?.body?.data?.status_history?.reverse(); //Reversing the status_history array
      leadDetails?.body?.data?.notes?.reverse(); //Reversing the notes array
      // console.log("state check",state?.QualificationStatus.includes(leadDetails?.body?.data?.status));
    }
  }, [leadDetails]);

  // const {
  //     data: filterDetailsData,
  //     isError: isFilterDetailsError,
  //     refetch: refetchFilters,
  // } = useQuery('filterDetails' + id, () => leadFilterDetailsApi(authToken, type, dealerID || corporateID, state?.lead
  // ), {
  //     staleTime: 3600000,
  // });

  // const { refetch: refetchDealer, data: dealersData } = useQuery(
  //     'dealers',
  //     () => dealerListApi(authToken),
  //     {
  //         staleTime: 3600000,
  //         enabled: !!leadDetails,
  //     },

  // );
  useEffect(() => {
    // refetch()
    if (action === "Appointment") {
      if (!dealerDetails?.workingHours) {
        setShowWarning(true);
        setIdleBtnLabel("OK");
        setWarningHeading("Working hour is not assigned");
        setWarningBody(
          "Working hours is not assigned for this dealer. please assign it first before booking the appointment."
        );
      }
    }
  }, [action, dealerDetails]);

  const leadMutation = useMutation(
    (data: any) => leadUpdateApi(authToken, data),
    {
      onSuccess: (res) => {
        console.log("res", res);
        setShowWarning(false);
        setIdleBtnLabel("");
        setActionBtnLabel("");
        toastify("success", res?.body?.message);
        refetch();
        setShowSecondaryNoModal(false);
        setDefaultActiveKey("third");
      },
    }
  );

  let body = {
    leadID: id,
    limit_per_page: 10,
    pages_to_skip: page - 1,
  };
  const fetchLogs = async () => {
    let resp = await axios.post(
      `${config.baseHost_backend}/twilio/call-logs`,
      body,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    return resp?.data?.body;
  };

  const {
    data,
    isLoading: isFetchallHistoryLoading,
    refetch: refetchallhistory,
  } = useQuery(
    [`fetch-call-logs${history.location.pathname.split("/")[1]}`, page],
    fetchLogs
  );

  // useEffect(() => {
  //     if (dealersData && leadDetails) {
  //         const _dealer_details = dealersData?.body?.data?.find((dealerr: any) => {
  //             return dealerr.dealer_id === leadDetails.body.data.dealer_id;
  //         });
  //         setDealer(_dealer_details);
  //         setIsDealerLoading(false);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dealersData, leadDetails]);

  // useEffect( () => {

  // },[]);

  useEffect(() => {
    let street_1 = dealerDetails?.street_1;
    let street_2 = dealerDetails?.street_2;
    let area_sector = dealerDetails?.area_sector;
    let state = states?.filter(
      (a) =>
        a.name.toLocaleLowerCase() === dealerDetails?.state?.toLocaleLowerCase()
    )[0]?.abbreviation;
    let pincode = dealerDetails?.pincode;

    let arr = [street_1, street_2, area_sector, state, pincode];
    setLongAddress(arr?.filter((a) => a));
    console.log("longaa", arr?.filter((a) => a).values());

  }, [dealerDetails]);

  const handleNotesSearch = (e) => {
    setNoteSearchVal(e.target.value);
  };

  useEffect(() => {
    if (!noteSearchVal) {
      setNotes(lead?.notes);
    } else {
      setNotes(lead.notes.filter((a) => a.note.includes(noteSearchVal)));
    }
  }, [noteSearchVal]);
  console.log("history", history);


  return (
    <>
      {/* <LoadingBar isActive={loading} /> */}
      <div className="containerBox">
        {isLeadLoading ? (
          <Loader />
        ) : (
          <>
            <Row className="spacing-1">
              <Col className="button-col d-flex justify-content-between">
                <div className="spacing-1-1">
                  {/* <div>
                    <Image
                      onClick={() => {
                        history.goBack();
                      }}
                      className="pointer"
                      src={leftArrow}
                    />
                  </div> */}
                  <div className="pad-button text-name">Lead Details</div>
                </div>
                <div className="d-flex gap-3">
                  <div className="text-dark rounded mt-auto mb-auto">
                    {time.hours.toString().padStart(2, "0")}:
                    {time.minutes.toString().padStart(2, "0")}:
                    {time.seconds.toString().padStart(2, "0")}{" "}
                    <span style={{ fontWeight: "normal" }}>Hrs</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      onClick={() => {
                        setShowTaskModal(true);
                      }}
                      className="btn tbuttonbgcolor btn-sm"
                    >
                      Create Task
                    </button>


                  </div>
                  <div>
                    <div
                      style={{ position: 'relative' }}
                      className="notf-icon-top"
                    >
                      <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Tooltip>
                            <div>unread notification</div>
                          </Tooltip>
                        }
                      >
                        <button style={{ border: "none", backgroundColor: "#f3f6f9" }} disabled={!(NotiList.length && NotiList.includes(lead._id))} onClick={() => {
                          deleteNotification(lead?._id)
                        }}>
                          <UnsubscribeIcon sx={{ fontSize: 40, marginLeft: "0px" }} />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>

                </div>

              </Col>
            </Row>

            <hr className="divider-line" style={{ margin: "0px" }} />

            {/* <Row className='spacing-1'> */}
            <div className="form-container">
              <Grid container className="mb-3" rowGap={{ lg: 3 }}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">Name</h3>
                  {lead && (
                    <p>
                      {lead.customer_details.first_name}{" "}
                      {lead.customer_details.last_name}
                    </p>
                  )}
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <div title={lead?.customer_details?.email}>
                    <h3 className="mb-0">Email</h3>
                    {lead && (
                      <p>
                        {!lead?.customer_details?.email
                          ? "N/A"
                          : lead?.customer_details?.email?.length >= 27
                            ? lead?.customer_details?.email
                              ?.substring(0, 27)
                              .concat("...")
                            : lead?.customer_details?.email}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">Phone</h3>
                  {lead && (
                    <div
                      className="d-flex flex-row gap-1"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className="mt-auto mb-auto"
                        src={phoneicon}
                        alt="Dailpad"
                        height={17}
                      />
                      {lead && (
                        <>
                          <p onClick={handleClick} className="mt-auto mb-auto">
                            {lead?.customer_details?.mobile_number || "N/A"}
                          </p>
                        </>
                      )}
                    </div>
                  )}
                  {open && (
                    <Dialpad
                      lead={lead && lead}
                      dealerDetails={dealerDetails}
                      handleClose={handleClose}
                      setOpen={() => {
                        setAnchorEl(null);
                      }}
                      dialerAlreadyVisited={dialerAlreadyVisited}
                      setDialerAlreadyVisited={setDialerAlreadyVisited}
                      showCloseBtn={true}
                    />
                  )}
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">Secondary phone</h3>
                  <p>
                    {(lead && lead?.customer_details?.secondary_number) || (
                      <button
                        title="Add secondary phone number"
                        className="btn btn-sm pbuttonbgcolor"
                        onClick={() => {
                          setShowSecondaryNoModal(true);
                        }}
                      >
                        <img src={plus} alt="plus" width={10} /> Add
                      </button>
                    )}
                  </p>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">Dealer Id</h3>
                  <p>{lead && lead.dealer_id}</p>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">Dealer Name</h3>
                  <p>
                    {(lead && lead?.dealer_name) || dealerDetails?.dealer_name}
                  </p>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">Dealer address</h3>
                  {!longAddress.length ? (
                    <p>N/A</p>
                  ) : (
                    <p> {longAddress?.map((add) => add + " ")}</p>
                  )}
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">RO Open Date</h3>
                  {lead && (
                    <p>
                      {lead?.ro_open_date === "-"
                        ? "N/A"
                        : moment(lead?.ro_open_date).format("LLL") +
                        "(" +
                        dealerDetails?.timezone +
                        ")"}
                    </p>
                  )}
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">RO Close Date</h3>
                  {lead && (
                    <p>
                      {lead?.ro_close_date === "-"
                        ? "N/A"
                        : moment(lead?.ro_close_date).format("LLL") +
                        "(" +
                        dealerDetails?.timezone +
                        ")"}
                    </p>
                  )}
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <h3 className="mb-0">Status</h3>
                  <Form.Select
                    value={lead?.status}
                    onChange={(event: any) => {
                      setShowWarning(true);
                      setWarningBody(
                        "Are you sure you want to update the status?"
                      );
                      setWarningHeading("Update status");
                      setIdleBtnLabel("Cancel");
                      setActionBtnLabel("Update");
                      setSelectedStatus(event.target.value);
                      // leadMutation.mutateAsync({
                      //     id,
                      //     status: event.target.value,

                      // });
                    }}
                    className="mb-3 select-field w-75"
                  >
                    {/* {console.log(
                      "hhh",
                      statuses?.filter((a) => a.value === lead.status)[0]?.label
                    )} */}

                    <option value={lead?.status} key={lead?.status}>
                      {leadStage[lead?.status]}
                    </option>
                    {statuses
                      .filter((s) => s.value !== lead.status)
                      ?.sort((a, b) =>
                        a?.value?.toLocaleLowerCase() >
                          b?.value?.toLocaleLowerCase()
                          ? 1
                          : -1
                      )
                      .map((obj, indx) => (
                        <option
                          value={obj.value}
                          key={indx}
                          className="capitialize"
                        >
                          {obj.label}
                        </option>
                      ))}
                  </Form.Select>
                </Grid>
                {role === "rep_internal" ? (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <h3 className="mb-0">Owner</h3>
                    <Form.Select
                      value={lead.owner}
                      onChange={(event: any) => {
                        leadMutation.mutate({
                          id,
                          owner: event.target.value,
                        });
                      }}
                      disabled={lead.owner !== ""}
                      className="mb-3 select-field w-75"
                    >
                      {
                        lead.owner === "" ? (
                          <>
                            <option key={"unassigned"} value={""}>
                              Unassigned
                            </option>
                            <option key="self" value={currentUserId}>
                              self
                            </option>
                          </>
                        ) : (
                          <></>
                        )
                        // (
                        //     userList?.map((user: any) => (
                        //         <option value={user.key} key={user.key}>
                        //             {user.value}
                        //         </option>
                        //     ))
                        // )
                      }
                    </Form.Select>
                  </Grid>
                ) : (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <h3 className="mb-0">Owner</h3>
                    <Form.Select
                      value={lead.owner}
                      onChange={(event: any) => {
                        leadMutation.mutate({
                          id,
                          owner: event.target.value,
                        });
                      }}
                      disabled={role === "rep_internal" && lead.owner !== ""}
                      className="mb-3 select-field w-75"
                    >
                      <option key={"unassigned"} value={""}>
                        Unassigned
                      </option>
                      <option key="self" value={currentUserId}>
                        self
                      </option>
                      {userList?.map((user: any) => (
                        <option value={user.key} key={user.key}>
                          {user.value}
                        </option>
                      ))}
                    </Form.Select>
                  </Grid>
                )}
              </Grid>
              <hr className="divider-line" />
              {/* <Row> */}

              <Tab.Container
                transition={false}
                defaultActiveKey={defaultActiveKey}
              >
                <Grid container>
                  <Grid item lg={2} md={2} sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item
                        onClick={() => {
                          setAction("Additionalinfo");
                        }}
                      >
                        <Nav.Link eventKey="second" className="pointer">
                          Additional Info
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setAction("dealershipInfo");
                        }}
                      >
                        <Nav.Link eventKey="seventh" className="pointer">
                          Dealership Info
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setAction("textMessage");
                          setNoteSearchVal("");
                        }}
                      >
                        <Nav.Link eventKey="eight" className="pointer">
                          Text Messages
                          {(NotiList.includes(lead._id)) && (
                              <img
                                src={active}
                                alt=""
                                height={"10px"}
                                width={"8px"}
                                style={{ position: "relative", top: "-7px" }}
                              />
                            )}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item
                        onClick={() => {
                          setNoteSearchVal("");
                          setAction("notes");
                        }}
                      >
                        <Nav.Link eventKey="third" className="pointer">
                          Notes
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setAction("statusHistory");
                        }}
                      >
                        <Nav.Link eventKey="fourth" className="pointer">
                          Status History
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setAction("Appointment");
                        }}
                      >
                        <Nav.Link eventKey="fifth" className="pointer">
                          Appointment
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setAction("Call");
                          setNoteSearchVal("");
                        }}
                      >
                        <Nav.Link eventKey="sixth" className="pointer">
                          Calls
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setAction("mail");
                          setNoteSearchVal("");
                          localStorage.setItem(
                            "LEAD_PAGE_URL",
                            location.pathname
                          );
                        }}
                      >
                        <Nav.Link eventKey="nine" className="pointer">
                          Mail
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setAction("tasks");
                        }}
                      >
                        <Nav.Link eventKey="ten" className="pointer">
                          Tasks
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Grid>
                  <Grid item lg={10} md={10} sm={9}>
                    <Tab.Content>
                      {/* <Tab.Pane eventKey='first'>
                                                <LeadPersonalDetails
                                                    leadDetails={leadDetails}
                                                    dealerDetails={dealerDetails}
                                                    messages={lead?.messages ?? []}
                                                    dealer={dealer ? dealer : {}}
                                                    refetch={refetch}
                                                />
                                            </Tab.Pane> */}
                      <Tab.Pane eventKey="second">
                        <AdditionalLeadInfo lead={lead && lead} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="seventh">
                        <DealerInfo
                          longAddress={longAddress}
                          setIdleBtnLabel={setIdleBtnLabel}
                          setShowWarning={setShowWarning}
                          setWarningHeading={setWarningHeading}
                          setWarningBody={setWarningBody}
                          dealer={dealerDetails}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="eight">
                        {/* {action === "textMessage" && (
                          <LeadPersonalDetails
                            leadDetails={leadDetails}
                            dealerDetails={dealerDetails}
                            // messages={lead?.messages.sort((v, n) => new Date(v.time) > new Date(n.time) ? -1 : 1) ?? []}
                            messages={lead?.messages ?? []}
                            dealer={dealerDetails ? dealerDetails : {}}
                            refetch={refetch}
                            smstemplate={smstemplate}
                            setNotificationcount={setNotificationcount}
                          />
                        )} */}

                        {/* <Reusabletabs
                          component={<LeadPersonalDetails
                            leadDetails={leadDetails}
                            dealerDetails={dealerDetails}
                            // messages={lead?.messages.sort((v, n) => new Date(v.time) > new Date(n.time) ? -1 : 1) ?? []}
                            messages={lead?.messages ?? []}
                            dealer={dealerDetails ? dealerDetails : {}}
                            refetch={refetch}
                            smstemplate={smstemplate}
                            setNotificationcount={setNotificationcount}
                          />}

                        /> */}
                        {action === "textMessage" && (
                          <div className="containerBox">
                            <Reusabletabs
                              component={LeadPersonalDetails}
                              handleNotesSearch={handleNotesSearch}
                              label1={"Text message"}
                              label2={"Notes"}
                              notes={notes1}
                              filterValue={"text"}
                              componentProps={{
                                leadDetails,
                                dealerDetails,
                                // messages={lead?.messages.sort((v, n) => new Date(v.time) > new Date(n.time) ? -1 : 1) ?? []}
                                messages: lead?.messages ?? [],
                                dealer: dealerDetails ? dealerDetails : {},
                                refetch,
                                smstemplate,
                                // setNotificationcount,
                                lead,
                              }}
                            />
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="containerBox">
                          <Card className="mx-1 ">
                            <Card.Header>
                              <Card.Title as="h4">Notes History</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ fontWeight: "normal" }}>
                              <div className="d-flex gap-4">
                                <button
                                  className="btn btn-sm pbuttonbgcolor mb-2"
                                  onClick={() => setOpenNote(true)}
                                >
                                  <img src={plus} alt="plus" /> Create Note
                                </button>
                                <Notessearch handleChange={handleNotesSearch} />
                              </div>
                              <div className="notes-list">
                                {
                                  <Notes
                                    lead={lead}
                                    notes={notes1}
                                    dealerDetails={dealerDetails}
                                    filterValue={"other"}
                                  />
                                }
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <div className="containerBox">
                          <Card className="mx-1 card-sec">
                            <Card.Header>
                              <Card.Title>
                                <h3>Status History</h3>
                              </Card.Title>
                            </Card.Header>
                            <Card.Body style={{ fontWeight: "normal" }}>
                              <div className="activity-list">
                                <Table bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Status</th>
                                      <th>Time</th>
                                      <th>Created by</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {lead &&
                                      lead.status_history &&
                                      lead.status_history?.map(
                                        (status: any, index: number) => (
                                          <tr key={index}>
                                            <td>
                                              {leadStage[status.new_status]}
                                            </td>
                                            <td>
                                              {`${moment(
                                                status.status_lastupdated_at
                                                // eslint-disable-next-line no-useless-concat
                                              ).format("LLL")}` +
                                                "(" +
                                                dealerDetails?.timezone +
                                                ")"}
                                            </td>
                                            <td>
                                              {/* {!status.byName && !status.updatedBy ? "N/A" : <> <p className='m-0'>{status?.byName || "N/A"}</p>
                                                                                                <div className='d-flex flex-row'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4336" className="bi bi-envelope-fill mt-auto mb-auto mr-1" viewBox="0 0 16 16">
                                                                                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                                                                                    </svg> {status?.updatedBy || "N/A"}
                                                                                                </div></>} */}
                                              {status.sender_type === "BOT"
                                                ? "BOT"
                                                : status.sender_type ===
                                                  "CUSTOMER"
                                                  ? leadDetails?.body?.data
                                                    ?.customer_details
                                                    ?.first_name +
                                                  " " +
                                                  leadDetails?.body?.data
                                                    ?.customer_details
                                                    ?.last_name
                                                  : status.byName || "N/A"}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </Table>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        <div className="containerBox">
                          <Appointment
                            lead={lead && lead}
                            dealerDetails={dealerDetails && dealerDetails}
                            refetch={refetch}
                            setLoading={setLoading}
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="sixth" id="startup-button">
                        <div className="containerBox">
                          {action === "Call" && isFetchallHistoryLoading ? (
                            <LoadingBar isActive={true} />
                          ) : (
                            <>
                              {/* <Calllist
                                leadID={lead && lead?._id}
                                data={data}
                                page={page}
                                setPage={setPage}
                              /> */}
                              <Reusabletabs
                                handleNotesSearch={handleNotesSearch}
                                label1={"Call list"}
                                filterValue={"phonecall"}
                                label2={"Notes"}
                                component={Calllist}
                                notes={notes1}
                                componentProps={{
                                  leadID: lead && lead?._id,
                                  data,
                                  page,
                                  setPage,
                                  lead,
                                  dealerDetails,
                                }}
                              />
                            </>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="seven">
                        <div className="containerBox">
                          <Sms />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="nine">
                        <div className="containerBox">
                          <Outlookogin
                            handleNotesSearch={handleNotesSearch}
                            notes={notes1}
                            email={lead?.customer_details?.email}
                            dealerDetails={dealerDetails}
                            leadDetails={leadDetails}
                            lead={lead}
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="ten">
                        <div className="containerBox">
                          <Tasks data={lead} refetch={refetch} />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Grid>
                </Grid>
              </Tab.Container>
              {/* </Row> */}
            </div>
            {/* </Row> */}

            <Modal centered show={showSecondaryNoModal}>
              <Modal.Header>
                <Modal.Title>Add secondary phone number</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <span style={{ fontWeight: "lighter" }}>
                    Please enter secondary phone number including{" "}
                    <b>country code with +</b>
                  </span>
                  <input
                    type="text"
                    name="secondaryno"
                    id="ns"
                    onChange={(e) => {
                      setSecNo(e.target.value);
                    }}
                    className="form-control bg-white mt-3"
                    autoFocus
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-sm sbuttonbgcolor"
                  onClick={() => {
                    setShowSecondaryNoModal(false);
                    setSecNo("");
                  }}
                >
                  Close
                </button>
                <button
                  disabled={!secNo}
                  className="btn btn-sm pbuttonbgcolor"
                  onClick={() => {
                    leadMutation.mutateAsync({
                      id,
                      type: "secondaryNumber",
                      secondaryNumber: secNo,
                    });
                  }}
                >
                  Save
                </button>
              </Modal.Footer>
            </Modal>

            <Modal
              centered
              show={openNote}
              onHide={() => {
                setOpenNote(false);
                setNote("");
                setNoteType(null);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Create new note</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <select
                    name=""
                    id=""
                    className="form-select mb-2"
                    onChange={(e) => {
                      setNoteType(e.target.value);
                    }}
                  >
                    <option value="null">Select note type</option>
                    <option value="phonecall">Phone Call</option>
                    <option value="text">Text</option>
                    <option value="email">Email</option>
                    <option value="other">Other</option>
                  </select>
                  <textarea
                    autoFocus
                    className="form-control bg-white"
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                    placeholder="Type your note here..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <div className="d-flex my-3">
                    <div
                      className=" mt-auto mb-auto mr-2"
                      style={{ fontWeight: "normal" }}
                    >
                      Current date
                    </div>
                    <Form.Check
                      type="switch"
                      className=""
                      onChange={(e) => {
                        setPastDate(e.target.checked);
                      }}
                    />
                    <div
                      className=" mt-auto mb-auto ml-1"
                      style={{ fontWeight: "normal" }}
                    >
                      Past date
                    </div>
                    {pastDate && (
                      <input
                        type="datetime-local"
                        name=""
                        id=""
                        className="ml-3"
                        onChange={(e) => {
                          setSelectedPastDate(
                            new Date(e.target.value).toISOString()
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-sm sbuttonbgcolor"
                  onClick={() => {
                    setOpenNote(false);
                    setNote("");
                    setNoteType(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm pbuttonbgcolor"
                  onClick={() => {
                    if (!noteType || noteType === "null") {
                      return toastify("failure", "Note type cannot be emply");
                    }
                    if (!note) {
                      return toastify("failure", "Note cannot be emply");
                    }
                    if (pastDate && !selectedPastDate) {
                      return toastify(
                        "failure",
                        "Please select the past date or Uncheck the toggle."
                      );
                    }
                    leadMutation.mutateAsync({
                      id,
                      note,
                      created_at: pastDate ? selectedPastDate : false,
                      noteType,
                      appointment: "no",
                      type: "note",
                      adf_info: {
                        dealer_name: dealerDetails?.dealer_name,
                        dealer_email: dealerDetails?.adf_email,
                      },
                    });
                    // leadMutation.mutateAsync({ id, note })
                    setOpenNote(false);
                    setNote("");
                    setNoteType(null);
                    setPastDate(false);
                    setSelectedPastDate("");
                  }}
                >
                  Save
                </button>
                <button
                  className="btn btn-sm pbuttonbgcolor"
                  onClick={() => {
                    if (!noteType || noteType === "null") {
                      return toastify("failure", "Note type cannot be emply");
                    }
                    if (!note) {
                      return toastify("failure", "Note cannot be emply");
                    }
                    leadMutation.mutateAsync({
                      id,
                      note,
                      noteType,
                      appointment: "yes",
                      type: "note",
                      adf_info: {
                        dealer_name: dealerDetails?.dealer_name,
                        dealer_email: dealerDetails?.adf_email,
                      },
                    });
                    // leadMutation.mutateAsync({ id, note })
                    setOpenNote(false);
                    setNote("");
                    setNoteType(null);
                    setPastDate(false);
                    setSelectedPastDate("");
                  }}
                >
                  {`Save & Send`}
                </button>
              </Modal.Footer>
            </Modal>

            {/* <Warningalert showWarning={showWarning} setShowWarning={setShowWarning} heading={warningHeading} body={warningBody} /> */}
            <Warningmodal
              showWarningModal={showWarning}
              handleCloseWarningModal={() => {
                setShowWarning(false);
                setIdleBtnLabel("");
                setActionBtnLabel("");
              }}
              body={warningBody}
              heading={warningHeading}
              actionbtnLabel={actionbtnLabel}
              handleWarningAction={() => {
                leadMutation.mutateAsync({
                  id,
                  status: selectedStatus,
                });
              }}
              idleBtnlabel={idlebtnLabel}
            />
          </>
        )}
        <Createtaskmodal
          show={showTaskModal}
          handleClose={() => {
            setShowTaskModal(false);
          }}
          lead={lead}
          refetch={refetch}
        />
      </div>
    </>
  );
};

export default LeadDetails;
