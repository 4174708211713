import React, { useContext } from 'react'
import Adminflow from './Adminflow'
import Dealerflow from './Dealerflow'
import { UserContext } from '../common/UserContext'
import axios from 'axios'
import config from '../../config.json'
import { useQuery } from 'react-query'
function Flow() {
    const { type } = useContext<any>(UserContext)
    const token: any = localStorage.getItem("$AuthToken");
    const fetchFlow = async () => {
        let body = {
            type: "fetch"
        }
        let resp = await axios.post(config.baseHost_backend + "/automation", body, {
            headers: {
                Authorization: token
            }
        });
        return resp
    }

    const { data,refetch ,isLoading} = useQuery("flow-fetch", fetchFlow)
    return (
        <div>
            <h3 className='font-weight-bold mx-3 mt-4'>{type === "dealer" ? "Create automation" : "Create flow"}</h3>
            <hr />
            {type === "cognitgo" && <Adminflow isLoading={isLoading} data={data && data?.data?.body} refetch={refetch}/>}
            {type === "dealer" && <Dealerflow data={data && data?.data?.body}/>}
        </div>
    )
}

export default Flow