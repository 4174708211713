
import config from '../../config.json'
import axios from 'axios';
let url = config.baseHost_backend + '/forgot-passowrd';

export const sendNotification = (body) =>
    axios.post(url, body).then((res) => {
        return res;
    }).catch((err) => {
        return err;
    })
