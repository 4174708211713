import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { useHistory, useLocation } from 'react-router-dom'
import leftArrow from '../../assets/images/leftArrow.svg';
import Timetable from './Timetable';
import NoDataFound from '../common/NoDataFound';
function Details() {
  const location: any = useLocation()
  console.log("location", location);
  const [myData, setmyData] = useState<any>(null);
  const [tableData,setTableData]=useState<any>([])
  const [leads,setLeads]=useState<any>([])
  const history = useHistory();
  useEffect(() => {
    if (location?.state?.data) {
      console.log(location?.state);
      let filtered =(location?.state?.selectedFromDate || location?.state?.selectedToDate) ? location?.state?.data?.active_leads?.filter((time) => new Date(time?.dateandtime) >= new Date(location?.state?.selectedFromDate) && new Date(time?.dateandtime) <= new Date(location?.state?.selectedToDate)) : location?.state?.data?.active_leads
      console.log("filtered",filtered);
      
      setTableData(filtered && filtered)
      let mergerd = [] as any;
      filtered?.sort((a,b)=>a?.date>b?.date ? 1 : -1).map((sd: any) => {
          mergerd.push(...sd?.leads)
      })
      // let sorted=mergerd?.sort(())
      setLeads(mergerd && mergerd)
      console.log("mergerd", mergerd, mergerd?.length);
    

      setmyData({
        labels: mergerd?.map((lead) => lead?.leadDetails?.first_name + " " + lead?.leadDetails?.last_name),
        datasets: [
          {
            label: 'Time spent on leads(HH:MM:SS)',
            data: mergerd?.map((point) => point?.timer?.minutes * 60 + point?.timer?.seconds),
            backgroundColor: ['blue'],
            barThickness: 40,
            tension: 0.5
          },
        ],
      })
    }
  }, [location?.state])
  console.log("myData",myData);
  
  return (
    <div>
      <div className='d-flex gap-2 p-3'>
        <div>
          <Image
            onClick={() => {
              history.push("/users")
            }}
            className='pointer'
            src={leftArrow}
          />
        </div>
        <div className='pad-button text-name mt-auto mb-auto'>
            User reporting
        </div>
      </div>
      <hr className='m-0'/>
      <div className="container mt-4">
        {myData && myData?.labels?.length>0 &&
          <Line
            data={myData}
            options={{
              responsive: true, maintainAspectRatio: false,
              scales: {
                xAxes: {
                  grid: {
                    display: false
                  },
                  ticks: { autoSkip: false },
                },

                yAxes: {
                  ticks: {
                    callback: function (value: any, index, ticks) {
                      console.log("value", value);
                      const totalSeconds = Math.round(value);
                      const hours = Math.floor(totalSeconds / 3600);
                      const minutes = Math.floor(value / 60);
                      const seconds = value % 60;
                      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;;
                    }
                  },
                  beginAtZero: false,
                  grid: {
                    display: false,
                  },


                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const value:any = context.raw;
                      // const hours = Math.floor(value / 3600);
                      // const seconds = context.parsed.y;
                      // const minutes = Math.floor(seconds / 60);
                      // const remainingSeconds = seconds % 60;
                      const hours = Math.floor(value / 3600);
                      const minutes = Math.floor((value % 3600) / 60);
                      const seconds = value % 60;
                      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                    },
                  },
                },
              },
            }} />}
      </div>
      <div className='container mt-5'>
        <Timetable data={tableData && tableData}/>
      </div>
      {!location?.state?.data?.active_leads ? <NoDataFound height={""} width={""} message1='No data Found' message2='' img=""/> : ""}

    </div>
  )
}

export default Details