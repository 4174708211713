import React, { useEffect, useState } from "react";
import { leadDetailsApiWithoutToken } from "../../controllers/leads";
import { useParams } from "react-router-dom";
import Dialpad from "../Leads/Call/Dialpad";
import profile from "../../../src/assets/images/contact.png";
import "./index.css";
import phoneicon from "../../assets/images/phoneicon.png";
import axios from "axios";
import config from "../../config.json";
function Dailer() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [data, setData] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [showNumber, setShowNumber] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams<any>();
  const fetchDataa = () => {
    setIsLoading(true);
    leadDetailsApiWithoutToken({}, id)
      .then((res) => {
        console.log("res", res);
        setData(res?.body);
        setIsLoading(false);
        if (res?.statusCode === 400) {
          setIsError(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataa();
  }, []);

  const saveLog = () => {
    axios.put(config.baseHost_backend + "/update-lead-bot", {
      functionType: "byEmail",
      callByEmail: true,
      leadId: data?.data?._id,
    }).then((res)=>{
        console.log("Log saved", res);
    }).catch((err)=>{
      console.log("error", err);
      
    })
  };

  return (
    <div className="dialer-container">
      <div className="card dialer-card bg-white shadow-sm">
        <div>
          <img src={profile} alt="logo" height={150} />
        </div>
        <div className="mt-3">
          <p className="text-center dealer-name-d">
            {isLoading ? "Loading..." : data && data?.data?.dealer_name}
          </p>
          <p className="text-center dealer-phone-d">
            {isLoading
              ? "Loading..."
              : showNumber ? config.DialerBDCNumber : <button className="btn btn-sm btn-outline-primary mt-2 hide-shw-num" onClick={()=>{setShowNumber(true); saveLog()}}>Show number</button>
              }
          </p>
          <p className="text-center dealer-phone-d enable-for-mobile ">
            {isLoading
              ? "Loading..."
              :  config.DialerBDCNumber
              }
          </p>
        </div>
        {!isError && (
          <div className="call-btn-d-cont-d dialer-call-b">
            <a
              onClick={saveLog}
              href={`tel:${config.DialerBDCNumber}`}
            >
              <img src={phoneicon} height={18} alt="" />
              Call
            </a>
          </div>
        )}
        {isError && (
          <div className="invalid-obj">
            <p className="">Something went wrong.</p>
            <p className="">
              URL may be broken. please try again with valid URL
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dailer;
