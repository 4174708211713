import { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import './dashboard.css';
import BdcStats from './BdcStats/Report';
import LeadGeneratedReport from './LeadGeneratedReport/Report';
import LeadByYear from './LeadsByYear/Report';
import LeadByModelReport from './LeadByModalReport/Report';
import Report from './ROIMatchBackReport/Report';
import InventoryReport from './InventoryReport/Report';
import { toastify } from '../common/notification';
import { UserContext } from '../common/UserContext';
import axios from 'axios';
import config from '../../config.json';
import LoadingBar from '../common/LoadingBar';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import jsPdf from 'jspdf'
import html2canvas from 'html2canvas';
import Select from 'react-select'
import { useQuery } from 'react-query';
import { dashboard, dashboardapi2 } from '../../controllers/dashboard';
import { useLocation } from 'react-router-dom';
import _ from 'lodash'
import { Grid } from '@mui/material';
import filter from '../../assets/images/filter.png'
import Loader from '../common/Loader';
import Applyfilter from '../common/Applyfilter';

const Dashboard = ({ setUser, refetchData }: any) => {
    const [values, setValues] = useState<DateObject | DateObject[] | null>([]);
    const [sampleBrands, setSampleBrands] = useState<any>([])
    const { authToken, dealerID, corporateID, type } = useContext(UserContext);
    const [fromDate, setFromDate] = useState<any>(null)
    const [headerLoading, setHeaderLoading] = useState(false)
    const [summeryLoading, setSummeryLoading] = useState(false)
    const [bdcStatsLoading, setBdcStatsLoading] = useState(false)
    const [vehicleModelLoading, setVehicleModelLoading] = useState(false)
    const [vehicleYearLoading, setVehicleYearLoading] = useState(false)
    const [roiNinventoryLoading, setRoiNinventoryLoading] = useState(false)
    const [singleDateSelected, setSingleDateSelected] = useState<any>(null)
    const [toDate, setToDate] = useState<any>(null)
    const [data, setData] = useState<any>(null)
    const [data2, setData2] = useState<any>(null)
    const [api2Data, setApi2Data] = useState<any>(null)
    const url = config['baseHost_backend'];
    const [selectedDealerID, setSelectedDealerID] = useState<any>(null)
    const [filteredDealersAndStates, setFilteredDealersAndStates] = useState<any>(null)
    const [location, setLocation] = useState<any>(null)
    const [brand, setBrand] = useState<any>(null)
    const formRef = useRef<any>(null);
    const locationRef = useRef<any>(null);
    const brandRef = useRef<any>(null);
    // const fromDateControl = useRef<any>(null);
    const dealerRef = useRef<any>(null)
    const [stateFilterd, setStateFiltered] = useState<any>([])
    const [filterCount, setFilterCount] = useState<any>(null)
    const [enableExportBtn, setEnableExportBtn] = useState<boolean>(false)
    const [options, setOptions] = useState<any>([])
    const [valuesObj, setValuesObj] = useState<any>(null)
    const [filterApplied, setFilterApplied] = useState<any>(false)
    // const [dashboardData1, setDashboardData1] = useState<any>(null)
    const location1: any = useLocation();

    const [leadGenReportData, setLeadGenReportData] = useState<any>({
        // roProcessed: 0,
        bot_msg_sent:0,
        // bot_serv_prot:0,
        serv_apptment:0,
        roMessaged: 0,
        leadGenerated: 0,
        serviceInterested: 0,
        roServiceMessaged: 0,
        interestedServiveAppointment:0,
        serviceAppointmentMsgSent:0,
        uniq_ro_processed:0,
        bot_serv_appontmnt_processed:0
    })
    const [bdcStatsData, setBdcStatsData] = useState<any>(null)
    const [vehicleModelData, setVehicleModelData] = useState<any>(null)
    const [vehicleYearData, setVehicleYearData] = useState<any>(null)
    const [roiNinventoryData, setRoiNinventoryData] = useState<any>(null)

    const divRef = useRef<any>(null)
    let userInfo = {
        data: {
            actionType: "dashboardFetch",
            corporateID: corporateID,
            dealerID: dealerID
        }
    }

    const handleFromYearChange = (dates: DateObject[]) => {
        setFilters({ ...filters, fromDate: "date" })
        if (dates.length === 1) {
            setFromDate(dates[0].toString());
            // setToDate(moment(dates[0].toString()).add(1, 'days').toString())

            let a: any = dates[0]
            let od: any = new Date(a)
            let stamp = new Date(od.setDate(od.getDate() + 1))
            setToDate(`${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`)
            // setToDate(dates[0].toString())
            setSingleDateSelected(true)
        }
        if (dates.length === 2) {
            setFromDate(dates[0].toString());
            // setToDate(moment(dates[1].toString()).add(1, 'days').toString())

            let a: any = dates[1]
            let od: any = new Date(a)
            let stamp = new Date(od.setDate(od.getDate() + 1))
            // setSelectedToDate(`${stamp.getFullYear()}/${stamp.getMonth()+1}/${stamp.getDate()}`)

            setToDate(`${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`)
            setSingleDateSelected(false)

        }
        if (dates.length === 0) {
            setFromDate(null);
            setToDate(null)
            setFilters({ ...filters, fromDate: null, toDate: null })
            setSingleDateSelected(null)
        }
        setValues(dates);
    };

    useEffect(() => {
        const newArr = [{ label: "All", value: null }]
        const brands = [{ label: "BMW", value: null }, { label: "MINI", value: null }, { label: "Alfa Romeo", value: null }, { label: "Chrysler", value: null }, { label: "Dodge", value: null }, { label: "FIAT", value: null }, { label: "Jeep", value: null }, { label: "RAM", value: null }, { label: "Buick", value: null }, { label: "Cadillac", value: null }, { label: "Chevrolet", value: null }, { label: "GMC", value: null }, { label: "Hyundai", value: null }, { label: "Genesis", value: null }, { label: "Kia", value: null }, { label: "Mazda", value: null }, { label: "Nissan", value: null }, { label: "Infiniti", value: null }, { label: "Subaru", value: null }]
        const sorted = brands.sort((a: any, b: any) => _.toLower(a.label) > _.toLower(b.label) ? 1 : -1)
        setSampleBrands(newArr.concat(sorted))
    }, [])
    const [filters, setFilters] = useState<any>({
        // fromDate: null,
        toDate: null,
        selectedDealerID: null,
        location: null,
        brand: null,
    })

    let val = 0;
    Object.entries(filters).forEach(
        ([key, value]) => {
            value && value !== "null" && value !== "All" && val++

        });

    useEffect(() => {
        setFilterCount(val)
    }, [filters, val])

    const clearForm = () => {
        setFromDate(null);
        setToDate(null);
        setBrand(null);
        setSelectedDealerID(null);
        setLocation(null);
        setValues([])
        setFilters({ ...filters, fromDate: null, toDate: null, location: null, selectedDealerID: null, brand: null })
        setUser((prev: any) => ({ ...prev, fromDate: null }))
        setUser((prev: any) => ({ ...prev, toDate: null }))
    }

    const fetchData = async (e: any) => {
        console.log("fetch");
        setSummeryLoading(true)
        setBdcStatsLoading(true)
        setVehicleModelLoading(true);
        setVehicleYearLoading(true);
        setRoiNinventoryLoading(true)
        e.preventDefault();
        let bodyData =
        {
            "dateFrom": fromDate,
            "dateTo": toDate,
            singleDateSelected,
            "brand": brand === 'null' || brand === 'All' ? null : brand,
            "selectedDealerID": selectedDealerID === 'null' || selectedDealerID === 'All' ? null : selectedDealerID,
            "location": location === 'null' || location === 'All' ? null : location,
            data: {
                dealerID: dealerID,
                corporateID: corporateID
            }
        }

        //new code starts
        axios.all([
            // axios.post(`${config.baseHost_backend}/dashboard/ro-processed`, bodyData, {
            //     headers: {
            //         Authorization: authToken
            //     }
            // }),
            axios.post(`${config.baseHost_backend}/dashboard/ro-processed`, {...bodyData, type:"unquie_ro_processed"}, {
                headers: {
                    Authorization: authToken
                }
            }),
            axios.post(`${config.baseHost_backend}/dashboard/ro-processed`, {...bodyData, type:"bot_service_appointment_processed"}, {
                headers: {
                    Authorization: authToken
                }
            }),
            // axios.post(`${config.baseHost_backend}/dashboard/ro-messaged`, bodyData, {
            //     headers: {
            //         Authorization: authToken
            //     }
            // }),
            axios.post(`${config.baseHost_backend}/dashboard/ro-messaged`, {...bodyData, type:"bot_msg_sent"}, {
                headers: {
                    Authorization: authToken
                }
            }),
            // axios.post(`${config.baseHost_backend}/dashboard/ro-messaged`, {...bodyData, type:"bot_service_protection"}, {
            //     headers: {
            //         Authorization: authToken
            //     }
            // }),
            axios.post(`${config.baseHost_backend}/dashboard/ro-messaged`, {...bodyData, type:"service_appointment"}, {
                headers: {
                    Authorization: authToken
                }
            }),
            axios.post(`${config.baseHost_backend}/dashboard/lead-generated`, bodyData, {
                headers: {
                    Authorization: authToken
                }
            }),
            axios.post(`${config.baseHost_backend}/dashboard/lead-service-interested`, bodyData, {
                headers: {
                    Authorization: authToken
                }
            }),
            axios.post(`${config.baseHost_backend}/dashboard/lead-interested-service-appointment`, bodyData, {
                headers: {
                    Authorization: authToken
                }
            }),
            axios.post(`${config.baseHost_backend}/dashboard/lead-interested-service-appointment`, {...bodyData,serviceAppointment: true}, {
                headers: {
                    Authorization: authToken
                }
            }),
            axios.post(`${config.baseHost_backend}/dashboard/lead-service-bot`, bodyData, {
                headers: {
                    Authorization: authToken
                }
            }),
        
        ])
            .then(axios.spread((unq_ro_proc,bot_ser_appmt_proc,bot_msg_sent,bot_ser_app, data3, data4, intserapp,serviceAppymsgSent,data5) => {
                // output of req.
                console.log( 'data2', data2, "data3", data3, "data4", data4,"intserapp",intserapp, "data5", data5, "serviceAppymsgSent",serviceAppymsgSent)
                setLeadGenReportData({
                    // roProcessed: data1?.data?.body?.totalLead,
                    bot_msg_sent:bot_msg_sent?.data?.body?.botResponse,
                    // bot_serv_prot:bot_ser_prot?.data?.body?.botServiceProtection,
                    serv_apptment:bot_ser_app?.data?.body?.botServiceAppointment,
                    // roMessaged: data2?.data?.body?.botResponse,
                    leadGenerated: data3?.data?.body?.customerResponded,
                    serviceInterested: data4?.data?.body?.serviceInterestedLead,
                    roServiceMessaged: data5?.data?.body?.roServiceMessaged,
                    interestedServiveAppointment:intserapp?.data?.body?.serviceAppointmentInterestedLead,
                    serviceAppointmentMsgSent:serviceAppymsgSent?.data?.body?.serviceAppointmentLead,
                    uniq_ro_processed:unq_ro_proc?.data?.body?.botResponse,
                    bot_serv_appontmnt_processed:bot_ser_appmt_proc?.data?.body?.botResponse
                })
                setSummeryLoading(false)

            }));
        //new code ends

        //bdc stats api start
        axios.post(`${config.baseHost_backend}/dashboard/bdc-status`, bodyData, {
            headers: {
                Authorization: authToken
            }
        }).then((data) => {
            console.log("bdc stats", data);
            setBdcStatsData(data?.data?.body?.status)
            setBdcStatsLoading(false)
        }).catch((err) => {

        })
        //ends

        //vehicle model starts
        axios.post(`${config.baseHost_backend}/dashboard/lead-by-model`, bodyData, {
            headers: {
                Authorization: authToken
            }
        }).then((data) => {
            console.log("models", data);
            setVehicleModelData(data?.data?.body?.carModel)
            setVehicleModelLoading(false)
        }).catch((err) => {

        })
        //ends

        //vehicle year starts
        axios.post(`${config.baseHost_backend}/dashboard/lead-by-year`, bodyData, {
            headers: {
                Authorization: authToken
            }
        }).then((data) => {
            console.log("year", data);
            setVehicleYearData(data?.data?.body?.vehicleByYear)
            setVehicleYearLoading(false)
        }).catch((err) => {

        })
        //ends


        //roi and inventory api starts
        axios.post(`${config.baseHost_backend}/drm-dashboard`, bodyData, {
            headers: {
                Authorization: authToken
            }
        }).then((data) => {
            console.log("drm-dashboard", data?.data);
            setRoiNinventoryData(data?.data)
            setRoiNinventoryLoading(false)
        }).catch((err) => {

        })
        //ends
        setFilterApplied(true)
    }


    useEffect(() => {
        setHeaderLoading(true)
        const url = config['baseHost_backend'] + '/drm-create-user';
        let body = {
            selectType: {
                ID: "",
                type: 'corporate',
                dealerID: dealerID,
                corporateID: corporateID
            }
        }
        axios.post(url, body, {
            headers: {
                Authorization: authToken
            },
        }).then((resp) => {
            setHeaderLoading(false)
            setFilteredDealersAndStates(resp?.data?.body)
            // setLoading(false)
        }).catch((err) => {
            setHeaderLoading(false)
            // setLoading(false)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])


    useEffect(() => {
        //changed
        if (filteredDealersAndStates) {
            const newArr = [{ label: 'All', value: 'null' }]
            let tempArray = [] as any
            filteredDealersAndStates?.dealers?.forEach((filter: any) => {

                tempArray.push({ label: filter._id.dealer_name, value: filter._id.dealerID })
            });
            const sorted = (tempArray.sort((a: any, b: any) => _.toLower(a.label).trim() > _.toLower(b.label).trim() ? 1 : -1))
            setOptions(newArr.concat(sorted))
            setValuesObj(filteredDealersAndStates?.dealers)
        }
    }, [filteredDealersAndStates])

    useEffect(() => {
        if (filteredDealersAndStates) {
            const newArr = [{ label: "All", value: "All" }]
            let tempArray = [] as any
            const arrayUniqueByKey = [...new Set(filteredDealersAndStates?.dealers?.map((item: any) => item._id.state))];
            const filter = arrayUniqueByKey?.filter((item: any) => item)
            //changed
            filter.forEach((obj: any) => {
                tempArray.push({ label: obj, value: obj })
            })
            const sorted = (tempArray.sort((a: any, b: any) => _.toLower(a.label).trim() > _.toLower(b.label).trim() ? 1 : -1))
            setStateFiltered(newArr.concat(sorted))
        }
    }, [filteredDealersAndStates])

    const exportPdf = (e: any) => {
        e.preventDefault();
        let input: any = document.getElementById('pdf');

        //Method
        var HTML_Width: any = document.getElementById('pdf')?.offsetWidth;
        var HTML_Height: any = document.getElementById('pdf')?.offsetHeight;
        var top_left_margin = 15;
        var PDF_Width = HTML_Width + (top_left_margin * 2);
        var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);


        html2canvas(input).then(canvas => {
            const contentDataURL = canvas.toDataURL('image/png')
            var margin = 10;
            var imgWidth = 210 - 2 * margin;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPdf('p', 'mm', 'a4');
            var position = 0;

            doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save('Report.pdf');
        })
    }


    return (

        <div className='containerBox ' id='pdf' ref={divRef}>
            {/* <form className='d-flex  flex-row justify-content-left shadow-sm bg-light p-3 filter-header' ref={formRef} onSubmit={fetchData} > */}
            <form className=' shadow-sm bg-light p-3 filter-header' ref={formRef} onSubmit={fetchData}>
                {/* <LoadingBar isActive={headerLoading}/> */}
                <Grid container gap={1}>
                    <Grid className='d-flex flex-row'>
                        <div className=' pl-3 d-flex flex-column'>
                            <span className='filter-control-lable'>Select message sent date</span>
                            <DatePicker
                                style={{ padding: '17px', marginRight: '20px' }}
                                // className="date-selecter"
                                value={values}
                                placeholder={'MM/DD/YYYY'}
                                format="MM/DD/YYYY"
                                onChange={handleFromYearChange}
                                range
                                plugins={[<DatePanel />]}
                            />
                        </div>
                    </Grid>

                    {type !== 'dealer' && (
                        <Grid className='w-25 size'>
                            <span className='filter-control-lable'>Select Location</span>
                            <div className='input p-0 input-select-search'>
                                <Select closeOnSelect={true}
                                    isSearchable={true}
                                    ref={locationRef}
                                    label={location}
                                    defaultValue="All"
                                    options={stateFilterd && stateFilterd}
                                    onChange={(opt: any) => {
                                        setLocation(opt && opt.label);
                                        setFilters({ ...filters, location: opt && opt.label })
                                    }}></Select>
                            </div>
                        </Grid>
                    )}


                    <Grid className='w-25 size'>
                        <span className='filter-control-lable'>Select Brand</span>
                        <div className='input p-0 input-select-search'>
                            <Select closeOnSelect={true}
                                isSearchable={true}
                                ref={brandRef}
                                className="bg-light"
                                // label={brand}
                                defaultValue="All"
                                options={sampleBrands && sampleBrands}
                                onChange={(opt: any) => {
                                    setBrand(opt && opt.label);
                                    setFilters({ ...filters, brand: opt && opt.label })
                                }}></Select>
                        </div>
                    </Grid>


                    {type !== 'dealer' && (
                        <Grid className='w-25 size'>
                            <span className='filter-control-lable'>Select Dealer</span>
                            <div className='input p-0 input-select-search'>
                                <Select closeOnSelect={true}
                                    isSearchable={true}
                                    ref={dealerRef}
                                    label={selectedDealerID}
                                    defaultValue="All"
                                    options={options && options}
                                    onChange={(opt: any) => {


                                        setSelectedDealerID(opt && opt.value);
                                        setFilters({ ...filters, selectedDealerID: opt && opt.value });
                                    }}></Select>
                            </div>
                        </Grid>
                    )}

                    <Grid className='filter-btns-grp d-flex flex-row'>
                        {filterCount !== 0 && (
                            <span className='filter-count'>{filterCount}</span>
                        )}
                        <button
                            className='btn btn-sm mt-auto mb-1 mr-2 button pbuttonbgcolor'
                            type='submit'
                            onClick={fetchData}>
                            Filter
                        </button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            setData(null);
                            setData2(null)
                            // fromDateControl.current.reset()
                            formRef.current.reset()
                            type !== 'dealer' && locationRef.current.select.clearValue()
                            brandRef.current.select.clearValue()
                            type !== 'dealer' && dealerRef.current.select.clearValue()

                            clearForm()
                            setFilterApplied(false)
                        }}
                            className='btn btn-sm sbuttonbgcolor  mt-auto mb-1'>
                            Reset
                        </button>
                        <button className='tbuttonbgcolor btn-sm mt-auto ml-2 mb-1' type='submit' onClick={exportPdf} disabled={!enableExportBtn || !filterApplied}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf mr-1" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                            </svg>
                            Export
                        </button>
                    </Grid>
                </Grid>
            </form>


            {filterApplied ? <>
                {summeryLoading ? <Loader /> : <Row className='spacing-1 mt-10'>
                    <Col>
                        {<LeadGeneratedReport leadGenReportData={leadGenReportData} />}
                    </Col>
                </Row>}

                {bdcStatsLoading ? <Loader /> : <Row className='spacing-1 my-3 pl-3'>
                    <Col>
                        {<BdcStats bdcStatsData={bdcStatsData} setEnableExportBtn={setEnableExportBtn} />}
                    </Col>
                </Row>}

                {vehicleModelLoading ? <Loader /> : <Row className='spacing-1 my-3 pl-3'>
                    <Col>
                        {<LeadByModelReport vehicleModelData={vehicleModelData} />}
                    </Col>
                </Row>}

                {vehicleYearLoading ? <Loader /> : <Row className='spacing-1 my-3 pl-3'>
                    <Col>
                        {<LeadByYear vehicleYearData={vehicleYearData} />}
                    </Col>
                </Row>}

                {roiNinventoryLoading ? <Loader /> : <Row className='spacing-1 mt-3 pl-3'>
                    <Col>
                        {<Report resData={roiNinventoryData && roiNinventoryData} setUser={setUser} options={options && options} valuesObj={valuesObj && valuesObj} />}
                    </Col>
                </Row>}
                {roiNinventoryLoading ? <Loader /> : <Row className='spacing-1 mt-3 pl-3'>
                    <Col>
                        {<InventoryReport resData={roiNinventoryData && roiNinventoryData} setUser={setUser} options={options && options} valuesObj={valuesObj && valuesObj} />}
                    </Col>
                </Row>}
            </> : <Applyfilter/>}
        </div >

    );
};
export default Dashboard;
