import React from 'react'

function Notessearch({handleChange}) {
  return (
    <div>
        <input type="text" name="" id="" onChange={handleChange} className='bg-white form-control form-control-sm p-2 mb-2' placeholder='Search notes..'/>
    </div>
  )
}

export default Notessearch