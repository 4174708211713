import React, { useContext, useState } from "react";
import { agentTypes } from "../common/globalValues";
import _ from "lodash";
import moment from "moment";
import { accessRoles } from "../../controller";
import { UserContext } from "../common/UserContext";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/delete.svg";
import Deletewarning from "../common/Deletewarning";
import { userDeleteApi } from "../../controllers/users";
import { toastify } from "../common/notification";
import downarrowoutline from "../../assets/icons/sortarrowoutlinedown.svg";
import uparrowoutline from "../../assets/icons/sortarrowoutlineup.svg";
import downarrowfill from "../../assets/icons/sortarrowfilleddown.svg";
import uparrowfill from "../../assets/icons/sortarrowfilledup.svg";
import activity from "../../assets/icons/activity.svg";
import "./style.css";
import {
  calculateAvgTime,
  calculateTotalTime,
} from "../common/calculatetotaltime";
function Userstable({
  data,
  refetch,
  page,
  sort,
  setSort,
  selectedFromDate,
  selectedToDate,
}) {
  const Globalvalues: any = agentTypes;
  const [deleteId, setDeleteId] = React.useState<any>(null);
  const { type, role, authToken } = useContext(UserContext);
  const history = useHistory();
  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  const [payload, setPayload] = useState<any>([]);

  const [activeSort, setActiveSort] = useState<any>(null);
  const [sortType, setSortType] = useState<any>("ASC");
  const [col, setCol] = useState<any>("Name");
  const editDelete = (data: any) => {
    if (
      accessRoles
        ?.filter(
          (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
        )[0]
        ?.permissions?.includes("editUsers")
    ) {
      if (
        accessRoles
          ?.filter(
            (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
          )[0]
          ?.permissions?.includes("editOnlyDealerAndCorporateInfo")
      ) {
        return (
          <td>
            <div className="action">
              {data.type === "dealer" || data.type === "corporate" ? (
                <div
                  className="iconDetail paddingRight"
                  onClick={() =>
                    history.push({
                      pathname: `/users/edit/${data._id}`,
                      state: data,
                    })
                  }
                >
                  <Image className="iconAction" src={editIcon} />
                </div>
              ) : (
                <div className="iconDetail paddingRight">
                  <Image className="iconAction not-allowed" src={editIcon} />
                </div>
              )}
              {
                <div
                  // onClick={() => {
                  //     setShow(!show);
                  //     // setDeleteId(data._id);
                  // }}
                  className="iconDetail paddingMiddle"
                >
                  <Image className="iconAction" src={deleteIcon} />
                </div>
              }
            </div>{" "}
          </td>
        );
      }
      return (
        <td>
          <div className="action">
            <div
              className="iconDetail paddingRight btn btn-sm"
              onClick={() =>
                history.push({
                  pathname: `/users/edit/${data._id}`,
                  state: data,
                })
              }
            >
              <Image title="Edit user" className="iconAction" src={editIcon} />
            </div>
            <div
              onClick={() => {
                setShow(!show);
                setDeleteId(data._id);
              }}
              className="iconDetail paddingRight btn btn-sm"
            >
              <Image
                title="Delete User"
                className="iconAction"
                src={deleteIcon}
              />
            </div>
            <div
              onClick={() => {
                history.push({
                  pathname: `/users/reporting/${data?._id}`,
                  state: { data: data, selectedFromDate, selectedToDate },
                });
              }}
              className="iconDetail paddingMiddle btn btn-sm"
            >
              <img
                title="User reporting"
                className=""
                src={activity}
                alt=""
                height={15}
              />
            </div>
          </div>
        </td>
      );
    }
  };

  const handleDelete = () => {
    const body: any = {
      type: "user",
      id: deleteId,
    };
    userDeleteApi(authToken, body).then((res: any) => {
      setShow(false);
      setDeleteId(null);
      refetch();
      if (res?.statusCode === 200) {
        toastify("success", "User deleted successfully.");
      } else {
        toastify("failure", "Unable to delete the user");
      }
    });
  };

  const handleSort = (field) => {
    let srt = sortType === "ASC" ? "DSC" : "ASC";
    setSortType(srt);
    setSort({ ...sort, type: srt, key: field });
    setCol(field);
  };
  // const avgTime = (timeArray, totalLeads) => {
  //     // Initialize variables to store the total time components
  //     let totalHours = 0;
  //     let totalMinutes = 0;
  //     let totalSeconds = 0;

  //     // Loop through the timeArray to accumulate total time
  //     timeArray?.forEach((time) => {
  //         totalHours += time?.timer?.hours;
  //         totalMinutes += time?.timer?.minutes;
  //         totalSeconds += time?.timer?.seconds;
  //     });
  //     // Calculate the total time in seconds
  //     const totalSecondsOverall = totalHours * 3600 + totalMinutes * 60 + totalSeconds;

  //     // Calculate the average time in seconds
  //     const averageSeconds = totalSecondsOverall / totalLeads;

  //     // Calculate the average hours, minutes, and seconds
  //     const averageHours = Math.floor(averageSeconds / 3600);
  //     const averageMinutes = Math.floor((averageSeconds % 3600) / 60);
  //     const averageSecondsRemainder = Math.floor(averageSeconds % 60);

  //     // Return the average time as an object
  //     console.log("avg", averageHours, averageMinutes, averageSecondsRemainder);

  //     return {
  //         hours: averageHours.toString().padStart(2, '0'),
  //         minutes: averageMinutes.toString().padStart(2, '0'),
  //         seconds: averageSecondsRemainder.toString().padStart(2, '0'),
  //     };
  // }

  // const calculateAvgTime = (timeArray) => {
  //     console.log("timeArray", timeArray);
  //     let filtered = (selectedFromDate || selectedToDate) ? timeArray?.filter((time) => new Date(time?.dateandtime) >= new Date(selectedFromDate) && new Date(time?.dateandtime) <= new Date(selectedToDate)) : timeArray
  //     console.log("filtered", filtered);
  //     let mergerd = [] as any;
  //     filtered?.map((sd: any) => {
  //         mergerd.push(...sd?.leads)
  //     })
  //     return avgTime(mergerd && mergerd, mergerd && mergerd?.length)
  // }

  // const addtime = (timeArray, totalLeads) => {
  //     console.log("timeArray", timeArray);

  //     let totalHours = 0;
  //     let totalMinutes = 0;
  //     let totalSeconds = 0;

  //     timeArray?.map((time) => {
  //         totalHours += time?.timer?.hours;
  //         totalMinutes += time?.timer?.minutes;
  //         totalSeconds += time?.timer?.seconds;
  //     });
  //     totalMinutes += Math.floor(totalSeconds / 60);
  //     totalSeconds %= 60;
  //     totalHours += Math.floor(totalMinutes / 60);
  //     totalMinutes %= 60;

  //     // Return the result as an object
  //     return { hours: totalHours.toString().padStart(2, '0'), minutes: totalMinutes.toString().padStart(2, '0'), seconds: totalSeconds.toString().padStart(2, '0'), totalLeads };
  // }

  // const calculateTotalTime = (timeArray) => {
  //     console.log("timeArray", timeArray);

  //     let filtered =(selectedFromDate || selectedToDate) ? timeArray?.filter((time) => new Date(time?.dateandtime) >= new Date(selectedFromDate) && new Date(time?.dateandtime) <= new Date(selectedToDate)) : timeArray
  //     console.log("filtered", filtered);

  //     let mergerd = [] as any;
  //     filtered?.map((sd: any) => {
  //         mergerd.push(...sd?.leads)
  //     })
  //     console.log("mergerd", mergerd);
  //     return addtime(mergerd && mergerd, mergerd && mergerd?.length)
  // }

  return (
    <div>
      <table className="table table-hover table-bordered">
        <thead className="table-head shadow-sm" style={{ fontSize: "14px" }}>
          <tr>
            <th scope="col" className="p-3 table-center-ele">
              #
            </th>
            <th
              scope="col"
              className="p-3 table-center-ele d-flex justify-content-between"
              onClick={() => {
                handleSort("Name");
              }}
            >
              <span className="mt-auto mb-auto">Name</span>
              <div className="d-flex flex-column">
                <img
                  src={
                    sortType === "ASC" && col === "Name"
                      ? uparrowfill
                      : uparrowoutline
                  }
                  alt=""
                />
                <img
                  src={
                    sortType === "DSC" && col === "Name"
                      ? downarrowfill
                      : downarrowoutline
                  }
                  alt=""
                />
              </div>
            </th>
            <th scope="col" className="p-3 table-center-ele">
              Email
            </th>
            {type !== "dealer" && (
              <th scope="col" className="p-3 table-center-ele">
                Company
              </th>
            )}
            {type !== "dealer" && (
              <th scope="col" className="p-3 table-center-ele">
                Type
              </th>
            )}
            <th scope="col" className="p-3 table-center-ele">
              Role
            </th>
            <th
              scope="col"
              className="p-3 table-center-ele d-flex justify-content-between"
              onClick={() => {
                handleSort("CreatedAt");
              }}
            >
              <span className="mt-auto mb-auto">Created</span>
              <div className="d-flex flex-column">
                <img
                  src={
                    sortType === "ASC" && col === "CreatedAt"
                      ? uparrowfill
                      : uparrowoutline
                  }
                  alt=""
                />
                <img
                  src={
                    sortType === "DSC" && col === "CreatedAt"
                      ? downarrowfill
                      : downarrowoutline
                  }
                  alt=""
                />
              </div>
            </th>
            <th scope="col" className="p-3 table-center-ele">
              Modified
            </th>
            <th scope="col" className="p-3 table-center-ele">
              Leads Accessed
            </th>
            <th scope="col" className="p-3 table-center-ele">
              Total time
            </th>
            <th scope="col" className="p-3 table-center-ele">
              Avg Time
            </th>
            {accessRoles
              ?.filter(
                (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
              )[0]
              ?.permissions?.includes("editUsers") && (
              <th scope="col" className="p-3 table-center-ele">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody style={{ fontWeight: "normal", fontSize: "14px" }}>
          {data?.map((user, indx) => (
            <tr key={user?._id}>
              <td className=" table-center-ele">
                {(page - 1) * 10 + indx + 1}
              </td>
              <td className=" table-center-ele">
                {user?.first_name + " " + user?.document?.last_name}
              </td>
              <td className=" table-center-ele">{user?.document?.email}</td>
              {type !== "dealer" && <td>{user?.document?.company}</td>}
              {type !== "dealer" && <td>{user?.document?.type}</td>}
              <td className=" table-center-ele">
                {Globalvalues[_.toLower(user?.document?.role)]}
              </td>
              <td className=" table-center-ele">
                {moment(user?.document?.createdAt).format("LLL")}
              </td>
              <td className=" table-center-ele">
                {moment(user?.document?.updatedAt).format("LLL")}
              </td>
              <td className=" table-center-ele">
                {calculateTotalTime(
                  user?.document?.active_leads,
                  selectedFromDate,
                  selectedToDate
                )?.totalLeads || 0}
              </td>
              <td className=" table-center-ele">
                {calculateTotalTime(
                  user?.document?.active_leads,
                  selectedFromDate,
                  selectedToDate
                )?.hours +
                  ":" +
                  calculateTotalTime(
                    user?.document?.active_leads,
                    selectedFromDate,
                    selectedToDate
                  )?.minutes +
                  ":" +
                  calculateTotalTime(
                    user?.document?.active_leads,
                    selectedFromDate,
                    selectedToDate
                  )?.seconds}
              </td>
              <td className=" table-center-ele">
                {user?.document?.active_leads
                  ? calculateAvgTime(
                      user?.document?.active_leads,
                      selectedFromDate,
                      selectedToDate
                    )?.hours +
                    ":" +
                    calculateAvgTime(
                      user?.document?.active_leads,
                      selectedFromDate,
                      selectedToDate
                    )?.minutes +
                    ":" +
                    calculateAvgTime(
                      user?.document?.active_leads,
                      selectedFromDate,
                      selectedToDate
                    )?.seconds
                  : "00:00:00"}
              </td>
              <td className=" table-center-ele">
                {editDelete(user?.document)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Deletewarning
        closeBtnLabel="Cancel"
        actionBtnLabel="Delete"
        message={"Are you sure you want to delete the user?"}
        show={show}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Delete User"
      />
    </div>
  );
}

export default Userstable;
