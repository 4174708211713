import React from 'react';
// import NoDataImg from '../../assets/icons/noData.svg';
import NoDataImg from '../../assets/icons/no-results.png';
import './index.css';

type NoDataFoundPropsT = {
    message1: string;
    message2: string;
};

const NoDataFound= ({ img ,message1, message2,height, width }) => {
    return (
        <div className='noDataContainer'>
            <img src={!img ? NoDataImg : img} alt='No Data' width={width ? width : 160} height={height ? height : 160} />
            <div className='noDataMessageConatiner'>
                <p className='noDataMessage1' style={{fontSize:height ? "18px" : '21px'}}>{message1}</p>
                <p className='noDataMessage2' style={{fontSize:height ? "15px" : '18px'}}>{message2}</p>
            </div>
        </div>
    );
};

export default NoDataFound;
