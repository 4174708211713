/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Pie, Bar } from 'react-chartjs-2'
// import { ModelsData } from '../Data'
import { Chart as chartJS, registerables, Tooltip } from 'chart.js';
import { dashboard } from '../../../controllers/dashboard';
import { useQuery } from 'react-query';
import { ColumnDescription, TableChangeHandler } from 'react-bootstrap-table-next';
import { UserContext } from '../../common/UserContext';
import { allLeadsApi } from '../../../controllers/leads';
import { CustomTable } from '../../common/customTable';
import axios from "axios";
import config from '../../../config.json';
import { toastify } from "../../common/notification";
import Loader from '../../common/Loader';
import ReactPaginate from 'react-paginate';
import { Col, Row, Modal, Card, Form, Button, Table, Tab, Nav } from 'react-bootstrap';
import { Grid } from '@mui/material';
const url = config['baseHost_backend'];
chartJS.register(...registerables, Tooltip);
function Report({ vehicleModelData }: any) {
    const [scrollMin, setScrollMin] = useState(0)
    const [scrollMax, setScrollMax] = useState(9)

    const [BarXlength, setBarXlength] = useState<any>(null)

    const { authToken } = useContext(UserContext);
    const [myData, setmyData] = useState<any>(null)
    const [filter, setFilter] = useState<any>('All')
    const { data: dashboardData, isLoading: isLeadsLoading } = useQuery('drm-dashboard', () =>
        dashboard(authToken, {}), { staleTime: 3600000 }
    );
    const [pageNumber, setPageNumber] = useState(0);
    const [rowPerPage, setRowperPage] = useState(10);
    const [data, setData] = useState<any>(null)
    const [displayData, setDisplayData] = useState<any>(null)
    const pageVisited = pageNumber * rowPerPage;
    const [loading, setLoading] = useState<boolean>(false)
    const [dealers, setDealers] = useState<any>(null)
    useEffect(() => {
        if (vehicleModelData) {
            setData(vehicleModelData)

        }
    }, [vehicleModelData])


    useEffect(() => {
        if (data && displayData) {
            const labelValue = filter === 'All' ? data?.sort((a: any, b: any) =>
                (a.percent > b.percent) ? -1 : 1).filter((model: any) => typeof (model._id) === 'string' && model._id.split('').length > 1).map((car: any) => car._id) : displayData?.map((car: any) => car._id)
            setBarXlength(labelValue.length)
            setmyData({
                labels: labelValue,
                datasets: [
                    {
                        label: 'Percentage of Lead Generation',
                        data: filter === 'All' ? data?.sort((a: any, b: any) =>
                            (a.percent > b.percent) ? -1 : 1).filter((model: any) => typeof (model._id) === 'string' && model._id.split('').length > 1).map((car: any) => (Math.ceil(car.percent))) : displayData?.map((car: any) => (Math.ceil(car.percent))),
                        backgroundColor: ['blue'],
                        barThickness: 40,
                    },
                ],
            })
        }
    }, [data, displayData, pageNumber, filter, scrollMax, scrollMin])


    useEffect(() => {

        let tempArray = [] as any;
        if (data) {

            if (filter === 'All') {
                data?.filter((model: any) => typeof (model._id) === 'string' && model._id.split('').length > 1)
                    .sort((a: any, b: any) =>
                        (a.count > b.count) ? -1 : 1)
                    .slice(pageVisited, pageVisited + rowPerPage)
                    .map((car: any) => {
                        tempArray.push({
                            _id: car._id,
                            percent: car.percent,
                            count: car.count
                        })
                    })
                setDisplayData(tempArray);


            }
            else {
                data?.filter((car: any) => car._id === filter)
                    .sort((a: any, b: any) =>
                        (a.count > b.count) ? -1 : 1)
                    .slice(pageVisited, pageVisited + rowPerPage)
                    .map((car: any) => {
                        tempArray.push({
                            _id: car._id,
                            percent: car.percent,
                            count: car.count
                        })
                    })
                setDisplayData(tempArray);
            }
        }
    }, [data, filter, pageNumber])




    let pageCount = filter === 'All' ?
        data?.filter((model: any) => typeof (model._id) === 'string' && model._id.split('').length > 1).length / rowPerPage
        : Math.ceil(displayData?.length / rowPerPage)




    const handleFilter = (e: any) => {
        setFilter(e.target.value)
    }

    const handlePageClick = (Selected: any) => {

        setPageNumber(Selected.selected)

    }


    return loading ? <Loader /> : (
        <Grid container style={{ width: '500px' }} className="container row w-100 container row w-100 d-flex justify-content-center m-auto bg-light shadow-sm py-3">
            {/* <h2 className='font-weight-bold py-0 my-2'>Lead By Model Report</h2> */}
            <h2 className='font-weight-bold py-0 my-2'>Lead By Vehicle Models</h2>
            <Grid item lg={6} className='spacing-1'   >

                <table className="table">
                    <thead className="thead-dark bg-dark text-white">
                        <tr>
                            <th scope="col">Model Name</th>
                            <th scope="col">Count</th>
                            <th scope="col">Percentage of Leads</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayData?.length >= 0 ?
                            displayData?.map((car: any, index: any) => (
                                <tr>
                                    <td>{car._id}</td>
                                    <td>{(car.count)}</td>
                                    <td>{Math.ceil(car.percent)}%</td>
                                </tr>
                            ))
                            : <div><h3>No Data Found</h3></div>}
                    </tbody>

                </table>
                <ReactPaginate

                    nextLabel=">>"
                    onPageChange={handlePageClick}
                    pageCount={data?.length >= rowPerPage ? pageCount : 1}
                    previousLabel="<<"
                    containerClassName='pagination'
                    pageClassName={filter === 'All' ? 'pagination' : 'page-item disabled'}
                    // pageClassName='page-item'
                    pageLinkClassName='page-link'
                    previousClassName='page-item'
                    previousLinkClassName='page-link'
                    nextClassName='page-item'
                    nextLinkClassName='page-link'
                    breakLabel='...'
                    breakLinkClassName='page-link'
                    activeClassName='active'
                    breakClassName='page-item'
                />
            </Grid>

            <Grid item lg={6} className={myData?.labels?.length < 1 ? "pacing-1 d-flex m-auto justify-content-center" : "pacing-1"}>
                {myData?.labels?.length < 1 ? <div><h3>No Data Found</h3></div> :
                    (<>
                        <Form.Select className='mb-3 select-field-sm' onChange={handleFilter}>
                            <option value="All">Model Name</option>
                            {
                                data?.filter((model: any) => typeof (model._id) === 'string' && model._id.split('').length > 1).map((car: any, index: any) => (
                                    <option value={car._id} key={index}>{car._id}</option>
                                ))
                            }

                        </Form.Select>

                        <div style={{ height: '400px' }}>
                            {myData && <Bar
                                data={myData}
                                width="100px"
                                height="100px"
                                options={{
                                    responsive: true, maintainAspectRatio: false,
                                    scales: {
                                        xAxes: {
                                            grid: {
                                                display: false
                                            },
                                            ticks: { autoSkip: false }, min: scrollMin, max: scrollMax
                                        },
                                        yAxes: {
                                            grid: {
                                                display: false
                                            },
                                            ticks: {
                                                stepSize: 2, callback: function (value, index, ticks) {
                                                    return value + '%';
                                                }
                                            }
                                        }
                                    }
                                }} />}
                        </div>
                        <div className='d-flex justify-content-between'>
                            <button className='btn btn-light shadow-lg' disabled={scrollMin === 0 || filter !== 'All'} onClick={() => {
                                setScrollMin(scrollMin - 10)
                                setScrollMax(scrollMax - 10)
                            }}>Prev</button>
                            <button className='btn btn-light shadow-lg' disabled={(BarXlength < scrollMax) || filter !== 'All'} onClick={() => {
                                setScrollMin(scrollMin + 10)
                                setScrollMax(scrollMax + 10)
                            }}>Next</button>
                        </div>
                    </>)}
            </Grid>

        </Grid>
    )
}

export default Report