import React from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import _ from 'lodash'
import Select from 'react-select';

function Filter({ setSelectedLead, dealers, leads, setSelectedDate, filter, resetFilter, dealerRef, leadRef, values, setSelectedDealer }: any) {
  return (
    <div className='d-flex gap-3'>
      <div className='d-flex flex-column'>
        <span className='filter-control-lable'>Select date</span>
        {/* <input type="date"ref={dateRef} name="" id="" className='form-control bg-light m-0' style={{ width: '200px', padding: '6px' }} onChange={(e) => setSelectedDate(e.target.value)} /> */}
        <DatePicker
          // style={{ width}}
          style={{ padding: '18px' }}
          value={values}
          placeholder={'MM/DD/YYYY'}
          format="MM/DD/YYYY"
          onChange={setSelectedDate}
          range
          plugins={[<DatePanel />]}
        />
      </div>

      <div style={{ width: '200px' }}>
        <span className='filter-control-lable'>Select lead</span>
        <Select closeOnSelect={true}
          isSearchable={true}
          options={leads && leads}
          defaultValue="All"
          ref={leadRef}
          onChange={(op: any) => setSelectedLead(op.value)}
        ></Select>
      </div>
      <div style={{ width: '200px' }}>
        <span className='filter-control-lable'>Select dealer</span>
        <Select closeOnSelect={true}
          isSearchable={true}
          options={dealers && dealers}
          defaultValue="All"
          ref={dealerRef}
          onChange={(op: any) => { setSelectedDealer(op.value) }}
        ></Select>
      </div>
      <button className='btn pbuttonbgcolor mt-auto mb-1 btn-sm' onClick={filter} >Filter</button>
      <button className='btn sbuttonbgcolor mt-auto mb-1 btn-sm' type='reset'>Reset filter</button>
    </div>
  )
}

export default Filter