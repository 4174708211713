/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { createDraftemail, fetchInboxBasedonleadEmail, fetchSentItemsBasedonleadEmail, getDraftemails, getemails, inboxmsgs, reply, sendExistingDraftEmail, sendemail, sentmails } from '../../GraphService'
import { useAppContext } from '../../AppContext';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Card,
    Chip,
    FormControl,
    Input,
    TextField,
    TextareaAutosize,
    makeStyles,
    Tab,
    Tabs
} from "@material-ui/core";
import { Modal, ModalBody } from 'react-bootstrap';
import Inbox from './Inbox';

import JoditReact from "jodit-react-ts";

import arrowright from '../../assets/images/arrowright.svg'
import house from '../../assets/images/house.svg'
import Sentitems from './Sentitems';
import { toastify } from '../common/notification';
import './style.css'
import { UserContext } from '../common/UserContext';
import refresh from '../../assets/images/refresh.svg'
import mail from '../../assets/images/mailwhitebg.svg'
import { useQuery } from 'react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { sendNotification } from './notification';
import { merger_fields } from '../../controller';
import moment from 'moment';
import config from '../../config.json'
import axios from 'axios';
import { findKey } from '../common/keyvalidation';
function Maillist({ email, dealerDetails, leadDetails }) {
    let filterEmail = config.filterEmail
    const { shortName, type, dealerID, user_first_name, user_last_name, authToken, aliasName } = useContext<any>(UserContext)
    let dealerAliasName = aliasName?.toLowerCase();
    let fromAddress = "notifications@eautoquote.net"
    const app: any = useAppContext();
    const location: any = useLocation();
    const [mf, setMf] = useState<any>(null)
    const [inboxItems, setInboxItems] = React.useState<any>(null);
    const [draftItems, setDraftItems] = React.useState<any>(null);
    const [tabs, setTabs] = React.useState<any>(0);
    const [sentItems, setSentItems] = React.useState<any>(null);
    const [show, setShow] = React.useState<any>(false);
    const [propData, setPropData] = React.useState<any>(null);
    const history = useHistory()

    //org
    let url = type === "dealer" ? `/me/mailFolders/Inbox/messages?&$search="recipients:notifications+${dealerAliasName}"` : `/me/mailFolders/Inbox/messages`
    const reactQuillref: any = useRef<any>(null);
    let sentItemsurl = `/me/mailFolders/sentItems/messages`
    const [values, setValues] = React.useState<any>(location.pathname.includes('leads') ? [email] : []);
    const [ccs, setCcs] = React.useState<any>([]);
    const [cccurrValue, setCccurrValue] = React.useState("");
    const [mailBody, setMailBody] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [currValue, setCurrValue] = React.useState("");
    const [nextPageLink, setNextPageLink] = React.useState<any>(null);
    const [currPageLink, setCurrPageLink] = React.useState<any>(url);
    const [sentItemCurrPageLink, setSentItemCurrPageLink] = React.useState<any>(sentItemsurl);
    // const textArea = useRef<any>(null)

    const useStyles = makeStyles((theme) => ({
        formControlRoot: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "100%",
            flexWrap: "wrap",
            flexDirection: "row",
            border: '1px solid lightgray',
            padding: 4,
            // borderRadius: '4px',
            "&> div.container": {
                gap: "6px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
            },
            "& > div.container > span": {
                backgroundColor: "gray",
                padding: "1px 3px",
                // borderRadius: "4px"
            }
        }
    }));
    const classes = useStyles();
    const editor: any = React.useRef(null);


    const fetchMsgs = async () => {
        location.pathname.includes("mails") && localStorage.removeItem("LEAD_PAGE_URL")
        if (tabs === 0) {
            let inboxMsgs: any
            //if it is lead details page
            if (location.pathname.includes('leads')) {
                inboxMsgs = await fetchInboxBasedonleadEmail(app.authProvider, email)
            } else {
                // inboxMsgs = await inboxmsgs(app.authProvider, 10, currPageLink)
                inboxMsgs = await inboxmsgs(app.authProvider, 10, currPageLink)
                console.log("filteredFrom", inboxMsgs);
            }

            var groupBy = function (xs, key) {
                return xs.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };

            let convertIDGrouped: any = groupBy(inboxMsgs?.value?.sort((a, b) => a.sentDateTime > b.sentDateTime ? -1 : 1), 'conversationId');
            let temp = [] as any;
            for (const key in convertIDGrouped) {
                if (convertIDGrouped.hasOwnProperty(key)) {
                    const values = convertIDGrouped[key];
                    let notRead = values.filter((d) => !d.isRead)
                    // console.log('notRead:', notRead);
                    temp.push(values[values.length - 1])
                }
            }
            setInboxItems(temp)

        } else if (tabs === 1) {
            let sentItems: any
            if (location.pathname.includes('leads')) {
                sentItems = await fetchSentItemsBasedonleadEmail(app.authProvider, email)
            } else {
                sentItems = await sentmails(app.authProvider, 10, sentItemCurrPageLink)
                // console.log("sentItems", sentItems);

                //sent items cannot filtered based on To, so filtering based on replyTo. replyTo filtering is not supported by graph API that's y using custom js filter
                if (type === "dealer") {
                    let filtered: any = {
                        value: []
                    }
                    // eslint-disable-next-line array-callback-return
                    sentItems?.value?.filter((jj) => {
                        if (jj?.replyTo?.[0]?.emailAddress?.address === `notifications+${dealerAliasName}@eautoquote.net`) {
                            filtered.value.push(jj)
                        }
                    })
                    sentItems = filtered;
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var groupBy = function (xs, key) {
                return xs.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };
            console.log("sentItems", sentItems);

            let convertIDGrouped: any = groupBy(sentItems?.value?.sort((a, b) => a.sentDateTime > b.sentDateTime ? -1 : 1), 'conversationId');
            // console.log("group2", convertIDGrouped);
            let temp = [] as any;
            for (const key in convertIDGrouped) {
                if (convertIDGrouped.hasOwnProperty(key)) {
                    const values = convertIDGrouped[key];
                    temp.push(values[values.length - 1])
                }
            }
            setSentItems(temp)
        } else {
            //fetch draft msgs
            let drafts: any = await getDraftemails(app.authProvider)
            setDraftItems(drafts?.value)
        }
    }

    useEffect(() => {
        const fetchMergeFields = async () => {
            let body = {
                function: "fetchAll"
            }

            let resp = await axios.post(config.baseHost_backend + "/mergefield", body, {
                headers: {
                    Authorization: authToken
                },
            })
            // console.log("merge fields api", resp)
            setMf(resp?.data?.body)
        }
        fetchMergeFields();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { isLoading, refetch } = useQuery(['fetch-conversations', tabs], fetchMsgs)

    const refreshData = async () => {
        refetch()
    }

    const handleMergeFieldSelected = (e: any) => {
        if (e.target.value !== "null") {
            const selectedOption = e.target.selectedOptions[0];
            const objectString = selectedOption.getAttribute('data-object');
            const object = JSON.parse(objectString);
            // console.log("object", object);

            const textarea = reactQuillref.current;
            if (textarea) {
                textarea.focus();
                const quillInstance = textarea.getEditor();
                if (quillInstance) {
                    const cursorPosition = quillInstance.getSelection().index;
                    quillInstance.insertText(cursorPosition, `[${object?.value}]`);
                }
            }
        }
    }

    const handleChange = (e) => {
        setCurrValue(e.target.value);
    };
    const handleChangecc = (e) => {
        setCccurrValue(e.target.value);
    };
    const handleDelete = (item, index, type) => {
        let arr = type === "To" ? [...values] : [...ccs]
        arr.splice(index, 1)
        type === "To" ? setValues(arr) : setCcs(arr)
    }
    const handleKeyUp = (e) => {
        if (e.target.value && e.target.value.trim()) {
            setValues((oldState: any) => [...oldState, e.target.value]);
            setCurrValue("");
        }
    };

    const handleKeyUpcc = (e) => {
        if (e.target.value && e.target.value.trim()) {
            setCcs((oldState: any) => [...oldState, e.target.value]);
            setCccurrValue("");
        }
    };

    const newmail = async () => {
        setShow(true)
    }

    const handleSend = async () => {
        let temp = [] as any;
        let temp2 = [] as any;
        let tempTos = [] as any
        values?.forEach((g) => {
            tempTos.push(g)
            temp.push({
                "emailAddress": {
                    "address": g,
                }
            })
        })
        ccs?.forEach((g) => {
            temp2.push({
                "emailAddress": {
                    "address": g,
                    "name": ""
                }
            })
        })
        console.log("dealerAliasName", dealerAliasName);

        let replyToEmail: any
        let replyToUsername: any

        if (location.pathname.includes('leads')) {
            replyToEmail = `notifications+${dealerAliasName}@eautoquote.net`
            replyToUsername = shortName
            // replyToEmail="neuron@eautoquote.net"
        } else {
            if (type === "dealer") {
                replyToEmail = `notifications+${dealerAliasName}@eautoquote.net`;
                replyToUsername = shortName
            } else {
                replyToEmail = `notifications@eautoquote.net`
                replyToUsername = "eAutoQuote Notifications"
            }
        }

        let mergeFieldPattern = /\[([^\]]+)\]/g;
        let hasmergeField = mergeFieldPattern.test(mailBody)

        let org_mailbody = mailBody;

        console.log("shortName", shortName);
        console.log("replyToEmail", replyToEmail);
        console.log("replyToUsername", replyToUsername);

        //if email body contains merge fields
        if (hasmergeField) {
            //fetch lead & dealer details for if dealer inbox
            tempTos?.forEach(async (tp) => {
                let body = {
                    type: "fetchDetails",
                    leadEmail: tp,
                }
                const result: any = await axios.post(config.baseHost_backend + "/forgot-passowrd", body, {
                    headers: {
                        Authorization: authToken
                    },
                })

                if (result?.data?.statusCode === 400) {
                    return toastify('failure', `Unable to map the merge-fields, this email address ${tp} does not matching with any leads records.`)
                }

                let leadDetail = result?.data?.body?.leadDetails;
                let dealerDetail = result?.data?.body?.dealerInfo;

                org_mailbody = mailBody.replace(mergeFieldPattern, (match, mergeField) => {
                    return findKey(leadDetail, mergeField) || findKey(dealerDetail, mergeField) || findKey({ user_first_name, user_last_name }, mergeField)
                });

                let draftEmailBody = {
                    "subject": subject,
                    "importance": "Low",
                    "body": {
                        "contentType": "HTML",
                        "content": org_mailbody
                    },
                    "toRecipients": [
                        {
                            "emailAddress": {
                                "address": tp,
                            }
                        }
                    ],
                    // "from": {
                    //     "emailAddress": {
                    //         "address": "neuron@eautoquote.net"
                    //     }
                    // },
                    "ccRecipients": temp2,
                    "replyTo": [
                        {
                            "emailAddress": {
                                "address": replyToEmail,
                                "name": replyToUsername
                            }
                        }
                    ]
                }
                let draft = await createDraftemail(app.authProvider, draftEmailBody)

                if (draft) {
                    let sentEmiail = await sendExistingDraftEmail(app.authProvider, draft?.id)
                    // console.log("email send", sentEmiail)
                    if (sentEmiail) {
                        toastify('success', 'Email sent successfully');
                        setShow(false);
                        let body = {
                            type: "CRM_inbox",
                            to: replyToEmail,
                            body: mailBody,
                            replyTo: replyToEmail
                        }
                        //send notification to dealer in crm page
                        location.pathname.includes('leads') && sendNotification(body).then((res) => {
                        }).catch((err) => {
                        })

                        history.push({ pathname: localStorage.getItem("LEAD_PAGE_URL") || '/mails', state: { dataChanged: true, tabs: 0 } })
                    }
                }
            })
        } else {
            let draftEmailBody = {
                "subject": subject,
                "importance": "Low",
                "body": {
                    "contentType": "HTML",
                    "content": org_mailbody
                },
                // "from": {
                //     "emailAddress": {
                //         "address": fromAddress,
                //         "name": replyToUsername
                //     }
                // },
                "toRecipients": temp,
                "ccRecipients": temp2,
                "replyTo": [
                    {
                        "emailAddress": {
                            "address": replyToEmail,
                            "name": replyToUsername
                        }
                    }
                ]
            }
            let draft = await createDraftemail(app.authProvider, draftEmailBody)
            if (draft) {
                let sentEmiail = await sendExistingDraftEmail(app.authProvider, draft?.id)
                // console.log("email send", sentEmiail)
                if (sentEmiail) {
                    toastify('success', 'Email sent successfully');
                    setShow(false);
                    let body = {
                        type: "CRM_inbox",
                        to: replyToEmail,
                        body: mailBody,
                        replyTo: replyToEmail
                    }
                    //send notification to dealer in crm page
                    location.pathname.includes('leads') && sendNotification(body).then((res) => {
                        // console.log("res", res)
                    }).catch((err) => {
                        // console.log("Error", err)
                    })

                    history.push({ pathname: localStorage.getItem("LEAD_PAGE_URL") || '/mails', state: { dataChanged: true, tabs: 0 } })
                }
            }
        }
    }



    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabs(newValue)
    }

    useEffect(() => {
        if (location?.state?.tabs) {
            setTabs(location.state.tabs)
        }
    }, [location.state])


    return (
        <div className='px-2'>
            <div className='d-flex justify-content-between mt-2'>
                <Tabs value={tabs} onChange={handleTabChange} >
                    <Tab label="Inbox" style={{ backgroundColor: tabs === 0 ? '#343a4024' : '#ffff', color: 'black' }} onClick={() => { setNextPageLink(null) }} />
                    <Tab label="Sent" style={{ backgroundColor: tabs === 1 ? '#343a4024' : '#ffff', color: 'black' }} onClick={() => { setNextPageLink(null) }} />
                    {/* <Tab label="Draft" style={{ backgroundColor: tabs === 2 ? '#343a4024' : '#ffff', }} onClick={() => { setNextPageLink(null) }} /> */}

                </Tabs>
                <div className='mt-auto mb-auto d-flex flex-row'>
                    <div className='d-flex flex-row justify-content-end'>
                    </div>
                    <button onClick={refreshData} className='btn btn-sm mt-auto mb-auto'><img src={refresh} alt="Refresh" /> Refresh</button>
                    <button onClick={newmail} className='btn btn-success btn-sm mt-auto mb-auto ml-3'><img src={mail} alt="mail" className='mr-1' /> New mail</button>
                    <button className='btn btn-link text-danger' onClick={app.signOut!}>sign out <img src={arrowright} alt="arrow" /></button>
                </div>
            </div>

            {tabs === 0 && <Inbox data={inboxItems} handleRefresh={refreshData} dealerDetails={dealerDetails} leadDetails={leadDetails} />}
            {tabs === 1 && <Sentitems data={sentItems && sentItems} handleRefresh={refreshData} dealerDetails={dealerDetails} leadDetails={leadDetails} />}

            {/* New email */}
            <Modal show={show} fullscreen >
                <ModalBody>
                    <div className='p-4'>
                        <div className='mb-3'>
                            <TextField required fullWidth placeholder='Add a subject' autoFocus onChange={(e) => { setSubject(e.target.value) }} />
                        </div>
                        <div className='d-flex flex-row mb-2 '>
                            <button className='btn text-dark mt-auto mb-auto' style={{ borderTopRightRadius: '0px', borderBottomRightRadius: "0px", border: '1px solid lightgray' }}>To</button>
                            <FormControl classes={{ root: classes.formControlRoot }} size='small' className='mt-auto mb-auto'>
                                <div >
                                    {values.map((item, index) => (
                                        <Chip size="small" onDelete={() => handleDelete(item, index, "To")} label={item} className='m-1' style={{ fontWeight: 'lighter' }} />
                                    ))}
                                </div>
                                <input
                                    type='text'
                                    style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: "0px", border: 'none', padding: '2px' }}
                                    value={currValue}
                                    onChange={handleChange}
                                    onBlur={handleKeyUp}
                                    className='chipsinput'

                                />
                            </FormControl>
                        </div>

                        <div className='d-flex flex-row mb-3 '>
                            <button className='btn btn-light mt-auto mb-auto' style={{ borderTopRightRadius: '0px', borderBottomRightRadius: "0px", border: '1px solid lightgray' }}>Cc</button>
                            <FormControl classes={{ root: classes.formControlRoot }} size='small' className='mt-auto mb-auto'>
                                <div >
                                    {ccs.map((item, index) => (
                                        <Chip size="small" onDelete={() => handleDelete(item, index, "Cc")} label={item} className='m-1' style={{ fontWeight: 'lighter' }} />
                                    ))}
                                </div>
                                <input
                                    type='text'
                                    style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: "0px", border: 'none', padding: '2px' }}
                                    value={cccurrValue}
                                    onChange={handleChangecc}
                                    onBlur={handleKeyUpcc}
                                    className='chipsinput'
                                />
                            </FormControl>
                        </div>

                        <div style={{ fontWeight: 'normal' }}>
                            <select name="" id="" className='form-select mt-auto mb-auto w-25' onChange={handleMergeFieldSelected} style={{ float: 'right' }}>
                                <option value="null">Select merge field</option>
                                {mf && mf?.sort((a, b) => a?.label?.toLocaleLowerCase() > b?.label?.toLocaleLowerCase() ? 1 : -1)?.map((mf) => (
                                    <option value={mf?.value} data-object={JSON.stringify(mf)}>{mf?.label}</option>
                                ))}
                            </select>
                            <ReactQuill ref={reactQuillref} value={mailBody} onChange={setMailBody} placeholder='Type here...' theme="snow" />
                        </div>
                        <button className='btn btn-primary my-3 btn-sm' onClick={handleSend} disabled={!subject || !values?.length}>Send</button>
                        <button className='btn btn-outline-dark my-3 ml-2 btn-sm' onClick={() => { setShow(false);setValues([]);setCcs([]);setMailBody("") }}>Discard</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default Maillist