// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import { Col, Image, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import rapicon from '../../assets/images/rapIcon.png';

import logout from '../../assets/images/logout.svg';
import './sideBar.css'
import { UserContext } from '../common/UserContext';
import sidebarItems from '../../controllers/SidebarElements';
import { useHistory, useLocation } from 'react-router';
import dashboard from '../../assets/images/dashboard.svg';
import users from '../../assets/images/users.svg';
import bot from '../../assets/images/bot.svg';
import books from '../../assets/images/books.svg';
import mail from '../../assets/images/mail.svg';
import phoneicon from '../../assets/icons/phone.svg';
import template from '../../assets/images/template.svg';
import chartBar from '../../assets/images/chart-line-solid.svg';
import cpu from '../../assets/images/cpu.svg';
import activity from '../../assets/icons/activity.svg'
import notoficationBell from '../../assets/images/notificationbell.svg';
import { accessRoles } from '../../controller';
import _ from 'lodash'
const Sidebar = () => {
    const { role, name, type, shortName } = useContext(UserContext);
    const { push } = useHistory();
    const location = useLocation();
    const history = useHistory();
    const logoutHandler = () => {
        localStorage.clear();
        history.push('/login');
    };

    const pathCheck = location.pathname.replace('/', '').split('/');
    console.log("pathCheck", pathCheck);

    // console.log("unReadMessagesCount", unReadMessagesCount);

    return (
        <div className='sidebarContainer'>
            <Row className='iconContainer'>
                <Col className='navigationIcon'>
                    <Row>
                        <Col className='icon'>
                            <Image className='logo text-white' src={rapicon} />
                        </Col>
                        <div
                            className={
                                pathCheck[0] === 'dashboard'
                                    ? 'side-bar-items side-bar-items-active'
                                    : 'side-bar-items'
                            }
                            onClick={() => push(`/dashboard`)}
                        >
                            <OverlayTrigger

                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Dashboard</strong>
                                    </Tooltip>
                                }
                            >
                                <img src={chartBar} alt={"Dashboard"} width={'20px'} />
                            </OverlayTrigger>
                        </div>

                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowLeadsandOpportunities") && <div
                            className={
                                pathCheck[0] === 'leads'
                                    ? 'side-bar-items side-bar-items-active'
                                    : 'side-bar-items'
                            }
                            onClick={() => push(`/leads`)}
                        >
                            <OverlayTrigger

                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Qualification and Opportunities</strong>
                                    </Tooltip>
                                }
                            >
                                <img src={books} alt={"leads"} width={'20px'} />
                            </OverlayTrigger>
                        </div>}

                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowDealersandCorporate") && <div
                            className={
                                pathCheck[0] === 'dealers'
                                    ? 'side-bar-items side-bar-items-active'
                                    : 'side-bar-items'
                            }
                            onClick={() => push(`/dealers`)}
                        >
                            <OverlayTrigger

                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Dealers</strong>
                                    </Tooltip>
                                }
                            >
                                <img src={dashboard} alt={"dealers"} width={'20px'} />
                            </OverlayTrigger>
                        </div>}

                        {/* {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowUsers") &&
                            <div
                                className={
                                    pathCheck[0] === 'users'
                                        ? 'side-bar-items side-bar-items-active'
                                        : 'side-bar-items'
                                }
                                onClick={() => push(`/users`)}
                            >
                                <OverlayTrigger

                                    placement='right'
                                    overlay={
                                        <Tooltip>
                                            <strong>Users</strong>
                                        </Tooltip>
                                    }
                                >
                                    <img src={users} alt={"users"} width={'20px'} />
                                </OverlayTrigger>
                            </div>} */}

                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowUsers") && <div
                            style={{ position: 'relative' }}
                            className={
                                pathCheck[0] === 'users'
                                    ? 'side-bar-items'
                                    : 'side-bar-items'
                            }
                            onClick={() => { push(`/users`); }}
                        >
                            <OverlayTrigger
                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Users</strong>
                                    </Tooltip>
                                }
                            >
                                <img src={users} alt={"CPU"} width={'20px'} className={pathCheck[0] === 'users' ? 'side-bar-items-active' : ''} />
                            </OverlayTrigger>
                        </div>}

                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowBotHealthMonitoring") &&
                            <div
                                className={
                                    pathCheck[0] === 'bot-health-monitor'
                                        ? 'side-bar-items side-bar-items-active'
                                        : 'side-bar-items'
                                }
                                onClick={() => push(`/bot-health-monitor`)}
                            >
                                <OverlayTrigger

                                    placement='right'
                                    overlay={
                                        <Tooltip>
                                            <strong>BoT Health Monitor</strong>
                                        </Tooltip>
                                    }
                                >
                                    <img src={bot} className='text-white' alt={"users"} width={'20px'} />
                                </OverlayTrigger>
                            </div>}

                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowDealerInbox") && <div

                            className={
                                pathCheck[0] === 'mails'
                                    ? 'side-bar-items side-bar-items-active'
                                    : 'side-bar-items'
                            }
                            onClick={() => { push(`/mails`); window.location.reload() }}
                        >
                            <OverlayTrigger

                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Mails</strong>
                                    </Tooltip>
                                }
                            >
                                <img src={mail} className='text-white' alt={"users"} width={'20px'} />
                            </OverlayTrigger>
                        </div>}
                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowTemplates") &&<div
                            className={
                                pathCheck[0] === 'template'
                                    ? 'side-bar-items side-bar-items-active'
                                    : 'side-bar-items'
                            }
                            onClick={() => { push(`/template`); }}
                        >
                            <OverlayTrigger

                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Template</strong>
                                    </Tooltip>
                                }
                            >
                                <img src={template} className='text-white' alt={"template"} width={'20px'} />
                            </OverlayTrigger>
                        </div>}
                        {/* <div
                            className={
                                pathCheck[0] === 'email-automation'
                                    ? 'side-bar-items side-bar-items-active'
                                    : 'side-bar-items'
                            }
                            onClick={() => { push(`/email-automation`); }}
                        >
                            <OverlayTrigger

                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Email automation</strong>
                                    </Tooltip>
                                }
                            >
                                <img src={cpu} className='text-white' alt={"CPU"} width={'20px'} />
                            </OverlayTrigger>
                        </div> */}
                        {/* 
                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowNotifications") && <div
                            style={{ position: 'relative' }}
                            className={
                                pathCheck[0] === 'notifications'
                                    ? 'side-bar-items'
                                    : 'side-bar-items'
                            }
                            onClick={() => { push(`/notifications/sms`); }}
                        >
                            <OverlayTrigger
                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Notifications</strong>
                                    </Tooltip>
                                }
                            >
                                <div>
                                    <img src={notoficationBell} alt={"CPU"} width={'20px'} className={pathCheck[0] === 'notifications' ? 'side-bar-items-active' : ''} />

                                </div>
                            </OverlayTrigger>
                            {unReadMessagesCount && unReadMessagesCount > 0 ? <span className="not-count">{unReadMessagesCount}</span> : ""}
                        </div>} */}
                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowAllCalllogs") && <div
                            style={{ position: 'relative' }}
                            className={
                                pathCheck[0] === 'calllogs'
                                    ? 'side-bar-items'
                                    : 'side-bar-items'
                            }
                            onClick={() => { push(`/calllogs`); }}
                        >
                            <OverlayTrigger
                                placement='right'
                                overlay={
                                    <Tooltip>
                                        <strong>Call logs</strong>
                                    </Tooltip>
                                }
                            >
                                <img src={phoneicon} alt={"CPU"} width={'20px'} className={pathCheck[0] === 'calllogs' ? 'side-bar-items-active' : ''} />
                            </OverlayTrigger>
                        </div>}

                    </Row>
                </Col>
            </Row>
        </div>
    );
};
export default Sidebar;
