export const globalValues = {
    single_task_agent: 'Single Bot',
    single_attended: 'Single task Attended',
    single_unattended: 'Single task UnAttended',
    multi_agent: 'Multi Bot',
    multiple_task_agent: 'Multi Bot',
    multi_attended: 'Multi Attended',
    multi_unattended: 'Multi UnAttended',
    single_task_attended: 'Single task Attended Bot',
    single_task_unattended: 'Single task Unattended Bot',
    multiple_task_attended: 'Multiple task Attended Bot',
    multiple_task_unattended: 'Multiple task Unattended Bot',
};

export const agentTypes = {
    admin: 'Admin',
    manager_internal: 'Manager Internal',
    rep_internal: 'REP Internal',
    dealer_admin: 'Dealer Admin',
    dealer_rep: 'Dealer REP',
    sales: 'Sales',
    bdcmanager: 'BDC Manager',
    bdc: 'BDC',
    user: 'User'
};

export const leadStage = {
    rescheduled: "Rescheduled",
    dropped: "Dropped",
    // bot_msg_sent: "Message sent",
    bot_msg_sent: "No response",
    cancelled: "Cancelled",
    buyBack: "Buyback",
    buyBackAndSold: "Buyback & Sold",
    booked: "Booked",
    showed: 'Showed',
    no_show: 'No show',
    sold: 'Sold',
    // bot_working: 'No Response',
    bot_working: 'Bot working',
    appointment: 'Appointment',
    appointment_cancelled: "Appointment Cancelled",
    customer_declined: 'Not Interested',
    new: 'Interested',
    dealer_working: 'Appointment',
    appointment_confirmed: 'Appointment',
    Filtered_Leads: "Non-Qualified Leads",
    Service_Interested:"Service Interested",
    service_appointment:"Service Appointment Sent",
    service_appointment_filtered_leads: "Service Appointment Non-Qualified",
    service_appointment_interested:"Service Appointment Interested",
    customer_rejected:"Customer Stop",
    new_by_email:"Email Interested",
    bot_service_protection:"Service Protection Sent",
    working_followup:"Working/Follow-up"
};

// export const leadStatusMapper = {
//     rescheduled: "Rescheduled",
//     cancelled: "Cancelled",
//     booked: "Booked",
//     showed: 'Showed',
//     no_show: 'No show',
//     sold: 'Sold',
//     bot_working: 'No Response',
//     appointment: 'Appointment',
//     appointment_cancelled: "Appointment Cancelled",
//     customer_declined: 'Not Interested',
//     new: 'Interested',
//     dealer_working: 'Appointment',
//     appointment_confirmed: 'Appointment',
// };

export const leadstats=[]

export const qualificatnstats=[]

export const taskTypes={
    ScheduleACall:"Schedule a call",
    Meeting:"Meeting",
    Completed:"Completed"
}

export const noteTypes={
    phonecall:"Phone Call",
    text:"Text",
    email:"Email",
    other:"Other"
}