
import React, { useContext, useEffect, useState } from 'react';
import './dealerList.css';
import deleteIcon from '../../assets/images/delete.svg';
import editIcon from '../../assets/images/edit.svg';
import addIcon from '../../assets/icons/AddButton.svg';
import { UserContext } from '../common/UserContext';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { userDeleteApi } from '../../controllers/users';
import { toastify } from '../common/notification';
import moment from 'moment';
import './style.css'
import Deletewarning from '../common/Deletewarning';
import downarrowoutline from '../../assets/icons/sortarrowoutlinedown.svg';
import uparrowoutline from '../../assets/icons/sortarrowoutlineup.svg';
import downarrowfill from '../../assets/icons/sortarrowfilleddown.svg';
import uparrowfill from '../../assets/icons/sortarrowfilledup.svg';
import activity from '../../assets/icons/activity.svg'
import { accessRoles } from '../../controller';
import { Image } from 'react-bootstrap';
function TableDealerCorp({ data, type1, refetchCorp, refetch, setSort, sort }: any) {
    const [sortType, setSortType] = useState<any>("ASC");
    const [col, setCol] = useState<any>("dealer_name" || "corporateName");
    const { authToken, type, role } = useContext(UserContext);
    const history = useHistory();
    const [deleteId, setDeleteId] = React.useState<any>(null);
    const [show, setShow] = React.useState<boolean>(false);

    const handleDelete = () => {
        const body: any = {
            type: type1,
            id: deleteId
        }

        userDeleteApi(authToken, body).then((res: any) => {
            setShow(false);
            setDeleteId(null);
            if (res?.statusCode === 200) {
                toastify('success', type1 === 'dealer' ? 'Dealer deleted successfully.' : 'Corporate deleted successfully.')
            } else {
                toastify('failure', 'Unable to delete the user')
            }
            type1 === "dealer" ? refetch() : refetchCorp()
        });
    };
    const handleClose = () => setShow(false);

    const handleSort = (field) => {
        let srt = sortType === "ASC" ? "DSC" : "ASC"
        setSortType(srt)
        setSort({ ...sort, type: srt, key: field })
        setCol(field)
    }

    return (
        <div className=''>
            <table className='table table-hover table-bordered'>
                <thead className='table-head shadow-sm' style={{ fontSize: '14px' }}>
                    <tr>
                        <th  className=' table-center-ele'>
                            <div className='d-flex justify-content-between' onClick={() => { handleSort(type1 === "dealer" ? "dealer_id" : "ID") }}>
                                <span className='mt-auto mb-auto'>{type1 === "dealer" ? "Dealer Id" : "Corporate Id"}</span>
                                <div className='d-flex flex-column'>
                                    <img src={sortType === "ASC" && col === (type1 === "dealer" ? "dealer_id" : "ID") ? uparrowfill : uparrowoutline} alt="" />
                                    <img src={sortType === "DSC" && col === (type1 === "dealer" ? "dealer_id" : "ID") ? downarrowfill : downarrowoutline} alt="" />
                                </div>
                            </div>
                        </th>
                        <th onClick={() => { handleSort(type1 === "dealer" ? "dealer_name" : "corporateName") }} className=' table-center-ele'>
                            <div className='d-flex justify-content-between'>
                                <span className='mt-auto mb-auto'>Name</span>
                                <div className='d-flex flex-column'>
                                    <img src={sortType === "ASC" && col === (type1 === "dealer" ? "dealer_name" : "corporateName") ? uparrowfill : uparrowoutline} alt="" />
                                    <img src={sortType === "DSC" && col === (type1 === "dealer" ? "dealer_name" : "corporateName") ? downarrowfill : downarrowoutline} alt="" />
                                </div>
                            </div>
                        </th>
                        <th className=' table-center-ele'>Email</th>
                        <th className=' table-center-ele'>Website</th>
                        <th className=' table-center-ele'>Timezone</th>
                        <th onClick={() => { handleSort("createdAt") }} className=' table-center-ele'>
                            <div className='d-flex justify-content-between'>
                                <span className='mt-auto mb-auto'>Created at</span>
                                <div className='d-flex flex-column'>
                                    <img src={sortType === "ASC" && col === "createdAt" ? uparrowfill : uparrowoutline} alt="" />
                                    <img src={sortType === "DSC" && col === "createdAt" ? downarrowfill : downarrowoutline} alt="" />
                                </div>
                            </div>
                        </th>
                        <th className=' table-center-ele'>Last Mod.</th>
                        {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("editDealersandCorporate") && <th className=' table-center-ele' style={{ width: 'auto' }}>Actions</th>}
                    </tr>
                </thead>
                <tbody style={{ fontWeight: 'normal', fontSize: '14px' }} >
                    {data && data?.map((dealer, indx) => (
                        <tr key={dealer?._id}>
                            <td className=' table-center-ele'>{dealer?.document?.dealer_id || dealer?.document?.ID}</td>
                            <td className=' table-center-ele'>{dealer?.document?.dealer_name || dealer?.document?.corporateName}</td>
                            <td className=' table-center-ele'>{dealer?.document?.adf_email || dealer?.document?.email}</td>
                            <td className=' table-center-ele'>{dealer?.document?.website}</td>
                            <td className=' table-center-ele'>{dealer?.document?.timezone}</td>
                            <td className=' table-center-ele'>{moment(dealer?.document?.createdAt).format('LLL')}</td>
                            <td className=' table-center-ele'>{moment(dealer?.document?.updatedAt).format('LLL')}</td>
                            <td className=' table-center-ele'>
                                <div className='action'>
                                    {accessRoles?.filter((dealer: any) => _.toLower(dealer.role) === _.toLower(type + role))[0]?.permissions?.includes("editDealersandCorporate") && <><div
                                        className='iconDetail paddingRight'
                                        onClick={() =>
                                            history.push({
                                                pathname: type1 === "dealer" ? `/dealers/edit/${dealer._id}` : `/corporate/edit/${dealer._id}`,
                                                state: { obj: dealer?.document },
                                            })
                                        }>
                                        <Image
                                            className='iconAction'
                                            src={editIcon}
                                        />
                                    </div>
                                        <div onClick={() => {
                                            setShow(!show);
                                            setDeleteId(dealer._id);
                                        }}
                                            className='iconDetail paddingMiddle'>
                                            <Image
                                                className='iconAction'
                                                src={deleteIcon}
                                            />
                                        </div></>}
                                </div>{' '}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Deletewarning
                actionBtnLabel={"Delete"}
                closeBtnLabel={"Cancel"}
                handleClose={handleClose}
                handleDelete={handleDelete}
                show={show}
                title={type1 === "dealer" ? "Delete dealer" : "Delete corporate"}
                message={type1 === "dealer" ? "Are you sure you want to delete this dealer?" : "Are you sure you want to delete this corporate?"}
            />
        </div>
    )
}
export default TableDealerCorp

