import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import dial from "../../../assets/images/dial.png";
import closeBtn from "../../../assets/images/closebtn.svg";
import hangup from "../../../assets/images/hangup.png";
import backspace from "../../../assets/images/backspace.svg";
import "./style.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { UserContext } from "../../common/UserContext";
import { toastify } from "../../common/notification";
import config from "../../../config.json";
import Warningmodal from "../../common/Warningmodal";
import { Device } from "twilio-client";
function Dialpad({ lead, handleClose, dealerDetails, setOpen,dialerAlreadyVisited,setDialerAlreadyVisited ,showCloseBtn}) {
  const [showWarning, setShowWarning] = React.useState(false);
  const [showWarning1, setShowWarning1] = React.useState(false);
  const [currentConnection, setCurrentConnection] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [callOngoing, setCallOngoing] = React.useState(false);
  const { email,user_first_name,user_last_name } = useContext(UserContext);
  const [twilioDevice, setTwilioDevice] = React.useState(null);
  const history = useHistory();
  const startUp = React.useRef(null);
  const dialer = React.useRef(null);
  const ref = React.useRef(null);
  const callBtn = React.useRef(null);
  const [msg, setMsg] = React.useState("");
  const [heading, setHeading] = React.useState("");
  const [number, setNumber] = React.useState("");
  const buttons = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];
  let url = config.baseHost_backend;

  useEffect(() => {
    const setupTwilioDevice = async () => {
      try {
        setTwilioDevice(null);
        setLoading(true);
        let resp = await axios.get(
          "https://2tx9vhkjz9.execute-api.us-east-1.amazonaws.com/v1/twilio/get-token"
        );
        const device = await Device.setup(resp.data.token);
        setTwilioDevice(device);
      } catch (error) {
        console.error("Error setting up Twilio Device:", error);
      }
    };

    setupTwilioDevice();
  }, []);

  useEffect(() => {
    if (twilioDevice) {
      console.log("twilioDevice",twilioDevice);
      dialerAlreadyVisited && setLoading(false);
      try {
        twilioDevice.on("ready", () => {
          setLoading(false);
          console.log("-----ready");
        });

      } catch (error) {
        console.log("------------error", error);
      }
    }
  }, [twilioDevice]);

  // useEffect(() => {
  //   if (flag) {
  //     setTimeout(() => {
  //       toastify("failure", "Reload the page");
  //     }, 5000);
  //   }
  // }, [flag]);

  const makeCall = (e) => {
    console.log("dealerDetails",dealerDetails);
    console.log("lead", lead);
    e.preventDefault();
    var params = {
      To: number,
      callerId: dealerDetails?.twilioPhone,
      callerid: dealerDetails?.twilioPhone,
      calledBy: "",

      dealerID: lead?.dealer_id,
      dealerName:lead?.dealer_name,
      timezone:lead?.timezone || dealerDetails?.timezone,
      dealerPhone:dealerDetails?.phone,
      leadID: lead?._id,
      leadName:lead?.customer_details?.first_name+" "+lead?.customer_details?.last_name,
      leadPrimaryNumber:lead?.customer_details?.mobile_number,
      leadEmail:lead?.customer_details?.email,
      leadStatus:lead?.status,
      calledByEmail: email,
      calledByName: user_first_name+" "+user_last_name,
    };

    console.log("params",params);
    setCallOngoing(true);
    const connection = twilioDevice.connect(params);
    setCurrentConnection(connection);
    twilioDevice.on("connect", (connection) => {
      console.log("Call connected");
    });

    connection.on("accept", () => {
      setCallOngoing(true);
      console.log("Call accepted");
    });
    connection.on("incoming", () => {
      console.log("INCOMMING CALL");
    });

    connection.on("disconnect", () => {
      setCallOngoing(false);
      console.log("Call disconnected");
    });

    connection.on("error", (error) => {
      setCallOngoing(false);
      console.error("Call error:", error);
    });
  };



  const handleBackSpace = () => {
    setNumber(number.substring(0, number?.length - 1));
  };

  useEffect(() => {
    if (lead) {
      setNumber(lead?.customer_details?.mobile_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

 

  useEffect(() => {
    if (dealerDetails) {
      if (
        !dealerDetails?.is_active === true ||
        !dealerDetails?.is_active === 1
      ) {
        setMsg("This dealer is not active at present");
        setHeading("Inactive dealer");
        setShowWarning(true);
        return;
      }
      if (!dealerDetails?.twilioPhone) {
        setMsg(
          "Twilio phone number is not mapped for this dealer. Please map it to make calls.."
        );
        setHeading("Twilio phone number is not mapped!");
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  }, [dealerDetails]);

  console.log("dealerDetails",dealerDetails);

  const handleUpdateAltNum = async (e) => {
    e.preventDefault();
    let re = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    if (re.test(number)) {
      const payload = {
        API_TYPE: "updateLead",
        id: lead?._id,
        alternateNumber: number,
      };
      const resp = await axios.put(url + "/update-user", payload);
    } else {
      toastify(
        "failure",
        "Invalid phone number, Please include country code with +"
      );
    }
  };

  return (
    <div>
      <div className="dialer-cont pt-3 shadow-lg">
        <main>
          <section class="center-column">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "auto" }}
              >
                <ScaleLoader color="#30346b" className="mb-5 mt-4" />
              </div>
            ) : (
              <div className="bg-white  px-3 pb-3">
                <div>
                  {/* <span className="badge text-dark p-0 m-0">**Phone number must include with<br/> country code</span> */}
                  <input
                    value={number}
                    type="tel"
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      setNumber(e.target.value);
                    }}
                    className="form-control dialer-input-box bg-light mb-2"
                  />
                  <img
                    src={backspace}
                    alt="Backspace"
                    className="backspace-logo"
                    onClick={handleBackSpace}
                  />
                  {buttons.map((char) => (
                    <button
                      className="d-btns"
                      key={char}
                      onClick={(e) => {
                        e.preventDefault();
                        setNumber(number + char);
                      }}
                    >
                      {char}
                    </button>
                  ))}
                  <div className="d-flex justify-content-center">
                    {!callOngoing && (
                      <img
                        src={dial}
                        alt="Dialer"
                        height={50}
                        width={50}
                        type="submit"
                        onClick={makeCall}
                      />
                    )}
                    {callOngoing && (
                      <img
                        src={hangup}
                        alt="Dialer"
                        height={50}
                        width={50}
                        onClick={() => {
                          if (currentConnection) {
                            currentConnection.disconnect();
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </section>
        </main>
     {showCloseBtn &&   <div>
          <button
            style={{
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
            }}
            className="w-100 btn btn-secondary btn-sm"
            onClick={() => {
              setDialerAlreadyVisited(true)
              if (callOngoing) {
                setShowWarning1(true);
              } else {
                setTwilioDevice(null);
                handleClose();
                setLoading(false);
              }
            }}
          >
            close
          </button>
        </div>}
      </div>
    </div>
  );
}

export default Dialpad;


