import React from "react";
import {
  Card,
  Chip,
  FormControl,
  Input,
  TextField,
  TextareaAutosize,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import LeadPersonalDetails from "../Leads/leadPersonalDetails";
import Notes from "./Notes";
import Notessearch from "./Notessearch";
function Reusabletabs({ component: Component, componentProps, label1, label2,filterValue, notes,handleNotesSearch }) {
  const [tabs, setTabs] = React.useState<any>(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabs(newValue);
  };

  // const Child = ({ component }) => {
  //   return (
  //     <>
  //      {component}
  //     </>
  //   )
  // }
  return (
    <div>
      <Tabs value={tabs} onChange={handleTabChange}>
        <Tab
          label={label1}
          style={{ backgroundColor: tabs === 0 ? '#343a4024' : '#ffff', color: 'black' }}
        ></Tab>
        <Tab
          label={label2}
          style={{ backgroundColor: tabs === 1 ? '#343a4024' : '#ffff', color: 'black' }}
        ></Tab>
      </Tabs>
      {tabs === 0 && <Component {...componentProps} />}
      {tabs === 1 && <Card className="bg-light m-2 shadow-sm"><Notessearch handleChange={handleNotesSearch} /><Notes lead={componentProps.lead} notes={notes} dealerDetails={componentProps.dealerDetails} filterValue={filterValue}/></Card>}
    </div>
  );
}

export default Reusabletabs;
