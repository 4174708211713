import { Button, Container, FormControl, InputGroup } from "react-bootstrap"
import { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom"
import forgotPass from '../../assets/images/forgotpass.png'
import config from '../../config.json';
import axios from "axios";
import AlertModal from "../common/AlertModal";
import { toastify } from "../common/notification";
const ForgotPassword = ({setForgotPassword}) => {
    const [showReset, setShowReset] = useState<any>(false)
    // const [forgotPassword, setForgotPassword] = useState<any>(false);
    const [email, setEmail] = useState<string>('');
    const history=useHistory();
    const cont1=useRef<any>(null)
    const handleReset = async (e) => {
        const originUrl = window.location.origin;
        e.preventDefault();
        const url = config['baseHost_backend'] + '/forgot-passowrd';
        
        

        axios
            .post(url, {email, type : "forgotPassword",user_email:email, WEBSITE:originUrl})
            .then((response) => {
                
                
                if(response?.data?.statusCode===200 || response?.data?.StatusCode===200){
                    setShowReset(true)
                }
                else{
                    toastify('failure','User does not exist')
                }

            }).catch((err) => {
                
            })
    }
    function handleClose(){
        setShowReset(false)
    }
    useEffect(() => {
        cont1.current.focus()
    }, [])
    return (
        <div>
            <Container
                className='d-flex align-items-center justify-content-center  rounded py-4'
                style={{ flexDirection: 'column' }}>
                <div
                    style={{
                        marginTop: '2%',
                        width: '350px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '4%',
                    }}>
                    <img src={forgotPass} className='mb-4' alt="forgot_password" height="150px"/>
                    <h2 className="pb-1 font-weight-bold">Forgot your Password?</h2>
                    <span className="text-center pr-2 h6">Enter your email and we'll send you a link to reset your password</span>
                </div>
                <div
                    style={{
                        height: '250px',
                        width: '450px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        // marginTop: '5%',
                        marginLeft: '10%',
                    }}>
                    <form onSubmit={handleReset} id='login'>
                        <div style={{ margin: '5%' }}>
                            <div>
                                <InputGroup className='input'>
                                    <FormControl
                                    ref={cont1}
                                    className="bg-white"
                                        placeholder='Enter Email'
                                        aria-label='Email'
                                        aria-describedby='basic-addon1'
                                        onChange={(e) => setEmail(e.target.value)}
                                        name='username'
                                        id='username'
                                    />
                                </InputGroup>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <button
                                    className='pbuttonbgcolor btn text-white'
                                   
                                    type='submit'
                                    style={{
                                        width: '85%',
                                        marginLeft: '2%',
                                        padding: '3%',
                                        marginTop: '3%',
                                    }}>

                                    SUBMIT

                                </button>

                            </div>
                            <div style={{ display: 'flex',marginRight:'50px', marginTop:'20px' }}>
                                <span
                                    onClick={() => {   setForgotPassword(false) }}
                                     className='login-forget-pass-txt ml-auto mr-auto'>Back to Login</span>
                            </div>
                            <br />
                        </div>
                    </form>
                </div>
                <AlertModal show={showReset} handleClose={handleClose} email={email}/>
            </Container>
        </div>
    )
}
export default ForgotPassword