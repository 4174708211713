import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import downarrowoutline from "../../assets/icons/sortarrowoutlinedown.svg";
import uparrowoutline from "../../assets/icons/sortarrowoutlineup.svg";
import downarrowfill from "../../assets/icons/sortarrowfilleddown.svg";
import uparrowfill from "../../assets/icons/sortarrowfilledup.svg";
import notesImg from "../../assets/images/notes.png";
import NoDataFound from "./NoDataFound";
import { noteTypes } from "./globalValues";
function Notes({ lead, dealerDetails, filterValue, notes }) {
  console.log("filter", (notes?.filter((a) => a.noteType === "other" || !a.noteType)?.length));
  const [sortType, setSortType] = useState<any>("DSC");
  const [col, setCol] = useState<any>("Time");

  
  const handleSort = (field) => {
    setCol(field);
    let curSort = sortType === "ASC" ? "DSC" : "ASC";
    if (field === "Time") {
      if (curSort === "ASC") {
        notes?.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
      } else {
        notes?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
      }
    } else {
      if (curSort === "ASC") {
        notes?.sort((a, b) =>
          a?.byName?.toLowerCase() > b?.byName?.toLowerCase() ? 1 : -1
        );
      } else {
        notes?.sort((a, b) =>
          a?.byName?.toLowerCase() > b?.byName?.toLowerCase() ? -1 : 1
        );
      }
    }
    setSortType(curSort);
  };

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Notes</th>
            <th>
              <div
                className="d-flex justify-content-between"
                onClick={() => {
                  handleSort("Time");
                }}
              >
                <span className="mt-auto mb-auto">Date</span>
                <div className="d-flex flex-column">
                  <img
                    src={
                      sortType === "ASC" && col === "Time"
                        ? uparrowfill
                        : uparrowoutline
                    }
                    alt=""
                  />
                  <img
                    src={
                      sortType === "DSC" && col === "Time"
                        ? downarrowfill
                        : downarrowoutline
                    }
                    alt=""
                  />
                </div>
              </div>
            </th>
            {filterValue==="other" && <th>Type</th>}
            <th>
              <div
                className="d-flex justify-content-between"
                onClick={() => {
                  handleSort("Createdby");
                }}
              >
                <span className="mt-auto mb-auto">Created by</span>
                <div className="d-flex flex-column">
                  <img
                    src={
                      sortType === "ASC" && col === "Createdby"
                        ? uparrowfill
                        : uparrowoutline
                    }
                    alt=""
                  />
                  <img
                    src={
                      sortType === "DSC" && col === "Createdby"
                        ? downarrowfill
                        : downarrowoutline
                    }
                    alt=""
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody style={{ fontWeight: "normal" }}>
          {filterValue === "other"
            ? notes &&
              notes
                // ?.filter((a) => a.noteType === "other" || !a.noteType)
                .map((note: any, index: number) => (
                  <tr key={index}>
                    <td>{note.note}</td>
                    <td>
                      {`${moment(
                        note?.created_at
                        // eslint-disable-next-line no-useless-concat
                      ).format("LLL")}` +
                        "(" +
                        dealerDetails?.timezone +
                        ")"}
                    </td>
                    <td>{noteTypes[note?.noteType] || "N/A"}</td>
                    <td>{note.byName || "N/A"}</td>
                  </tr>
                ))
            : notes &&
              notes
                ?.filter((a) => a.noteType === filterValue)
                .map((note: any, index: number) => (
                  <tr key={index}>
                    <td>{note.note}</td>
                    <td>
                      {`${moment(
                        note?.created_at
                        // eslint-disable-next-line no-useless-concat
                      ).format("LLL")}` +
                        "(" +
                        dealerDetails?.timezone +
                        ")"}
                    </td>
                    <td>{note.byName || "N/A"}</td>
                  </tr>
                ))}
        </tbody>
      </Table>
      {(filterValue!=="other"  && (notes?.filter((a) => a.noteType === filterValue))?.length===0) ? (
        <NoDataFound
          img={notesImg}
          height={120}
          width={120}
          message1={"No notes found"}
          message2={""}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Notes;
