import moment from "moment";
import React from "react";
import { Button, Card, Modal } from "react-bootstrap";

function Recordings({ show, clearForm, modalData }) {
  console.log("modalData", decodeURIComponent(modalData));
  return (
    <Modal centered show={show} onHide={clearForm} onBackdropClick={clearForm}>
      <div className="p-1">
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <h3 className="mt-1">
                {!modalData ? "No recordings found" : "View recordings"}
                {/* {modalType==="callInfo" && modalTitle } */}
              </h3>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {
              <audio
                controls
                src={decodeURIComponent(modalData)}
                key={decodeURIComponent(modalData)}
              >
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            }
          </Card.Body>
        </Card>
      </div>
      <button
        className="sbuttonbgcolor btn btn-sm"
        onClick={clearForm}
        style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
      >
        close
      </button>
    </Modal>
  );
}

export default Recordings;
