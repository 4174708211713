import { Col, Row, Button, Table, Image, Modal } from 'react-bootstrap';
const AlertModal = ({ show, email, handleClose }:any) => {

    let str:any = email
    str = str.split('');
    let finalArr = [] as any;
    let len = str.indexOf('@');
    str.forEach((item:any, pos:any) => {
        (pos >= 1 && pos <= len - 2) ? finalArr.push('*') : finalArr.push(str[pos]);
    })
    
    return (
        <Modal centered show={show}>
            <Modal.Header>
                <Modal.Title>Check your email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column'>
                    {/* <span className='' style={{ fontWeight: 'normal' }}>We've sent an email to <span className='font-weight-bold'>{finalArr.join('')}</span>. click the link in the email to reset your password.</span> */}
                    <span className='' style={{ fontWeight: 'normal' }}>We just sent you an email with a password reset link, please click the link to reset your password.</span>
                </div>
                <div className='mt-3'>
                    <span className='h6'>*Please make sure to check your Spam Folder</span>
                </div>
            </Modal.Body>
            <Modal.Footer>

                <button
                    className='btn btn-sm pbuttonbgcolor'
                onClick={handleClose}
                >
                    OK
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default AlertModal