export const calculateTotalTime = (
  timeArray,
  selectedFromDate,
  selectedToDate
) => {
  const addtime = (timeArray, totalLeads) => {
    console.log("timeArray", timeArray);

    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;

    timeArray?.map((time) => {
      totalHours += time?.timer?.hours;
      totalMinutes += time?.timer?.minutes;
      totalSeconds += time?.timer?.seconds;
    });
    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds %= 60;
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    // Return the result as an object
    return {
      hours: totalHours.toString().padStart(2, "0"),
      minutes: totalMinutes.toString().padStart(2, "0"),
      seconds: totalSeconds.toString().padStart(2, "0"),
      totalLeads,
    };
  };

  console.log("timeArray", timeArray);

  let filtered =
    selectedFromDate || selectedToDate
      ? timeArray?.filter(
          (time) =>
            new Date(time?.dateandtime) >= new Date(selectedFromDate) &&
            new Date(time?.dateandtime) <= new Date(selectedToDate)
        )
      : timeArray;
  console.log("filtered", filtered);

  let mergerd = [];
  filtered?.map((sd) => {
    mergerd.push(...sd?.leads);
  });
  console.log("mergerd", mergerd);
  return addtime(mergerd && mergerd, mergerd && mergerd?.length);
};

export const calculateAvgTime = (
  timeArray,
  selectedFromDate,
  selectedToDate
) => {
  const avgTime = (timeArray, totalLeads) => {
    // Initialize variables to store the total time components
    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;

    // Loop through the timeArray to accumulate total time
    timeArray?.forEach((time) => {
      totalHours += time?.timer?.hours;
      totalMinutes += time?.timer?.minutes;
      totalSeconds += time?.timer?.seconds;
    });
    // Calculate the total time in seconds
    const totalSecondsOverall =
      totalHours * 3600 + totalMinutes * 60 + totalSeconds;

    // Calculate the average time in seconds
    const averageSeconds = totalSecondsOverall / totalLeads;

    // Calculate the average hours, minutes, and seconds
    const averageHours = Math.floor(averageSeconds / 3600);
    const averageMinutes = Math.floor((averageSeconds % 3600) / 60);
    const averageSecondsRemainder = Math.floor(averageSeconds % 60);

    // Return the average time as an object
    console.log("avg", averageHours, averageMinutes, averageSecondsRemainder);

    return {
      hours: averageHours.toString().padStart(2, "0"),
      minutes: averageMinutes.toString().padStart(2, "0"),
      seconds: averageSecondsRemainder.toString().padStart(2, "0"),
    };
  };

  console.log("timeArray", timeArray);
  let filtered =
    selectedFromDate || selectedToDate
      ? timeArray?.filter(
          (time) =>
            new Date(time?.dateandtime) >= new Date(selectedFromDate) &&
            new Date(time?.dateandtime) <= new Date(selectedToDate)
        )
      : timeArray;
  console.log("filtered", filtered);
  let mergerd = [];
  filtered?.map((sd) => {
    mergerd.push(...sd?.leads);
  });
  return avgTime(mergerd && mergerd, mergerd && mergerd?.length);
};

export const filterleadsbyselecteddate = (
  timeArray,
  selectedFromDate,
  selectedToDate
) => {
  let filtered =
    selectedFromDate || selectedToDate
      ? timeArray?.filter(
          (time) =>
            new Date(time?.dateandtime) >= new Date(selectedFromDate) &&
            new Date(time?.dateandtime) <= new Date(selectedToDate)
        )
      : timeArray;
  console.log("filtered", filtered);

  let mergerd = [];
  filtered?.map((sd) => {
    mergerd.push(...sd?.leads);
  });
  return mergerd;
};
