import axios from "axios";
import { useContext } from "react";
import { toastify } from "../components/common/notification";
import { UserContext } from "../components/common/UserContext";
import config from "../config.json";

const url = config["baseHost_backend"];

export const leadListApi = (
  token: any,
  type,
  functionType,
  ID,
  sort,
  CRDsort,
  LCDsort,
  config: {
    page: number;
    limit: number;
    filterQuery: {
      status: string;
      dealer: string;
      owner: string;
      sdate: string;
      edate: string;
    };
  }
) =>
  axios
    .post(
      `${url}/fetch-all-leads`,
      {
        pages_to_skip: config.page - 1,
        limit_per_page: config.limit,
        ...config.filterQuery,
        type: type,
        functionType,
        ID: ID,
        sort,
        CRDsort,
        LCDsort,
      },
      {
        headers: {
          Authorization: token,
        },

        // params: {
        //     pages_to_skip: config.page - 1,
        //     limit_per_page: config.limit,
        //     ...config.filterQuery,
        //     type: type,
        //     ID: ID,
        //     sort,
        //     CRDsort,
        //     LCDsort
        // },
      }
    )
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const leadDetailsApi = (token: string, id: string) =>
  axios
    .get(`${url}/fetch-lead-detail/${id}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const leadDetailsApiWithoutToken = (body:any,id: string) =>
  axios
    .get(`${url}/fetch-lead-detail/${id}`,{
        params:{
            auth:"false"
        }
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const leadUpdateApi = (token: string, data: any) =>
  axios
    .put(`${url}/update-leads`, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const leadFilterDetailsApi = (
  token: string,
  type: any,
  ID: any,
  appointment_status: any
) =>
  axios
    .get(`${url}/fetch-filter`, {
      headers: {
        Authorization: token,
      },
      params: {
        type: type,
        ID: ID,
        appointment_status,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const botLeadsApi = (token: string) =>
  axios
    .get(`${url}/bot-leads`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );
export const allLeadsApi = (token: string) =>
  axios
    .get(`${url}/all-lead-by-date`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );
export const openLeadsDealerApi = (token: string) =>
  axios
    .get(`${url}/open-leads-dealer`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const openLeadsOwnerApi = (token: string) =>
  axios
    .get(`${url}/open-lead-owner`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const leadsProgressionApi = (token: string) =>
  axios
    .get(`${url}/leads-progression`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );
