import React, { useState } from 'react'
import leftArrow from '../../../assets/images/leftArrow.svg';
import { Form, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { JSONTree } from 'react-json-tree';
import samplejson from './samplejson.json'
import dealerjson from './sampledealerjson.json'
import info from '../../../assets/images/info.svg'
import { trim } from 'lodash';
import config from '../../../config.json'
import { toastify } from '../../common/notification';
import axios from 'axios';
import LoadingBar from '../../common/LoadingBar';
function Mergefield() {
    const [lable, setlabel] = useState<any>("")
    const [key, setKey] = useState<any>("")
    const [loading, setLoading] = useState<any>(false)
    const history = useHistory();
    const token: any = localStorage.getItem("$AuthToken");
    const handleBack = () => {
        history.push({
            pathname: '/template',
            state: {
                dataChanged: true
            }
        })
    }

    const handleSave = async (e: any) => {
        e.preventDefault();

        if (!lable) {
            return toastify("failure", "Label cannot be empty")
        }
        if (!key) {
            return toastify("failure", "Key cannot be empty")
        }
        setLoading(true)
        let body = {
            function: "create",
            body: {
                label: trim(lable),
                value: trim(key)
            }
        }

        let resp = await axios.post(config.baseHost_backend + "/mergefield", body, {
            headers: {
                Authorization: token
            },
        })
        if (resp?.data?.statusCode === 200) {
            setLoading(false);
            toastify('success', 'Merge-field created successfully.')
        }else{
            setLoading(false);
            toastify('failure', resp?.data?.body?.message)
        }

        
    }
    return (
        <div>
            {<LoadingBar isActive={loading} />}
            <div className='p-3'>
                <Image
                    onClick={() => {
                        handleBack();
                    }}
                    className='pointer'
                    src={leftArrow}
                />
                <span className='ml-2'>Create merge-field</span>
            </div>
            <hr className='m-0 p-0' />
            <Form className='p-3'>
                <Row>
                    <Form.Group className='mb-3'>
                        <Form.Label className='text mb-0'>Merge-field label<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                        <input type="text" className='form-control p-2 bg-white' value={lable} onChange={(e) => { setlabel(e.target.value) }} required />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label className='text mb-0'>Merge-field key<span className='text-danger h6 font-weight-bold'>*</span>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 0, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        This field is case sensitive, please refer the below json and find the required key and place it here.
                                    </Tooltip>
                                }
                            >
                                <img src={info} alt="Upto 10,000 record can be exported" className='ml-2' height={13} />
                            </OverlayTrigger>
                        </Form.Label>
                        <input type="text" className='form-control p-2 bg-white' value={key} onChange={(e) => { setKey(e.target.value) }} required />
                    </Form.Group>
                </Row>
                <button className='btn btn-sm pbuttonbgcolor' type='submit' onClick={handleSave}>Save</button>
            </Form>
            <hr className='m-0 p-0' />
            <div className='p-3 d-flex'>
                <div style={{ width: '50%' }}>
                    <h3>Lead json</h3>
                    <JSONTree data={samplejson} />
                </div>
                <div style={{ width: '50%' }}>
                    <h3>Dealer json</h3>
                    <JSONTree data={dealerjson} />
                </div>

            </div>
        </div>

    )
}

export default Mergefield