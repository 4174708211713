import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import { leadUpdateApi } from "../../controllers/leads";
import { toastify } from "../common/notification";
import { UserContext } from "../common/UserContext";
import DatePicker from "react-datetime";
import "./appoinemtnet.css";
import "react-datetime/css/react-datetime.css";
import LoadingBar from "../common/LoadingBar";
import axios from "axios";
import config from "../../config.json";
import Deletewarning from "../common/Deletewarning";
import { leadStage } from "../common/globalValues";
import states from "../common/states";
function Upcommingappointments({
  data,
  refetch,
  setLoading,
  disableWeekends,
  clearForm,
  selectedAppoiintmentDate,
  handleDateChange,
  timeSlots,
  selectedTimeSlot,
  handleSlotSelected,
  SetIsReshedule,
  isReshedule,
  dealerDetails,
  confirmTime,
}) {
  const [selctedOption, setSelctedOption] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setShow(false);
  const handleWarningModalClose = () => setWarningShow(false);
  const { authToken, email } = useContext(UserContext);
  const [show, setShow] = React.useState<any>(false);
  const [warningShow, setWarningShow] = React.useState<any>(false);
  const [showPopup, setShowPopup] = React.useState<any>(false);
  const selectRef = useRef<any>(null);
  const [date, setDate] = React.useState<any>(null);
  const [actionType, setActionType] = React.useState<any>(null);
  const [modalPayload, setModalPayload] = React.useState<any>(null);
  const [reschedulePayload, setReschedulePayload] = React.useState<any>(null);
  const [cancelPayload, setCancelPayload] = React.useState<any>(null);
  const [appDate, setAppDate] = React.useState<any>(null);
  const [appTime, setAppTime] = React.useState<any>(null);
  const [appData, setAppData] = React.useState<any>(null);
  const [updating, setUpdating] = useState<any>(false);
  const [appID, setAppID] = useState<any>(null);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const leadMutation = useMutation(
    (data: any) => leadUpdateApi(authToken, data),
    {
      onSuccess: (res) => {
        setAppID(res?.body?.id);
        setUpdating(false);
        setLoading(false);
        setShow(false);
        actionType === "Cancel"
          ? toastify("success", "Appointment cancelled successfully")
          : toastify("success", "Appointment rescheduled successfully");
        setShowPopup(false);
        refetch();
        clearForm();
      },
      onError: () => {
        setUpdating(false);
        setLoading(false);
        actionType === "Cancel"
          ? toastify("failed", "Unable to cancel the appointment")
          : toastify("failed", "Unable to reschedule the appointment");
        setShow(false);
        clearForm();
      },
    }
  );

  useEffect(() => {
    setDate(selectedAppoiintmentDate);
  }, [selectedAppoiintmentDate]);

  const handleCancelAppointmrnt = () => {
    setWarningShow(false);

    setUpdating(true);
    const body = {
      type: "updateStatus",
      id: modalPayload?.leadInfo?._id,
      appointment_id: appID,
      status: "Cancelled",
      lead_status: "appointment_cancelled",
      appointment_time: appTime,
      appointment_date: appDate,
    };

    // leadMutation.mutateAsync(body)
    setCancelPayload(body);

    selectRef.current.selectedIndex = 0;
    setShowPopup(true);
  };

  const handleMenuselected = (e: any, app: any) => {
    if (e.target.value !== "null") {
      if (e.target.value === "Rescheduled") {
        SetIsReshedule(true);
        setModalPayload(app);

        setShow(true);
      } else {
        SetIsReshedule(false);
        setAppID(app?.appointment_id);
        setAppDate(app?.appointment_date);
        setAppTime(app?.appointment_time);
        setModalPayload(app);
        setActionType("Cancel");
        setAppData(app);
        setWarningShow(true);
      }
    }
  };
  const handleClick = () => {
    // setUpdating(true)
    console.log("modalPayload", modalPayload);
    const body = {
      type: "updateStatus",
      id: modalPayload?.leadInfo?._id,
      appointment_id: modalPayload?.appointment_id,
      status: "Rescheduled",
      lead_status: "appointment_rescheduled",
      appointment_time: selectedTimeSlot,
      appointment_date: selectedAppoiintmentDate,
      old_appointment_time: modalPayload?.appointment_time,
      old_appointment_date: modalPayload?.appointment_date,
      timezone: modalPayload?.timeZone,
    };

    setShow(false);
    setShowPopup(true);
    setReschedulePayload(body);
    selectRef.current.selectedIndex = 0;
  };

  const handleModalClose = () => {
    setShow(false);
    clearForm();
    selectRef.current.selectedIndex = 0;
  };

  const handleAction = (e) => {
    setSelctedOption(e.target.value);
  };

  const handleSend = () => {
    leadMutation.mutateAsync(isReshedule ? reschedulePayload : cancelPayload); //cancel appointment

    if (selctedOption !== "none") {
      const url = config["baseHost_backend"] + "/forgot-passowrd";
      setLoading(true);
      console.log("resch/cancel", modalPayload);
      let Hrformated = moment(
        actionType === "Cancel" ? appTime : selectedTimeSlot,
        "HH:mm"
      );

      let HrformatedOldappdate = moment(modalPayload?.appointment_time,"HH:mm");

      const appoinment = {
        leadInfo: {
          _id: modalPayload?.leadInfo?._id,
          email: modalPayload?.leadInfo?.email,
          first_name: modalPayload?.leadInfo?.first_name,
          last_name: modalPayload?.leadInfo?.last_name,
          mobile_number: modalPayload?.leadInfo?.mobile_number,
        },
        dealer_info: {
          dealer_id: modalPayload?.dealer_info?.dealer_id,
          dealer_name: modalPayload?.dealer_info?.dealer_name,
          dealer_email: modalPayload?.dealer_info?.dealer_email,
          dealer_phone: modalPayload?.dealer_info?.dealer_phone,
          dealer_address: modalPayload?.dealer_info?.dealer_email,
        },
        appointment_date:
          actionType === "Cancel" ? appDate : selectedAppoiintmentDate,
        appointment_time: actionType === "Cancel" ? appTime : selectedTimeSlot,
        appointment_status:
          actionType === "Cancel" ? "Cancelled" : "Rescheduled",
        appointment_id:  actionType === "Cancel" ? appID : modalPayload?.appointment_id,
        timeZone: modalPayload?.timeZone,
      };
      const data = {
        type: actionType === "Cancel" ? "appoinmentCancel" : "Reschedule",
        // type: "Reschedule",
        model: selctedOption,
        appoinment: appoinment,
        // email: email,
        shortName: dealerDetails?.shortName,
        aliasName: dealerDetails?.aliasName,
        dealerID: dealerDetails?.dealer_id,
        from: dealerDetails?.twilioPhone,

        appointment_date:
          actionType === "Cancel" ? appDate : selectedAppoiintmentDate,
        appointment_status:
          actionType === "Cancel" ? "Cancelled" : "Rescheduled",
        appointment_time: Hrformated.format("hh:mm A"),
        dealer_address: modalPayload?.dealer_info?.dealer_email,
        dealer_email: modalPayload?.dealer_info?.dealer_email,
        dealer_id: modalPayload?.dealer_info?.dealer_id,
        dealer_name: modalPayload?.dealer_info?.dealer_name,
        dealer_phone: modalPayload?.dealer_info?.dealer_phone,
        email: modalPayload?.leadInfo?.email,
        first_name: modalPayload?.leadInfo?.first_name,
        last_name: modalPayload?.leadInfo?.last_name,
        mobile_number: modalPayload?.leadInfo?.mobile_number,
        old_appointment_date:modalPayload?.appointment_date,
        old_appointment_time:HrformatedOldappdate.format("hh:mm A"),
        user_email:email
      };

      axios
        .post(url, data)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            selctedOption === "Both" &&
              toastify("success", `Both Email and SMS sent successfully`);

            selctedOption !== "Both" &&
              toastify("success", `${selctedOption} sent successfully`);
            setShowPopup(false);
            setLoading(false);
            setActionType(null);
            // location.pathname.includes('update-profile') ? setShowModal(true) : setShowReset(true)
          } else {
            selctedOption === "Both" &&
              toastify("failure", `Unable to send both SMS and Email`);
            selctedOption !== "Both" &&
              toastify("failure", `Unable to send ${selctedOption}`);
            setShowPopup(false);
            setLoading(false);
            setActionType(null);
            // toastify('failure', 'User does not exist')
          }
        })
        .catch((err) => {
          setLoading(false);
        });
      console.log("data p", data);
    }
    setActionType(null);
  };

  return (
    <div className="px-4 py-3">
      <h3 className="font-weight-bold" style={{ color: "#00158d" }}>
        Upcoming Appointments
      </h3>
      {!data || data === null || !data.length ? (
        <div style={{ color: "gray" }} className="text-center h6">
          No records found
        </div>
      ) : (
        data
          ?.sort((a: any, b: any) => {
            let d1: any = new Date(a.appointment_date);
            let d2: any = new Date(b.appointment_date);
            return d2 - d1;
          })
          .map((app: any) => (
            <div className="p-3 bg-white text-dark shadow-sm mb-1">
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <span style={{ fontSize: "13px" }}>
                    {app?.appointment_date}
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "13px" }} className="p-0 m-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-clock mb-1 text-warning"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>{" "}
                    {moment(app?.appointment_time, ["HH:mm a"]).format(
                      "hh:mm A"
                    )}
                    ({app?.timeZone})
                  </span>
                </div>
                <div>
                  <span
                    style={{ fontSize: "13px" }}
                    className={
                      app?.appointment_status === "Cancelled"
                        ? "badge bg-danger text-white mt-auto mb-auto"
                        : "badge bg-light text-primary mt-auto mb-auto"
                    }
                  >
                    {leadStage[(app?.appointment_status).toLowerCase()]}
                  </span>
                </div>
                <div>
                  <select
                    disabled={
                      app?.appointment_status === "Cancelled" ||
                      app?.appointment_status === "Showed" ||
                      app?.appointment_status === "No_show"
                    }
                    className=""
                    name=""
                    id=""
                    onChange={(e: any) => {
                      handleMenuselected(e, app);
                    }}
                    style={{ paddingTop: "0px" }}
                    ref={selectRef}
                  >
                    <option value="null">Select status</option>
                    <option value="Rescheduled">Rescheduled</option>
                    <option value="Cancel">Cancel</option>
                  </select>
                </div>
              </div>
            </div>
          ))
      )}

      <Modal centered show={show}>
        <LoadingBar isActive={updating} />
        <Modal.Header>
          <Modal.Title className="w-100 d-flex justify-content-between">
            <span>Reschedule Appointment</span>
            <span onClick={handleModalClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <DatePicker
              input={false}
              open={true}
              timeFormat={false}
              isValidDate={disableWeekends}
              onChange={handleDateChange}
            />
          </div>
          {timeSlots && (
            <div className="px-4 my-2">
              <Grid container id="slot-container">
                {timeSlots?.map((times: any) => (
                  <span
                    className={
                      times.disabled
                        ? `p-2 m-2 disable-slot`
                        : `p-2 app-date-card m-2 text-white ${
                            selectedTimeSlot.includes(times.slot) &&
                            "active-slot"
                          }`
                    }
                    style={{ fontSize: "12px", borderRadius: "6px" }}
                    onClick={(e) =>
                      !times.disabled && handleSlotSelected(e, times)
                    }
                  >
                    {moment(times?.slot, ["HH:mm a"]).format("hh:mm A")}
                  </span>
                ))}
              </Grid>
            </div>
          )}
          <div className="d-flex justify-content-center my-3">
            <button
              className="text-center btn btn-sm pbuttonbgcolor"
              onClick={handleClick}
              disabled={!selectedTimeSlot?.length}
            >
              Confirm Time
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={showPopup}>
        <Modal.Body>
          <div className="d-flex flex-column">
            <span>Please select the preferred channel of communication.</span>
            <select
              name=""
              id=""
              className="w-50 my-3 form-control form-select-sm"
              onChange={handleAction}
            >
              <option value="null">Select</option>
              <option value="Email">Email</option>
              <option value="SMS">SMS</option>
              <option value="Both">Both</option>
              <option value="none">None</option>
            </select>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex gap-2">
            {actionType !== "Cancel" && (
              <button
                // disabled={!selctedOption || selctedOption === "null"}
                onClick={() => {
                  setShow(false);
                  setShowPopup(false);
                  setLoading(false);
                  toastify("success", "Current operation cancelled by user");
                  selectRef.current.selectedIndex = 0;
                }}
                className="btn btn-sm pbuttonbgcolor"
                color="error"
              >
                CANCEL
              </button>
            )}
            <button
              disabled={!selctedOption || selctedOption === "null"}
              onClick={handleSend}
              className="btn btn-sm pbuttonbgcolor"
            >
              {actionType === "Cancel"
                ? "CANCEL APPOINTMENT"
                : "RESCHEDULE APPOINTMENT"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Deletewarning
        message={"Are you sure you want to cancel this appointment?"}
        show={warningShow}
        title="Cancel appointment"
        closeBtnLabel="No"
        handleClose={handleWarningModalClose}
        actionBtnLabel="Yes"
        handleDelete={handleCancelAppointmrnt}
      />
    </div>
  );
}

export default Upcommingappointments;
