import axios from 'axios'
import React, { useContext, useEffect } from 'react'
import config from '../../config.json'
import { useQuery } from 'react-query'
import Calllist from '../common/Calllist'
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Filter from './Filter'
import { UserContext } from '../common/UserContext'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import Loader from '../common/Loader'
import LoadingBar from '../common/LoadingBar'
import NoDataFound from '../common/NoDataFound'
import noHistory from '../../assets/images/history3.png'
import Allcalllist from '../common/Allcalllist'
function Calllogs() {
    const [selectedDealer, setSelectedDealer] = React.useState<any>(null);
    const [selectedFromDate, setSelectedFromDate] = React.useState<any>(null);
    const [selectedToDate, setSelectedToDate] = React.useState<any>(null);
    const [selectedLead, setSelectedLead] = React.useState<any>(null);

    const [values, setValues] = React.useState<DateObject | DateObject[] | null>([]);
    const { authToken } = useContext(UserContext)
    const [singleDateSelected, setSingleDateSelected] = React.useState<any>(false);
    const [page, setPage] = React.useState<any>(1);
    const dateRef = React.useRef<any>(null)
    const dealerRef = React.useRef<any>(null)
    const leadRef = React.useRef<any>(null)
    const history = useHistory();
    const [dealers, setDealers] = React.useState<any>([]);
    const [leads, setLeads] = React.useState<any>([]);
    const [reset, setReset] = React.useState<any>(false);
    const [filterApplied, setFilterApplied] = React.useState<any>(false);


    const handleDateChange = (dates: DateObject[]) => {
        if (dates.length === 1) {
            setSelectedFromDate(dates[0].toString());
            let a: any = dates[0]
            let od: any = new Date(a)
            let stamp = new Date(od.setDate(od.getDate() + 1))
            setSelectedToDate(`${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`)
            setSingleDateSelected(true)
        }
        if (dates.length === 2) {
            setSelectedFromDate(dates[0].toString());
            let a: any = dates[1]
            let od: any = new Date(a)
            let stamp = new Date(od.setDate(od.getDate() + 1))
            setSelectedToDate(`${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`)
            setSingleDateSelected(false)

        }
        if (dates.length === 0) {
            setSelectedFromDate("");
            setSelectedToDate("")
            setSingleDateSelected(null)
        }
        setValues(dates);
    }


    const filter = (e) => {
        e.preventDefault();
        setReset(false)
        setFilterApplied(true)
        setPage(1)
        refetch()
    }


    let body = {
        getAllLogs: true,
        limit_per_page: 10,
        pages_to_skip: page - 1,
        dealerName: selectedDealer,
        leadName: selectedLead,
        sdate: selectedFromDate,
        edate: selectedToDate,
        reset
    }

    const fetchLogs = async () => {
        let resp = await axios.post(`${config.baseHost_backend}/twilio/call-logs`, body, {
            headers: {
                Authorization: authToken
            },
        });
        return resp?.data?.body
    }
    const { data, isLoading, refetch, isRefetching, isFetching } = useQuery([`fetch-call-logs}`, page, reset], fetchLogs)

    const fetchFilterOptions = async () => {
        let resp = await axios.get(`${config.baseHost_backend}/twilio/fetch-filter`, {
            headers: {
                Authorization: authToken
            },
        });
        return resp?.data?.body
    }
    const { data: filterOptns, isLoading: isFilterOptnLoading, } = useQuery(`fetchFilterOptions`, fetchFilterOptions)
    console.log("filterOptns", filterOptns);

    useEffect(() => {
        if (filterOptns) {
            let tempDealers = [] as any;
            let tempLeads = [] as any;
            let initial = [{ label: "All", value: "All" }]
            let filteredDealers = filterOptns?.dealerFilter?.filter((h) => h._id !== "undefined");
            let filteredLeads = filterOptns?.userFilter?.filter((h) => h._id !== "undefined");
            console.log("gg", filteredDealers);

            filteredDealers?.forEach((obj) => {
                tempDealers.push({ label: obj?._id, value: obj?._id })
            })
            filteredLeads?.forEach((obj) => {
                tempLeads.push({ label: obj?._id, value: obj?._id })
            })
            setDealers([...initial, ...tempDealers]);
            setLeads([...initial, ...tempLeads])
            console.log("filterOptns1", [...initial, ...tempDealers]);
        }
    }, [filterOptns])


    const resetFilter = (e) => {
        e.preventDefault();
        setReset(true)
        setFilterApplied(false)
        setPage(1)
        // setFilteredData(null)
        if (selectedDealer) {
            dealerRef.current.state.value = ""
        }
        if (selectedLead) {
            leadRef.current.state.value = ""
        }
        setValues([])
        setSelectedFromDate(null);
        setSelectedToDate(null)
        setSelectedDealer(null);
        setSelectedLead(null)
        // refetch()
    }

    console.log("isLoading && isRefetching && isFetching", isLoading, isRefetching, isFetching);



    return (
        <div>
            <LoadingBar isActive={isFetching || isLoading}/>
            <form className='d-flex flex-row gap-3 justify-content-between py-3 px-3 bhmheader shadow-sm' onReset={resetFilter} onSubmit={filter}>
                <Filter
                    selectedDealer={selectedDealer}
                    setSelectedDealer={setSelectedDealer}
                    selectedLead={selectedLead}
                    setSelectedLead={setSelectedLead}
                    setSelectedDate={handleDateChange}
                    filter={filter}
                    // resetFilter={resetFilter}
                    reset={reset}
                    dateRef={dateRef}
                    dealerRef={dealerRef}
                    leadRef={leadRef}
                    values={values}
                    dealers={dealers}
                    leads={leads}

                />
            </form>
            {
                !isLoading && !data?.allLogs?.length && !filterApplied ?
                    <div style={{ paddingTop: '100px' }}>
                        <NoDataFound height={100} width={100} message1='No call logs' message2='' img={noHistory} />
                    </div> :
                    !isLoading && !data?.allLogs?.length && filterApplied ? <div style={{ paddingTop: '100px' }}>
                        <NoDataFound height={100} width={100} message1='No call logs' message2='Try different filters or reset the filters' img={noHistory} />
                    </div> :
                        <><div style={{ paddingTop: '95px' }} className='container-fluid'>
                            <Allcalllist
                                data={data}
                                page={page}
                                setPage={setPage}
                            />
                        </div>
                        </>}


        </div>
    )
}

export default Calllogs