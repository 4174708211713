import moment from 'moment'
import React, { useContext } from 'react'
import { Button, Divider, Menu, MenuItem, MenuProps } from '@mui/material';
import { useMutation } from 'react-query';
import { leadUpdateApi } from '../../controllers/leads';
import { toastify } from '../common/notification';
import { UserContext } from '../common/UserContext';
import stringCaps from './Stringcaps';
import { leadStage } from '../common/globalValues';
function Previousappoinemnts({ data, refetch, setLoading }) {
  const { authToken } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const leadMutation = useMutation((data: any) => leadUpdateApi(authToken, data), {
    onSuccess: () => {
      setLoading(false);
      toastify('success', 'Status updated successfully');
      refetch();
    },
  });

  const handleMenuselected = (e: any, app: any) => {
    setLoading(true)
    const value: any = e.target.value;
    if (value !== "null") {
      const body = {
        type: "updateStatus",
        id: app?.leadInfo?._id,
        appointment_id: app?.appointment_id,
        status: e.target.value,
        lead_status: e.target.value.toLowerCase(),
      }
      leadMutation.mutateAsync(body)
    }
  }
  return (
    <div className='px-4 py-3'>
      <h3 className='font-weight-bold' style={{ color: "#00158d" }}>Previous Appointments</h3>
      {
        !data || data === null || !data.length ? (<div style={{ color: 'gray' }} className="text-center h6">No records found</div>) :
          data?.map((app: any, index: any) => (
            <div className='p-3 bg-white text-dark shadow-sm mb-1'>

              <div style={{ height: 'auto', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <span style={{ fontSize: '13px' }}>{app?.appointment_date}</span>
                <div>
                  <span style={{ fontSize: '13px' }} className="p-0 m-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock mb-1 text-warning" viewBox="0 0 16 16">
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg> {moment(app?.appointment_time, ["HH:mm a"]).format("hh:mm A")}({app?.timeZone})</span>
                </div>
                <div>
                  <span style={{ fontSize: '13px' }}
                    className={app?.appointment_status === "Cancelled" ? 'badge bg-danger text-white mt-auto mb-auto' :
                      'badge bg-light text-primary mt-auto mb-auto'
                    }>{leadStage[(app?.appointment_status).toLowerCase()]}</span>
                </div>
                <select disabled={app?.appointment_status === "Cancelled" || app?.appointment_status === "Showed" || app?.appointment_status === "No_show"} name="" id="" onChange={(e: any) => { handleMenuselected(e, app) }} style={{ paddingTop: "0px" }}>
                  <option value="null" >Select status</option>
                  <option value="Showed" >Showed</option>
                  {/* <option value="Sold" >Sold</option> */}
                  <option value="No_show" >No show</option>
                </select>
              </div>
            </div>
          ))
      }
    </div >
  )
}

export default Previousappoinemnts

