/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
import { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { read, utils } from 'xlsx'
// import readXlsxFile from 'read-excel-file'
import axios from 'axios'
import config from '../../../config.json';
import { UserContext } from '../../common/UserContext';
import { toastify } from '../../common/notification'
import moment from 'moment'
import '../dashboard.css'
import ReactPaginate from 'react-paginate'
import Loader from '../../common/Loader'
import Filter from './Filter';
import { CSVLink } from 'react-csv'
import _ from 'lodash';
import NoDataFound from '../../common/NoDataFound';
import Template from './Template';
// type Props = {}
import Select from 'react-select';
import Warningalert from '../../common/Warningalert';
const url = config['baseHost_backend'];
function Report({ setUser, resData, valuesObj, options }: any) {
    const [filter, setFilter] = useState<any>('last3monthRecord')
    // const [data, setData] = useState<any>(null)
    const [showWarning, setShowWarning] = useState<any>(false);
    const [backendData, setBackendData] = useState<any>(null)
    // const { authToken, fromDate, toDate, type, ID } = useContext(UserContext);
    const { authToken, fromDate, toDate, type, dealerID, corporateID, user_first_name, subscriptionFee } = useContext(UserContext);
    let fileUpload = useRef<any>(null)
    const [front, setFront] = useState<any>(0)
    const [back, setBack] = useState<any>(0)
    const [total, seTtotal] = useState<any>(0)
    const [ROI, setROI] = useState<any>(0)
    const [pageNumber, setPageNumber] = useState(0);
    const [rowPerPage, setRowperPage] = useState(10);
    const [displayData, setDisplayData] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const pageVisited = pageNumber * rowPerPage;
    const [filteredROIReport, setFilteredROIReport] = useState<any>(null)
    const [fileName, setFileName] = useState<any>(null);
    const [defaultSelectedFiltervalue, setDefaultSelectedFilterValue] = useState<any>("All")
    const [csvData, setcsvData] = useState<any>(null);
    const [last3monthrecord, setLast3monthRecord] = useState<any>(null)
    const [years, setYears] = useState<any>([])
    const [uploadedData, setUploadedData] = useState<any>(null)
    const [globalSellingValue, setGlobalSellingValue] = useState<any>(0)
    const [globalSubscriptionfee, setGlobalSubscriptionfee] = useState<any>(0)
    const [show, setShow] = useState<any>(false)
    const [noDataDisable, setNoDataDisable] = useState<any>(false)
    // const [csvObj, setCsvObj] = useState<any>()
    const [selectedDealerID, setSelectedDealerID] = useState<any>({
        dealer_name: "",
        dealer_id: "",
        subscriptionFee: ""
    })
    const handleBtn = () => {
        fileUpload.current.click()
    }

    

    const handleClose = () => setShow(false)

    const handleFileChange = (event: any) => {
        if (event?.target?.files[0]) {
            
            setFileName(event.target.files[0]?.name)
            var reader = new FileReader();
            reader.readAsArrayBuffer(event.target.files[0]);
            reader.onload = (event: any) => {
                setLoading(true)
                
                const data = event.target.result;
                // const workBook = read(data, { type: "binary" });
                const workBook = read(data, { type: "binary", cellDates: true });
                
                workBook.SheetNames.forEach((sheet) => {
                    let rowObject: any = utils.sheet_to_json(workBook.Sheets[sheet])
                    
                    //axios call
                    let bodyData = rowObject;
                    let userInfo = {
                        data: {
                            actionType: 'dashboardFetch',
                            DEALERINFO: {
                                dealer_id: type === 'dealer' ? dealerID : selectedDealerID.dealer_id,
                                dealer_name: type === 'dealer' ? user_first_name : selectedDealerID.dealer_name,
                                subscriptionFee: type === 'dealer' ? subscriptionFee : selectedDealerID.subscriptionFee
                            },
                            AUTHINFO: {
                                dealerID: dealerID,
                                corporateID: corporateID
                            },
                            // type: type,
                            // ID: type==='corporate' ? ID : Number(ID),
                            InventoryBody: bodyData
                        }
                    }

                    axios
                        .post(`${url}/drm-dashboard`, userInfo, {
                            headers: {
                                Authorization: authToken
                            },
                        })
                        .then((res) => {
                            setSelectedDealerID({
                                dealer_name: "",
                                dealer_id: "",
                                subscriptionFee: ""
                            });
                            setGlobalSellingValue(0);
                            setGlobalSubscriptionfee(0);
                            
                            setUploadedData(res?.data)
                            setFilteredROIReport(null)

                            const validdata = res?.data?.body?.data?.UPDATA?.data?.filter((obj: any) => {
                                return obj?.VehicleVIN
                            })

                            if (!validdata?.length) {
                                // toastify('failure', 'No lead is matching for uploaded file')
                                setShow(true)
                            }
                            if (!res?.data?.body?.data?.inventory?.data?.length) {
                                setNoDataDisable(true)
                            } else {
                                setNoDataDisable(false)
                            }
                            setLoading(false)
                            
                            toastify('success', 'File uploaded successfully.')
                            rowObject = ""
                        })
                        .catch((error) => {
                            setLoading(false)
                            toastify(
                                'failure',
                                error?.response?.data?.message.length > 0
                                    ? error.response.data.message
                                    : 'Something went wrong'
                            )
                        }
                        );
                })
            };
            reader.onerror = function (error) {
                
            };
        }
    }

    //On page load last 1 month record
    useEffect(() => {
        if (uploadedData || resData) {
            
            const data = uploadedData || resData;
            console.log("data",data);
            
            //calculate data with in last 90days
            let today = new Date(Date.now());
            // today.setMonth(today.getMonth() - 3);
            today.setMonth(today.getMonth() - 1);
            const last3monthdate = new Date(today.toLocaleDateString())
            
            const temp = [{ data: [] }] as any;

            let loopdata=data?.inventory?.data || data?.body?.data?.inventory?.data
            loopdata?.forEach((obj: any) => {
                let year: any = new Date(obj["Date"]).getFullYear()
                years.push(year)
                
                
                if (new Date(obj["Date"]) >= last3monthdate && new Date(obj["Date"]) <= new Date(Date.now())) {
                    temp[0].data.push(obj)
                }
            })
            

            setLast3monthRecord(temp)
            setBackendData(data)
            if (!data?.body?.data?.inventory?.data?.length) {
                setNoDataDisable(true)
            } else {
                setNoDataDisable(false)
            }
        }
    }, [resData, uploadedData])


    //code for genrating data from CSV download
    useEffect(() => {
        if (filteredROIReport || last3monthrecord) {
            // if (defaultSelectedFiltervalue === "Monthly" || defaultSelectedFiltervalue === "Quarterly" || defaultSelectedFiltervalue === "yearly") {
            let masterArray = [] as any;
            
            const data = filteredROIReport || last3monthrecord;
            data?.forEach((obj: any) => {
                let t1 = [{
                    A: "Vehicle",
                    B: "Dealer name",
                    C: "Vin",
                    D: "Model",
                    E: "Year",
                    F: "Age",
                    G: "Inventory date",
                    H: "Mesg Date",
                    I: "Stock",
                    J: "Cost",
                    K: "Price"
                }]
                let t2 = [] as any;
                let t3 = [] as any;
                t2.push({ A: obj.month || obj.quarter || obj.year })
                t3.push({ I: "Total", J: obj?.totalCost, K: obj?.totalPrice })

                obj?.data?.forEach((obj1: any) => {
                    t1.push({
                        A: obj1["Make"],
                        B: obj1["Dealer_name"],
                        C: obj1["Vin"],
                        D: obj1["Model"],
                        E: obj1["Year"],
                        F: obj1["Age"],
                        G: moment(obj1["Date"]).format('ll'),
                        H: moment(obj1["Message Date"]).format('ll'),
                        I: obj1["Stock#"],
                        J: obj1["Cost"],
                        K: obj1["Price"],
                    })
                })

                t2.forEach((obj: any) => {
                    masterArray.push(obj)
                })
                t1.forEach((obj: any) => {
                    masterArray.push(obj)
                })
                t3.forEach((obj: any) => {
                    masterArray.push(obj)
                })

            })
            setcsvData(masterArray)
        }
    }, [defaultSelectedFiltervalue, filteredROIReport, ROI, front, back, total, last3monthrecord])


    useEffect(() => {
        let tempArray = [] as any;
        if (filteredROIReport) {
            
            filteredROIReport?.slice(pageVisited, pageVisited + rowPerPage)
                .map((obj: any) => {
                    tempArray.push(obj)
                })
            setDisplayData(tempArray);
            
            return

        }

        if (last3monthrecord) {
            

            last3monthrecord?.slice(pageVisited, pageVisited + rowPerPage)
                .map((obj: any) => {
                    tempArray.push(obj)
                })
            
            setDisplayData(tempArray);
        }
    }, [filteredROIReport, filter, pageNumber, defaultSelectedFiltervalue, last3monthrecord])

    let pageCount = filter === 'All' ? Math.ceil(backendData?.length / rowPerPage) : Math.ceil(displayData?.length / rowPerPage)

    const handlePageClick = (Selected: any) => {
        
        setPageNumber(Selected.selected)

    }

    const handleDealerselected = (opt: any) => {
        const filtered = valuesObj?.filter((obj: any) => obj?._id?.dealerID === opt?.value)
        
        setSelectedDealerID({
            dealer_name: filtered[0]?._id?.dealer_name,
            dealer_id: filtered[0]?._id?.dealerID,
            subscriptionFee: filtered[0]?._id?.subscriptionFee
        });
    }
    const dv = {
        label: user_first_name,
        value: dealerID
    }
    return (
        <>
            <Row className="container row w-100 d-flex justify-content-center m-auto bg-light shadow-sm py-3">
                {loading ? <Loader /> :
                    (<Col className='spacing-1'>
                        <div className="d-flex w-100 justify-content-between py-3">
                            <h2 className='font-weight-bold p-0 m-0'>Inventory Matchback Report</h2>
                        </div>
                        <div className='filters-cont-r'>
                            <Filter
                                setGlobalSubscriptionfee={setGlobalSubscriptionfee}
                                globalSubscriptionfee={globalSubscriptionfee}
                                globalSellingValue={globalSellingValue}
                                setGlobalSellingValue={setGlobalSellingValue}
                                setFilter={setFilter}
                                years={_.uniq(years)}
                                data={backendData}
                                setFilteredROIReport={setFilteredROIReport}
                                defaultSelectedFiltervalue={defaultSelectedFiltervalue}
                                setDefaultSelectedFilterValue={setDefaultSelectedFilterValue}
                                // options={options}
                                setSelectedDealerID={setSelectedDealerID}
                                selectedDealerID={selectedDealerID}
                                noDataDisable={noDataDisable}
                            />
                            <div style={{ width: '410px' }}>
                                <span className='pb-4 h6' style={{ float: 'right' }}>{fileName && fileName}</span>
                                <div className='componentWrapper d-flex justify-content-left gap-3 p-3'>
                                    <div className="header">
                                        {type === "dealer" ? "Upload inventory report" : "Select the dealer and upload inventory report"}

                                    </div>
                                    <div style={{ width: '250px' }}>
                                        <Select closeOnSelect={true}
                                            value={type === "dealer" ? dv : !selectedDealerID.dealer_id ? { label: "Select", value: 'null' } : { label: selectedDealerID.dealer_name, value: selectedDealerID.dealer_id }}
                                            isDisabled={type === "dealer"}
                                            isSearchable={true}
                                            className="mt-auto mb-auto"
                                            label={selectedDealerID?.dealer_name}
                                            defaultValue="All"
                                            options={options && options.slice(1)}
                                            onChange={(opt: any) => {
                                                
                                                handleDealerselected(opt)
                                            }}></Select>
                                    </div>

                                    <div className='d-flex flex-column align-items-end mt-auto mb-auto'>
                                        <input type="file" onChange={handleFileChange} hidden ref={fileUpload} name="excel" id="excel" accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' />
                                        <div className='d-flex'>
                                            <button className='btn pbuttonbgcolor btn-sm' onClick={handleBtn} disabled={type === "dealer" ? false : !selectedDealerID?.dealer_id}>Choose File</button>
                                        </div>
                                        <div className='pt-1'>
                                            <Template />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {<>
                            <div className="d-flex justify-content-between pb-2 pt-3">
                                <div className='d-flex flex-column'>
                                </div>
                                <div className='d-flex flex-column'>
                                </div>
                                <div className='d-flex flex-column'>
                                </div>
                                <div className='d-flex flex-row'>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <table className="table">
                                    <thead className="thead-dark bg-dark text-white">
                                        <tr>
                                            <th scope="col">Vehicle</th>
                                            <th scope="col">Dealer name</th>
                                            <th scope="col">Vin</th>
                                            <th scope="col">Model</th>
                                            <th scope="col">Year</th>
                                            <th scope="col">Age</th>
                                            <th scope="col">Inventory date</th>
                                            <th scope="col">Mesg Date</th>
                                            <th scope="col">Stock</th>
                                            <th scope="col">Cost</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            displayData?.map((data2: any, index: any) => (
                                                data2.data.length === 0 ? "" :
                                                    <>
                                                        {(<span className='bg-month px-3 badge'>{data2?.month || data2?.quarter || data2?.year}</span>)}
                                                        {data2?.data?.map((obj: any) => (
                                                            <>
                                                                <tr></tr>
                                                                <tr style={{ border: '1px solid black' }}>

                                                                    <td>{obj?.Make}</td>
                                                                    <td>{obj?.Dealer_name}</td>
                                                                    <td>{obj?.Vin}</td>
                                                                    <td>{obj?.Model}</td>
                                                                    <td>{obj?.Year}</td>
                                                                    <td>{obj?.Age}</td>
                                                                    <td>{moment(obj["Date"])?.format('ll')}</td>
                                                                    <td>{moment(obj["Message Date"])?.format('ll')}</td>
                                                                    <td>{obj["Stock#"]}</td>
                                                                    <td>{obj?.Cost}</td>
                                                                    <td>{obj?.Price}</td>
                                                                    {/* <td>{obj?.FrontGross || 0}</td>
                                                                <td>{obj?.BackGross || 0}</td>
                                                                <td>{obj["Total Gross"] || 0}</td>
                                                                <td>{obj["SellingPrice"] || 0}</td> */}
                                                                    {/* <td>{obj?.subscriptionFee   }</td> */}
                                                                </tr>
                                                            </>
                                                        ))}

                                                        {<tr style={{ border: '1px solid black' }}>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className='bg-td-footer'>TOTAL</td>
                                                            <td className='bg-td-footer'>{data2?.totalCost?.toFixed(2)}</td>
                                                            <td className='bg-td-footer'>{data2?.totalPrice?.toFixed(2)}</td>
                                                        </tr>}
                                                    </>
                                            ))

                                        }
                                    </tbody>
                                    {csvData && displayData[0]?.data?.length ? <div className='mt-auto mb-auto' style={{ pointerEvents: !displayData[0]?.data?.length ? 'none' : "auto" }}>
                                        <CSVLink data={csvData} filename={"Report"}
                                        ><button className='btn-sm tbuttonbgcolor mt-2' >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-csv" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z" />
                                                </svg>Download</button></CSVLink>
                                    </div> : ""}
                                </table>
                            </div>

                            {
                                displayData?.map((data2: any, index: any) => (
                                    data2.data.length === 0 && <NoDataFound height={""} width={""} img="" message1='No data found' message2='' />))
                            }

                            {!displayData || displayData?.length < 1 &&
                                <ReactPaginate
                                    nextLabel=">>"
                                    onPageChange={handlePageClick}
                                    pageCount={pageCount}
                                    previousLabel="<<"
                                    containerClassName='pagination'
                                    pageClassName={filter === 'All' ? 'pagination' : 'page-item disabled'}
                                    // pageClassName='page-item'
                                    pageLinkClassName='page-link'
                                    previousClassName='page-item'
                                    previousLinkClassName='page-link'
                                    nextClassName='page-item'
                                    nextLinkClassName='page-link'
                                    breakLabel='...'
                                    breakLinkClassName='page-link'
                                    activeClassName='active'
                                    breakClassName='page-item'
                                />
                            }
                        </>}
                    </Col>)}
            </Row>
            <Modal centered show={show}>
                <Modal.Header >
                    <Modal.Title>
                        <div className='d-flex gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="text-warning mt-auto mb-auto bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                            </svg>
                            <h2 className='pl-2 mt-auto mb-auto'>No lead is matching</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column'>
                        <span className=''>No lead is matching for uploaded file!</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <button
                        className='btn btn-sm pbuttonbgcolor'
                        onClick={handleClose}
                    >
                        OK
                    </button>
                </Modal.Footer>
            </Modal>

            <Warningalert showWarning={showWarning} setShowWarning={setShowWarning} heading="Subscription fee not found" body="Subscription fee is not assigned for this dealer. please assign it first before uploading the sold report." />
        </>
    )
}

export default Report