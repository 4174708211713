import moment from 'moment';
import React from 'react'
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'

function Timetable({ data }) {
    console.log("data", data);

    const history = useHistory();
    return (
        <div>
            <div className="accordion my-2" id="accordionExample" >
                {data && data?.sort((a,b)=>a?.date>b?.date ? 1 : -1)?.map((obj, indx) => (
                    <div className="accordion-item open  shadow-sm mb-4" aria-expanded="true"  >
                        <div className="accordion-header my-0 py-0" id={'heading' + indx}>
                            <button className="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target={'#' + 'collapse' + indx} aria-expanded="false" aria-controls={'collapse' + indx}>
                                <div style={{ width: '100%' }} className='d-flex justify-content-between'>
                                    <span style={{ fontWeight: 'bold' }} className='mt-auto mb-auto'>{moment(obj?.date).format("LL")}</span>
                                </div>
                            </button>
                        </div>
                        <div id={'collapse' + indx} className="accordion-collapse collapse show" aria-labelledby={'heading' + indx} data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <Table bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Lead name</th>
                                            <th>Email</th>
                                            <th>Dealership</th>
                                            <th>Timer(HH:MM:SS)</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody style={{ fontWeight: 'normal' }}>
                                        {obj?.leads?.map(
                                            (
                                                lead: any,
                                                index: any
                                            ) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{lead?.leadDetails?.first_name + " " + lead?.leadDetails?.last_name}</td>
                                                    <td>{lead?.leadDetails?.email || "N/A"}</td>
                                                    <td>{lead?.dealerName || "N/A"}</td>
                                                    <td>
                                                        {
                                                            lead?.timer?.hours?.toString().padStart(2, '0') + ":" + lead?.timer?.minutes?.toString().padStart(2, '0') + ":" + lead?.timer?.seconds?.toString().padStart(2, '0')
                                                        }
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-outline-primary btn-sm mr-5' onClick={() => {
                                                            history.push(`/leads/${lead?.leadID}`)
                                                        }}>Open lead details</button>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Timetable