import moment from "moment";
import states from "./states";
// export const findKey = (obj, key) => {
//     if (obj.hasOwnProperty(key)) {
//         if (key === "appointment_date") {
//             return obj[key] ? obj[key] + obj["appointment_time"] && moment(obj["appointment_time"], ["HH:mm a"]).format("hh:mm A") + (obj["timeZone"]) : { key: key, msg: "Key not exist" }
//         } else if (key === "address") {
//             console.log("dealer", obj);
//             let street_1 = obj?.street_1
//             let street_2 = obj?.street_2
//             let area_sector = obj?.area_sector
//             let state = states?.filter((a) => a.name.toLocaleLowerCase() === obj?.state?.toLocaleLowerCase())[0]?.abbreviation;
//             let pincode = obj?.pincode;
//             let arr = [street_1, street_2, area_sector, state, pincode]
//             let fil = arr?.filter((a) => a);
//             console.log("fil", fil);
//             return fil && fil?.length ? fil.toString() : { key: key, msg: "Key not exist" };
//         }
//         else {
//             return obj[key] !== undefined ? obj[key] : { key: key, msg: "Key not exist" };
//         }

//     }
//     for (let prop in obj) {
//         if (typeof obj[prop] === 'object' && obj[prop] !== null) {
//             const value = findKey(obj[prop], key);
//             console.log("value000", value);
//             if (value !== undefined) {
//                 return value;
//             }
//         }
//     }
//     return { key: key, msg: "Key not exist" };
// }





export const findKey = (obj, key) => {
    if (obj.hasOwnProperty(key)) {
        if (key === "appointment_date") {
            return obj[key] +" "+ moment(obj["appointment_time"], ["HH:mm a"]).format("hh:mm A") + " "+ (obj["timeZone"])
        } else if (key === "address") {
            let street_1 = obj?.street_1
            let street_2 = obj?.street_2
            let area_sector = obj?.area_sector
            let state = states?.filter((a) => a.name.toLocaleLowerCase() === obj?.state?.toLocaleLowerCase())[0]?.abbreviation;
            let pincode = obj?.pincode;
            let arr = [street_1, street_2, area_sector, state, pincode]
            let fil = arr?.filter((a) => a);
            return fil && fil.toString()
        }
        else {
            return obj[key]
        }
    }
    for (let prop in obj) {
        if (typeof obj[prop] === 'object' && obj[prop] !== null) {
            const value = findKey(obj[prop], key);
            if (value !== undefined) {
                return value;
            }
        }
    }
    return undefined;
}


// export const findKey = (obj, key) => {
//     if (obj.hasOwnProperty(key)) {
//         console.log("obj000",obj,key,obj[key]);
//         return obj[key];
//     }
//     for (let prop in obj) {
//         if (typeof obj[prop] === 'object' && obj[prop] !== null) {
//             const value = findKey(obj[prop], key);
//             console.log("value000",value);
//             if (value !== undefined) {
//                 return value;
//             }
//         }
//     }
//     return undefined;
// }