import moment from "moment";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { taskTypes } from "./globalValues";
import { Table } from "react-bootstrap";
import eye from "../../assets/icons/eye.svg";
import Viewtask from "./Viewtask";
import NoDataFound from "./NoDataFound";

function Tasks({ data ,refetch}: any) {
  console.log("tasks", data);
  const [show, setShow] = useState<any>(false);
  const [modaldata, setModaldata] = useState<any>("");
  const history = useHistory();

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title className="text-dark mt-1">Tasks</Card.Title>
        </Card.Header>
        <Card.Body>
          <div>
            {!data?.taskManagements?.length && <NoDataFound message1="No task planned" message2={`Click "Create task" to create a task `} img={""} height={100} width={100}/>}
            <div className="accordion my-2" id="accordionExample">
              {data &&
                data?.taskManagements
                  ?.sort((a, b) => (a.sdatetime > b.sdatetime ? 1 : -1))
                  ?.map((task, indx) => (
                    <div
                      className="accordion-item open  shadow-sm mb-4"
                      aria-expanded="true"
                    >
                      <div
                        className="accordion-header my-0 py-0"
                        id={"heading" + indx}
                      >
                        <button
                          className="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#" + "collapse" + indx}
                          aria-expanded="false"
                          aria-controls={"collapse" + indx}
                        >
                          <div
                            style={{ width: "100%" }}
                            className="d-flex justify-content-between"
                          >
                            <span
                              style={{ fontWeight: "bold" }}
                              className="mt-auto mb-auto"
                            >
                              {moment(task?.date).format("LL")}
                            </span>
                          </div>
                        </button>
                      </div>
                      <div
                        id={"collapse" + indx}
                        className="accordion-collapse collapse show"
                        aria-labelledby={"heading" + indx}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <Table bordered hover>
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th>Type</th>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody style={{ fontWeight: "normal" }}>
                              {task?.tasks
                                ?.map((md: any, index: any) => (
                                  <tr key={index}>
                                    <td>{md?.title}</td>
                                    <td>{taskTypes[md?.tasktype]}</td>
                                    <td>
                                      {moment(md?.t_edatetime).format("hh:mm")}
                                    </td>
                                    <td>{md?.status}</td>
                                    <td>
                                      <img
                                        style={{ cursor: "pointer" }}
                                        src={eye}
                                        alt="open"
                                        onClick={() => {
                                          setModaldata({...md,date:task?.date});
                                          setShow(true);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))
                                .reverse()}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Viewtask
        modalData={modaldata}
        setShow={() => {
          setShow(false);
        }}
        show={show}
        showOpenleadBtn={false}
        lead={data}
        refetch={refetch}
      />
    </>
  );
}

export default Tasks;
