import React, { useEffect, useRef, useState } from 'react';
import { Container, Button, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router';
import { toastify } from '../common/notification';
import axios from 'axios';
import './Login.css'
import config from '../../config.json';
import ForgotPassword from './ForgotPassword';

const RightPanel = ({ setUser }: { setUser: Function }) => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<any>(' ');
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [forgotPassword, setForgotPassword] = useState<any>(false)
    const history = useHistory();
    const cont1 = useRef<any>(null)
    const handleLogin = (e: React.SyntheticEvent) => {
        e.preventDefault();
        localStorage.removeItem('filterQuery')
        localStorage.removeItem("toggle")
        localStorage.removeItem("FILTERS_OBJ")
        localStorage.removeItem("DATE_LENGTH")
        localStorage.removeItem("CUST_RESP_DATE_LENGTH")
        localStorage.removeItem('sr')
        localStorage.removeItem("CRDsort")
        localStorage.removeItem("Template_TAB")
        if (username === '' || password === '') return;

        setIsLoading(true);

        const url = config['baseHost_backend'] + '/login';
        const requestOptions = {
            email: username.trim().toLocaleLowerCase(),
            password: password.trim(),
        };
        axios
            .post(url, requestOptions)
            .then((response) => {
                

                setIsLoading(false);
                
                if (response.data.statusCode === 200) {
                    console.log("response",response);
                    
                    localStorage.setItem('$AuthToken', response.data.body.access_token);
                    setUser({
                        id: response?.data?.body?.user_info?._id,
                        email: response.data.body.user_info.email,
                        name: `${response.data.body.user_info.first_name} ${response.data.body.user_info.last_name}`,
                        role: response.data.body.user_info.role,
                        authToken: response.data.body.access_token,
                        user_first_name: response.data.body.user_info.first_name,
                        user_last_name: response.data.body.user_info.last_name,
                        phone: response?.data?.body?.user_info?.contactdetails?.cell_number ? response.data.body.user_info.contactdetails.cell_number : "",
                        linkedin: response.data.body.user_info.linkedin,
                        imageUrl: response.data.body.user_info.imageUrl,
                        workNumber: response?.data?.body?.user_info?.contactdetails?.work_number ? response.data.body.user_info.contactdetails.work_number : "",
                        type: response?.data?.body?.user_info?.type ? response.data.body.user_info.type : "",
                        communicationAddress: response?.data?.body?.user_info?.contactdetails?.communication_address ? response.data.body.user_info.contactdetails.communication_address : "",
                        company: response?.data?.body?.user_info?.company,
                        dealerID: response?.data?.body?.user_info?.dealerID,
                        corporateID: response?.data?.body?.user_info?.corporateID,
                        timezone:response?.data?.body?.user_info?.timezone,
                        shortName: response?.data?.body?.user_info?.shortName,
                        aliasName:response?.data?.body?.user_info?.aliasName
                    });

                    setIsSuccess(true);
                    toastify('success', 'Login Success');
                    history.push('/dashboard');
                    // window.location.href="/#/dashboard"
                    // window.location.reload()
                } else {
                    toastify('failure', 'Invalid Username and Password');
                }
            })
            .catch((err) => {
                toastify('failure', err.message);
                setIsLoading(false);
            });
    };

    return (
        <div>
            {
                !forgotPassword ? <Container
                    className='d-flex align-items-center justify-content-center rounded py-4'
                    style={{ flexDirection: 'column'}}>
                    <div

                        style={{
                            marginTop: '2%',
                            width: '450px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <h1 className='bolder'>Sign in to Cognitgo</h1>
                    </div>
                    <div
                        style={{
                            height: '250px',
                            width: '450px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginTop: '5%',
                            marginLeft: '10%',
                        }}>
                        <form onSubmit={handleLogin} id='login' >
                            <div style={{ margin: '5%' }}>
                                <div >
                                    <InputGroup className='input'>
                                        <FormControl
                                            ref={cont1}
                                            className='bg-white'
                                            placeholder='Email'
                                            aria-label='Email'
                                            aria-describedby='basic-addon1'
                                            onChange={(e) => setUsername(e.target.value)}
                                            name='username'
                                            id='username'
                                        />
                                    </InputGroup>
                                    <InputGroup className='input'>
                                        <FormControl
                                            className='bg-white'
                                            placeholder='Password'
                                            aria-label='password'
                                            name='password'
                                            type={showPassword ? 'text' : 'password'}
                                            id='password'
                                            onChange={(e) => setPassword(e.target.value)}
                                            aria-describedby='basic-addon1'
                                        />
                                        <Button
                                            onClick={() => setShowPassword(!showPassword)}
                                            variant='outline-secondary'
                                            id='button-addon2'>
                                            {showPassword ? (
                                                <svg
                                                    style={{ height: '25px' }}
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    stroke='currentColor'
                                                    strokeWidth={2}>
                                                    <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        d='M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21'
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    style={{ height: '25px' }}
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    stroke='currentColor'
                                                    strokeWidth={2}>
                                                    <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                                                    />
                                                    <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
                                                    />
                                                </svg>
                                            )}
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <button
                                        className='pbuttonbgcolor btn'
                                        type='submit'
                                        style={{
                                            width: '85%',
                                            marginLeft: '2%',
                                            padding: '3%',
                                            marginTop: '3%',
                                        }}
                                        onClick={handleLogin}>
                                        {isLoading ? (
                                            <Spinner animation='border' variant='primary' />
                                        ) : (
                                            'Sign in'
                                        )}
                                    </button>

                                </div>
                                <div style={{ display: 'flex',marginRight:'50px', marginTop:'20px' }}>
                                    <span
                                        onClick={() => { setForgotPassword(true) }}
                                        className='login-forget-pass-txt ml-auto mr-auto'>Forgot Password?</span>
                                </div>
                                <br />
                            </div>
                        </form>
                    </div>
                </Container> :
                    <ForgotPassword setForgotPassword={setForgotPassword} />
            }
        </div>
    );
};

export default RightPanel;
