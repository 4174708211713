import logo from './logo.svg';
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import './App.css';
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
// import Logs from './Logs';

function Call() {
  const startUp = useRef(null)


  useEffect(() => {
    // startUp.current.click()
    // <script src="./quickstart.js"></script>
  }, [])

  // setTimeout(() => {
    // startUp.current.click()
  // }, 2000);

  const recordCall = () => {

  }
  return (
    <div className="App container-fluid">
      {/* <input type="number" onChange={(e)=>{setNum(e.target.value)}}></input>
        <button onClick={callNow}>Call now</button>
        <span>Calling...</span> */}
      <header >
        <h1>Twilio Voice JavaScript SDK Quickstart</h1>
        <button id="startup-button" ref={startUp}>Start up the Device</button>
      </header>
      <main id="controls">
        <section class="left-column" id="info" style={{display:'none'}}>
          <h2>Your Device Info</h2>
          <div id="client-name"></div>
          <div id="output-selection" class="hide">
            <label>Ringtone Devices</label>
            <select id="ringtone-devices" multiple></select>
            <label>Speaker Devices</label>
            <select id="speaker-devices" multiple></select
            ><br />
            <button id="get-devices">Seeing "Unknown" devices?</button>
          </div>
        </section>
        <section class="center-column">
          <h2 class="instructions py-2 h4">Make a Call</h2>
          <div id="call-controls" class="hide">
            <form>
              {/* <label for="phone-number"
              >Enter a phone number or client name</label
              > */}
              {/* <input id="phone-number" type="text" placeholder="+15552221234" /> */}
              {/* <span id="phone-number" value="+918610674154">++918971561491</span> */}
              <span id="phn" value="+918971561491" style={{display:'none'}}>+918971561491</span>
              <button id="button-call" type="submit" className='btn btn-success'>Call +918971561491</button>
            </form>
            <button id="button-hangup-outgoing" class="hide btn my-3">Hang Up</button>
            {/* <button onClick={recordCall}>Record</button> */}
            <div id="incoming-call" class="hide">
              <h2>Incoming Call Controls</h2>
              <p class="instructions">
                Incoming Call from <span id="incoming-number"></span>
              </p>
              <button id="button-accept-incoming">Accept</button>
              <button id="button-reject-incoming">Reject</button>
              <button id="button-hangup-incoming" class="hide">Hangup</button>
            </div>
            <div id="volume-indicators" class="hide">
              <label>Mic Volume</label>
              <div id="input-volume"></div>
              <br /><br />
              <label>Speaker Volume</label>
              <div id="output-volume"></div>
            </div>
          </div>
        </section>
        <section class="right-column">
          <h2 className='h4 py-3'>Event Log</h2>
          <div class="hide" id="log"></div>
        </section>
      </main>
      {/* <Logs /> */}

    </div>
  );
}

export default Call;
