import moment from "moment";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserContext } from "./UserContext";
import config from "../../config.json";
import axios from "axios";
import { toastify } from "./notification";
import { taskTypes } from "./globalValues";
import LoadingBar from "./LoadingBar";
function Viewtask({ show, modalData, setShow, showOpenleadBtn, lead,refetch }) {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [status, setStatus] = useState<any>("");
  const { id } = useContext<any>(UserContext);
  let token: any = localStorage.getItem("$AuthToken");
  console.log("modalData", modalData);
  const handleUpdateStatus = (e) => {
    e.preventDefault();
    console.log("modalData", modalData);
    let payload = {
      createdByid: id,
      taskid: modalData?.taskid,
      status: status,
      leadID:lead?._id,
      date:modalData?.date,
      type: "update",
    };
    console.log("payload", payload);
    setLoading(true);
    console.log("payload", payload);
    axios
      .post(config.baseHost_backend + "/task-management", payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("res", res);
        setLoading(false);
        setShow(false);
        refetch()
        if (res?.data?.statusCode === 200) {
          toastify("success", "Task updated successfully");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
      });
  };
  return (
    <Modal show={show} centered>
      <LoadingBar isActive={loading} />
      <Modal.Header>
        <Modal.Title className="p-0 m-0">{modalData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div>
            <h3>
              <span style={{ fontWeight: "bold" }}>Task type:</span>{" "}
              {taskTypes[modalData?.tasktype]}
            </h3>

            <h3>
              <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
              {modalData?.status}
            </h3>
            <h3>
              <span style={{ fontWeight: "bold" }}>Date:</span>{" "}
              {moment(modalData?.start || modalData?.t_edatetime).format("LLL")}
            </h3>
            <h3>
              <span style={{ fontWeight: "bold" }}>Note:</span>{" "}
              <textarea name="" value={modalData?.note || "N/A"} id="" disabled className="form-control" ></textarea>
            </h3>
            {showOpenleadBtn && (
              <button
                className="btn btn-sm tbuttonbgcolor"
                onClick={() => {
                  history.push(`/leads/${modalData?.leadid}`);
                }}
              >
                Open lead details
              </button>
            )}

            {modalData?.status !== "Completed" && (
              <div>
                {" "}
                <hr />
                <span>Update task status</span>
                <select
                  className="form-select"
                  name=""
                  id=""
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="null">Select status</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            className="btn btn-sm pbuttonbgcolor"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </button>
          {status && status != "null" && (
            <button
              className="btn btn-sm pbuttonbgcolor ml-2"
              onClick={handleUpdateStatus}
            >
              Update
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default Viewtask;
