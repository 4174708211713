import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { createDraftEmailonReply, createDraftemail, fetchConversations, fetchMessageDetails, markReadStatus, reply, sendExistingDraftEmail } from '../../../GraphService';
import { useAppContext } from '../../../AppContext';
import { styled } from '@mui/material/styles';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import '../style.css'
import leftArrow from '../../../assets/images/leftArrow.svg';
import replypic from '../../../assets/images/reply.svg';
import replyAllpic from '../../../assets/images/replyAll.svg';
import {
    Card,
    Chip,
    FormControl,
    Input,
    makeStyles,
} from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UserContext } from '../../common/UserContext';
import { Image } from 'react-bootstrap';
import JoditEditor from 'jodit-react';
import { toastify } from '../../common/notification';
import moment from 'moment';
import momenttz from 'moment-timezone'
import { merger_fields } from '../../../controller';
import axios from 'axios';
import config from '../../../config.json'
import { findKey } from '../../common/keyvalidation';
function Inbox() {
    let filterEmail = "neuron@eautoquote.net"
    const { shortName,aliasName, dealerID, type, user_first_name, user_last_name, authToken } = useContext(UserContext);
    let dealerAliasName=aliasName?.toLowerCase();
    const location: any = useLocation();
    // console.log('location', location)
    const reactQuillref: any = React.useRef<any>(null);
    const app: any = useAppContext();
    const [ccs, setCcs] = React.useState<any>([]);
    const [mf, setMf] = useState<any>(null)
    const [cccurrValue, setCccurrValue] = React.useState("");
    const [threadData, setThreadData] = React.useState<any>(null);
    const editor = React.useRef(null);
    // const { shortName, dealerID, type, user_first_name, user_last_name, authToken } = useContext(UserContext);

    const useStyles = makeStyles((theme) => ({
        formControlRoot: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "100%",
            flexWrap: "wrap",
            flexDirection: "row",
            border: '1px solid lightgray',
            padding: 4,
            // borderRadius: '4px',
            "&> div.container": {
                gap: "6px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
            },
            "& > div.container > span": {
                backgroundColor: "gray",
                padding: "1px 3px",
                // borderRadius: "4px"
            }
        }
    }));
    const classes = useStyles();

    const [Tos, setTos] = React.useState<any>([]);
    const [currValue, setCurrValue] = React.useState("");
    const [replyBody, setReplyBody] = React.useState("");
    const [state, setState] = React.useState('view');
    const [mailDetails, setMailDetails] = React.useState<any>(null);
    const history = useHistory();
 
    useEffect(() => {
        const fetchMergeFields = async () => {
            let body = {
                function: "fetchAll"
            }

            let resp = await axios.post(config.baseHost_backend + "/mergefield", body, {
                headers: {
                    Authorization: authToken
                },
            })
            // console.log("merge fields api", resp)
            setMf(resp?.data?.body)
        }
        fetchMergeFields();

    }, [authToken])

    const handleReply = async () => {
        let temp = [] as any;
        let temp2 = [] as any;
        let tempTos = [] as any
        Tos?.forEach((g) => {
            tempTos.push(g)
            temp.push({
                "emailAddress": {
                    "address": g,
                    "name": ""
                }
            })
        })
        ccs?.forEach((g) => {
            temp2.push({
                "emailAddress": {
                    "address": g,
                    "name": ""
                }
            })
        })

        let replyToEmail: any
        let replyToUsername:any
        
        if (location.pathname.includes('leads')) {
            replyToEmail = `notifications+${location?.state?.leadDetails?.body?.dealerDetails?.aliasName}@eautoquote.net`
            replyToUsername=location?.state?.leadDetails?.body?.dealerDetails?.shortName
        } else {
            if (type === "dealer") {
                replyToEmail = `notifications+${dealerAliasName}@eautoquote.net`;
                replyToUsername=shortName
            } else {
                // replyToEmail = `notifications@eautoquote.net`
                replyToUsername="eAutoQuote Notifications"
                // replyToEmail = location?.state?.leadDetails ? `notifications+${location?.state?.leadDetails?.body?.dealerDetails?.aliasName}@eautoquote.net` : `notifications@eautoquote.net`
                replyToEmail = `notifications@eautoquote.net`
            }
        }

        console.log("shortName",shortName);
        console.log("replyToEmail",replyToEmail);
        console.log("replyToUsername",replyToUsername);
        
        
        
        let mergeFieldPattern = /\[([^\]]+)\]/g;
        let hasmergeField = mergeFieldPattern.test(replyBody)
        let org_mailbody = replyBody;

        //if email body contains merge fields
        if (hasmergeField) {
            tempTos?.forEach(async (tp) => {
                let body1 = {
                    type: "fetchDetails",
                    leadEmail: tp,
                }
                //fetch lead & dealer details for if dealer inbox
                const result: any = await axios.post(config.baseHost_backend + "/forgot-passowrd", body1, {
                    headers: {
                        Authorization: authToken
                    },
                })
 
                if (result?.data?.statusCode === 400) {
                    return toastify('failure', `Unable to map the merge-fields, this email address ${tp} does not matching with any leads records.`)
                }


                // let leadDetail = location.pathname.includes('leads') ? location?.state?.leadDetails?.body?.data : result?.data?.body?.leadDetails;
                // let dealerDetail = location.pathname.includes('leads') ? location?.state?.leadDetails?.body?.dealerDetails : result?.data?.body?.dealerInfo;

                let leadDetail = result?.data?.body?.leadDetails;
                let dealerDetail = result?.data?.body?.dealerInfo;

                org_mailbody = replyBody.replace(mergeFieldPattern, (match, mergeField) => {
                    return findKey(leadDetail, mergeField) || findKey(dealerDetail, mergeField) || findKey({ user_first_name, user_last_name }, mergeField)
                })
                let body = {
                    "message": {
                        "toRecipients": [
                            {
                                "emailAddress": {
                                    "address": tp,
                                }
                            }
                        ],
                        "ccRecipients": temp2,
                        "replyTo": [
                            {
                                "emailAddress": {
                                    "address": replyToEmail,
                                    "name": replyToUsername
                                }
                            }
                        ]
                    },
                    "comment": org_mailbody
                }


                let draft = await createDraftEmailonReply(app.authProvider, threadData?.id, body)
                if (draft) {
                    let sentEmiail = await sendExistingDraftEmail(app.authProvider, draft?.id)
                    if (sentEmiail) {
                        toastify('success', 'Reply sent successfully')
                        history.push({ pathname: localStorage.getItem("LEAD_PAGE_URL") || '/mails', state: { dataChanged: true } })
                    }

                }
            })
        } else {
            let body = {
                "message": {
                    // "from": {
                    //     "emailAddress": {
                    //         "address": filterEmail
                    //     }
                    // },
                    "toRecipients": temp,
                    "ccRecipients": temp2,
                    "replyTo": [
                        {
                            "emailAddress": {
                                "address": replyToEmail,
                                "name": replyToUsername
                            }
                        }
                    ]
                },
                "comment": org_mailbody
            }

            let draft = await createDraftEmailonReply(app.authProvider, threadData?.id, body)
            if (draft) {
                let sentEmiail = await sendExistingDraftEmail(app.authProvider, draft?.id)
                if (sentEmiail) {
                    toastify('success', 'Reply sent successfully')
                    history.push({ pathname: localStorage.getItem("LEAD_PAGE_URL") || '/mails', state: { dataChanged: true } })

                }
            }
        }

    }

    const handleKeyUp = (e) => {
        // console.log(e.keyCode);
        if (e.target.value && e.target.value.trim()) {
            setTos((oldState) => [...oldState, e.target.value]);
            setCurrValue("");
        }
    };

    const handleChange = (e) => {
        setCurrValue(e.target.value);
    };
    const handleDelete = (item, index, type) => {
        let arr = type === "To" ? [...Tos] : [...ccs]
        arr.splice(index, 1)
        // console.log(item)
        type === "To" ? setTos(arr) : setCcs(arr)
    }
    const handleKeyUpcc = (e) => {
        // console.log(e.keyCode);
        if (e.target.value && e.target.value.trim()) {
            setCcs((oldState: any) => [...oldState, e.target.value]);
            setCccurrValue("");
        }

    };
    const handleChangecc = (e) => {
        setCccurrValue(e.target.value);
    };

    useEffect(() => {
        const fetchDetails = async () => {
            let details: any = await fetchConversations(app.authProvider, location.pathname.split("/")[3])
            let fecchedConversations = details?.value?.sort((a, b) => a.conversationIndex > b.conversationIndex ? 1 : -1)
            // console.log("conversatiosn", fecchedConversations)
            setMailDetails(fecchedConversations);

            //update isRead
            let body = {
                isRead: true
            }
            let notRead = fecchedConversations?.filter((v) => !v.isRead);
            if (notRead) {
                notRead?.map(async (nr) => {
                    await markReadStatus(app.authProvider, nr.id, body);
                })
            }
        }
        fetchDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleReplyBtn = (md) => {
        setThreadData(md)
        if (location.pathname.split("/")[2] === "sentItems") {
            if (md?.replyTo?.length) {
                setTos((oldState: any) => [...oldState, md?.replyTo[0]?.emailAddress?.address]);
            } else {
                setTos((oldState: any) => [...oldState, md?.from?.emailAddress?.address]);
            }
            setState('reply')
        }

        if (location.pathname.split("/")[2] === "inbox") {
            if (md?.replyTo?.length) {
                setTos((oldState: any) => [...oldState, md?.replyTo[0]?.emailAddress?.address]);
            } else {
                setTos((oldState: any) => [...oldState, md?.from?.emailAddress?.address]);
            }
            setState('reply');
        }
    }


    const handleReplyAllBtn = (md) => {
        setThreadData(md)
        let toaddress = [] as any
        let ccaddress = [] as any

        md?.toRecipients?.forEach((gg) => {
            toaddress.push(gg.emailAddress.address)
        })
        md?.ccRecipients?.forEach((gg) => {
            ccaddress.push(gg.emailAddress.address)
        })

        setTos(toaddress?.filter((v) => v !== `notifications+${dealerID}@eautoquote.net`));
        setTos((oldState: any) => [...oldState, md?.from?.emailAddress?.address]);
        setCcs(ccaddress)
        setState('replyAll');
    }

    const handleMergeFieldSelected = (e: any) => {
        if (e.target.value !== "null") {
            const selectedOption = e.target.selectedOptions[0];
            const objectString = selectedOption.getAttribute('data-object');
            const object = JSON.parse(objectString);
            // console.log("object", object);

            const textarea = reactQuillref.current;
            if (textarea) {
                textarea.focus();
                const quillInstance = textarea.getEditor();
                if (quillInstance) {
                    const cursorPosition = quillInstance.getSelection().index;
                    quillInstance.insertText(cursorPosition, `[${object?.value}]`);
                }
            }
        }
    }

    return (
        <div className=''>
            <div className='d-flex flex-row gap-2 container mt-3'>
                <div>
                    <Image
                        onClick={() => {
                            history.push(localStorage.getItem("LEAD_PAGE_URL") || '/mails')
                        }}
                        className='pointer'
                        src={leftArrow}
                    />
                </div>
                <div className='mt-auto mb-auto text-name'>
                    {location.pathname.split("/")[2] === "inbox" ? 'Inbox' : 'Sent items'}
                </div>
            </div>
            <hr />

            <div className='container' style={{ fontWeight: 'normal' }}>
                <h2 className='mt-0 pb-2 font-weight-bold' style={{ fontWeight: 'revert-layer' }}>{mailDetails && mailDetails[mailDetails?.length - 1]?.subject}</h2>
                {mailDetails?.sort((a, b) => a.sentDateTime > b.sentDateTime ? -1 : 1)?.map((md) => (
                    <div className='p-3 my-2' style={{ border: '1px solid lightgray' }}>
                        <div className='d-flex flex-row justify-content-between'>
                            <div>
                                <div>
                                    <span style={{ color: '#0F6CBD' }}>{md?.from?.emailAddress?.name} </span>
                                    <span style={{ fontWeight: 'lighter', fontSize: '14px' }}>&lt;{md?.from?.emailAddress?.address}&gt;</span>
                                </div>
                                <div className='d-flex flex-column'>
                                    <span style={{ fontWeight: 'lighter' }}>To: {md?.toRecipients?.map((tr) => (
                                        <span>{tr?.emailAddress?.address}, </span>
                                    ))}</span>
                                    {md?.ccRecipients.length > 0 && <span style={{ fontWeight: 'lighter' }}>Cc: {md?.ccRecipients?.map((tr) => (
                                        <span>{tr?.emailAddress?.address}, </span>
                                    ))}</span>}
                                </div>
                            </div>
                            <div>
                                {/* <span>{moment(md?.sentDateTime).format("LLL")}</span> */}
                                <span>{momenttz(md?.sentDateTime).tz('America/Los_Angeles').format("LLL")}</span>
                            </div>
                        </div>
                        <div className="bodyemail my-3" dangerouslySetInnerHTML={{ __html: md?.body?.content }}>
                        </div>
                        {state === "view" && <><button className='btn btn-primary btn-sm' onClick={() => { handleReplyBtn(md) }}><img src={replypic} alt="reply" /> Reply</button>
                            {<button className='btn btn-primary ml-3 btn-sm' onClick={() => { handleReplyAllBtn(md) }}
                            ><img src={replyAllpic} alt="reply all" /> Reply all</button>}</>}
                    </div>
                ))}



                {
                    (state === 'reply' || state === 'replyAll') && <Card className='shadow-lg p-3' style={{ width: '900px', position: 'fixed', top: '18%' }} >
                        <div className='d-flex flex-row mb-2 '>
                            <button className='btn' style={{ borderTopRightRadius: '0px', borderBottomRightRadius: "0px", border: '1px solid lightgray' }}>To</button>
                            <FormControl classes={{ root: classes.formControlRoot }} size='small' className='mt-auto mb-auto'>
                                <div >
                                    {Tos && Tos?.map((item, index) => (
                                        <Chip size="small" onDelete={() => handleDelete(item, index, "To")} label={item} className='m-1' style={{ fontWeight: 'lighter' }} />
                                    ))}
                                </div>
                                <input
                                    type='text'
                                    style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: "0px", border: 'none', padding: '2px' }}
                                    value={currValue}
                                    onChange={handleChange}
                                    onBlur={handleKeyUp}
                                    className='chipsinput'
                                />
                            </FormControl>
                        </div>
                        <div className='d-flex flex-row mb-2 '>
                            <button className='btn ' style={{ borderTopRightRadius: '0px', borderBottomRightRadius: "0px", border: '1px solid lightgray' }}>Cc</button>
                            <FormControl classes={{ root: classes.formControlRoot }} size='small' className='mt-auto mb-auto'>
                                <div >
                                    {ccs.map((item, index) => (
                                        <Chip size="small" onDelete={() => handleDelete(item, index, "Cc")} label={item} className='m-1' style={{ fontWeight: 'lighter' }} />
                                    ))}
                                </div>
                                <input
                                    type='text'
                                    style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: "0px", border: 'none', padding: '2px' }}
                                    value={cccurrValue}
                                    onChange={handleChangecc}
                                    onBlur={handleKeyUpcc}
                                    className='chipsinput'
                                />
                            </FormControl>
                        </div>
                        <div>
                            {/* <textarea name="" id="emailbodytextarea" rows={5} className='form-control bg-white p-1' onChange={(e) => { setReplyBody(e.target.value) }}></textarea> */}
                            <div style={{ fontWeight: 'normal' }} >
                                <select name="" id="" className='form-select mt-auto mb-auto w-25' onChange={handleMergeFieldSelected} style={{ float: 'right' }}>
                                    <option value="null">Select merge field</option>
                                    {mf && mf?.sort((a, b) => a?.label?.toLocaleLowerCase() > b?.label?.toLocaleLowerCase() ? 1 : -1)?.map((mf) => (
                                        <option value={mf?.value} data-object={JSON.stringify(mf)}>{mf?.label}</option>
                                    ))}
                                </select>
                                <ReactQuill ref={reactQuillref} value={replyBody} onChange={setReplyBody} placeholder='Type here...' theme="snow" />
                            </div>
                        </div>
                        <button className='btn btn-primary my-3 btn-sm' onClick={handleReply} disabled={!Tos?.length || !replyBody}>Send</button>
                        <button className='btn my-3 ml-2 btn-sm' onClick={() => { setState(""); setState('view'); setTos([]); setCcs([]); setThreadData(null);setReplyBody("") }}>Discard</button>
                        {/* <button className='btn btn-outline-dark my-3 ml-2' onClick={() => { setState(""); setCcs([]); setState('view'); setTos([location?.state?.from?.emailAddress?.address]) }}>Close</button> */}
                    </Card>
                }
            </div>
        </div >
    )
}

export default Inbox