import axios from "axios";
import React, { useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import config from "../../config.json";
import { Button, Card, Modal, Table } from "react-bootstrap";
import moment from "moment";
import Recordings from "../Leads/Call/Recordings";
import { useQuery } from "react-query";
import NoDataFound from "./NoDataFound";
import { UserContext } from "./UserContext";
import noHistory from "../../../assets/images/history3.png";
import Loader from "./Loader";
import record from "../../assets/icons/record.svg";
import { useHistory } from "react-router-dom";
import LoadingBar from "./LoadingBar";
function Calllist({ leadID, data, setPage, page }: any) {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalData, setmodalData] = React.useState<any>();

  const [callsid, setcallsid] = React.useState("");
  const { authToken, id } = useContext<any>(UserContext);
  const history = useHistory();
  console.log("history", data);
  const handlemodal = (RecordingUrl) => {
    setmodalData(RecordingUrl);
    setShow(true);
  };
  const clearForm = () => {
    setcallsid("");
    setShow(false);
    localStorage.removeItem("RRECORD_PAGE_NUM");
  };
  const handlePageClick = (Selected) => {
    console.log(Selected);
    setPage(Selected.selected + 1);
  };

  return (
    <Card>
      <Card.Header className="text-dark">
        <Card.Title>
          <h3 className="mt-2">Call logs</h3>
        </Card.Title>
      </Card.Header>
      {loading ? (
        <LoadingBar isActive={true} />
      ) : (
        <Card.Body>
          <div className="table-responsive">
            {!data?.allLogs?.length ? (
              <NoDataFound
                message1={"No call logs"}
                message2={""}
                img={""}
                height={""}
                width={""}
              />
            ) : (
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th className="p-3">Name</th>
                    <th className="p-3">Dealer name</th>
                    <th className="p-3">From</th>
                    <th className="p-3">To</th>
                    {/* <th className='p-3'>Direction</th> */}
                    <th className="p-3">Status</th>
                    <th className="p-3">Duration</th>
                    <th className="p-3">Source</th>
                    <th className="p-3">Created</th>
                    {/* {!leadID && <th className='p-3'>Action</th>} */}
                  </tr>
                </thead>

                <tbody
                  style={{ fontWeight: "400", fontSize: "14px" }}
                  className="bg-white"
                >
                  {data &&
                    data?.allLogs?.map((log) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // localStorage.setItem("RRECORD_PAGE_NUM", pageNumber);
                          // setLoading(true);
                          handlemodal(log?.RecordingUrl);
                        }}
                      >
                        <td className="p-3">{log?.leadName}</td>
                        {leadID && <td className="p-3">{log?.dealerName}</td>}
                        <td className="p-3">{log?.callerId}</td>
                        <td className="p-3">{log?.To}</td>
                        <td className="p-3">
                          <span
                            className="badge text-white"
                            style={{
                              backgroundColor: "#30346b",
                              textTransform: "capitalize",
                            }}
                          >
                            {log?.DialCallStatus || "N/A"}
                          </span>
                        </td>
                        <td className="p-3 d-flex flex-row gap-1">
                          {log?.RecordingUrl && (
                            <img
                              src={record}
                              title="Recording available"
                              alt="record"
                              height={"20"}
                              width={"20"}
                              className="mt-auto mb-auto"
                            />
                          )}
                          <span className="mt-auto mb-auto">
                            {log?.RecordingDuration || "-"}
                          </span>
                        </td>
                        <td className="p-3">
                          {log?.calledByEmail ? "Email" : "Unknow"}
                        </td>
                        <td className="p-3">
                          {moment(log?.createdAt).format("LLL")}
                        </td>
                        {!leadID && (
                          <td className="p-3">
                            <button
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => {
                                history.push(`/leads/${log?.leadID}`);
                              }}
                            >
                              Open lead details
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </Card.Body>
      )}
      <ReactPaginate
        nextLabel=">>"
        onPageChange={handlePageClick}
        pageCount={data?.allLogsCount / 10 || 0}
        forcePage={page - 1}
        previousLabel="<<"
        containerClassName="pagination"
        // pageClassName={filter === 'All' ? 'pagination' : 'page-item disabled'}
        // pageClassName='page-item'
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakLinkClassName="page-link"
        activeClassName="active"
        breakClassName="page-item"
      />

      <Recordings show={show} modalData={modalData} clearForm={clearForm} />
    </Card>
  );
}

export default Calllist;
