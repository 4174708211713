import React from 'react'
import NoDataFound from '../common/NoDataFound'
import { useQuery } from 'react-query'
import config from '../../config.json'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import LoadingBar from '../common/LoadingBar'
function Flows({ data,isLoading }: any) {

    return (
        <div className='container-fluid'>
            {data && data?.fetch?.length ? <table style={{width:'100%'}} className='table table-bordered' >
                <thead className='table-head'>
                    <tr>
                        <th >#</th>
                        <th >Name</th>
                        <th >Created at</th>
                        <th >Action</th>
                    </tr>
                </thead>
                <tbody style={{fontWeight:'normal'}}>
                    {
                        data && data?.fetch?.map((obj, indx) => (
                            <tr key={indx + "abcd"}>
                                <td>{indx + 1}</td>
                                <td>{obj?.name}</td>
                                <td>{obj?.createdAt}</td>
                                <td>delete</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>:""}
            {!data?.fetch?.length && !isLoading ? <NoDataFound height={""} width={""} message1='No flows found' message2='' img=""/> : <LoadingBar isActive={isLoading}/>}
        </div>
    )
}

export default Flows