import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Row } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import config from '../../config.json'
import { UserContext } from '../common/UserContext'
import { toastify } from '../common/notification'
function Dealerflow({ data }) {
    const [flow, setFlow] = useState<any>(null)
    const [status, setStatus] = useState<any>(null)
    const {dealerID}=useContext<any>(UserContext)
    const [selectedFlow, setSelectedFlow] = useState<any>("")
    const [selectedStatus, setSelectedStatus] = useState<any>("")
    const [selectedID, setSelectedID] = useState<any>("")
    const [selected_ID, setSelected_ID] = useState<any>("")
    const token: any = localStorage.getItem("$AuthToken")

    const handleSubmit = async (e) => {
        e.preventDefault();
        let body = {
            type: "updateDealer",
            _id: selected_ID,
            name: selectedFlow,
            listIds: selectedID,
            status: selectedStatus,
            dealerId: dealerID
        }
        let resp = await axios.post(config.baseHost_backend + "/automation", body, {
            headers: {
                Authorization: token
            }
        });
        console.log("resp",resp);
        
        if(resp?.data?.statusCode){
            toastify('success','Success')
        }

    }

    const handleFlowSelected=(e)=>{
        if (e.target.value !== "null") {
            const selectedOption = e.target.selectedOptions[0];
            const objectString = selectedOption.getAttribute('data-object');
            const object = JSON.parse(objectString);
            setSelectedID(object?.id);
            setSelected_ID(object?._id)
            setSelectedFlow(object?.name)
        }
    }

    return (
        <div className='container-fluid'>
            <Form className='container-fluid my-3'>
                <Row>
                    <Form.Group className='mb-3'>
                        <Form.Label className='mb-0'>Select flow</Form.Label>
                        <select name="" id="" className='form-select p-2 bg-white' onChange={handleFlowSelected}>
                            <option value="null">Select flow</option>
                            {data?.fetch?.map((st) => (
                                <option data-object={JSON.stringify(st)}>{st?.name}</option>
                            ))}
                        </select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className='mb-0'>Select Status</Form.Label>
                        <select name="" id="" className='form-select p-2 bg-white' onChange={(e) => { setSelectedStatus(e.target.value) }}>
                            <option value="null">Select status</option>
                            {data?.allStatus?.map((st) => (
                                <option value={st?.value}>{st?.name}</option>
                            ))}
                        </select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <button className='btn pbuttonbgcolor' type='submit' onClick={handleSubmit}>Submit</button>
                    </Form.Group>
                </Row>
            </Form>
        </div>
    )
}

export default Dealerflow