import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Tabs, Tab, Card, Table, Modal, Button } from "react-bootstrap";
import { dateformater, timeformater } from "../common/dateFormat";
import DealerInfo from "../Dealer/dealerInfo";
import moment from "moment";
import "./style.css";
import axios from "axios";
import config from "../../config.json";
import Warningmodal from "../common/Warningmodal";
// import MenuItems from './Dropdowns/Menuitems';
// import { menus } from './Dropdowns/menus';
import "../../components/Leads/Dropdowns/style.css";
import { useAppContext } from "../../AppContext";
import { UserContext } from "../common/UserContext";
import { findKey } from "../common/keyvalidation";
import { toastify } from "../common/notification";
import downarrow from "../../assets/images/downarrow.svg";
// import { notificationcount } from "../../controllers/notificationcount";
export type Message = {
  sender_type: string;
  text: string;
  time: string;
};

export type Dealer = {
  _id: string;
  dealer_id: number;
  dealer_name: string;
  website: string;
  timezone: string;
  contact_details: Contactdetails;
};

interface Contactdetails {
  cell_number: string;
  work_number: string;
  email: string;
  communication_address: string;
}

type Props = {
  messages: Message[];
  dealer: Dealer | {};
  leadDetails: any;
  dealerDetails: any;
  refetch: any;
  smstemplate: any;
};

export default function LeadPersonalDetails({
  messages,
  dealer,
  leadDetails,
  dealerDetails,
  refetch,
  smstemplate,
  setNotificationcount,
}: any) {
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [msg, setMsg] = useState<any>("");
  const { user_first_name, user_last_name } = useContext<any>(UserContext);
  const [heading, setHeading] = useState<any>("");
  const [body, setBody] = useState<any>("");
  const url = config.baseHost_backend;
  const [tempData, setTempdata] = useState<any>(null);
  let ref: any = useRef();
  let scrolRef: any = useRef();

  let token: any = localStorage.getItem("$AuthToken");
  useEffect(
    () => {
      axios
        .post(
          url + "/update-message",
          {
            leadID: leadDetails?.body?.data?._id,
            type: "Update_msg_read_status",
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          refetch();

          //calling notification count API to update the count in UI
          // const fetchount = async () => {
          //   let resp = await notificationcount(token);
          //   console.log("resp", resp);
          //   setNotificationcount(resp?.body?.unReadMessagesCount);
          // };
          // fetchount();
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleImport = (id: any) => {
    console.log("selected",id);
    if(id){
      let filtered = smstemplate?.filter((st) => st._id === id);
      setMsg(filtered[0]?.content);
    }else{
      setMsg("")
    }
  };

 

 


  useEffect(() => {
    const masterTemplate:any = [];
    smstemplate?.forEach((obj) => {
      let category=obj?.template_category;
      if(masterTemplate.find((a)=>a.category===category)){
        masterTemplate[0].menus.push(obj)
      }else{
        masterTemplate.push({
          category:obj?.template_category,
          menus:[obj]
        })
      }
    });
    console.log("templatesMaster", masterTemplate);

    setTempdata(masterTemplate);
  }, [smstemplate]);

  const handleSendMsg = async () => {
    if (dealerDetails?.is_active === false || dealerDetails?.is_active === 0) {
      setBody("This dealer is not active at present");
      setHeading("Inactive dealer");
      setShowWarningModal(true);
      return;
    }
    if (!dealerDetails?.twilioPhone) {
      setBody(
        "Twilio phone number is not mapped for this dealer. Please map it to send SMS.."
      );
      setHeading("Twilio phone number is not mapped!");
      setShowWarningModal(true);
    } else {
      let mergeFieldPattern = /\[([^\]]+)\]/g;
      let filterd = leadDetails?.body?.data?.appointments?.filter(
        (ap) => ap.appointment_status === "Booked"
      );
      let str = msg.replace(mergeFieldPattern, (match, mergeField) => {
        let found =
          findKey(leadDetails?.body?.data, mergeField) ||
          findKey(dealerDetails, mergeField) ||
          findKey({ user_first_name, user_last_name }, mergeField);
        return found ? found : `[${mergeField}]`;
        // return key
      });

      const body = {
        leadID: leadDetails?.body?.data?._id,
        from: dealerDetails?.twilioPhone,
        to: leadDetails?.body?.data?.customer_details?.mobile_number,
        text: str,
        senderType: "Sales Person",
        timezone: dealerDetails?.timezone,
      };

      let token: any = localStorage.getItem("$AuthToken");
      let resp = await axios.post(url + "/update-message", body, {
        headers: {
          Authorization: token,
        },
      });
      console.log("resp",resp);
      
      if(resp){
        toastify('success','Message sent successfully')
      }
      refetch();
      setMsg("");
    }
  };



  return (
    <div className="containerBox bg-white">
      {/* <Tabs defaultActiveKey='first'> */}
      {/* <Tab eventKey='first' title='Text Messages'> */}
      <div className="containerBox lll">
        <Card className="mx-1 ">
          <Card.Header>
            <div className="d-flex justify-content-between">
              <Card.Title as="h4" className="mt-auto mb-auto">
                Messages History
              </Card.Title>
              <img
                src={downarrow}
                alt="arrow"
                title="Scroll to bottom"
                onClick={() => {
                  scrolRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                  });
                }}
                className="btn"
              ></img>
            </div>
          </Card.Header>
          <Card.Body style={{ fontWeight: "normal" }} ref={scrolRef}>
            <div className="notes-list">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Sender</th>
                    <th>Text</th>
                    <th>Time</th>
                    <th>Created by</th>
                  </tr>
                </thead>

                <tbody>
                  {messages?.map((message: any, index: any) => (
                    <tr key={index}>
                      <td>{message.sender_type}</td>
                      <td>{message.text}</td>
                      <td>
                        {moment(message.time).format("LLL") +
                          "(" +
                          dealerDetails?.timezone +
                          ")"}
                      </td>
                      <td>
                        {/* {!message.byName && !message.updatedBy ? "N/A" : <> <p className='m-0'>{message?.byName || "N/A"}</p>
                                                    <div className='d-flex flex-row'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4336" className="bi bi-envelope-fill mt-auto mb-auto mr-1" viewBox="0 0 16 16">
                                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                                        </svg> {message?.updatedBy || "N/A"}
                                                    </div></> } */}

                        {message.sender_type === "BOT"
                          ? "BOT"
                          : message.sender_type === "CUSTOMER"
                          ? leadDetails?.body?.data?.customer_details
                              ?.first_name +
                            " " +
                            leadDetails?.body?.data?.customer_details?.last_name
                          : message.byName || "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
  
            <div className="dropdown-container">
                <div className="dropdown-wrapper">
                  <select className="custom-dropdown"  onChange={(e:any)=>{
                        handleImport(e.target.value)
                      }}>
                    <option value="">Select a template</option>
                    {tempData?.map((submenu, subIndex) => (
                      <optgroup label={submenu.category} key={subIndex}>
                        {submenu.menus.map((sMenu, sIndex) => (
                          <option value={sMenu._id} key={sIndex}>
                            {sMenu.template_name}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>
              
            </div>
            <div className="d-flex flex-column">
              <textarea
                className="form-control bg-white p-2"
                onChange={(e: any) => {
                  setMsg(e.target.value);
                }}
                value={msg}
                placeholder="Type your message..."
                rows={6}
              />
              <button
                disabled={!msg}
                className="btn btn-primary"
                onClick={handleSendMsg}
              >
                Send
              </button>
            </div>
          </Card.Body>
        </Card>
      </div>

      <Warningmodal
        showWarningModal={showWarningModal}
        handleCloseWarningModal={() => {
          setShowWarningModal(false);
        }}
        body={body}
        heading={heading}
        idleBtnlabel={"OK"}
        handleWarningAction={""}
        actionbtnLabel={""}
      />
    </div>
  );
}
