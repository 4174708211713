import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import _ from 'lodash'
import Select from 'react-select';
import axios from 'axios';
import config from '../../config.json'
import { toastify } from './notification';
function Addlead({ show, handlelose, dealerList, statusList }) {
    const [firstname, setFirstName] = useState<any>("")
    const [lastname, setLastName] = useState<any>("")
    const [phone, setPhone] = useState<any>("")
    const [email, setEmail] = useState<any>("")
    const [filteredDealersList, setFilteredDealersList] = useState<any>(null)
    const [filteredStatusList, setFilteredStatusList] = useState<any>(null)
    const [selectedDealer, setSelectedDealer] = useState<any>("")
    const [selectedStatus, setSelectedStatus] = useState<any>("")
    const token: any = localStorage.getItem("$AuthToken");
    useEffect(() => {
        const tempArray = [] as any
        const cc = [] as any
        if (dealerList) {
            dealerList?.forEach((obj: any) => {
                tempArray.push({ label: obj.value, value: obj.key })
            })
            tempArray.sort((a: any, b: any) => _.toLower(a.label).trim() > _.toLower(b.label).trim() ? 1 : -1);

            let combined = [...cc, ...tempArray]
            setFilteredDealersList(combined)
        }
    }, [dealerList])

    useEffect(() => {
        const tempArray = [] as any

        if (statusList) {
            let filt = statusList?.filter((gd) => {
                if (gd?.label === "Opportunities") {
                    return gd?.options.splice(0, 1)
                }
                return gd
            })
            filt?.forEach((obj: any) => {
                if (obj?.options) {
                    tempArray.push({ label: obj?.label, options: obj?.options })
                } else {
                    tempArray.push({ label: obj.label, value: obj.value })
                }
            })
            setFilteredStatusList(tempArray)
        }
        console.log('tempArray', tempArray);

    }, [statusList])

    const handleStatusChange = (value, event) => {
        setSelectedStatus(value?.value)
    }

    const handleAdd = () => {
        if(!firstname){
            return toastify("failure", "First name cannot be empty")
        }

        if(!phone){
            return toastify("failure",'Phone number cannot be empty')
        }
        if(!email){
            return toastify("failure",'Email cannot be empty')
        }
        if(!selectedDealer?.value){
            return toastify("failure",'Dealership cannot be empty')
        }
        if(!selectedStatus){
            return toastify("failure",'Status cannot be empty')
        }
        const payload = {
            customer_details: {
                email,
                first_name: firstname,
                last_name: lastname,
                mobile_number: phone
            },
            dealer_id: selectedDealer.value,
            dealer_name: selectedDealer.label,
            status: selectedStatus,
            test: true,
            messages: [],
            notes: [],
            owner: "",
            status_history: [{ "new_status": selectedStatus, "status_lastupdated_at": new Date(), updatedBy: "Test", byName: "Test" }],
            customer_message: false,
            bot_message: false,
            bot_service: false,
            customer_service: false,
            bot_appointment: false,
            customer_appointment: false

        }
        console.log("payload", payload);

        axios.post(config.baseHost_backend+"/create-test-lead", payload,{
            headers:{
                Authorization: token,
            }
        }).then((res)=>{
            console.log("lead insert", res);
            if(res?.data?.statusCode===200){
                toastify('success', res?.data?.body?.message)
                handlelose();
            }else{
                toastify('failure', res?.data?.body?.message)
            }
        }).catch((err)=>{
            console.log('Error while inseting lead', err);
            
        })

    }
    return (
        <Modal show={show}>
            <Modal.Header>Add Lead</Modal.Header>
            <Modal.Body>

                <div>
                    <input type="text" placeholder='Enter first name' className='form-control bg-light mb-2' onChange={(e: any) => { setFirstName(e.target.value) }} />
                    <input type="text" placeholder='Enter last name' className='form-control bg-light mb-2' onChange={(e) => { setLastName(e.target.value) }} />
                    <input type="text" placeholder='Enter lead phone number' className='form-control bg-light mb-2' onChange={(e) => { setPhone(e.target.value) }} />
                    <input type="text" placeholder='Enter lead email' className='form-control bg-light mb-2' onChange={(e) => { setEmail(e.target.value) }} />

                    <Form.Label className='mb-0 pb-0' style={{ fontSize: '14px' }}>Select dealership</Form.Label>
                    <Select closeOnSelect={true}
                        // ref={dealerRef}
                        // defaultInputValue={{label:"Ramesh",value:0}}
                        name='dealer'
                        className="mb-2"
                        isSearchable={true}
                        // defaultValue={defaultDealer}
                        // options={[{ label: 'All', value: "All"}] ,...filteredDealersList}
                        options={filteredDealersList}
                        onChange={(e: any) => { setSelectedDealer({ label: e.label, value: e.value }) }}
                    ></Select>

                    <Form.Label className='mb-0 pb-0' style={{ fontSize: '14px' }}>Select lead status</Form.Label>
                    <Select closeOnSelect={true}
                        isMulti={false}

                        // value={selectedstatus}
                        // ref={statusRef}
                        name='status'
                        isSearchable={true}
                        // defaultValue={{label:"1234","value":"678"}}
                        options={filteredStatusList && filteredStatusList}
                        // onChange={(e: any) => {
                        //     setSelectedstatus(e); console.log("status", e);
                        //     // setSelectedstatusLabel(e.label)
                        // }}
                        onChange={handleStatusChange}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                    ></Select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-sm sbuttonbgcolor' onClick={() => {

                    handlelose()
                }}>Cancel</button>
                <button className='btn btn-sm pbuttonbgcolor' onClick={handleAdd}>Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default Addlead