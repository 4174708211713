import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'

function Dealercontacts({showContactsModal,setShowContactsModal, contactsInfo}) {
    return (
        <Modal centered show={showContactsModal} size='lg'>
            <Modal.Header>
                <Modal.Title><b>Contacts</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table className='table table-bordered'>
                    <thead className='head'>
                        <tr className='p-3'>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Title</th>
                            <th>Mobile phone</th>
                            <th>Work phone</th>
                        </tr>
                    </thead>
                    <tbody style={{fontWeight:"lighter"}}>
                        {contactsInfo?.map((contact,index)=>(
                            <tr>
                                <th>{index+1}</th>
                                <td>{contact?.name || "N/A"}</td>
                                <td>{contact?.email || "N/A"}</td>
                                <td>{contact?.title || "N/A"}</td>
                                <td>{contact?.mobilePhone || "N/A"}</td>
                                <td>{contact?.workPhone || "N/A"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {!contactsInfo?.length && <h3 className='text-center p-3'>No contacts found!</h3>}
            </Modal.Body>
            <Modal.Footer>
                <button
                    className='btn btn-sm sbuttonbgcolor'
                    onClick={() => { setShowContactsModal(false); }}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default Dealercontacts