import { Col, Row, Button, Table, Image, Modal } from 'react-bootstrap';
const ProfileUpdatedalert = ({ show, email, handleClose }) => {

    let str: any = email
    str = str.split('');
    let finalArr = [] as any;
    let len = str.indexOf('@');
    str.forEach((item: any, pos: any) => {
        (pos >= 1 && pos <= len - 2) ? finalArr.push('*') : finalArr.push(str[pos]);
    })
    
    return (
        <Modal centered show={show}>
            <Modal.Header>
                <div className='d-flex gap-2'>
                    <Modal.Title><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="mt-auto mb-auto text-success bi bi-check2-circle" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                    </svg></Modal.Title>
                    <h2 className='mt-auto mb-auto'>Profile updated successfully</h2>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column text-center'>
                    <span className='' style={{ fontWeight: 'normal' }}>
                        You have Successfully updated your profile!<br /> You will receive the access credentials to the Neuron Application to your email - <span className='font-weight-bold'>{email}</span>
                    </span>
                </div>
                <div className='mt-3'>
                    <span className='h6'>*Note: Please make sure to check your spam folder.</span>
                </div>
            </Modal.Body>
            <Modal.Footer>

                <button
                    className='btn btn-sm pbuttonbgcolor'
                    onClick={handleClose}
                >
                    OK
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ProfileUpdatedalert