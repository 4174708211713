import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toastify } from '../common/notification'
import { UserContext } from '../common/UserContext'

function Addcontacts({ showContactModal, setShowContactModal, setContacts, contacts, dealerUsers }) {
    const [cName, setCName] = useState<any>("")

    const [cEmail, setCemail] = useState<any>("")
    const [cTitle, setCTitle] = useState<any>("")
    const [cMobilePhone, setcMobilePhone] = useState<any>("")
    const [cWorkPhone, setCWorkPhone] = useState<any>("")
    const { email, name, role } = useContext<any>(UserContext)
    const handleSave = () => {
        if (!cName) {
            return toastify('failure', "Name cannot be empty")
        }
        if (!cEmail) {
            return toastify('failure', "Email cannot be empty")
        }
        if (!cMobilePhone) {
            return toastify('failure', "Mobile phone number cannot be empty")
        }
        
        let numbers = [] as any
        dealerUsers?.forEach((obj) => {
            numbers.push(obj?.contactdetails?.cell_number)
            numbers.push(obj?.contactdetails?.work_number)
        })
        
        let filtered = numbers.filter((v) => v)
        
        if (filtered.includes(cMobilePhone)) {
            return toastify("failure", "Entered Mobile phone number has already been taken")
        }
        if (filtered.includes(cWorkPhone)) {
            return toastify("failure", "Entered Work phone number has already been taken")
        }

        setContacts([...contacts, {
            name: cName,
            email: cEmail,
            title: cTitle,
            mobilePhone: cMobilePhone,
            workPhone: cWorkPhone,
            createdAt: Date.now(),
            createdBy: {
                name, email, role
            },
            id: Date.now()
        }])
        setShowContactModal(false);
    }
    return (
        <Modal centered show={showContactModal}>
            <Modal.Header>
                <Modal.Title><b>Add contacts</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>Required fields are indicated with "<span className='text-danger h6 font-weight-bold'>*</span>"</span>
                <div className='mb-3 mt-3'>
                    <span style={{ fontWeight: 'lighter' }}>Enter name<span className='text-danger h6 font-weight-bold'>*</span></span>
                    <input autoFocus type="text" name="secondaryno" id="ns" onChange={(e) => { setCName(e.target.value) }} className='form-control form-control-sm bg-white mt-1' />
                </div>
                <div className='mb-3'>
                    <span style={{ fontWeight: 'lighter' }}>Enter email<span className='text-danger h6 font-weight-bold'>*</span></span>
                    <input type="email" name="secondaryno" id="ns" onChange={(e) => { setCemail(e.target.value) }} className='form-control form-control-sm bg-white mt-1' />
                </div>
                <div className='mb-3'>
                    <span style={{ fontWeight: 'lighter' }}>Enter title</span>
                    <input type="text" name="secondaryno" id="ns" onChange={(e) => { setCTitle(e.target.value) }} className='form-control form-control-sm bg-white mt-1' />
                </div>
                <div className='mb-3'>
                    <span style={{ fontWeight: 'lighter' }}>Enter Mobile Phone<span className='text-danger h6 font-weight-bold'>*</span></span>
                    <input type="text" name="secondaryno" id="ns" onChange={(e) => { setcMobilePhone(e.target.value) }} className='form-control form-control-sm bg-white mt-1' />
                </div>
                <div>
                    <span style={{ fontWeight: 'lighter' }}>Enter Work Phone</span>
                    <input type="text" name="secondaryno" id="ns" onChange={(e) => { setCWorkPhone(e.target.value) }} className='form-control form-control-sm bg-white mt-1' />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                   className='btn btn-sm sbuttonbgcolor'
                    onClick={() => { setShowContactModal(false); setCName(""); setCemail(""); setCTitle(""); setcMobilePhone(""); setCWorkPhone(""); }}
                >
                    Close
                </button>
                <button
                    className='btn btn-sm pbuttonbgcolor'
                    onClick={handleSave}
                // onClick={() => { leadMutation.mutateAsync({ id, type: "secondaryNumber", secondaryNumber: secNo }) }}
                >
                    Save
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default Addcontacts