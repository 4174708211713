import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { toastify } from '../common/notification';

import { Col, Row, Button, Image, InputGroup, Form } from 'react-bootstrap';

import leftArrow from '../../assets/images/leftArrow.svg';

import { UserContext } from '../common/UserContext';

import Loader from '../common/Loader';
import { corporateListApi, createCorporateApi, createDealerApi } from '../../controllers/dealers';
import states1 from '../common/states'
import LoadingBar from '../common/LoadingBar';

const CreateCorporate = () => {
    const [isLoading, setIsLoading] = React.useState<Boolean>(false);

    const [name, setName] = React.useState<string>('');
    const [address, setAddress] = React.useState<string>('');
    const [phone, setPhone] = React.useState<string>('');
    const [websiteUrl, setWebsiteUrl] = React.useState<string>('');
    const [timezone, setTimezone] = React.useState<string>('');
    const [crmName, setCrmName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    
    const [inventoryTool, setInventoryTool] = useState<string>('');
    const [preferredBook, setPreferredBook] = useState<string>('');
    const [contactName, setContactName] = useState<string>('');
    const [dealershipGroup, setDealershipGroup] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [dealType, setDealType] = React.useState('');

    const [flat_houseno_building, setFlat_houseno_building] = useState<string>('')
    const [street_1, setStreet_1] = useState<string>('')
    const [street_2, setStreet_2] = useState<string>('')
    const [area_sector, setArea_sector] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [pincode, setPincode] = useState<string>('')
    const [states, setStates] = useState<any>(null)
    const [loading, setLoading] = useState<any>(false)

    const { authToken, role: currentUserRole, company } = useContext(UserContext);

    const history = useHistory();

    const location: {
        pathname: any;
        state: any;
        search: any;
    } = useLocation();

    const handleCreate = () => {
        const corporateData = {
            corporateName: name?.trim(),
            website: websiteUrl,
            timezone,
            contact_name: contactName,
            phone: phone,
            // address,
            // crm_name: crmName,
            email,
            // preferred_book: preferredBook,
            // inventory_tool: inventoryTool,
            // dealership_group: dealershipGroup,
            notes: notes,
            // deal_type: dealType,
            is_active: true,
            is_update: 0,
            flat_houseno_building,
            street_1,
            street_2,
            area_sector,
            city,
            state,
            pincode
        };

        if (!name) {
            return toastify('failure', "Corporate Name cannot be empty");
        }
        if (!phone) {
            return toastify('failure', "Phone number cannot be empty");
        }
        if (!websiteUrl) {
            return toastify('failure', "Website URL cannot be empty");
        }
        if (!timezone) {
            return toastify('failure', "Please select the timezone");
        }
        if (!email) {
            return toastify('failure', "Email cannot be empty");
        }

        if (!state) {
            return toastify('failure', 'Please select the State')
        }
        setLoading(true)

        

        createCorporateApi(authToken, corporateData).then((res) => {
            if (res.statusCode === 400) {
                setLoading(false)
                toastify('failure', res.body.message);
                
            } else {
                setLoading(false)
                toastify('success', 'Corporate created successfully.');
                handleBack();
            }
            
        });
    };
    

    const handleUpdate = () => {
        const corporateData = {
            id: location.state.obj._id,
            ID: location.state.obj?.dealer_id,
            corporateName: name?.trim(),
            website: websiteUrl,
            timezone,
            contact_name: contactName,
            phone: phone,
            // address,
            // crm_name: crmName,
            // adf_email: crmAdfMail,
            // preferred_book: preferredBook,
            // inventory_tool: inventoryTool,
            // dealership_group: dealershipGroup,
            notes: notes,
            // deal_type: dealType,
            is_active: true,
            is_update: 1,
            flat_houseno_building,
            street_1,
            street_2,
            area_sector,
            city,
            state,
            pincode
        };

        if (!name) {
            return toastify('failure', "Corporate Name cannot be empty");
        }
        if (!phone) {
            return toastify('failure', "Phone number cannot be empty");
        }
        if (!websiteUrl) {
            return toastify('failure', "Website URL cannot be empty");
        }
        if (!timezone) {
            return toastify('failure', "Please select the timezone");
        }
        if (!email) {
            return toastify('failure', "Email cannot be empty");
        }

        if (!state) {
            return toastify('failure', 'Please select the State')
        }
        setLoading(true)
        

        createCorporateApi(authToken, corporateData).then((res) => {
            if (res.statusCode === 400) {
                setLoading(false)
                toastify('failure', res.body.message);
            } else {
                toastify('success', 'Details updated successfully.');
                setLoading(false)
                handleBack();
            }
            
        });
    };


    const handleBack = () => {
        history.push({
            pathname: '/dealers',
            state: {
                dataChanged: true
            }
        })
    };

    useEffect(() => {
        setStates(states1)
    }, [])

    useEffect(() => {
        if (location.pathname.includes('edit')) {
            const state: any = history.location.state;
            console.log("corp state",state);
            
            setName(state?.obj?.corporateName);
            // setAddress(state?.obj?.address);
            setWebsiteUrl(state?.obj?.website);
            setTimezone(state?.obj?.timezone);
            setEmail(state?.obj?.email)
          
            setNotes(state?.obj?.notes);
            setContactName(state?.obj?.contact_name);
            setPhone(state?.obj?.phone);
            setFlat_houseno_building(state?.obj?.flat_houseno_building);
            setStreet_1(state?.obj?.street_1);
            setStreet_2(state?.obj?.street_2);
            setArea_sector(state?.obj?.area_sector);
            setCity(state?.obj?.city);
            setState(state?.obj?.state);
            setPincode(state?.obj?.pincode);

        }
    }, [location, history]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className='containerBox'>
            <LoadingBar isActive={loading} />
            {/* Topbar Code*/}
            <Row className='spacing-2'>
                <Col className='button-col'>
                    <div className='spacing-1-1'>
                        <div>
                            <Image
                                onClick={() => {
                                    handleBack();
                                }}
                                className='pointer'
                                src={leftArrow}
                            />
                        </div>
                        <div className='pad-button text-name'>
                            {location.pathname.includes('create')
                                ? 'Create Corporate'
                                : 'Update Corporate'}
                        </div>
                    </div>
                </Col>
            </Row>
            <hr className='divider-line' />

            <Row className='spacing-1'>
                <div className='form-container'>
                    <span className='' >Required fields are indicated with "<span className='text-danger h6 font-weight-bold'>*</span>"</span>
                    <Form className='mt-3'>
                        <Row className='mb-3'>
                            {/* Name Field */}
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Corporate Name<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={name}
                                        onChange={(e: any) => setName(e.target.value)}
                                        placeholder='Enter name'
                                    />
                                </InputGroup>
                            </Form.Group>
                            {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Address</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        disabled
                                        size='sm'
                                        type='input'
                                        value={address}
                                        onChange={(e: any) => setAddress(e.target.value)}
                                        placeholder='Enter address'
                                    />
                                </InputGroup>
                            </Form.Group> */}
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Phone<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={phone}
                                        onChange={(e: any) => setPhone(e.target.value)}
                                        placeholder='Enter phone'
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Website<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={websiteUrl}
                                        onChange={(e: any) => setWebsiteUrl(e.target.value)}
                                        placeholder='Enter website url'
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Timezone<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                                <Form.Select
                                    aria-label='Select timezone'
                                    value={timezone}
                                    onChange={(e: any) => setTimezone(e.target.value)}>
                                    <option>Select timezone</option>
                                    <option value='PST'>PST</option>
                                    <option value='MST'>MST</option>
                                    <option value='CST'>CST</option>
                                    <option value='EST'>EST</option>
                                </Form.Select>
                            </Form.Group>
                            {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>CRM Name</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={crmName}
                                        disabled={location.pathname.includes('edit') ? true : false}
                                        onChange={(e: any) => setCrmName(e.target.value)}
                                        placeholder='Enter crm name'
                                    />
                                </InputGroup>
                            </Form.Group> */}
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Email<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        disabled={location.pathname.includes('edit') ? true : false}
                                        value={email}
                                        onChange={(e: any) => setEmail(e.target.value)}
                                        placeholder='Enter Email'
                                    />
                                </InputGroup>
                            </Form.Group>
                            {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Inventory Tool</Form.Label>
                                <Form.Select
                                    aria-label='Select inventory tool'
                                    value={inventoryTool}
                                    onChange={(e: any) => setInventoryTool(e.target.value)}>
                                    <option>Select inventory tool</option>
                                    <option value='inventory tool 1'>inventory tool 1</option>
                                    <option value='inventory tool 2'>inventory tool 2</option>
                                    <option value='inventory tool 3'>inventory tool 3</option>
                                </Form.Select>
                            </Form.Group> */}
                            {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail' className='mb-2'>
                                <Form.Label className='text'>Preferred Book</Form.Label>
                                <Form.Select
                                    aria-label='Select preferred book'
                                    value={preferredBook}
                                    onChange={(e: any) => setPreferredBook(e.target.value)}>
                                    <option>Select preferred book</option>
                                    <option value='preffered book 1'>preferred book 1</option>
                                    <option value='preffered book 2'>preferred book 2</option>
                                    <option value='preffered book 3'>preferred book 3</option>
                                </Form.Select>
                            </Form.Group> */}
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Contact Name</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={contactName}
                                        onChange={(e: any) => setContactName(e.target.value)}
                                        placeholder='Enter contact name'
                                    />
                                </InputGroup>
                            </Form.Group>
                            {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Dealership Group</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={dealershipGroup}
                                        onChange={(e: any) => setDealershipGroup(e.target.value)}
                                        placeholder='Enter dealership group'
                                    />
                                </InputGroup>
                            </Form.Group> */}

                            {/*new field */}
                            {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Flat, House no., Building, Company, Apartment</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={flat_houseno_building}
                                        onChange={(e: any) => setFlat_houseno_building(e.target.value)}
                                        placeholder='Flat, House no., Building, Company, Apartment'
                                    />
                                </InputGroup>
                            </Form.Group> */}
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Street 1</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={street_1}
                                        onChange={(e: any) => setStreet_1(e.target.value)}
                                        placeholder='Enter Street 1'
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Street 2</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={street_2}
                                        onChange={(e: any) => setStreet_2(e.target.value)}
                                        placeholder='Enter Street 2'
                                    />
                                </InputGroup>
                            </Form.Group>
                            {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Area, Sector, Village</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={area_sector}
                                        onChange={(e: any) => setArea_sector(e.target.value)}
                                        placeholder='Enter Area, Sector, Village'
                                    />
                                </InputGroup>
                            </Form.Group> */}
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>City</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={city}
                                        onChange={(e: any) => setCity(e.target.value)}
                                        placeholder='Enter City/Town'
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>State<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                                <Form.Select
                                    aria-label='Select inventory tool'
                                    value={state}
                                    onChange={(e: any) => setState(e.target.value)}>
                                    <option>Select State</option>
                                    {states?.map((state: any) => (
                                        <option value={state.name}>{state.name}</option>
                                    ))}

                                </Form.Select>
                            </Form.Group>
                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Zip Code</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={pincode}
                                        onChange={(e: any) => setPincode(e.target.value)}
                                        placeholder='Enter zip code'
                                    />
                                </InputGroup>
                            </Form.Group>
                            {/*new field */}

                            <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Notes</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        as='textarea'
                                        rows={3}
                                        value={notes}
                                        onChange={(e: any) => setNotes(e.target.value)}
                                        placeholder='Type notes here'
                                    />
                                </InputGroup>
                            </Form.Group>
                            {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Deal Type</Form.Label>
                                <Form.Select
                                    aria-label='Select deal type'
                                    value={dealType}
                                    onChange={(e: any) => setDealType(e.target.value)}>
                                    <option>Select deal type</option>
                                    <option value='deal type 1'>deal type 1</option>
                                    <option value='deal type 2'>deal type 2</option>
                                    <option value='deal type 3'>deal type 3</option>
                                </Form.Select>
                            </Form.Group> */}
                        </Row>
                    </Form>

                    <div className='button-container'>
                        <Button
                            onClick={
                                location.pathname.includes('create') ? handleCreate : handleUpdate
                            }
                            variant='primary'
                            type='submit'
                        >
                            {location.pathname.includes('create')
                                ? '+ Create Corporate'
                                : 'Update Corporate'}
                        </Button>
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default CreateCorporate;
