/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../common/UserContext";
import "./index.css";
// import logo from "../../assets/images/Cognitgo_Logo-Dark.png";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import axios from "axios";
import _ from "lodash";
import * as React from 'react';
import {
    Dropdown,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { useOnClickOutside } from 'usehooks-ts';
import { useAppContext } from "../../AppContext";
import notoficationBell from '../../assets/images/notificationbell.svg';
import openMail from '../../assets/images/read.png';
import config from "../../config.json";
import { accessRoles } from "../../controller";
import { getNotificationCount } from "../../controllers/notificationcount";
import "./drawer.css";
import { toastify } from './notification';
import NoDataFound from "./NoDataFound";
import './sideBar.css';
import noNotificationImg from '../../assets/images/nonotification.png'


let token: any = localStorage.getItem("$AuthToken");


const useStyles: any = makeStyles({
    paper: {
        background: "black",
        color: "red",
    },
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    item: any;
}

interface NotificationI {
    first10PriceNotiNotifications: any[];
    priceNotificationCount: number;
    first10SmsNotifications: any[];
    smsNotificationCount: number;
}

export const deleteNotification = async (notiId: string) => {

    // let deleteNotiEle = (elementId == "") ? null : () => { }
    console.log("function : mark as read ")

    let body = {
        notificationId: notiId,
    };
    let url: any = config.baseHost_backend;

    console.log("body of mark as read api : ", body)
    let response = await axios.delete(
        url + "/notification/mark_as_read/" + notiId,
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.data.body.result.deletedCount == 0) {
        toastify("failure", "not deleted")
    }


    console.log("markAsRead api response : ", response.data.body.result.deletedCount, response)
    return response?.data;

}


const NotificationComp = () => {
    const { name, role, type, authToken, setUser, company } = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = useState<any>(false);
    const [openDrawer, setpenDrawer] = useState<any>(false);
    const app: any = useAppContext();
    let QueryClient = useQueryClient();




    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [noNotiVisible, setNoNotiVisible] = useState(false)
    const [boxvalue, setBoxValue] = React.useState(0);
    const popupRef = useRef(null);
    const [notificationount, setNotificationcount] = useState<NotificationI>(
        {
            smsNotificationCount: 0,
            priceNotificationCount: 0,
            first10SmsNotifications: [],
            first10PriceNotiNotifications: []
        });
    const [justNotiCount, setJustNotiCount] = useState(0)



    const ref = useRef(null)

    const handleClickOutside = () => {
        // Your custom logic here
        console.log('clicked outside')
        setDropdownVisible(false)
    }

    useOnClickOutside(ref, handleClickOutside)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setBoxValue(newValue);
    };

    function handleNotiClick(linkToOpen: any) {
        // const linkToOpen = '/your-link';
        console.log("opening link -- ", linkToOpen)
        setDropdownVisible(false)
        // Open link in a new tab
        window.open(linkToOpen, '_blank');


    }

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, item, ...other } = props;

        const [showThisNoti, setshowThisNoti] = useState(true)

        const handleDeleteNoti = () => {
            console.log("removing notification from state")
            setshowThisNoti(false);
        };

        return (
            <div>
                {showThisNoti && <div
                    role="tabpanel"

                    id={`simple-tabpanel-${index}`}
                    // aria-labelledby={`simple-tab-${index}`}
                    {...other}
                    style={{ display: "flex", justifyContent: "center" ,borderWidth:"2,border"}}
                >
                    {value === index && (
                        <Box sx={{ p: 1, backgroundColor: "#FFFFFF", marginLeft: "7px", marginRight: "7px", marginTop: "4px", height: "fit-content", borderRadius: "4px", display: "flex", justifyContent: "center", boxShadow: "3" ,width:"100%",borderTop: '1px solid #ccc'}} >
                            <Typography sx={{ color: "black", fontSize: "12px", margin: "3px", fontFamily: "Calibri , sans-serif", padding: "3px" ,width:"100%",border:"3",borderColor:"black"}}>
                                {children}
                            </Typography>
                            {/* <div style={{ display: "flex", width: "35px", justifyContent: "center", alignContent: "center", alignItems: "center" }}><UnsubscribeIcon onClick={() => {
                                handleDeleteNoti()
                                deleteNotification(item.lead_id);
                                // fetchount();
                            }}/>
                            <img src={openMail} alt={"CPU"} width={'25px'} onClick={() => {
                                handleDeleteNoti()
                                deleteNotification(item._id);
                                // fetchount();
                            }} />
                            </div> */}
                        </Box>
                    )}
                </div>}
            </div>
        );
    }

    function ViewallTabPanel(props: TabPanelProps) {
        const { children, value, index, item, ...other } = props;
        if (item) {
            return (
                <div
                    role="tabpanel"

                    id={`simple-tabpanel-${index}`}
                    // aria-labelledby={`simple-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box style={{ height: '18px', margin: "8px", marginTop: "3px", marginBottom: "0px", minWidth: "fit-content", width: "fit-content", borderRadius: "7px " }}>
                            <Typography sx={{ color: "black", fontSize: "12px", margin: "3px" }}>{children}</Typography>
                        </Box>
                    )}


                </div>
            );
        }
        else {
            return (
                <div
                    role="tabpanel"

                    id={`simple-tabpanel-${index}`}
                    // aria-labelledby={`simple-tab-${index}`}
                    {...other}
                > {value === index && <NoDataFound
                    message1={"No Notifications yet"}
                    message2={""}
                    img={noNotificationImg}
                    height={"150px"}
                    width={"150px"}
                />} </div>)
        }
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const fetchount = async () => {
        try {
            let resp = await getNotificationCount(authToken);
            let previsousCount: boolean = false;
            setNotificationcount(resp?.body);

            setJustNotiCount(preNotiCount => {
                if (preNotiCount < resp?.body.priceNotificationCount + resp?.body.smsNotificationCount) {
                    console.log("got message", preNotiCount, resp?.body.priceNotificationCount, resp?.body.smsNotificationCount)
                    previsousCount = true
                    return resp?.body.priceNotificationCount + resp?.body.smsNotificationCount
                }
                else {
                    return preNotiCount
                }
            })

            if (previsousCount) {
                toastify("success", "Got a Message");
                previsousCount = false
            }
        }

        catch (e) {
            console.log(e)
            setNotificationcount({
                "first10PriceNotiNotifications": [],
                "priceNotificationCount": 0,
                "smsNotificationCount": 0,
                "first10SmsNotifications": []
            });

        }
    };

    useEffect(() => {
        console.log("inside useEffect")
        async function notificationCount() {
            let resp = await getNotificationCount(authToken);

            setNotificationcount(resp?.body);

            setJustNotiCount(resp?.body.priceNotificationCount + resp?.body.smsNotificationCount)
        }

        notificationCount()
        accessRoles
            ?.filter(
                (obj) => _.toLower(obj.role) === _.toLower(type + role)
            )[0]
            ?.permissions?.includes("allowNotifications") && setInterval(fetchount, 7000);
    }, []);

    const handleNotiBell = () => {
        console.log("clicked bell");
        setDropdownVisible(!dropdownVisible)
    }

    return (
        <div className="mt-auto mb-auto ">
            {accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowNotifications") && <div
                style={{ position: 'relative' }}
                className="notf-icon-top"


            >
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            <strong>Notifications</strong>
                        </Tooltip>
                    }
                >
                    <div>
                        <img src={notoficationBell} alt={"CPU"} width={'20px'} onClick={handleNotiBell} />
                    </div>
                </OverlayTrigger>

                {dropdownVisible && (
                    <Dropdown
                        ref={ref}
                        align="end"
                        style={{ position: 'absolute', top: '54%', right: 100 }}
                        show={dropdownVisible}
                    >


                        <Dropdown.Menu style={{ width: '350px', margin: "0px", backgroundColor: "#FFFFFF" }} >
                            <Box sx={{ width: '100%', fontSize: '11px', margin: "0px", "& div": { padding: "0px", borderBottom: "0px" } }} >

                                <Box height='30px' sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    fontSize: '11px',
                                    width: "fit-content",
                                    marginBottom:"5",
                                    paddingBottom:"5",

                                    height: "fit-content",
                                    "& div.MuiTabs-scroller": { minHeight: "fit-content", height: "100px" },
                                    "& div.MuiBox-root": { minHeight: "fit-content", height: "100px" },
                                    "& div.MuiTabs-root": { minHeight: "fit-content", height: "100px" },

                                    "& button ": { backgroundColor: "#C1CAD9", opacity: "0.7", borderRadius: "7px", height: "10px" },
                                    "& button:active": { backgroundColor: "#E1ECFF" },
                                    "& button.Mui-selected": { backgroundColor: "#AACBEE", opacity: "0.8", color: "#000000", fontWeight: "bold", borderRadius: "7px",boxShadow:"3" },


                                    "& div": { padding: "0px", borderBottom: "0px" }
                                }} >
                                    <Tabs value={boxvalue} style={{ height: "29px" }} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{ hidden: true }} sx={{ margin: "0px", padding: "0px" }}>
                                        <Tab label={`Sms (${notificationount.smsNotificationCount.toString()})`} {...a11yProps(0)} style={{ minHeight: '10px', fontSize: '13px', minWidth: "fit-content", marginLeft: "8px" }} />
                                        <Tab label={`Pricing (${notificationount.priceNotificationCount.toString()})`} {...a11yProps(1)} style={{ minHeight: '10px', fontSize: '13px', minWidth: "fit-content", marginLeft: "8px" }} />
                                    </Tabs>
                                </Box>

                                {notificationount.smsNotificationCount ? notificationount.first10SmsNotifications.map(item => (
                                    <CustomTabPanel value={boxvalue} index={0} item={item} >
                                        <div onClick={() => {
                                            handleNotiClick(`/leads/${item.lead_id}`)
                                        }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ width: "fit-content" }}>
                                                    <strong >
                                                        {item.customer_details ? item.customer_details.first_name : "no name"} {item.customer_details ? item.customer_details.last_name : ""}
                                                    </strong> / {item.dealer_name}
                                                </div>
                                                <div style={{ width: "fit-content" }}>
                                                    {item.messages[0].time} {item.timezone}
                                                </div>
                                            </div>
                                            <div style={{ width: "fit-content" }}>

                                                {item.messages[item.messages.length -1].text.slice(0, 70)}
                                                {(item.messages[item.messages.length -1].text.length > 70) && <strong>...</strong>}
                                                
                                            </div>
                                        </div>

                                    </CustomTabPanel>
                                )) : null}
                                {notificationount.priceNotificationCount ? notificationount.first10PriceNotiNotifications.map(item => (
                                    <CustomTabPanel value={boxvalue} index={1} item={item} >
                                        <div onClick={() => {
                                            handleNotiClick(`/leads/${item.lead_id}`)
                                            // history.push(`/leads/${item.lead_id}`)
                                        }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ width: "fit-content" }}>
                                                    <strong >
                                                        {item.customer_details.first_name} {item.customer_details.last_name}
                                                    </strong> / {item.dealer_name}
                                                </div>
                                                <div style={{ width: "fit-content" }}>
                                                    {item.messages[item.messages.length -1].time} {item.timezone}
                                                </div>
                                            </div>
                                            {item.messages[0].text.slice(0, 70)}
                                            <strong>...</strong>
                                        </div>


                                    </CustomTabPanel>

                                )) : null}

                                <ViewallTabPanel value={boxvalue} index={1} item={notificationount.priceNotificationCount}>
                                    <div
                                        tabIndex={1}
                                        onClick={() => {
                                            setDropdownVisible(false)
                                            history.push("/notifications/pricing");

                                        }}  ><div style={{ margin: "5px" }}>view all ...</div></div>

                                </ViewallTabPanel>

                                <ViewallTabPanel value={boxvalue} index={0} item={notificationount.smsNotificationCount}>
                                    <div
                                        tabIndex={0}
                                        onClick={() => {
                                            setDropdownVisible(false)
                                            history.push("/notifications/sms");
                                        }}  ><div style={{ margin: "5px" }}>view all ...</div></div>

                                </ViewallTabPanel>
                            </Box>

                        </Dropdown.Menu>
                    </Dropdown>
                )}

                {
                    (notificationount.smsNotificationCount || notificationount.priceNotificationCount)
                        && notificationount.smsNotificationCount + notificationount.priceNotificationCount > 0 ?
                        <span className="not-count">{notificationount.smsNotificationCount + notificationount.priceNotificationCount}</span> : ""}
            </div>}
        </div>
    )

};

export default NotificationComp;