import axios from "axios";
import config from '../config.json';
import { toastify } from "../components/common/notification";



const url = config['baseHost_backend'];




export const getNotificationCount = (token: any) => {
    
   return axios
        .get(`${url}/notification/unread-messages-count`, {
            headers: {
                Authorization: token
            }
        })
        .then((res) => res?.data)
        .catch((error) =>
            toastify(
                'failure',
                error.response.data.message.length > 0
                    ? error.response.data.message
                    : 'Something went wrong'
            )
        );

}