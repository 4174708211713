
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import axios from 'axios';
import { Button, Form, InputGroup } from 'react-bootstrap'
// import '../../User/userList.css'
import '../dashboard.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Chart as chartJS, registerables } from 'chart.js';
import { dashboard } from '../../../controllers/dashboard';
import { UserContext } from '../../common/UserContext';
import { useQuery } from 'react-query';
import Loader from '../../common/Loader';
import config from '../../../config.json';
import { toastify } from '../../common/notification';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import _ from 'lodash'
import Select from 'react-select';
const url = config['baseHost_backend'];

function Filter({ data,
    setFilter,
    setFilteredROIReport,
    defaultSelectedFiltervalue,
    setDefaultSelectedFilterValue,
    years,
    globalSellingValue,
    setGlobalSellingValue,
    setGlobalSubscriptionfee,
    setSelectedDealerID,
    selectedDealerID,
    noDataDisable,
    // handleDealerSelectedview
}: any) {
    const { authToken, dealerID, corporateID, user_first_name, type, subscriptionFee } = useContext(UserContext);
    const [activeYear, setActiveYear] = useState(new Date());
    const userInfo = {}
    const selectFilter = useRef<any>(null);
    const selectFilter1 = useRef<any>(null);
    const [dealers, setDealers] = useState<any>(null)
    const [yearsM, setYearsM] = useState<any>(null)
    const [selectedDealer, setSelectedDealer] = useState<any>(null)
    const [tempselectedDealer, setTempSelectedDealer] = useState<any>(null)
    const [filter2Value, setFilter2value] = useState<any>(null)
    const [selectedYear, setSelectedYear] = useState<any>(null)
    const yearRef = useRef<any>(null)

    const getQuarter = (date = new Date()) => {
        const quarter = Math.floor(date.getMonth() / 3 + 1);
        switch (quarter) {
            case 1:
                return "Q1 - [Jan-Feb-Mar]"
            case 2:
                return "Q2 - [Apr-May-Jun]"
            case 3:
                return "Q3 - [Jul-Aug-Sep]"
            case 4:
                return "Q4 - [Oct-Nov-Dec]"
            default:
                break;
        }
    }

    

    useEffect(() => {
        if (years) {
            let temp = [] as any;
            years?.map((yr) => {
                temp.push({ label: yr, value: yr })
            })
            setYearsM(temp)
        }
    }, [years])


    useEffect(() => {
        if (data && data?.body?.data) {
            // selectFilter.current.focus()
            let temp2 = [{ label: "All", value: "null" }] as any
            

            const map = new Map();
            for (const item of data?.body?.data?.roi?.data) {
                if (!map.has(item.Dealer_id)) {
                    map.set(item.Dealer_id, true);    // set any value to Map
                    temp2.push({
                        label: item.Dealer_name,
                        value: item.Dealer_id
                    });
                }
            }
            let sorted = temp2.sort((a, b) => a.label.trim().toLowerCase() > b.label.trim().toLowerCase() ? 1 : -1)
            // sorted.push(temp)
            setDealers(sorted)
            
        }
    }, [data])

    const filter = async (type: any) => {


        const years = [] as any;
        const months = [] as any;
        const monthlyData = [] as any;
        let val = 0;
        if (type === "Monthly") {
            data?.map((data1: any) => {
                if (months.indexOf(moment(data1["SoldDate"]).format('LL').split(" ")[0]) === -1) {
                    months.push(moment(data1["SoldDate"]).format('LL').split(" ")[0])
                }
            })
            data?.map((data1: any) => {
                let filterd = months?.find((month: any) => month === moment(data1["SoldDate"]).format('LL').split(" ")[0])
                
                if (monthlyData.find((obj: any) => obj.month === filterd)) {
                    // setFrontTotal(data1["Front"])
                    let index = monthlyData.findIndex((obj: any) => obj.month === filterd);
                    // monthlyData[index]={...monthlyData[index], data1}
                    monthlyData[index].data.push(data1)
                    monthlyData[index].frontTotal = monthlyData[index].frontTotal + data1["FrontGross"]
                    monthlyData[index].backTotal = monthlyData[index].backTotal + data1["BackGross"]
                    monthlyData[index].totalTotal = monthlyData[index].totalTotal + data1["Total Gross"]
                    monthlyData[index].salePriceTotal = monthlyData[index].salePriceTotal + data1["SellingPrice"]
                }
                else {
                    monthlyData.push({ "month": filterd, "data": [data1], "frontTotal": data1["FrontGross"], "backTotal": data1["BackGross"], "totalTotal": data1["Total Gross"], "salePriceTotal": data1["SellingPrice"] })
                }
            })
            setDefaultSelectedFilterValue("Monthly")
            setFilteredROIReport(monthlyData)
        }
        else if (type === 'Quarterly') {
            const QuarterlyData = [] as any;
            const getQuarter = (date = new Date()) => {
                const quarter = Math.floor(date.getMonth() / 3 + 1);
                switch (quarter) {
                    case 1:
                        return `Q1 - [Jan-Feb-Mar]`
                    case 2:
                        return `Q2 - [Apr-May-Jun]`
                    case 3:
                        return `Q3 - [Jul-Aug-Sep]`
                    case 4:
                        return `Q4 - [Oct-Nov-Dec]`
                    default:
                        break;
                }
            }
            data?.map((data1: any) => {
                const Quarter = getQuarter(new Date(data1["SoldDate"]));
                if (QuarterlyData.find((obj: any) => obj.quarter === Quarter)) {
                    let index = QuarterlyData.findIndex((obj: any) => obj.quarter === Quarter);
                    // QuarterlyData.push({ "data": data1 })
                    QuarterlyData[index].data.push(data1)
                    QuarterlyData[index].frontTotal = QuarterlyData[index].frontTotal + data1["FrontGross"]
                    QuarterlyData[index].backTotal = QuarterlyData[index].backTotal + data1["BackGross"]
                    QuarterlyData[index].totalTotal = QuarterlyData[index].totalTotal + data1["Total Gross"]
                    QuarterlyData[index].salePriceTotal = QuarterlyData[index].salePriceTotal + data1["SellingPrice"]
                }
                else {
                    QuarterlyData.push({ "quarter": Quarter, "data": [data1], "frontTotal": data1["FrontGross"], "backTotal": data1["BackGross"], "totalTotal": data1["Total Gross"], "salePriceTotal": data1["SellingPrice"] })
                }
            })
            setDefaultSelectedFilterValue("Quarterly")
            setFilteredROIReport(QuarterlyData)
            // 
        }
        else {
            
            
            const yearlyData = [] as any;

            data?.forEach((obj: any) => {
                let year: any = new Date(obj["SoldDate"]).getFullYear();

                // yearlyData.push({year:year.getFullYear(), data:[]})

                if (yearlyData.find((obj: any) => obj.year === year)) {
                    let index = yearlyData.findIndex((obj: any) => obj.year === year);
                    // QuarterlyData.push({ "data": data1 })
                    yearlyData[index].data.push(obj)
                    yearlyData[index].frontTotal = yearlyData[index].frontTotal + obj["FrontGross"]
                    yearlyData[index].backTotal = yearlyData[index].backTotal + obj["BackGross"]
                    yearlyData[index].totalTotal = yearlyData[index].totalTotal + obj["Total Gross"]
                    yearlyData[index].salePriceTotal = yearlyData[index].salePriceTotal + obj["SellingPrice"]
                } else {
                    yearlyData.push({ "year": year, "data": [obj], "frontTotal": obj["FrontGross"], "backTotal": obj["BackGross"], "totalTotal": obj["Total Gross"], "salePriceTotal": obj["SellingPrice"] })
                }
            })

            

            setFilteredROIReport(yearlyData)
            // setFilteredROIReport("Yearly")
        }
    }
    var getDaysInMonth = function (month: any, year: any) {
        return new Date(year, month, 0).getDate();
    };


    const handleYearChange = (e: any) => {
        

        yearRef.current.state.value = ""
        setFilter2value(null)
        setSelectedYear(e)
        if (e === "null") {
            setFilteredROIReport(null)
        }
    }
    const handleFilter2change = (e: any) => {
        setFilter2value(e)
        const annual = [{ data: [] }] as any;
        
        if (e !== "null") {
            if (e === "Annual") {
                setGlobalSellingValue(0)
                setGlobalSubscriptionfee(0)
                setFilter2value(e)
                let ftotal = 0;
                let btotal = 0;
                let ttotal = 0;
                let stotal = 0;

                const yearFilterd = data?.body?.data?.roi?.data?.forEach((obj: any) => {

                    let condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear) :
                        new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear) && obj?.Dealer_id === selectedDealer

                    
                    if (condition) {
                        ftotal += obj["FrontGross"] ? obj["FrontGross"] : 0;
                        btotal += obj["BackGross"] ? obj["BackGross"] : 0;
                        ttotal += obj["Total Gross"] ? obj["Total Gross"] : 0;
                        stotal += obj["SellingPrice"] ? obj["SellingPrice"] : 0;

                        annual[0].data.push(obj);
                        annual[0].frontTotal = ftotal
                        annual[0].backTotal = btotal
                        annual[0].totalTotal = ttotal
                        annual[0].salePriceTotal = stotal
                        obj["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
                        obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"] * 12))
                    }
                })
                
                setFilteredROIReport(annual)
            } else if (e === "Quarterly") {
                defaultQuaterlyDataPopulate()
            } else if (e === "Monthly") {
                defaultMonthlyDataPopulate()
            }
        }
    }

    const handleByAnuualFilterchange = (e: any) => {
        const h1Data = [{ data: [] }] as any;
        const h2Data = [{ data: [] }] as any;
        const allData = [{ data: [] }] as any;
        const firstHalfYearStart = new Date(`01 Jan ${selectedYear}`)
        const firstHalfYearEnd = new Date(`${getDaysInMonth(selectedYear, 6)} Jun ${selectedYear}`)

        const secondHalfYearStart = new Date(`01 Jul ${selectedYear}`)
        const secondHalfYearEnd = new Date(`${getDaysInMonth(selectedYear, 12)} Dec ${selectedYear}`)

        
        

        if (e === "H1") {
            setGlobalSellingValue(0)
            setGlobalSubscriptionfee(0)
            let ftotal = 0;
            let btotal = 0;
            let ttotal = 0;
            let stotal = 0;

            const h1Filterd = data?.body?.data?.roi?.data?.forEach((obj: any) => {
                let condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["SoldDate"]) >= firstHalfYearStart && new Date(obj["SoldDate"]) <= firstHalfYearEnd :
                    new Date(obj["SoldDate"]) >= firstHalfYearStart && new Date(obj["SoldDate"]) <= firstHalfYearEnd && obj?.Dealer_id === selectedDealer

                if (condition) {
                    ftotal += obj["FrontGross"] ? obj["FrontGross"] : 0;
                    btotal += obj["BackGross"] ? obj["BackGross"] : 0;
                    ttotal += obj["Total Gross"] ? obj["Total Gross"] : 0;
                    stotal += obj["SellingPrice"] ? obj["SellingPrice"] : 0;
                    h1Data[0].data.push(obj)
                    h1Data[0].frontTotal = ftotal
                    h1Data[0].backTotal = btotal
                    h1Data[0].totalTotal = ttotal
                    h1Data[0].salePriceTotal = stotal
                    obj["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
                    obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"] * 6))
                }
            })
            
            setFilteredROIReport(h1Data)
        } else if (e === "H2") {
            setGlobalSellingValue(0)
            setGlobalSubscriptionfee(0)
            let ftotal = 0;
            let btotal = 0;
            let ttotal = 0;
            let stotal = 0;
            const h2Filterd = data?.body?.data?.roi?.data?.forEach((obj: any) => {
                let condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["SoldDate"]) > secondHalfYearStart && new Date(obj["SoldDate"]) <= secondHalfYearEnd :
                    new Date(obj["SoldDate"]) > secondHalfYearStart && new Date(obj["SoldDate"]) <= secondHalfYearEnd && obj?.Dealer_id === selectedDealer

                if (condition) {
                    ftotal += obj["FrontGross"] ? obj["FrontGross"] : 0;
                    btotal += obj["BackGross"] ? obj["BackGross"] : 0;
                    ttotal += obj["Total Gross"] ? obj["Total Gross"] : 0;
                    stotal += obj["SellingPrice"] ? obj["SellingPrice"] : 0;
                    h2Data[0].data.push(obj)
                    h2Data[0].frontTotal = ftotal
                    h2Data[0].backTotal = btotal
                    h2Data[0].totalTotal = ttotal
                    h2Data[0].salePriceTotal = stotal
                    obj["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
                    obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"] * 6))
                }
            })
            
            setFilteredROIReport(h2Data)
        } else {
            setGlobalSellingValue(0)
            setGlobalSubscriptionfee(0)
            let ftotal = 0;
            let btotal = 0;
            let ttotal = 0;
            let stotal = 0;
            // All
            const allData2 = data?.body?.data?.roi?.data?.forEach((obj: any) => {
                let condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear) :
                    new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear) && obj?.Dealer_id === selectedDealer

                if (condition) {
                    ftotal += obj["FrontGross"] ? obj["FrontGross"] : 0;
                    btotal += obj["BackGross"] ? obj["BackGross"] : 0;
                    ttotal += obj["Total Gross"] ? obj["Total Gross"] : 0;
                    stotal += obj["SellingPrice"] ? obj["SellingPrice"] : 0;
                    allData[0].data.push(obj)
                    allData[0].frontTotal = ftotal
                    allData[0].backTotal = btotal
                    allData[0].totalTotal = ttotal
                    allData[0].salePriceTotal = stotal
                    obj["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
                    obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"] * 12))
                }
            })
            
            setFilteredROIReport(allData)
        }
    }

    const handleQuarterlyChange = (e: any) => {
        //limit the data to selected Year
        const yearFilterd = data?.body?.data?.roi?.data?.filter((obj: any) => {
            return new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear)
        })

        if (e !== 'all') {
            setGlobalSellingValue(0)
            setGlobalSubscriptionfee(0)
            let ftotal = 0;
            let btotal = 0;
            let ttotal = 0;
            let stotal = 0;
            const QuarterlyData = [{ data: [] }] as any
            const data = yearFilterd?.forEach((obj: any) => {
                let date = new Date(new Date(obj["SoldDate"]))
                let quarter = Math.floor(date.getMonth() / 3 + 1)

                let condition = (selectedDealer === "null" || selectedDealer === null) ? Number(quarter) === Number(e) :
                    Number(quarter) === Number(e) && obj?.Dealer_id === selectedDealer

                if (condition) {
                    ftotal += obj["FrontGross"] ? obj["FrontGross"] : 0;
                    btotal += obj["BackGross"] ? obj["BackGross"] : 0;
                    ttotal += obj["Total Gross"] ? obj["Total Gross"] : 0;
                    stotal += obj["SellingPrice"] ? obj["SellingPrice"] : 0;
                    QuarterlyData[0].data.push(obj)
                    QuarterlyData[0].frontTotal = ftotal
                    QuarterlyData[0].backTotal = btotal
                    QuarterlyData[0].totalTotal = ttotal
                    QuarterlyData[0].salePriceTotal = stotal
                    obj["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
                    obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"] * 3))
                }
                setFilteredROIReport(QuarterlyData)
            })
            // 


        } else if (e === "all") {
            defaultQuaterlyDataPopulate()
        }
    }

    const defaultQuaterlyDataPopulate = () => {
        setGlobalSellingValue(0)
        setGlobalSubscriptionfee(0)
        let ftotal = 0;
        let btotal = 0;
        let ttotal = 0;
        let stotal = 0;
        let condition;
        const QuarterlyData = [] as any;

        const yearFilterd = data?.body?.data?.roi?.data?.filter((obj: any) => {
            condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear) :
                new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear) && obj?.Dealer_id === selectedDealer
            return condition
        })
        yearFilterd?.map((data1: any) => {
            const Quarter = getQuarter(new Date(data1["SoldDate"]));

            if (QuarterlyData.find((obj: any) => obj.quarter === Quarter)) {
                let index = QuarterlyData.findIndex((obj: any) => obj.quarter === Quarter);

                ftotal += data1["FrontGross"] ? data1["FrontGross"] : 0;
                btotal += data1["BackGross"] ? data1["BackGross"] : 0;
                ttotal += data1["Total Gross"] ? data1["Total Gross"] : 0;
                stotal += data1["SellingPrice"] ? data1["SellingPrice"] : 0;

                QuarterlyData[index].data.push(data1)
                QuarterlyData[index].frontTotal = QuarterlyData[index].frontTotal + ftotal
                QuarterlyData[index].backTotal = QuarterlyData[index].backTotal + btotal
                QuarterlyData[index].totalTotal = QuarterlyData[index].totalTotal + ttotal
                QuarterlyData[index].salePriceTotal = QuarterlyData[index].salePriceTotal + stotal
                data1["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(data1["SellingPrice"]))
                data1["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(data1["subscriptionFee"] * 12))
            }
            else {
                data1["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(data1["SellingPrice"]))
                data1["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(data1["subscriptionFee"] * 12))
                QuarterlyData.push({ "quarter": Quarter, "data": [data1], "frontTotal": data1["FrontGross"] || 0, "backTotal": data1["BackGross"] || 0, "totalTotal": data1["Total Gross"] || 0, "salePriceTotal": data1["SellingPrice"] || 0 })
            }
        })
        
        setFilteredROIReport(QuarterlyData)
    }

    const defaultMonthlyDataPopulate = () => {
        setGlobalSellingValue(0)
        setGlobalSubscriptionfee(0)
        const monthlyData = [] as any;
        let condition;
        const yearFilterd = data?.body?.data?.roi?.data?.filter((obj: any) => {
            return condition = (selectedDealer === "null" || selectedDealer === null) ? new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear) :
                new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear) && obj?.Dealer_id === selectedDealer

        })

        yearFilterd?.forEach((obj: any) => {
            // let condition = (selectedDealer === "null" || selectedDealer === null) ? monthlyData?.find((obj: any) => obj.month === month) :
            //     monthlyData?.find((obj: any) => obj.month === month) && obj?.Dealer_id === selectedDealer

            const month = moment(new Date(obj["SoldDate"])).format('LL').split(" ")[0]
            if (monthlyData?.find((obj: any) => obj.month === month)) {
                let index = monthlyData.findIndex((obj: any) => obj.month === month);
                // monthlyData.push({ "data": data1 })
                monthlyData[index].data.push(obj)
                monthlyData[index].frontTotal = monthlyData[index].frontTotal + obj["FrontGross"] || 0;
                monthlyData[index].backTotal = monthlyData[index].backTotal + obj["BackGross"] || 0;
                monthlyData[index].totalTotal = monthlyData[index].totalTotal + obj["Total Gross"] || 0;
                monthlyData[index].salePriceTotal = monthlyData[index].salePriceTotal + obj["SellingPrice"] || 0;
                obj["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
                obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"] * 12))
            }
            else {
                obj["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
                obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"] * 12))
                monthlyData.push({ "month": month, "data": [obj], "frontTotal": obj["FrontGross"] || 0, "backTotal": obj["BackGross"] || 0, "totalTotal": obj["Total Gross"] || 0, "salePriceTotal": obj["SellingPrice"] || 0 })
            }
        })
        
        setFilteredROIReport(monthlyData)
    }
    

    const handleMonthlyFilterChange = (e: any) => {
        setGlobalSellingValue(0)
        setGlobalSubscriptionfee(0)
        const yearFilterd = data?.body?.data?.roi?.data?.filter((obj: any) => {
            return new Date(obj["SoldDate"]).getFullYear() === Number(selectedYear)
        })
        if (e === "all") {
            defaultMonthlyDataPopulate();
        } else {
            let ftotal = 0;
            let btotal = 0;
            let ttotal = 0;
            let stotal = 0;
            const monthData = [{ data: [] }] as any
            const data = yearFilterd?.forEach((obj: any) => {
                let month = moment(new Date(obj["SoldDate"])).format('LL').split(" ")[0]
                let condition = (selectedDealer === "null" || selectedDealer === null) ? month === e :
                    month === e && obj?.Dealer_id === selectedDealer

                if (condition) {
                    ftotal += obj["FrontGross"] ? obj["FrontGross"] : 0;
                    btotal += obj["BackGross"] ? obj["BackGross"] : 0;
                    ttotal += obj["Total Gross"] ? obj["Total Gross"] : 0;
                    stotal += obj["SellingPrice"] ? obj["SellingPrice"] : 0;
                    monthData[0].data.push(obj)
                    monthData[0].frontTotal = ftotal
                    monthData[0].backTotal = btotal
                    monthData[0].totalTotal = ttotal
                    monthData[0].salePriceTotal = stotal
                    obj["SellingPrice"] && setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
                    obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"]))
                }
                // setFilteredROIReport(QuarterlyData)
            })
            
            setFilteredROIReport(monthData)

        }
    }

    const handleDealerSelectedview = (did, dname) => {
        
        setSelectedDealer(did)
        setTempSelectedDealer({ label: dname, value: did })
        selectFilter1.current.state.value = '';
        yearRef.current.state.value = ""
        // setGlobalSellingValue(0)
        // setGlobalSubscriptionfee(0)
        // let dataFilteredbyDealer: any;
        // if (did === "null") {
        //     dataFilteredbyDealer = data?.body?.data?.roi?.data?.filter((obj: any) => {
        //         return obj
        //     })
        // } else {
        //     dataFilteredbyDealer = data?.body?.data?.roi?.data?.filter((obj: any) => {
        //         return obj.Dealer_id === did
        //     })
        // }

        // 

        // const temp = [{ data: [] }] as any;
        // let ftotal = 0;
        // let btotal = 0;
        // let ttotal = 0;
        // let stotal = 0;
        // dataFilteredbyDealer?.forEach((obj: any) => {
        //     // let year: any = new Date(obj["SoldDate"]).getFullYear()
        //     // years.push(year)
        //     // 
        //     // 
        //     // if (new Date(obj["SoldDate"]) >= last3monthdate && new Date(obj["SoldDate"]) <= new Date(Date.now())) {
        //     
        //     ftotal += obj["FrontGross"] ? obj["FrontGross"] : 0;
        //     btotal += obj["BackGross"] ? obj["BackGross"] : 0;
        //     ttotal += obj["Total Gross"] ? obj["Total Gross"] : 0;
        //     stotal += obj["SellingPrice"] ? obj["SellingPrice"] : 0;

        //     temp[0].data.push(obj)
        //     temp[0].frontTotal = ftotal
        //     temp[0].backTotal = btotal
        //     temp[0].totalTotal = ttotal
        //     temp[0].salePriceTotal = stotal
        //     setGlobalSellingValue((globalSellingValue) => Number(globalSellingValue) + Number(obj["SellingPrice"]))
        //     obj["subscriptionFee"] && setGlobalSubscriptionfee((globalSubscriptionfee) => Number(globalSubscriptionfee) + Number(obj["subscriptionFee"]))

        //     const tempBox = [] as any;

        //     // }
        // })
        // setFilteredROIReport(temp)
    }

    const dv = {
        label: user_first_name,
        value: dealerID
    }

    let annualValues = [
        { label: "Annual", value: "Annual" },
        { label: "Bi-Annual", value: "Bi-Annual" },
        { label: "Quarterly", value: "Quarterly" },
        { label: "Monthly", value: "Monthly" }
    ]

    let BiannualValues = [
        { label: "All", value: "all" },
        { label: "H1 [Jan - Jun]", value: "H1" },
        { label: "H2 [Jul - Dec]", value: "H2" },
    ]
    let quarterlyValues = [
        { label: "All", value: 'all' },
        { label: "Q1 [Jan - Mar]", value: 1 },
        { label: "Q2 [Apr - Jun]", value: 2 },
        { label: "Q3 [Jul - Sep]", value: 3 },
        { label: "Q4 [Oct - Dec]", value: 4 }
    ]

    let monthlyValue = [
        { label: "All", value: "all" },
        { label: "Jan", value: "January" },
        { label: "Feb", value: "February" },
        { label: "Mar", value: "March" },
        { label: "Apr", value: "April" },
        { label: "May", value: "May" },
        { label: "Jun", value: "June" },
        { label: "Jul", value: "July" },
        { label: "Aug", value: "August" },
        { label: "Sep", value: "September" },
        { label: "Oct", value: "October" },
        { label: "Nov", value: "November" },
        { label: "Dec", value: "December" },
    ]


    return (
        <div className='mt-auto mb-auto resp-width-r'>
            {/* <InputGroup className='input mt-auto mb-auto' > */}
            <div className=''>
                <div className='componentWrapper d-flex justify-content-left gap-3 p-3'>
                    <div className="header">
                        View filters
                    </div>
                    <div className="input-select-search" style={{ width: '200px' }}>
                    <span className='filter-control-lable'>Select dealer</span>
                        <Select closeOnSelect={true}
                            // ref={compRef}
                            isDisabled={type === "dealer"}
                            value={type === "dealer" ? dv : tempselectedDealer}
                            isSearchable={true}
                            defaultValue="All"
                            options={dealers && dealers}
                            onChange={(opt) => { handleDealerSelectedview(opt.value, opt.label) }}
                        ></Select>
                    </div>
                    {/* <Form.Select
                        onChange={handleYearChange}
                        ref={selectFilter1}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        aria-describedby='basic-addon1'
                    >
                        <option value="null">Select</option>
                        {years?.map((year: any) => (
                            <option value={year}>{year}</option>
                        ))}
                    </Form.Select> */}
                    <div className="input-select-search" style={{ width: '200px' }}>
                    <span className='filter-control-lable'>Select year</span>
                        <Select closeOnSelect={true}
                            // ref={compRef}
                            ref={selectFilter1}
                            isSearchable={true}
                            defaultValue="All"
                            options={yearsM && yearsM}
                            onChange={(opt) => { handleYearChange(opt.value) }}
                        ></Select>
                    </div>


                    {/* <Form.Select
                        ref={yearRef}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        onChange={handleFilter2change}
                        // defaultValue={defaultSelectedFiltervalue}
                        aria-describedby='basic-addon1'
                    // disabled={noDataDisable}
                    >
                        <option value="null">Select</option>
                        <option value="Annual">Annual</option>
                        <option value="Bi-Annual">Bi-Annual</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Monthly">Monthly</option>
                    </Form.Select> */}
                    <div className="input-select-search" style={{ width: '200px' }}>
                    <span className='filter-control-lable'>Select report type</span>
                        <Select closeOnSelect={true}
                            ref={yearRef}
                            isSearchable={true}
                            options={annualValues}
                            onChange={(opt) => { handleFilter2change(opt.value) }}
                        ></Select>
                    </div>


                    {/* {filter2Value === "Bi-Annual" && <Form.Select
                        ref={selectFilter}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        onChange={handleByAnuualFilterchange}
                        // defaultValue={defaultSelectedFiltervalue}
                        aria-describedby='basic-addon1'
                    // disabled={noDataDisable}
                    >
                        <option value="all">All</option>
                        <option value="H1">H1 [Jan - Jun]</option>
                        <option value="H2">H2 [Jul - Dec]</option>
                    </Form.Select>} */}
                    {filter2Value === "Bi-Annual" && <div className="input-select-search" style={{ width: '200px' }}>
                        <span className='filter-control-lable'>Select annual type</span>
                        <Select closeOnSelect={true}
                            ref={selectFilter}
                            isSearchable={true}
                            defaultValue={{ label: "All", value: 'all' }}
                            options={BiannualValues}
                            onChange={(opt) => { handleByAnuualFilterchange(opt.value) }}
                        ></Select>

                    </div>}



                    {/* {filter2Value === "Quarterly" && (<Form.Select
                        ref={selectFilter}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        onChange={handleQuarterlyChange}
                        // defaultValue={defaultSelectedFiltervalue}
                        aria-describedby='basic-addon1'
                    // disabled={noDataDisable}
                    >
                        <option value="all">All</option>
                        <option value={1}>Q1 [Jan - Mar]</option>
                        <option value={2}>Q2 [Apr - Jun]</option>
                        <option value={3}>Q3 [Jul - Sep]</option>
                        <option value={4}>Q4 [Oct - Dec]</option>
                    </Form.Select>)} */}
                    {filter2Value === "Quarterly" && <div className="input-select-search" style={{ width: '200px' }}>
                    <span className='filter-control-lable'>Select quarter</span>
                        <Select closeOnSelect={true}
                            defaultValue={{ label: "All", value: 'all' }}
                            ref={selectFilter}
                            isSearchable={true}
                            options={quarterlyValues}
                            onChange={(opt) => { handleQuarterlyChange(opt.value) }}
                        ></Select>

                    </div>}



                    {/* {filter2Value === "Monthly" && <Form.Select
                        ref={selectFilter}
                        onChange={handleMonthlyFilterChange}
                        className='p-2 select-field-sm mt-auto mb-auto'
                        size='sm'
                        // defaultValue={defaultSelectedFiltervalue}
                        aria-describedby='basic-addon1'
                    // disabled={noDataDisable}
                    >
                        <option value="all">All</option>
                        <option value="January">Jan</option>
                        <option value="February">Feb</option>
                        <option value="March">Mar</option>
                        <option value="April">Apr</option>
                        <option value="May">May</option>
                        <option value="June">Jun</option>
                        <option value="July">Jul</option>
                        <option value="August">Aug</option>
                        <option value="September">Sep</option>
                        <option value="October">Oct</option>
                        <option value="November">Nov</option>
                        <option value="December">Dec</option>
                    </Form.Select>} */}
                    {filter2Value === "Monthly" && <div className="input-select-search" style={{ width: '200px' }}>
                    <span className='filter-control-lable'>Select month</span>
                        <Select closeOnSelect={true}
                            ref={selectFilter}
                            isSearchable={true}
                            defaultValue={{ label: "All", value: 'all' }}
                            options={monthlyValue}
                            onChange={(opt) => { handleMonthlyFilterChange(opt.value) }}
                        ></Select>
                    </div>}


                </div>

                {/* </div> */}
            </div>

            {/* </InputGroup> */}
        </div >

    )

}
export default Filter