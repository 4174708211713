import { Button } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useContext } from "react";
import { Image, Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import leftArrow from "../../assets/images/leftArrow.svg";
import { leadUpdateApi } from "../../controllers/leads";
import { toastify } from "../common/notification";
import { converter } from "../common/timeZoneconverter";
import { UserContext } from "../common/UserContext";
import Warningalert from "../common/Warningalert";
import config from "./../../config.json";
function Previewappointment({
  bookingPayload,
  setToggle,
  refetch,
  setLoading,
  setTimeSlots,
  clearForm,
  dealerDetails,
  lead,
}: any) {
  const { authToken, email } = useContext(UserContext);
  const [appID, setAppID] = React.useState<any>(null);
  const [show, setShow] = React.useState<any>(false);
  const [showWarning, setShowWarning] = React.useState<any>(false);
  const [selctedOption, setSelctedOption] = React.useState<any>(null);
  function handleBack() {
    setToggle("date-select");
  }
  const body = {
    id: bookingPayload?.leadInfo?._id,
    type: "appointment",
    lead_status: "appointment",
    appointment: bookingPayload,
  };
  const leadMutation = useMutation(
    (data: any) => leadUpdateApi(authToken, body),
    {
      onSuccess: (dat) => {
        setLoading(false);
        bookingPayload.appointment_id = dat?.body?.data?.appointment_id;
        setAppID(dat?.body?.data?.appointment_id);
        toastify("success", "Appointment created successfully");
        refetch();
        clearForm();
        setLoading(false);
        setShow(false);
      },
    }
  );
  const checkIfAppointmentExistwithoutChangingStatus = () => {
    let filt = lead?.appointments?.filter((obj) => {
      return (
        obj?.appointment_status === "Booked" ||
        obj?.appointment_status === "Rescheduled"
      );
    });
    if (filt && filt?.length) {
      return true;
    } else {
      return false;
    }
  };

  const bookAppointment = () => {
    if (checkIfAppointmentExistwithoutChangingStatus()) {
      return setShowWarning(true);
    } else {
      setLoading(true);
      setShow(true); //show dropdown
      // leadMutation.mutateAsync(bookingPayload)
    }
  };

  const handleAction = (e: any) => {
    setSelctedOption(e.target.value);
  };

  const handleSend = () => {
    leadMutation.mutateAsync(bookingPayload) //create an appointment
    if (selctedOption !== "none") {
      const url = config["baseHost_backend"] + "/forgot-passowrd"; //send email/sms
      setLoading(true)
      let Hrformated=moment(bookingPayload?.appointment_time,'HH:mm');
      const data = {
        type: "createAppoinment",
        model: selctedOption,
        appoinment: bookingPayload,
        // email: email,
        shortName: dealerDetails?.shortName,
        aliasName: dealerDetails?.aliasName,
        dealerID: dealerDetails?.dealer_id,
        from: dealerDetails?.twilioPhone,

        appointment_date: bookingPayload?.appointment_date,
        appointment_status: bookingPayload?.appointment_status,
        appointment_time: Hrformated.format('hh:mm A'),
        dealer_address: bookingPayload?.dealer_info?.dealer_address,
        dealer_email: bookingPayload?.dealer_info?.dealer_email,
        dealer_id: bookingPayload?.dealer_info?.dealer_id,
        dealer_name: bookingPayload?.dealer_info?.dealer_name,
        dealer_phone: bookingPayload?.dealer_info?.dealer_phone,
        email: bookingPayload?.leadInfo?.email,
        first_name:bookingPayload?.leadInfo?.first_name,
        last_name:bookingPayload?.leadInfo?.last_name,
        mobile_number:bookingPayload?.leadInfo?.mobile_number,
        user_email:email
      };
      console.log("appmrnt", data);
      
     
      

      axios
          .post(url, data)
          .then((response) => {
              console.log("response",response);

              if (response?.data?.statusCode === 200) {

                  selctedOption === "Both" && toastify("success", `Both Email and SMS sent successfully`)

                  selctedOption !== "Both" && toastify("success", `${selctedOption} sent successfully`)
                  setShow(false)
                  setLoading(false)
                  // location.pathname.includes('update-profile') ? setShowModal(true) : setShowReset(true)
              }
              else {
                  selctedOption === "Both" && toastify("failure", `Unable to send both SMS and Email`)
                  selctedOption !== "Both" && toastify("failure", `Unable to send ${selctedOption}`)
                  setShow(false)
                  setLoading(false)
                  // toastify('failure', 'User does not exist')
              }

          }).catch((err) => {
              setLoading(false)
          })
    }
  };

  return (
    <div>
      <div className="">
        <Image
          onClick={() => {
            handleBack();
          }}
          className="pointer"
          src={leftArrow}
        />
      </div>
      <div className="p-3 bg-white shadow-sm">
        <span
          style={{ fontSize: "19px", color: "#30346b" }}
          className="badge bg-info d-block mb-3"
        >
          Appointment details
        </span>
        <span style={{ fontSize: "20px" }} className="pb-2 text-dark">
          Customer Details
        </span>
        <div
          style={{ width: "100%", height: "auto" }}
          className="d-flex flex-row justify-content-between "
        >
          <div className="d-flex flex-column">
            <span className="p-1 text-dark">Name</span>
            <span className="p-1 text-dark">Email</span>
            <span className="p-1 text-dark">Mobile Number</span>
          </div>
          <div className="d-flex flex-column text-right ">
            <span style={{ textAlign: "right" }} className="p-1 text-dark ">
              {bookingPayload?.leadInfo?.first_name}{" "}
              {bookingPayload?.leadInfo?.last_name}
            </span>
            <span style={{ textAlign: "right" }} className="p-1 text-dark ">
              {bookingPayload?.leadInfo?.email}
            </span>
            <span style={{ textAlign: "right" }} className="p-1 text-dark ">
              {bookingPayload?.leadInfo?.mobile_number}
            </span>
          </div>
        </div>
        <hr />
        <div>
          <span style={{ fontSize: "20px" }} className="pb-2 text-dark">
            Appointment
          </span>
          <div
            style={{ width: "100%", height: "auto" }}
            className="d-flex flex-row justify-content-between "
          >
            <div className="d-flex flex-column">
              <span className="p-1 text-dark">Date</span>
              <span className="p-1 text-dark">Time</span>
            </div>
            <div className="d-flex flex-column text-right">
              <span style={{ textAlign: "right" }} className="p-1 text-dark">
                {bookingPayload?.appointment_date}
              </span>
              <span style={{ textAlign: "right" }} className="p-1 text-dark">
                {moment(bookingPayload?.appointment_time, ["HH:mm a"]).format(
                  "hh:mm A"
                )}
                ({dealerDetails?.timezone})
              </span>
            </div>
          </div>
        </div>
        <hr />
        <span style={{ fontSize: "20px" }} className="text-dark">
          Dealer details
        </span>
        <div
          style={{ width: "100%", height: "auto" }}
          className="d-flex flex-row justify-content-between"
        >
          <div className="d-flex flex-column">
            <span className="p-1 text-dark">Name</span>
            <span className="p-1 text-dark">Email</span>
            <span className="p-1 text-dark">Mobile Number</span>
            <span className="p-1 text-dark">Address</span>
          </div>
          <div className="d-flex flex-column text-right">
            <span style={{ textAlign: "right" }} className="p-1 text-dark">
              {bookingPayload?.dealer_info?.dealer_name}
            </span>
            <span style={{ textAlign: "right" }} className="p-1 text-dark">
              {bookingPayload?.dealer_info?.dealer_email}
            </span>
            <span style={{ textAlign: "right" }} className="p-1 text-dark">
              {bookingPayload?.dealer_info?.dealer_phone}
            </span>
            <span style={{ textAlign: "right" }} className="p-1 text-dark">
              {bookingPayload?.dealer_info?.dealer_address}
            </span>
          </div>
        </div>
        <Button variant="contained" className="my-3" onClick={bookAppointment}>
          CONFIRM
        </Button>
      </div>
      <Warningalert
        heading={"Update the status"}
        body="Please update the status of previous appointment before proceeding to schedule a new appointment."
        showWarning={showWarning}
        setShowWarning={setShowWarning}
      />
      <Modal centered show={show}>
        <Modal.Body>
          <div className="d-flex flex-column">
            <span>Please select the preferred channel of communication.</span>
            <select
              name=""
              id=""
              className="w-50 my-3 form-control form-select-sm"
              onChange={handleAction}
            >
              <option value="null">Select</option>
              <option value="Email">Email</option>
              <option value="SMS">SMS</option>
              <option value="Both">Both</option>
              <option value="none">None</option>
            </select>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex gap-2">
            <button
              // disabled={!selctedOption || selctedOption === "null"}
              onClick={() => {
                setShow(false);
                setLoading(false);
                toastify("success", "Current operation cancelled by user");
              }}
              className="btn btn-sm sbuttonbgcolor"
              color="inherit"
            >
              CANCEL
            </button>
            <button
              disabled={!selctedOption || selctedOption === "null"}
              onClick={handleSend}
              className="btn btn-sm pbuttonbgcolor"
            >
              BOOK APPOINTMENT
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Previewappointment;
