import React from 'react';
import { Card, Modal } from 'react-bootstrap';
import { Dealer } from '../Leads/leadPersonalDetails';
import { Grid } from '@mui/material';
import Operationhours from './Operationhours';
import { userListApi } from '../../controllers/users';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import Dealercontacts from './Dealercontacts';



export default function DealerInfo({ dealer, setShowWarning, setWarningHeading, setWarningBody, longAddress,setIdleBtnLabel }: any) {
    const [workingHrs, setWorkingHrs] = React.useState<any>(null)
    const [show, setShow] = React.useState<any>(false)
    const [users, setUsers] = React.useState<any>(null)

    const [showContactsModal, setShowContactsModal] = React.useState<any>(false)
    const [contactsInfo, setContactsInfo] = React.useState<any>(null)
    

    React.useEffect(() => {
        if (dealer) {
            setWorkingHrs(dealer?.workingHours)
        }
    }, [dealer])

    const handleClose = () => {
        setShow(false)
    }

    let token: any = localStorage.getItem("$AuthToken")
    const { data: userList, isLoading: isLeadsLoading, refetch } = useQuery('users', () =>
        userListApi(token), { staleTime: 3600000 }
    );


    React.useEffect(() => {
        if (userList) {
            const dealersusers = userList?.body?.data?.filter((obj: any) =>
                obj.dealerID === dealer?.dealer_id
            )
            setUsers(dealersusers)
            

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList])


    return (
        <div className='containerBox'>
            <Card className='mx-1 card-sec'>
                <Card.Header>
                    <Card.Title className='d-flex gap-2'>
                        <h3 className='pt-1'>Dealership Info</h3> 
                       {!dealer?.is_active && <span className='badge bg-danger mt-auto mb-auto' style={{fontSize:'11px'}}>in Active</span>}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Grid container className='text-dark' spacing={2}>
                        <Grid item lg={4}>
                            <div>
                                <p className='mb-0'>Address</p>
                                {
                                    !longAddress.length ? <p style={{ fontWeight: 'lighter' }}>N/A</p> :
                                    <p style={{ fontWeight: 'lighter' }}>  {
                                        longAddress?.map((add) => (
                                            add + " "
                                        ))
                                    }</p>
                                }
                            </div>
                        </Grid>
                        <Grid item lg={4}>
                            <div>
                                <p className='mb-0'>Phone</p>
                                <p style={{ fontWeight: 'lighter' }}>{dealer?.phone ?? "N/A"}</p>
                            </div>
                        </Grid><Grid item lg={4}>
                            <div>
                                <p className='mb-0'>Time zone</p>
                                <p style={{ fontWeight: 'lighter' }}>{dealer?.timezone ?? "N/A"}</p>
                            </div>
                        </Grid><Grid item lg={4}>
                            <div>
                                <p className='mb-0'>Hours of Operation</p>
                                <button className='btn-link btn btn-sm' onClick={
                                    () => {
                                        if (!workingHrs) {
                                            setShowWarning(true)
                                            setIdleBtnLabel("OK")
                                            setWarningBody("Hours of Operation is not assigned for this dealer.")
                                            setWarningHeading("Working hour is not assigned");
                                        } else {
                                            setShow(true)
                                        }
                                    }}>View</button>
                            </div>
                        </Grid><Grid item lg={4}>
                            <div>
                                <p className='mb-0'>Twilio phone</p>
                                <p style={{ fontWeight: 'lighter' }}>{dealer?.twilioPhone ?? "N/A"}</p>
                            </div>
                        </Grid><Grid item lg={4}>
                            <div>
                                <p className='mb-0'>CRM Name</p>
                                <p style={{ fontWeight: 'lighter' }}>{dealer?.crm_name ?? "N/A"}</p>
                            </div>
                        </Grid><Grid item lg={4}>
                            <div>
                                <p className='mb-0'>Preferred Book</p>
                                <p style={{ fontWeight: 'lighter' }}>{dealer?.preferred_book ?? "N/A"}</p>
                            </div>
                        </Grid>
                        <Grid item lg={4}>
                            <div>
                                <p className='mb-0'>Corporate</p>
                                <p style={{ fontWeight: 'lighter' }}>{dealer?.corporateName ?? "N/A"}</p>
                            </div>
                        </Grid>
                        <Grid item lg={4}>
                            <div>
                                <p className='mb-0'>Contacts</p>
                                <button className='btn-link btn btn-sm' onClick={
                                    () => {
                                        setContactsInfo(dealer?.contacts);
                                        setShowContactsModal(true)
                                    }}>View</button>
                            </div>
                        </Grid>

                    </Grid>
                </Card.Body>
            </Card>
            <Card className='text-dark mx-1 mt-2'>
                <Card.Header>
                    <Card.Title>
                        <h3 className='pt-1'>Users</h3>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <table className='table table-bordered'>
                        <thead className=''>
                            <tr>
                                <th>Name</th>
                                <th>Phone number</th>
                                <th>Email</th>
                                <th>Title</th>
                            </tr>
                        </thead>
                        {!isLeadsLoading && <tbody style={{ fontWeight: 'lighter' }}>
                            {users?.map((obj) => (
                                <tr>
                                    <td>{obj?.first_name + ' ' + obj?.last_name}</td>
                                    <td>{!obj?.contactdetails?.cell_number ? "N/A" : obj?.contactdetails?.cell_number}</td>
                                    <td>{obj?.email ?? "N/A"}</td>
                                    <td>{obj?.title ?? "N/A"}</td>
                                </tr>
                            ))}
                        </tbody>}
                    </table>
                    {isLeadsLoading && <Loader />}
                    {!isLeadsLoading && !users?.length && <h3 className='text-center py-3'>No users found</h3>}
                </Card.Body>
            </Card>
            {showContactsModal && <Dealercontacts
                showContactsModal={showContactsModal}
                contactsInfo={contactsInfo}
                setShowContactsModal={() => setShowContactsModal(false)}
            />
            }
            {show && <Operationhours handleClose={handleClose} show={show} defaultValues={dealer && dealer?.workingHours} setWorkingHrs={setWorkingHrs} title="Working hours" readOnly={true} />}
        </div>
    );
}
