import React, { useEffect, useState } from "react";
import deleteImg from "../../../assets/images/delete.svg";
import Deletewarning from "../../common/Deletewarning";
import axios from "axios";
import config from "../../../config.json";
import editIcon from "../../../assets/images/edit.svg";
import moment from "moment";
import { toastify } from "../../common/notification";
import NoDataFound from "../../common/NoDataFound";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
function Smstemplate({ data, setCategoryDropdown, refetch, isLoading }: any) {
  const [masterData, setMasterData] = useState<any>(null);
  // const [categories, setCategories] = useState<any>(null)
  const [show, setShow] = useState<any>(false);
  const history = useHistory();
  const [obj_id, setObj_id] = useState<any>("");
  let token: any = localStorage.getItem("$AuthToken");

  const handleDelete = async () => {
    let body = {
      isDelete: true,
      _id: obj_id,
    };
    let resp = await axios.post(
      config.baseHost_backend + "/save-sms-template",
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (resp) {
      setShow(false);
      refetch();
      toastify("success", "Template deleted successfully.");
    }
  };

  useEffect(() => {
    const templatesMaster = [] as any;
    const cate = [] as any;
    data &&
      data?.forEach((obj) => {
        let category = obj?.template_category;
        cate.push(category);
        if (templatesMaster?.find((tc: any) => tc.category === category)) {
          let findIndex = templatesMaster?.findIndex(
            (g) => g.category === category
          );
          templatesMaster[findIndex].data.push(obj);
        } else {
          templatesMaster.push({ category: category, data: [obj] });
        }
      });

    setMasterData(templatesMaster);
    setCategoryDropdown(cate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      {masterData &&
        masterData
          ?.sort((a, b) =>
            a.category.toLowerCase() > b.category.toLowerCase() ? 1 : -1
          )
          ?.map((md) => (
            <div>
              <span style={{ fontWeight: "bolder" }}>{md.category}</span>
              <table
                className="table table-bordered mt-2"
                style={{ fontWeight: "normal" }}
              >
                <thead className="table-head" style={{ fontWeight: "normal" }}>
                  <tr>
                    <th style={{ fontWeight: "normal" }}>#</th>
                    <th style={{ fontWeight: "normal" }}>Template name</th>
                    <th style={{ fontWeight: "normal" }}>Created At</th>
                    <th style={{ fontWeight: "normal" }}>Updated At</th>
                    <th style={{ fontWeight: "normal" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {md?.data
                    ?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                    ?.map((temp, index) => (
                      <tr key={index + "ue"}>
                        <td>{index + 1}</td>
                        <td>{temp?.template_name}</td>
                        <td>{moment(temp?.createdAt).format("LLL")}</td>
                        <td>{!temp?.updatedAt ? moment(temp?.createdAt).format("LLL") : moment(temp?.updatedAt).format("LLL")}</td>
                        <td style={{ cursor: "pointer" }}>
                          <Image
                            className="iconAction mr-2"
                            src={editIcon}
                            onClick={() => {
                              history.push({
                                pathname: "/template/sms/create",
                                state: { edit: true, editData: temp },
                              });
                            }}
                          />
                          <img
                            title="Delete"
                            src={deleteImg}
                            alt="delete"
                            onClick={() => {
                              setObj_id(temp?._id);
                              setShow(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ))}
      <Deletewarning
        actionBtnLabel={"Delete"}
        closeBtnLabel={"Cancel"}
        handleClose={() => {
          setShow(false);
        }}
        handleDelete={handleDelete}
        show={show}
        title="Delete template"
        message="Are you sure you want to delete this template?"
      />
      {!isLoading && !masterData?.length && (
        <NoDataFound
          height={""}
          width={""}
          img=""
          message1="No templates found"
          message2='Click on "Create" to create a template '
        />
      )}
    </div>
  );
}

export default Smstemplate;
