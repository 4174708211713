/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Image,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./dealerList.css";
import addIcon from "../../assets/icons/AddButton.svg";
import { accessRoles, dealerProcesses } from "../../controller";
import { corporateListApi, dealerListApi } from "../../controllers/dealers";
import config from "../../config.json";
import { UserContext } from "../common/UserContext";
import Loader from "../common/Loader";
import { useHistory, useLocation } from "react-router-dom";
import TableDealerCorp from "./TableDealerCorp";
import _ from "lodash";
import Select from "react-select";
import { useQuery } from "react-query";
import axios from "axios";
import { Grid } from "@mui/material";
import close from "../../assets/images/close.svg";
import ReactPaginate from "react-paginate";
import LoadingBar from "../common/LoadingBar";
import NoDataFound from "../common/NoDataFound";
const DealerList = () => {
  const [corporates, setCorporates] = React.useState<any>([]);
  const [toggleValue, setToggleValue] = useState<any>(false);
  const [searchVal, setSearchVal] = React.useState("");
  const [selectedProcess, setSelectedProcess] = useState<any>([]);
  const history = useHistory();
  const [sort, setSort] = useState<any>({
    type: null,
    key: null,
  });
  const [reset, setReset] = useState<boolean>(false);
  const { authToken, type, role, dealerID, corporateID } =
    useContext(UserContext);
  const location = useLocation();
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10);

  let body = {
    pages_to_skip: page - 1,
    limit_per_page: limit,
    reset,
    filter: searchVal,
    sort,
    logged_in_usertype: type,
    dealerID: dealerID,
    corporateID: corporateID,
    process: selectedProcess
      ?.filter((d) => d.value !== "All process")
      ?.map((ps) => ps.value),
  };

  const {
    data: filterData,
    isLoading: isDealerLoading,
    refetch,
    isFetching,
    isRefetching,
  } = useQuery(
    ["fetch-dealer-list", searchVal, reset, page, sort],
    () => dealerListApi(authToken, body),
    { staleTime: 3600000 }
  );

  const bodyObj = {
    pages_to_skip: page - 1,
    limit_per_page: limit,
    reset: false,
    filter: searchVal,
    sort,
  };

  const fetchCorporates = async () => {
    let resp = await axios.post(
      `${config.baseHost_backend}/corporates`,
      bodyObj,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    console.log("resp", resp);
    setCorporates(resp?.data?.body);
    return resp?.data?.body;
  };

  const { data: allCorporates, refetch: refetchCorp } = useQuery(
    ["fetch-corporates", page, sort, searchVal],
    fetchCorporates
  );

  useEffect(() => {
    if (location.state) {
      const data: any = location.state;
      if (data?.dataChanged) {
        refetch();
      }
    }
  }, []);

  const handleToggleChange = (e: any) => {
    setPage(1);
    setSearchVal("");
    setToggleValue(e.target.checked);
  };

  const handlePageClick = async (Selected) => {
    setPage(Selected.selected + 1);
  };

  const handleProcessChange = (value, event) => {
    // console.log("value", value.length);

    if (
      event.action === "select-option" &&
      event.option.value === "All process"
    ) {
      setSelectedProcess(dealerProcesses);
      console.log(1);
    } else if (
      event.action === "select-option" &&
      value?.length === dealerProcesses.length - 1
    ) {
      setSelectedProcess(dealerProcesses);
      console.log(5);
    } else if (
      event.action === "select-option" &&
      event.option.value !== "All process"
    ) {
      setSelectedProcess(value?.filter((o) => o.value !== "All process"));
      console.log(2);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All process"
    ) {
      setSelectedProcess([]);
      console.log(3);
    } else if (event.action === "deselect-option") {
      setSelectedProcess(value.filter((o) => o.value !== "All process"));
      console.log(4);
    } else {
      setSelectedProcess(value);
      console.log(6);
    }
  };

  const handleFilter = () => {
    setReset(false);
    console.log(body);
    refetch();
  };

  return (
    <div>
      <form className="d-flex flex-row gap-3 justify-content-between py-3 px-3 bhmheader shadow-sm">
        <div className="d-flex mt-auto mb-auto">
          <div className="pad-button card-title">Dealers</div>
          {type !== "corporate" && (
            <div className="d-flex flex-row">
              <Form.Check
                type="switch"
                onChange={handleToggleChange}
                id=""
                label=""
                // checked={toggle !== 'lead' ? true : false}
                className="card-title ml-3"
              />
              <div className="pad-button card-title">Corporates</div>
            </div>
          )}
        </div>

        <div className="mt-auto mb-auto">
          <Form.Control
            className="form-input p-2"
            size="sm"
            style={{ width: "300px" }}
            type="input"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder={`Search ${
              toggleValue ? "Corporate" : "Dealer"
            } by ID/Name or Email`}
          />
          {searchVal && (
            <img
              src={close}
              alt="close"
              height={10}
              className="searchboxClsbtn"
              onClick={() => {
                setSearchVal("");
              }}
            />
          )}
        </div>
        {accessRoles
          ?.filter(
            (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
          )[0]
          ?.permissions?.includes("allowSelectDealerProcessFilter") && (
          <div style={{ width: "350px" }} className="">
            <Form.Label className="pb-0 mb-0 filter-control-lable">
              Select dealer process
            </Form.Label>
            <Select
              isMulti
              isSearchable={true}
              closeMenuOnSelect={false}
              closeOnSelect={true}
              hideSelectedOptions={false}
              options={dealerProcesses}
              onChange={handleProcessChange}
              value={
                selectedProcess?.length === dealerProcesses.length
                  ? [{ label: "All process", value: "All process" }]
                  : selectedProcess
              }
            />
          </div>
        )}
        <div className="mt-auto mb-auto">
          <button
            className="btn pbuttonbgcolor btn-sm mr-2"
            onClick={handleFilter}
            type="button"
          >
            Filter
          </button>
          <button
            className="btn sbuttonbgcolor btn-sm"
            type="reset"
            onClick={() => {
              setSelectedProcess([]);
              setPage(1);
              setReset(true);
              setSearchVal("");
            }}
          >
            Reset
          </button>
        </div>

        <div className="mt-auto mb-auto">
          {accessRoles
            ?.filter(
              (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
            )[0]
            ?.permissions?.includes("addDealersandCorporate") && (
            <div className=" mt-auto mb-auto">
              <button
                onClick={() =>
                  toggleValue
                    ? history.push("/corporate/create")
                    : history.push("/dealers/create")
                }
                className="btn pbuttonbgcolor btn-sm"
              >
                Add
              </button>
            </div>
          )}
        </div>
      </form>
      <div
        style={{
          paddingTop: accessRoles
            ?.filter(
              (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
            )[0]
            ?.permissions?.includes("allowSelectDealerProcessFilter")
            ? "105px"
            : "85px",
        }}
        className="container-fluid"
      >
        {
          <TableDealerCorp
            refetch={refetch}
            refetchCorp={refetchCorp}
            data={
              toggleValue ? corporates?.corporateList : filterData?.body?.data
            }
            type1={toggleValue ? "corporate" : "dealer"}
            setSort={setSort}
            sort={sort}
          />
        }
      </div>
      <div className="container-fluid">
        <ReactPaginate
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageCount={
            toggleValue
              ? corporates?.corporateCount / limit || 0
              : filterData?.body?.dealerCount / limit || 0
          }
          previousLabel="<<"
          containerClassName="pagination"
          pageClassName={"pagination"}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakLinkClassName="page-link"
          activeClassName="active"
          breakClassName="page-item"
          initialPage={page - 1}
        />
      </div>
      {!isDealerLoading && !filterData?.body?.data?.length ? (
        <NoDataFound
          img={""}
          height={""}
          width={""}
          message1={toggleValue ? "No corporates found" : "No dealers found"}
          message2={""}
        />
      ) : isDealerLoading ? (
        <LoadingBar isActive={true} />
      ) : (
        ""
      )}
    </div>
  );
};
export default DealerList;
