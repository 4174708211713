export const statusExtractor = (data, status) => {
    let appointmentCount
    let appointmentCancelledCount
    let noShow
    let interested
    let botWorking
    let botMesgSent
    let noResponse
    let notIntersted
    let sold
    let showed
    let newdata
    let buyBack;
    let dropped;
    let buyBackAndSold;
    let opportunities;
    let interestedService;
    let workingFollowUp;

    appointmentCount = data?.filter(
        (status) =>
            status._id === "appointment" || status._id === "APPOINTMENT"
    );
    appointmentCancelledCount = data?.filter(
        (status) => status._id === "appointment_cancelled"
    );
    noShow = data?.filter(
        (status) => status._id === "no_show"
    );

    showed = data?.filter(
        (status) => status._id === "showed"
    );
    sold = data?.filter(
        (status) => status._id === "sold" || status._id === "SOLD"
    );

    interested = data?.filter(
        (status) => status._id === "new"
    );
    interestedService = data?.filter(
        (status) => status._id === "Service_Interested"
    );

    notIntersted = data?.filter(
        (status) => status._id === "customer_declined"
    );

    botWorking = data?.filter(
        (status) => status._id === "bot_working"
    );
    console.log("botWorking", botWorking, botWorking[0]?.count);

    botMesgSent = data?.filter(
        (status) => status._id === "bot_msg_sent"
    );
    dropped = data?.filter(
        (status) => status._id === "dropped"
    );


    buyBack = data?.filter(
        (status) => status._id === "buyBack"
    );

    workingFollowUp = data?.filter(
        (status) => status._id === "working_followup"
    );

    buyBackAndSold = data?.filter(
        (status) => status._id === "buyBackAndSold"
    );

    opportunities = Number(appointmentCount[0]?.count ?? 0) +
        Number(sold[0]?.count ?? 0) +
        Number(appointmentCancelledCount[0]?.count ?? 0) +
        Number(showed[0]?.count ?? 0) +
        Number(noShow[0]?.count ?? 0) +
        Number(buyBack[0]?.count ?? 0) +
        Number(buyBackAndSold[0]?.count ?? 0) +
        Number(dropped[0]?.count ?? 0)


    newdata = status?.map((status) =>
        status.text === "appointment_cancelled"
            ? {
                ...status,
                value:
                    appointmentCancelledCount && appointmentCancelledCount[0]?.count
                        ? appointmentCancelledCount[0]?.count
                        : 0,
            }
            : status.text === "appointments"
                ? {
                    ...status,
                    value:
                        appointmentCount && appointmentCount[0]?.count
                            ? appointmentCount[0]?.count
                            : 0,
                }
                : status.text === "bot_working"
                    ? {
                        ...status,
                        value:
                            botWorking && botWorking[0]?.count
                                ? botWorking[0]?.count
                                : 0,
                    }
                    : status.text === "dropped"
                        ? {
                            ...status,
                            value:
                                dropped && dropped[0]?.count
                                    ? dropped[0]?.count
                                    : 0,
                        }
                        : status.text === "bot_msg_sent"
                            ? {
                                ...status,
                                value:
                                    botMesgSent && botMesgSent[0]?.count
                                        ? botMesgSent[0]?.count
                                        : 0,
                            }
                            : status.text === "sold"
                                ? { ...status, value: sold && sold[0]?.count ? sold[0].count : 0 }
                                : status.text === "showed"
                                    ? {
                                        ...status,
                                        value: showed && showed[0]?.count ? showed[0]?.count : 0,
                                    }
                                    : status.text === "no_show"
                                        ? {
                                            ...status,
                                            value: noShow && noShow[0]?.count ? noShow[0]?.count : 0,
                                        }
                                        : status.text === "opportunities"
                                            ? {
                                                ...status,
                                                value: opportunities ?? 0,
                                            }
                                            : status.text === "interested"
                                                ? {
                                                    ...status,
                                                    value:
                                                        interested && interested[0]?.count ? interested[0]?.count : 0,
                                                }

                                                : status.text === "not_interested"
                                                    ? {
                                                        ...status,
                                                        value:
                                                            notIntersted && notIntersted[0]?.count
                                                                ? notIntersted[0]?.count
                                                                : 0,
                                                    }
                                                    : status.text === "interestedService"
                                                        ? {
                                                            ...status,
                                                            value:
                                                                interestedService && interestedService[0]?.count ? interestedService[0]?.count : 0,
                                                        }
                                                        : status.text === "buyBack"
                                                            ? {
                                                                ...status,
                                                                value:
                                                                    buyBack && buyBack[0]?.count ? buyBack[0]?.count : 0,
                                                            }
                                                            : status.text === "working_followup"
                                                                ? {
                                                                    ...status,
                                                                    value:
                                                                        workingFollowUp && workingFollowUp[0]?.count ? workingFollowUp[0]?.count : 0,
                                                                }
                                                                : status.text === "buyBackAndSold"
                                                                    ? {
                                                                        ...status,
                                                                        value:
                                                                            buyBackAndSold && buyBackAndSold[0]?.count ? buyBackAndSold[0]?.count : 0,
                                                                    }
                                                                    : status
    );
    return newdata;
}