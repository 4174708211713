import { Button } from 'react-bootstrap'
import React, { useEffect } from 'react'
import { CSVLink } from 'react-csv'
function Template() {
    const [template, setTemplate] = React.useState<any>(null)

    useEffect(() => {
        let t1 = [
            ["Vin",
                "Model",
                "Year",
                "Status",
                "Invoice",
                "Date",
                "Location",
                "Miles",
                "Ext Color",
                "Age",
                "Cost",
                "N/U/T",
                "Make",
                "Stock#",
                "Trim",
                "DMS Status",
                "Price",
                "Date",
              ]
        ]
        setTemplate(t1)
    }, [])

    return (
        <div className='mt-auto mb-auto'>
            {template && <CSVLink data={template} filename={"Template_v1"}>
                <span className='d-flex gap-1 btn-sm btn-outlined'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mt-auto mb-auto pr-1 bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                        <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                    </svg> <span className='mt-auto mb-auto h6'>Template</span>
                </span>
            </CSVLink>}
        </div>
    )
}

export default Template

