import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import { toastify } from "./notification";
import config from "../../config.json";
import axios from "axios";
import { useAppContext } from "../../AppContext";
import { UserContext } from "./UserContext";
import LoadingBar from "./LoadingBar";
function Createtaskmodal({ show, handleClose, lead,refetch }) {
  console.log("lead", lead);

  const { user_first_name, user_last_name, email, id, name } =
    useContext<any>(UserContext);
  const [title, setTitle] = useState<any>("");
  const [taskType, setTaskType] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [note, setNote] = useState<any>("");
  let token: any = localStorage.getItem("$AuthToken");
  const clearForm=()=>{
    setTitle("");
    setTaskType("");
    setDate("");
    setNote("");
  }
  const handleCreateTask = async () => {
    if (!taskType) {
      return toastify("failure", "Task type cannot be empty");
    }
    if (!title) {
      return toastify("failure", "Task title cannot be empty");
    }
    if (!date) {
      return toastify("failure", "Task date cannot be empty");
    }
    setLoading(true);
    // let payload = {
    //   type: "create",
    //   createdByEmail: email,
    //   createdByname: name,
    //   createdByid: id,
    //   task: {
    //     leadid: lead?._id,
    //     dealer_id: lead?.dealer_id,
    //     dealer_name: lead?.dealer_name,
    //     leadName:
    //       lead?.customer_details?.first_name +
    //       " " +
    //       lead?.customer_details?.last_name,
    //     leadEmail: lead?.customer_details?.email,
    //     taskid: id + Date.now(),
    //     title,
    //     start: new Date(date).getTime(),
    //     end: new Date(date).getTime(),
    //     status: "Not started",
    //     note,
    //     tasktype: taskType,
    //   },
    // };

    const payload = {
      type: "create",
      date: date.split(" ")[0],
      sdatetime: new Date(date).getTime(),
      edatetime: new Date(date).getTime(),
      leadid: lead?._id,
      tasks: {
        taskid: lead?._id + Date.now(),
        title,
        t_sdatetime: new Date(date).getTime(),
        t_edatetime: new Date(date).getTime(),
        tasktype: taskType,
        status: "Not started",
        note,
        createdByEmail: email,
        createdByname: name,
        createdByid: id,
      },
    };
    console.log("payload", payload);
  
    let resp = await axios
      .post(config.baseHost_backend + "/task-management", payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((resp) => {
        refetch()
        console.log("resp", resp);
        if (resp?.data?.statusCode === 200) {
          clearForm();
          toastify("success", "Task created successfully.");
        } else {
          toastify("failure", "Unable to create the task.");
        }
        setLoading(false);
        handleClose();
        
      })
      .catch((err) => {
        clearForm()
        setLoading(false);
        handleClose();
        console.log("error", err);
      });
  };

  const handleDateChange = (date) => {
    setDate(date.toString());
  };

  return (
    <div>
      <Modal show={show}>
      <LoadingBar isActive={loading} />
        <Modal.Header>
          <Modal.Title>
            <h2 className="mb-0">Create task</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <label htmlFor="" className="mb-0">
              Select task type
              <span className="text-danger h6 font-weight-bold">*</span>
            </label>
            <select
              name=""
              className="form-select"
              id=""
              onChange={(e) => {
                setTaskType(e.target.value);
              }}
            >
              <option value="null">Select</option>
              <option value="Meeting">Meeting</option>
              <option value="ScheduleACall">Schedule a call</option>
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-0">
              Enter task title
              <span className="text-danger h6 font-weight-bold">*</span>
            </label>
            <input
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              type="text"
              className="form-control form-control-sm bg-white"
            />
          </div>

          <div className="mb-2 d-flex flex-column">
            <label htmlFor="" className="mb-0">
              Select date
              <span className="text-danger h6 font-weight-bold">*</span>
            </label>
            <DatePicker
              onChange={handleDateChange}
              value={date}
              style={{ padding: "23px" }}
              format="MM/DD/YYYY HH:mm"
              // multiple={true}
              minDate={new Date()}
              plugins={[<TimePicker hideSeconds />]}
            />
            {/* <input type="datetime-local" name="" id="" onChange={(e)=>{
              let newdate=e.target.value.split("-");
              // 2023-11-05T15:17
              let year=newdate[0];
              console.log("newdate",newdate);
              
              let month=newdate[1]
              let datee=newdate[2].slice(0,2);
              let hour=e.target.value.split("T")[0];
              let mins=e.target.value.split("T")[1];
              setDate(month+"-"+datee+"-"+year+" "+hour+":"+mins)

            }}/> */}
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-0">
              Enter notes
            </label>
            <textarea
              onChange={(e) => setNote(e.target.value)}
              name=""
              id=""
              cols={10}
              rows={6}
              className="form-control bg-white"
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-sm sbuttonbgcolor mr-3"
              onClick={()=>{
                clearForm();
                handleClose();
              }}
            >
              Close
            </button>
            <button
              className="btn btn-sm pbuttonbgcolor"
              onClick={handleCreateTask}
            >
              Create
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      
    </div>
  );
}

export default Createtaskmodal;
