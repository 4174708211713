/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Pie, Bar } from 'react-chartjs-2'
// import { ModelsData } from '../Data'
import { Chart as chartJS, registerables, Tooltip } from 'chart.js';
import { dashboard } from '../../../controllers/dashboard';
import { useQuery } from 'react-query';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { UserContext } from '../../common/UserContext';
import { allLeadsApi } from '../../../controllers/leads';
import { CustomTable } from '../../common/customTable';
import axios from "axios";
import config from '../../../config.json';
import { toastify } from "../../common/notification";
import Loader from '../../common/Loader';
import { Col, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Grid } from '@mui/material';
const url = config['baseHost_backend'];
chartJS.register(...registerables, Tooltip);
function Report({ leadGenReportData }: any) {
    const { authToken } = useContext(UserContext);
    const [myData, setmyData] = useState<any>(null)
    const [pageNumber, setPageNumber] = useState(0);
    const [data, setData] = useState<any>(null)
    const [displayData, setDisplayData] = useState<any>(null)
    const [filter, setFilter] = useState<any>('All')
    const [scrollMin, setScrollMin] = useState(0)
    const [scrollMax, setScrollMax] = useState(9)
    const [loading, setLoading] = useState<boolean>(false)

    const [leadGenPer, setLeadGenPer] = useState<any>(null)
    const [interestedServicePer, setInterestedServicePer] = useState<any>(null)
    const [serviceAppointmentInterstedPercentage, setServiceAppointmentInterstedPercentage] = useState<any>(null)

    const [botmsgSentPerctage,setBotmsgSentPerctage]=useState<any>(null)
    const [botServiceAppointmentPerctage,setBotServiceAppointmentPerctage]=useState<any>(null)
    const [botServiProtectionPerctage,setBotServiProtectionPerctage]=useState<any>(null)

    
    useEffect(() => {
        console.log("leadGenReportData", leadGenReportData)
        setData(leadGenReportData)
    }, [leadGenReportData])

    useEffect(() => {
        let leadGeneratedPercentage;
        let interstedServicePercentage;
        let serviceAppntmentInterstedPercetage;

        leadGeneratedPercentage = data?.leadGenerated / data?.bot_msg_sent;
        leadGeneratedPercentage = leadGeneratedPercentage * 100;
        setLeadGenPer(leadGeneratedPercentage)

        interstedServicePercentage = data?.serviceInterested / data?.roServiceMessaged;
        interstedServicePercentage = interstedServicePercentage * 100;
        setInterestedServicePer(interstedServicePercentage===Infinity ? 0 : interstedServicePercentage)

        serviceAppntmentInterstedPercetage=data?.interestedServiveAppointment / data?.serv_apptment;
        serviceAppntmentInterstedPercetage=serviceAppntmentInterstedPercetage*100;
        setServiceAppointmentInterstedPercentage(serviceAppntmentInterstedPercetage===Infinity ? 0 : serviceAppntmentInterstedPercetage)

        //calculate RO's messaged
        let totalRosmsged=data?.bot_msg_sent+data?.serv_apptment;
        let botMsgsentPercentage;
        // let botServProtPercentage;
        let botServAppointmentPercentage;

        botMsgsentPercentage=data?.bot_msg_sent / data?.uniq_ro_processed;
        botMsgsentPercentage=botMsgsentPercentage*100;
        setBotmsgSentPerctage(botMsgsentPercentage===Infinity ? 0 : botMsgsentPercentage)

        // botServProtPercentage=data?.bot_serv_prot / totalRosmsged;
        // botServProtPercentage=botServProtPercentage*100;
        // setBotServiProtectionPerctage(botServProtPercentage===Infinity ? 0 : botServProtPercentage)

        botServAppointmentPercentage=data?.serv_apptment / data?.bot_serv_appontmnt_processed;
        botServAppointmentPercentage=botServAppointmentPercentage*100;
        setBotServiceAppointmentPerctage(botServAppointmentPercentage===Infinity ? 0 : botServAppointmentPercentage)

        //calculate Ro processed %
        let totalRoProccesd=data?.uniq_ro_processed + data?.bot_serv_appontmnt_processed;
        let uniq_ro_proc_percentage



        if (data) {
            console.log("data", data);
            
            setmyData({
                labels: ['RO’s processed', 'RO’s messaged', 'Leads generated'],
                datasets: [
                    {
                        // label:,
                        data: [data?.uniq_ro_processed + data?.bot_serv_appontmnt_processed, data?.bot_msg_sent+data?.serv_apptment, data?.leadGenerated+data?.serviceInterested+data?.interestedServiveAppointment],
                        backgroundColor: ['#30346b', 'rgb(236, 107, 86)', 'rgb(59 173 48)'],
                        barThickness: 78,
                    },
                ],
                type: 'bar'
            })

        }
    }, [data, displayData, pageNumber, filter, scrollMax, scrollMin])

    return loading ? <Loader /> : (
        <Grid container style={{ width: '500px' }} className="container row w-100 d-flex justify-content-center m-auto bg-light shadow-sm py-3" >
            <h2 className='font-weight-bold py-0 my-2'>Summary</h2>
            <Grid item lg={6} className='spacing-1'>
                <table className="table">
                    <thead className="thead-dark bg-dark text-white">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Total</th>
                            <th scope="col">Percentage</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>RO's Processed</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <li>Unique RO's processed</li>
                            <td>{data?.uniq_ro_processed}</td>
                            {/* <td>{(data?.uniq_ro_processed/(data?.uniq_ro_processed + data?.bot_serv_appontmnt_processed)*100).toFixed(2)}%</td> */}
                            <td>{" "}</td>
                        </tr>
                        <tr>
                            <li>Bot Service Appointment Processed</li>
                            <td>{data?.bot_serv_appontmnt_processed}</td>
                            {/* <td>{(data?.bot_serv_appontmnt_processed/(data?.uniq_ro_processed + data?.bot_serv_appontmnt_processed)*100).toFixed(2)}%</td> */}
                            <td>{" "}</td>
                        </tr>


                        {/* <tr>
                 <td>RO's Processed</td>
                 <td>{data?.roProcessed}</td>
                 <td>-</td>
             </tr> */}

                        {/* <tr>
                 <td>RO’s Messaged</td>
                 <td>{data?.roMessaged}</td>
                 <td>-</td>
             </tr> */}
                        <td>RO’s Messaged</td>
                        <tr>
                            <li>BoT Message Sent</li>
                            <td>{data?.bot_msg_sent}</td>
                            {/* <td>{(data?.bot_msg_sent / (data?.uniq_ro_processed) * 100).toFixed(2)}%</td> */}
                            <td>{botmsgSentPerctage ? botmsgSentPerctage.toFixed(2): 0}%</td>
                        </tr>
                        {/* <tr>
                 <li>BoT Service Protection Sent</li>
                 <td>{data?.bot_serv_prot}</td>
                 <td>{botServiProtectionPerctage ? botServiProtectionPerctage.toFixed(2): 0}%</td>
             </tr> */}
                        <tr>
                            <li>BoT Service Appointment Sent</li>
                            <td>{data?.serv_apptment}</td>
                            {/* <td>{data?.bot_serv_appontmnt_processed !== 0 ? (data?.serv_apptment / (data?.bot_serv_appontmnt_processed) * 100).toFixed(2):0}%</td> */}
                            <td>{botServiceAppointmentPerctage ? botServiceAppointmentPerctage.toFixed(2): 0}%</td>
                        </tr>

                        <td>Leads Generated</td>

                        <tr>
                            {/* <td>Leads generated</td> */}
                            <li>Interested Value</li>
                            <td>{data?.leadGenerated}</td>
                            {/* <td>{(data?.leadGenerated / (data?.bot_msg_sent) * 100).toFixed(2)}%</td> */}
                            <td>{leadGenPer ? leadGenPer.toFixed(2) : 0}%</td>
                        </tr>
                        <tr>
                            <li>Interested Service Protection</li>
                            <td>{data?.serviceInterested}</td>
                            <td>{interestedServicePer ? interestedServicePer.toFixed(2) : 0}%</td>
                        </tr>
                        <tr>
                            <li>Interested Service Appointment</li>
                            <td>{data?.interestedServiveAppointment}</td>
                            <td>{serviceAppointmentInterstedPercentage ? serviceAppointmentInterstedPercentage.toFixed(2) : 0}%</td>
                            {/* <td>{(data?.interestedServiveAppointment / (data?.serv_apptment) * 100).toFixed(2)}%</td> */}
                        </tr>
                    </tbody>
                </table>
            </Grid>

            <Grid item lg={6} className='spacing-1 d-flex m-auto justify-content-center mt-0 pt-0'>
                {!myData?.datasets[0]?.data[0] && !myData?.datasets[0]?.data[1] && !loading ? (<div><h3>No Data Found</h3></div>) :
                    (<div style={{ height: '300px', width: '500px' }}>
                        {/* {myData && <Pie data={myData} />} */}
                        <Bar
                            data={myData}

                            options={{
                                indexAxis: 'y',
                                responsive: true, maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false
                                    }
                                },
                                scales: {
                                    xAxes: {
                                        grid: {
                                            display: true
                                        },

                                        // ticks: { autoSkip: false }, min: scrollMin, max: scrollMax
                                    },
                                    yAxes: {
                                        grid: {

                                            display: false
                                        },
                                    }
                                }
                            }} />
                    </div>)}
            </Grid>
        </Grid>
    )
}

export default Report