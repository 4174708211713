import React, { useContext, useState } from "react";
import downarrowoutline from "../../assets/icons/sortarrowoutlinedown.svg";
import uparrowoutline from "../../assets/icons/sortarrowoutlineup.svg";
import downarrowfill from "../../assets/icons/sortarrowfilleddown.svg";
import uparrowfill from "../../assets/icons/sortarrowfilledup.svg";
import activity from "../../assets/icons/activity.svg";
import { leadStage } from "../common/globalValues";
import { UserContext } from "../common/UserContext";
import "./Leads.css";
import { Link, useHistory } from "react-router-dom";
import { accessRoles } from "../../controller";
import moment from "moment";
import _ from "lodash";
function Leadtable({
  data,
  CRDsort,
  LCDsort,
  page,
  MSDSort,
  headerList,
  setMSDSort,
  setCRDSort,
  setLPDsort,
  LPDsort,
  setLCDsort,
  QualificationStatus,
  leadStatus,
}) {
  const [sortType, setSortType] = useState<any>(
    localStorage.getItem("CRM_SORT") || "ASC"
  );
  //   const [col, setCol] = useState<any>("");
  const [col, setCol] = useState<any>(
    localStorage.getItem("CRM_TABLE_COLUMN") || ""
  );
  const { type, role, authToken } = useContext(UserContext);
  const history = useHistory();
  const handleSort = (field) => {
    let srt = sortType === "ASC" ? "DSC" : "ASC";
    setSortType(srt);
    if (field === "custrespdate") {
      setCRDSort(srt);
      setLCDsort(null);
      setMSDSort(null);
      setLPDsort(null);

      //   localStorage.setItem("CRDSORT", srt);
      //   localStorage.removeItem("LCDSORT");
      //   localStorage.removeItem("MSDSORT");
      //   localStorage.removeItem("LPDSORT");
      setColumn(field);
      setSort(srt);
    } else if (field === "lastcontacteddate") {
      setLCDsort(srt);
      setMSDSort(null);
      setCRDSort(null);
      setLPDsort(null);

      //   localStorage.removeItem("CRDSORT");
      //   localStorage.setItem("LCDSORT", srt);
      //   localStorage.removeItem("MSDSORT");
      //   localStorage.removeItem("LPDSORT");
      setColumn(field);
      setSort(srt);
    } else if (field === "msgsentdate") {
      setMSDSort(srt);
      setCRDSort(null);
      setLCDsort(null);
      setLPDsort(null);

      //   localStorage.removeItem("CRDSORT");
      //   localStorage.removeItem("LCDSORT");
      //   localStorage.setItem("MSDSORT", srt);
      //   localStorage.removeItem("LPDSORT");
      setColumn(field);
      setSort(srt);
    } else if (field === "leadprocessed") {
      setLPDsort(srt);
      setMSDSort(null);
      setCRDSort(null);
      setLCDsort(null);

      //   localStorage.removeItem("CRDSORT");
      //   localStorage.removeItem("LCDSORT");
      //   localStorage.removeItem("MSDSORT");
      //   localStorage.setItem("LPDSORT", srt);
      setColumn(field);
      setSort(srt);
    }
    setCol(field);
  };

  const setColumn = (newColumn) => {
    localStorage.removeItem("CRM_TABLE_COLUMN");
    localStorage.setItem("CRM_TABLE_COLUMN", newColumn);
  };

  const setSort = (newValue) => {
    localStorage.removeItem("CRM_SORT");
    localStorage.setItem("CRM_SORT", newValue);
  };
  console.log("cooool", col);

  

  return (
    <div className="w-100">
      <table className="table table-hover table-bordered" id="myTable">
        <thead className="table-head shadow-sm" style={{ fontSize: "14px" }}>
          <tr>
            <th scope="col" className="p-3 table-center-ele">
              Count
            </th>
            <th
              scope="col"
              className="p-3 table-center-ele "
              onClick={() => {
                handleSort("Name");
              }}
            >
              <div className="d-flex justify-content-between">
                <span className="mt-auto mb-auto">Name</span>
                {/* <div className='d-flex flex-column'>
                                    <img src={sortType === "ASC" && col === "Name" ? uparrowfill : uparrowoutline} alt="" />
                                    <img src={sortType === "DSC" && col === "Name" ? downarrowfill : downarrowoutline} alt="" />
                                </div> */}
              </div>
            </th>
            <th
              scope="col"
              className="p-3 table-center-ele "
              onClick={() => {
                handleSort("Name");
              }}
            >
              <div className="d-flex justify-content-between">
                <span className="mt-auto mb-auto">Dealership</span>
                {/* <div className='d-flex flex-column'>
                                    <img src={sortType === "ASC" && col === "Dealership" ? uparrowfill : uparrowoutline} alt="" />
                                    <img src={sortType === "DSC" && col === "Dealership" ? downarrowfill : downarrowoutline} alt="" />
                                </div> */}
              </div>
            </th>
            {headerList?.includes("Email") && (
              <th
                scope="col"
                className="p-3 table-center-ele "
                onClick={() => {
                  handleSort("Name");
                }}
              >
                <div className="d-flex justify-content-between">
                  <span className="mt-auto mb-auto">Email</span>
                  {/* <div className='d-flex flex-column'>
                                    <img src={sortType === "ASC" && col === "Email" ? uparrowfill : uparrowoutline} alt="" />
                                    <img src={sortType === "DSC" && col === "Email" ? downarrowfill : downarrowoutline} alt="" />
                                </div> */}
                </div>
              </th>
            )}
            {headerList?.includes("Mobile") && (
              <th scope="col" className="p-3 table-center-ele">
                Mobile
              </th>
            )}
            {headerList?.includes("Status") && (
              <th scope="col" className="p-3 table-center-ele">
                Status
              </th>
            )}
            {/* {headerList?.includes("Owner") &&<th scope="col" className='p-3 table-center-ele'>Owner</th>} */}
            {headerList?.includes("Last contacted date") && (
              <th
                scope="col"
                className="p-3 table-center-ele "
                onClick={() => {
                  handleSort("lastcontacteddate");
                }}
              >
                <div className="d-flex justify-content-between">
                  <span className="mt-auto mb-auto">Last contacted date</span>
                  <div className="d-flex flex-column">
                    <img
                      src={
                        sortType === "ASC" && col === "lastcontacteddate"
                          ? uparrowfill
                          : uparrowoutline
                      }
                      alt=""
                    />
                    <img
                      src={
                        sortType === "DSC" && col === "lastcontacteddate"
                          ? downarrowfill
                          : downarrowoutline
                      }
                      alt=""
                    />
                  </div>
                </div>
              </th>
            )}
            {headerList?.includes("Message sent date") && (
              <th
                scope="col"
                className="p-3 table-center-ele "
                onClick={() => {
                  handleSort("msgsentdate");
                }}
              >
                <div className="d-flex justify-content-between">
                  <span className="mt-auto mb-auto">Message sent date</span>
                  <div className="d-flex flex-column">
                    <img
                      src={
                        sortType === "ASC" && col === "msgsentdate"
                          ? uparrowfill
                          : uparrowoutline
                      }
                      alt=""
                    />
                    <img
                      src={
                        sortType === "DSC" && col === "msgsentdate"
                          ? downarrowfill
                          : downarrowoutline
                      }
                      alt=""
                    />
                  </div>
                </div>
              </th>
            )}
            {headerList?.includes("Customer response date") && (
              <th
                scope="col"
                className="p-3 table-center-ele "
                onClick={() => {
                  handleSort("custrespdate");
                }}
              >
                <div className="d-flex justify-content-between">
                  <span className="mt-auto mb-auto">
                    Customer response date
                  </span>
                  <div className="d-flex flex-column">
                    <img
                      src={
                        sortType === "ASC" && col === "custrespdate"
                          ? uparrowfill
                          : uparrowoutline
                      }
                      alt=""
                    />
                    <img
                      src={
                        sortType === "DSC" && col === "custrespdate"
                          ? downarrowfill
                          : downarrowoutline
                      }
                      alt=""
                    />
                  </div>
                </div>
              </th>
            )}
            {headerList?.includes("Lead processed date") && (
              <th
                scope="col"
                className="p-3 table-center-ele "
                onClick={() => {
                  handleSort("leadprocessed");
                }}
              >
                <div className="d-flex justify-content-between">
                  <span className="mt-auto mb-auto">Lead processed date</span>
                  <div className="d-flex flex-column">
                    <img
                      src={
                        sortType === "ASC" && col === "leadprocessed"
                          ? uparrowfill
                          : uparrowoutline
                      }
                      alt=""
                    />
                    <img
                      src={
                        sortType === "DSC" && col === "leadprocessed"
                          ? downarrowfill
                          : downarrowoutline
                      }
                      alt=""
                    />
                  </div>
                </div>
              </th>
            )}
          </tr>
        </thead>
        {
          <tbody
            className="table-body"
            style={{ fontWeight: "normal", fontSize: "14px" }}
          >
            {data?.map((obj: any, index: any) => (
                <tr  className='table-row' onClick={()=>{
                    window.open(`/leads/${obj._id}`, '_blank');
                }}>
              {/* <Link to={`/leads/${obj._id}`} target="_blank"> */}
                
                  <td>{index + 1 + page * 10 - 10}</td>

                  <td>
                    {obj.customer_details.first_name}{" "}
                    {obj.customer_details.last_name}
                  </td>
                  <td>{obj?.dealer_name}</td>
                  {headerList?.includes("Email") && (
                    <td>
                      {!obj.customer_details.email
                        ? "N/A"
                        : obj.customer_details.email}
                    </td>
                  )}
                  {headerList?.includes("Mobile") && (
                    <td>
                      {!obj.customer_details.mobile_number
                        ? "N/A"
                        : obj.customer_details.mobile_number}
                    </td>
                  )}
                  {/* {headerList?.includes("Lead Stage") && <td>{obj.status}</td>} */}
                  {headerList?.includes("Status") && (
                    <td>{leadStage[obj.status]}</td>
                  )}
                  {/* {headerList?.includes("Owner") && <td>{!obj.owner_name ? "N/A" : obj?.owner_name}</td>} */}
                  {headerList?.includes("Last contacted date") &&
                    accessRoles
                      ?.filter(
                        (obj: any) =>
                          _.toLower(obj.role) === _.toLower(type + role)
                      )[0]
                      ?.permissions?.includes(
                        "allowSeeLastContactedDatetime"
                      ) && (
                      <td>
                        {!obj?.lastContactedTime
                          ? "N/A"
                          : moment(obj.lastContactedTime).format("LLL") +
                            ("(" + obj?.timezone + ")")}
                      </td>
                    )}
                  {headerList?.includes("Message sent date") &&
                    accessRoles
                      ?.filter(
                        (obj: any) =>
                          _.toLower(obj.role) === _.toLower(type + role)
                      )[0]
                      ?.permissions?.includes("allowSeeMesgSentDate") && (
                      <td>
                        {!obj?.message_sent_at
                          ? "N/A"
                          : moment(obj.message_sent_at).format("LLL") +
                            ("(" + obj?.timezone + ")")}
                      </td>
                    )}
                  {headerList?.includes("Customer response date") &&
                    accessRoles
                      ?.filter(
                        (obj: any) =>
                          _.toLower(obj.role) === _.toLower(type + role)
                      )[0]
                      ?.permissions?.includes(
                        "allowSeeCustomerResponseDate"
                      ) && (
                      <td>
                        {!obj?.lead_created_at
                          ? "N/A"
                          : moment(obj.lead_created_at).format("LLL") +
                            ("(" + obj?.timezone + ")")}
                      </td>
                    )}
                  {headerList?.includes("Lead processed date") &&
                    accessRoles
                      ?.filter(
                        (obj: any) =>
                          _.toLower(obj.role) === _.toLower(type + role)
                      )[0]
                      ?.permissions?.includes("allowSeeLeadProcessedDate") && (
                      <td>
                        {!obj?.lead_processed_time
                          ? "N/A"
                          : moment(
                              obj?.lead_processed_time
                                ?.split(" ")
                                .slice(0, 2)
                                .join(" ")
                            ).format("LLL") +
                            ("(" + obj?.timezone + ")")}{" "}
                      </td>
                    )}
             
              {/* </Link> */}
              </tr>
            ))}
          </tbody>
        }
      </table>
    </div>
  );
}

export default Leadtable;
