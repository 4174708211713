// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, Suspense, cach } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  Router,
  BrowserRouter,
  HashRouter,
  useHistory,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Loader from "./components/common/Loader";
import "./App.css";
import Login from "./components/Login/Login";
import OutlookLogin from "./components/Mails/Outlookogin";
// import UserList from "./components/User/userList";
// import CreateUser from './components/User/Usercreate';
import Usercreate from "./components/User/Usercreate";
import WithoutBars from "./components/common/withoutbar";
import DealerList from "./components/Dealer/dealerList";
import _ from "lodash";
import { UserContext } from "./components/common/UserContext";
import WithBars from "./components/common/withbar";
import { selfUser } from "./controllers/users";
import sidebarItems from "./controllers/SidebarElements";
import Leads from "./components/Leads/Leads";
import LeadDetails from "./components/Leads/LeadDetails";
import { ToastContainer } from "react-toastify";
// import { toastify } from './notification';
// import Dashboard from './components/Dashboard';
import CreateDealer from "./components/Dealer/createDealer";
// import Newleads from './components/Leads/Newleads'
import Dashboard from "./components/Nueron-Dashboard/index";
import CreateCorporate from "./components/Dealer/createCorporate";
import EditProfile from "./components/common/EditProfile";
import { accessRoles } from "./controller";
import ResetPassword from "./components/Login/ResetPassword";
import ForgotPassword from "./components/Login/ForgotPassword";
import ProfileUpdate from "./components/common/ProfileUpdate";
import moment from "moment";
import Misc from "./components/Misc";
import Call from "./Call";
import { MsalProvider } from "@azure/msal-react";
import Bothealthmonitor from "./components/BoT-healthMonitor/Bothealthmonitor";
// import Call2 from './components/Leads/Call/Call';
// export type ProtectedRouteProps = {
//     isAuthenticated: boolean;
// } & RouteProps;
import ProvideAppContext from "./AppContext";
import Inbox from "./components/Mails/View/Inbox";
import Template from "./components/Template/Template";
import Createtemplate from "./components/Template/Createtemplate";
import Mergefield from "./components/Template/Mergefield/Mergefield";
import Flow from "./components/Email-automation/Flow";
import Smsnotification from "./components/Notification/SMS/Smsnotification";
import Notification from "./components/Notification/Notificationt.tsx";
// import { notificationcount } from "./controllers/notificationcount";
import Calllogs from "./components/Call-logs/Calllogs";
import Users from "./components/Users-reporting/Users";
import Details from "./components/Users-reporting/Details";
import Greetings from "./components/common/Greetings";
import Dailer from "./components/common/Dailer";
import Mycalendar from "./components/common/Mycalendar";
import axios from "axios";
import config from './config.json'
import { Modal } from "react-bootstrap";
import Createemailtemplate from "./components/Template/Createemailtemplate";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
function ProtectedRoute({ isAuthenticated, ...routeProps }) {
  if (isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: "/login" }} />;
  }
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App({ pca }) {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      console.debug = () => { };
      console.info = () => { };
      console.warn = () => { };
    }
  }, []);
  const history = useHistory();
  const [user, setUser] = useState({
    id: "",
    email: "",
    name: "",
    role: "",
    authToken: "",
    soldvalue: "0",
    fromDate: "",
    toDate: "",
    user_first_name: "",
    user_last_name: "",
    phone: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    imageUrl: "",
    workNumber: "",
    type: "",
    communicationAddress: "",
    company: "",
    dealerID: "",
    corporateID: "",
    subscriptionFee: "",
    sort: null,
    CRDsort: null,
    LCDsort: null,
    loading: false,
    timezone: "",
    shortName: "",
    aliasName: "",
  });
  // const [notificationount, setNotificationcount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [route, setRoute] = useState("login");
  const token = localStorage.getItem("$AuthToken");
  const [todayTasks, setTodayTasks] = useState(null)
  const [showTaskModal, setshowTaskModal] = useState(false)


  console.log("showTaskModal", showTaskModal);
  useEffect(() => {
    if (token) {
      selfUser(token)
        .then((data) => {
          if (!data) {
            localStorage.removeItem("$AuthToken");
          } else {
            console.log("refresh token", data);
            setUser({
              id: data.data._id,
              email: data.data.email,
              name: data.data.first_name + " " + data.data.last_name,
              user_first_name: data.data.first_name,
              user_last_name: data.data.last_name,
              role: data.data.role,
              authToken: token,
              soldvalue: "0",
              fromDate: "",
              toDate: "",
              type: data.data.type,
              phone: data.data.contactdetails.cell_number,
              linkedin: data.data.linkedin,
              facebook: data.data.facebook,
              instagram: data.data.instagram,
              twitter: data.data.twitter,
              tiktok: data.data.tiktok,
              imageUrl: data.data.imageUrl,
              workNumber: data.data.contactdetails.work_number,
              communicationAddress:
                data.data.contactdetails.communication_address,
              company: data.data.company,
              dealerID: data.data.dealerID,
              corporateID: data.data.corporateID,
              subscriptionFee: data?.data?.subscriptionFee,
              loading: false,
              sort: null,
              CRDsort: null,
              LCDsort: null,
              timezone: data.data.timezone,
              shortName: data.data.shortName,
              aliasName: data.data.aliasName,
            });

            const firstElement = sidebarItems.find((item) =>
              item.users?.includes(data.role)
            );

            setRoute(firstElement?.route || "/login");
          }
          setTimeout(() => setLoading(false), 100);
        })
        .catch((error) => {
          setLoading(false);
          localStorage.removeItem("$AuthToken");
        });
    } else {
      setLoading(false);
    }
  }, [token]);

  const isAuthenticate = () => {
    let path = window.location.hash.split("/")[1];
    return user.email !== "";
  };

  // useEffect(() => {
  //   if (user.id) {
  //     const payload = {
  //       type: "fetch",
  //       createdByid: user.id,
  //     };
  //     const fetchEvents = async () => {
  //       setLoading(true);
  //       axios
  //         .post(config.baseHost_backend + "/task-management", payload, {
  //           headers: {
  //             Authorization: token,
  //           },
  //         })
  //         .then((resp) => {
  //           console.log("Events", resp);

  //           let todayTasks = resp?.data?.body[0]?.task?.filter((s) => {
  //             return (new Date(s.start).getDate() === new Date().getDate()) && s.status !== "Completed"
  //           })
  //           console.log("todayTasks", todayTasks);
  //           setTodayTasks(todayTasks);
  //           todayTasks?.length && setshowTaskModal(true)


  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           console.log("error", err);
  //         });
  //     };
  //     localStorage.getItem("TASK_MODAL_TODAY") != "NO" && fetchEvents();
  //   }

  // }, [token, user]);

  // useEffect(() => {
  //   if (token) {
  //     const fetchount = async () => {
  //       let resp = await notificationcount(token);
  //       console.log("resp", resp);
  //       setNotificationcount(resp?.body);
  //     };
  //     accessRoles
  //       ?.filter(
  //         (obj) => _.toLower(obj.role) === _.toLower(user.type + user.role)
  //       )[0]
  //       ?.permissions?.includes("allowNotifications") && fetchount();
  //   }
  // }, [token, user]);

  if (loading) {
    return <Loader />;
  }
  const handleNavigate = () => {
    window.location.href = "/mycalendar"
  }

  return (
    <MsalProvider instance={pca}>

      <ProvideAppContext>
        <Suspense fallback={Loader}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Modal show={showTaskModal}>
                <Modal.Header className="p-3">
                  <Modal.Title>Today tasks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    {todayTasks && todayTasks?.map((tt) => (
                      <div className="card p-2">
                        <h3>Title: {tt?.title}</h3>
                        <h3>Customer: {tt?.leadName}</h3>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="sbuttonbgcolor btn btn-sm" onClick={() => { setshowTaskModal(false) }}>Close</button>
                  <button className="sbuttonbgcolor btn btn-sm" onClick={() => { setshowTaskModal(false); localStorage.setItem("TASK_MODAL_TODAY", "NO") }}>Don't show again</button>
                  <button className="pbuttonbgcolor btn btn-sm" onClick={handleNavigate}>View task(s)</button>
                </Modal.Footer>
              </Modal>
              <UserContext.Provider
                value={{
                  id: user.id,
                  name: user.name,
                  role: user.role,
                  email: user.email,
                  authToken: user.authToken,
                  soldvalue: user.soldvalue,
                  fromDate: user.fromDate,
                  toDate: user.toDate,
                  user_first_name: user.user_first_name,
                  user_last_name: user.user_last_name,
                  phone: user.phone,
                  linkedin: user.linkedin,
                  facebook: user.facebook,
                  instagram: user.instagram,
                  twitter: user.twitter,
                  tiktok: user.tiktok,
                  imageUrl: user.imageUrl,
                  workNumber: user.workNumber,
                  type: user.type,
                  company: user.company,
                  dealerID: user.dealerID,
                  corporateID: user.corporateID,
                  communicationAddress: user.communicationAddress,
                  setUser: setUser,
                  subscriptionFee: user?.subscriptionFee,
                  sort: user.sort,
                  CRDsort: user.CRDsort,
                  LCDsort: user.LCDsort,
                  loading: user.loading,
                  timezone: user.timezone,
                  shortName: user.shortName,
                  aliasName: user.aliasName,
                  // unReadMessagesCount: notificationount,
                }}
              >
                <div className="flex container-div ">
                  <Switch>
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/leads"
                      render={() => (
                        <WithBars render={() => <Leads setUser={setUser} />} />
                      )}
                      // render={() => <WithBars render={() => <Newleads setUser={setUser} />  } />}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/dashboard"
                      // render={() => <WithBars render={() => <Dashboard />} />}
                      render={() => (
                        <WithBars
                          render={() => <Dashboard setUser={setUser} />}
                        />
                      )}
                      exact
                    />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/leads/:id"
                      render={() => (
                        <WithBars
                          render={() => (
                            <React.Suspense fallback={<Loader />}>
                              <LeadDetails
                                // setNotificationcount={setNotificationcount}
                              />
                            </React.Suspense>
                          )}
                        />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/dealers"
                      render={() => <WithBars render={() => <DealerList />} />}
                      exact
                    />
                    <Route
                      exact
                      path="/update-profile/:id"
                      // component={ProfileUpdate}
                      render={() => (
                        <WithBars
                          render={() => <ProfileUpdate setUser={setUser} />}
                        />
                      )}
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/edit-profile"
                      render={() => (
                        <WithBars
                          render={() => <EditProfile setUser={setUser} />}
                        />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/dealers/edit/:id"
                      render={() => (
                        <WithBars render={() => <CreateDealer />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/dealers/create"
                      render={() => (
                        <WithBars render={() => <CreateDealer />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/corporate/create"
                      render={() => (
                        <WithBars render={() => <CreateCorporate />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/corporate/edit/:id"
                      render={() => (
                        <WithBars render={() => <CreateCorporate />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/bot-health-monitor"
                      render={() => (
                        <WithBars render={() => <Bothealthmonitor />} />
                      )}
                      exact
                    />

                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/mails"
                      render={() => (
                        <WithBars
                          render={() => (
                            <OutlookLogin
                              email={null}
                              dealerDetails={null}
                              leadDetails={null}
                            />
                          )}
                        />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/template"
                      render={() => <WithBars render={() => <Template />} />}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/email-automation"
                      render={() => <WithBars render={() => <Flow />} />}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/template/sms/create"
                      render={() => (
                        <WithBars render={() => <Createtemplate />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/template/email/create"
                      render={() => (
                        <WithBars render={() => <Createemailtemplate />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/merge-field/create"
                      render={() => <WithBars render={() => <Mergefield />} />}
                      exact
                    />

                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/view/:type/:id/:dealerid"
                      render={() => <WithBars render={() => <Inbox />} />}
                      exact
                    />

                    <Route
                      // isAuthenticated={isAuthenticate()}
                      exact
                      path="/reset-password/:id"
                      // component={ResetPassword}
                      render={() => (
                        <WithoutBars render={() => <ResetPassword />} />
                      )}
                    />
                    <Route
                      // isAuthenticated={isAuthenticate()}
                      exact
                      path="/email-interested/:id"
                      // component={ResetPassword}
                      render={() => (
                        <WithoutBars render={() => <Greetings />} />
                      )}
                    />
                    <Route
                      // isAuthenticated={isAuthenticate()}
                      exact
                      path="/service-interested/:id"
                      // component={ResetPassword}
                      render={() => (
                        <WithoutBars render={() => <Greetings />} />
                      )}
                    />

                    <Route
                      exact
                      path="/neuron-dialer/:id"
                      render={() => (
                        <WithoutBars render={() => <Dailer />} />
                      )}
                    />

                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/users/create"
                      render={() => <WithBars render={() => <Usercreate />} />}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/mycalendar"
                      render={() => <WithBars render={() => <Mycalendar />} />}
                      exact
                    />

                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/users/edit/:id"
                      render={() => <WithBars render={() => <Usercreate />} />}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/users"
                      render={() => <WithBars render={() => <Users />} />}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/users/reporting/:id"
                      render={() => <WithBars render={() => <Details />} />}
                      exact
                    />
                    {/* <ProtectedRoute
                                            isAuthenticated={isAuthenticate()}
                                            path='/notifications'
                                            render={() => <WithBars render={() => <Notification />} />}
                                            exact
                                        /> */}
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/notifications/:type"
                      render={() => (
                        <WithBars render={() => <Notification />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticate()}
                      path="/calllogs"
                      render={() => <WithBars render={() => <Calllogs />} />}
                      exact
                    />
                    {/* <ProtectedRoute
                                            isAuthenticated={isAuthenticate()}
                                            path='/notifications/tab/:type'
                                            render={() => <WithBars render={() => <Notification />} />}
                                            exact
                                        /> */}
                    {/* <Route
                                // isAuthenticated={isAuthenticate()}
                                path='/misc'
                                // render={() => <WithBars render={() => <Misc />} />}
                                // render={() =>  <Call2 />
                               component={ Call2}
                            
                                exact
                            /> */}
                    <Route
                      path={"/login"}
                      render={() => (
                        <WithoutBars
                          render={() => <Login setUser={setUser} />}
                        />
                      )}
                      exact
                    />
                    <Route
                      render={() => (
                        <WithoutBars
                          render={() => <Login setUser={setUser} />}
                        />
                      )}
                      exact
                    />

                    {isAuthenticate() ? (
                      <Redirect to={`/${route}`} />
                    ) : (
                      <Redirect to={"/login"} />
                    )}
                  </Switch>
                </div>
              </UserContext.Provider>
            </BrowserRouter>
            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {/* <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} /> */}
            {/* </MsalProvider> */}
          </QueryClientProvider>

        </Suspense>
      </ProvideAppContext>

    </MsalProvider>
  );
}

export default App;
