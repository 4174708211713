import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./style.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useAppContext } from "../../AppContext";
import Maillist from "./Maillist";
import inboxPng from "../../assets/images/inbox2.png";
import Reusabletabs from "../common/Reusabletabs";
function Outlookogin({ email, dealerDetails, leadDetails, lead, notes,handleNotesSearch }) {
  const app = useAppContext();

  const MailApp = ({
    app,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    email,
    dealerDetails,
    leadDetails,
    inboxPng,
  }) => {
    return (
      <div className="ml-auto mr-auto">
        <AuthenticatedTemplate>
          <div className="d-flex justify-content-between">
            {/* <h3 className='font-weight-bold mt-auto mb-auto'>Welcome {app.user?.displayName || ''}!</h3>
             <button className='btn btn-link text-danger' onClick={app.signOut!}>sign out <img src={arrowright} alt="arrow" /></button> */}
          </div>
          <Maillist
            email={email}
            dealerDetails={dealerDetails}
            leadDetails={leadDetails}
          />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className="outlook-login-cont">
            <img src={inboxPng} alt="Inbox logo" height={250} width={250} />
            <button className="btn btn-sm pbuttonbgcolor" onClick={app.signIn!}>
              Click here to sign in
            </button>
          </div>
        </UnauthenticatedTemplate>
      </div>
    );
  };
  return (
    <>
      <Reusabletabs
      handleNotesSearch={handleNotesSearch}
        notes={notes}
        label1={"Mail"}
        label2={"Notes"}
        filterValue={"email"}
        component={MailApp}
        componentProps={{
          app,
          AuthenticatedTemplate,
          UnauthenticatedTemplate,
          email,
          dealerDetails,
          leadDetails,
          inboxPng,
          lead,
        }}
      />
    </>
  );
}

export default Outlookogin;
