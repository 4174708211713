import axios from 'axios';
import { toastify } from '../components/common/notification';
import config from '../config.json';

const url = config['baseHost_backend'];

export const dealerListApi = (token: any, body) =>
    axios
        .post(`${url}/fetch-all-dealer`,body, {
            headers: {
                Authorization: token
            },
        })
        .then((res) => res.data)
        .catch((error) =>
            toastify(
                'failure',
                error.response.data.message.length > 0
                    ? error.response.data.message
                    : 'Something went wrong'
            )
        );

// export const dealerRepCorpID = (token: any) =>
//     axios
//         .get(`${url}/fetch-all-dealer`, {
//             headers: {
//                 Authorization: token
//             },
//         })
//         .then((res) => res.data)
//         .catch((error) =>
//             toastify(
//                 'failure',
//                 error.response.data.message.length > 0
//                     ? error.response.data.message
//                     : 'Something went wrong'
//             )
//         );

export const corporateListApi = (token: any,body:any) =>
    axios
        .post(`${url}/corporates`,body, {
            headers: {
                Authorization: token
            },
        })
        .then((res) => res.data)
        .catch((error) =>
            toastify(
                'failure',
                error.response.data.message.length > 0
                    ? error.response.data.message
                    : 'Something went wrong'
            )
        );

export const createDealerApi = (token: string, data: Object) =>
    axios
        .post(`${url}/create-dealer`, data, {
            headers: {
                Authorization: token
            },
        })
        .then((res) => res.data)
        .catch((error) =>
            toastify(
                'failure',
                error.response.data.message.length > 0
                    ? error.response.data.message
                    : 'Something went wrong'
            )
        );

export const createCorporateApi = (token: string, data: Object) =>
    axios
        .post(`${url}/create-corporate`, data, {
            headers: {
                Authorization: token
            },
        })
        .then((res) => res.data)
        .catch((error) =>
            toastify(
                'failure',
                error.response.data.message.length > 0
                    ? error.response.data.message
                    : 'Something went wrong'
            )
        );
