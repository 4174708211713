import React from "react";
import { Spinner } from "react-bootstrap";
import {ScaleLoader } from "react-spinners";
import RapIcon from '../../assets/icons/rapIcon.png'
import './LoadingBar.css'

const LoadingBar = (props: any) => {
    return (
        <>
            {props.isActive && <div className="load-cont">
                {/* <div className="loader1" >
                </div> */}
                <ScaleLoader color="#30346b"/>
            </div>}
        </>
    )
}

export default LoadingBar;
