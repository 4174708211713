import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

export type VehicleInfo = {
    vehicle_details: {
        year: string;
        vin: string;
        make: string;
        model: string;
        trim: string;
        odometer: string;
        engine: string;
        transmission: string;
        body_type: string;
        exterior_colour: string;
        interior_colour: string;
        carfax_info: any;
        option_info: [string];
        valuation_Info: {
            valuation: string;
            MSRP: string;
        };
    };
};
type Props = {
    vehicle_info: VehicleInfo;
};

export default function VehicleDetails({ vehicle_info }: Props) {

    return (
        <div className='containerBox'>
            <Card className='mx-1 card-sec mt-1'>
                <Card.Header>
                    <Card.Title>
                        <h3 className='pt-1'>Vehicle Details</h3>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {vehicle_info && <div className='vehicle-details'>
                        {vehicle_info?.vehicle_details && (
                            <div className='text-black'>
                                <p>VIN: {vehicle_info?.vehicle_details?.vin}</p>
                                <p>Year: {vehicle_info?.vehicle_details?.year}</p>
                                <p>Make: {vehicle_info?.vehicle_details?.make}</p>
                                <p>Model: {vehicle_info?.vehicle_details?.model}</p>
                                <p>Trim: {vehicle_info?.vehicle_details?.trim}</p>
                                <p>Odometer: {vehicle_info?.vehicle_details?.odometer}</p>
                                <p>Engine: {vehicle_info?.vehicle_details?.engine}</p>
                                <p>Transmission: {vehicle_info?.vehicle_details?.transmission}</p>
                                <p>Body Type: {vehicle_info?.vehicle_details?.body_type}</p>
                                <p>
                                    Exterior Color: {vehicle_info?.vehicle_details?.exterior_colour}
                                </p>
                                <p>
                                    Interior Color: {vehicle_info?.vehicle_details?.interior_colour}
                                </p>
                            
                                <Accordion defaultActiveKey='0'>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header>Options Info</Accordion.Header>
                                        <Accordion.Body>
                                            {Array.isArray(vehicle_info?.vehicle_details?.option_info) ? vehicle_info?.vehicle_details?.option_info?.map(
                                                (option: string, index: number) => {
                                                    return (
                                                        <div key={index}>
                                                            <p>{option}</p>
                                                        </div>
                                                    );
                                                }
                                            ) : vehicle_info?.vehicle_details?.option_info}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey='1'>
                                        <Accordion.Header>Valuation Info</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='d-flex flex-row gap-2'>
                                                <p>Valuation: </p>
                                                {
                                                    vehicle_info?.vehicle_details?.valuation_Info
                                                        ?.valuation
                                                }
                                            </div>
                                            <div className='d-flex flex-row gap-2'>
                                               <p> MSRP: </p>
                                                {vehicle_info?.vehicle_details?.valuation_Info?.MSRP}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        )}
                    </div>}
                </Card.Body>
            </Card>
        </div>
    );
}
