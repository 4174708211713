import React, { useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import config from "../../config.json";
import axios from "axios";
import { UserContext } from "./UserContext";
import LoadingBar from "./LoadingBar";
import { Modal } from "react-bootstrap";
import { taskTypes } from "./globalValues";
import { toastify } from "./notification";
import { useHistory } from "react-router-dom";
const localizer = momentLocalizer(moment);
function Mycalendar() {
  const [eventsData, setEventsData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [modalData, setModalData] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const history = useHistory<any>();
  const [show, setShow] = useState<any>(false);
  const { id } = useContext<any>(UserContext);
  let token: any = localStorage.getItem("$AuthToken");
  // const handleSelect = ({ start, end }) => {
  //   console.log(start);
  //   console.log(end);
  //   const title = window.prompt("New Event name");
  //   if (title)
  //     setEventsData([
  //       ...eventsData,
  //       {
  //         start,
  //         end,
  //         title,
  //       },
  //     ]);
  // };

  useEffect(() => {
    const payload = {
      type: "fetch",
      createdByid: id,
    };
    const fetchEvents = async () => {
      setLoading(true);
      axios
        .post(config.baseHost_backend + "/task-management", payload, {
          headers: {
            Authorization: token,
          },
        })
        .then((resp) => {
          console.log("Events", resp);
          let temp = [] as any;
          resp?.data?.body?.forEach((d) => {
            d?.task?.forEach((h) => {
              let dtStart = new Date(h.start);

              let yr = dtStart.getFullYear();
              let mnth = dtStart.getMonth();
              let dte = dtStart.getDate();
              let hrs = dtStart.getHours();
              let mins = dtStart.getMinutes();

              console.log("formated", yr, mnth, dte, hrs, mins);

              h.start = new Date(yr, mnth, dte, hrs, mins);
              h.end = new Date(yr, mnth, dte, hrs, mins);

              temp.push(h);
            });
          });
          console.log("New events", temp);
          setLoading(false);
          setEventsData(temp);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    };

    fetchEvents();
  }, []);

  const events2 = [
    {
      id: 1,
      title: "Meeting with neuron",
      start: new Date(2023, 10, 20, 10, 30), // year, month (0-indexed), day, hour, minute
      end: new Date(2023, 10, 20, 10, 30),
    },
    {
      id: 2,
      title: "Meeting with neuron",
      start: new Date(2023, 10, 23, 10, 0), // year, month (0-indexed), day, hour, minute
      end: new Date(2023, 10, 23, 10, 0),
    },

    // Add more events as needed
  ];

  const handleUpdateStatus = (e) => {
    e.preventDefault();
    let payload = {
      createdByid: id,
      taskid: modalData?.taskid,
      status: status,
      type: "update",
    };

    console.log("payload", payload);
    axios
      .post(config.baseHost_backend + "/task-management", payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("res", res);
        if (res?.data?.statusCode === 200) {
          toastify("success", "Task updated successfully");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    console.log("event", event);
    var backgroundColor = event?.status === "Completed" ? "#05962b" : "#1655A5";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  return (
    <div className="p-3">
      <LoadingBar isActive={loading} />
      <Calendar
        views={["month", "day", "work_week"]}
        eventPropGetter={eventStyleGetter}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        style={{ height: "100vh" }}
        onSelectEvent={(event) => {
          setModalData(event);
          setShow(true);
          console.log(event);
        }}
        // onSelectSlot={handleSelect}
        showAllEvents={true}
      />
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title className="p-0 m-0">{modalData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between w-100">
            <div>
              <h3>
                <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                {modalData?.leadName}
              </h3>
              <h3>
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                {modalData?.leadEmail || "N/A"}
              </h3>
              <h3>
                <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
                {modalData?.status}
              </h3>
              <h3>
                <span style={{ fontWeight: "bold" }}>Date:</span>{" "}
                {moment(modalData?.start).format("LLL")}
              </h3>
              <h3>
                <span style={{ fontWeight: "bold" }}>Note:</span>{" "}
                {modalData?.note || "N/A"}
              </h3>
              <button className="btn btn-sm tbuttonbgcolor" onClick={()=>{
                history.push(`/leads/${modalData?.leadid}`)
              }}>Open lead details</button>

              {modalData?.status !== "Completed" && (
                <div>
                  {" "}
                  <hr />
                  <span>Update task status</span>
                  <select
                    className="form-select"
                    name=""
                    id=""
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="null">Select status</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
              )}
            </div>
            <div>
              <h2 className="badge bg-primary" style={{ fontSize: "14px" }}>
                {taskTypes[modalData?.tasktype]}
              </h2>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-sm pbuttonbgcolor"
              onClick={() => {
                setShow(false);
              }}
            >
              Close
            </button>
            {status && status != "null" && (
              <button
                className="btn btn-sm pbuttonbgcolor ml-2"
                onClick={handleUpdateStatus}
              >
                Update
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {/* 
      <Modal show>
        <Modal.Body>
          hello
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default Mycalendar;
