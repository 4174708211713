/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { toastify } from "../common/notification";

import {
  Col,
  Row,
  Button,
  Image,
  InputGroup,
  FormControl,
  Form,
  Modal,
} from "react-bootstrap";

import leftArrow from "../../assets/images/leftArrow.svg";
import eyeOpen from "../../assets/images/eyeOpen.svg";
import eyeClose from "../../assets/images/eyeClose.svg";

import { UserContext } from "../common/UserContext";

import { createUserApi } from "../../controllers/users";

import Loader from "../common/Loader";
import ToggleSwitch from "../common/toggleSwitch";
import axios from "axios";
import { roles, types } from "../../controller";
import _ from "lodash";
import { corporateListApi, dealerListApi } from "../../controllers/dealers";
import LoadingBar from "../common/LoadingBar";
import config from "../../config.json";
import AlertModal from "../common/AlertModal";
const Usercreate = () => {
  const [isLoading] = React.useState<Boolean>(false);

  const [first_name, setFirstName] = React.useState<string>("");
  // const [timezone, setTimezone] = React.useState<string>('UTC');
  const [last_name, setLastName] = React.useState<string>("");
  const [cell_number, setCellNumber] = React.useState<string>("");
  const [work_number, setWorkNumber] = React.useState<string>("");
  const [communication_address, setCommunicationAddress] =
    React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [title, setTitle] = React.useState<any>("");
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [role, setRole] = React.useState<any>(null);
  const url = config["baseHost_backend"];
  const [filteredRoles, setFilteredRoles] = React.useState<any>(null);
  const [userType, setuserType] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [flag, setFlag] = useState<boolean>(false);
  const [status, setStatus] = React.useState(true);
  const [companyName, setCompanyName] = useState<any>(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState<any>(null);
  const [shortName, setShortName] = useState<any>(null);
  const [aliasName, setAliasName] = useState<any>(null);
  const [selectedDealerOrCorporateID, setSelectedDealerOrCorporateID] =
    useState<any>(null);
  const { authToken, type, name, corporateID, dealerID, company } =
    useContext(UserContext);
  const [index, setIndex] = useState<any>(null);
  const [filteredDealers, setFilteredDealers] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  // const [timezone, setTimezone] = React.useState<string>('');
  const history = useHistory();
  const [showReset, setShowReset] = useState<any>(false);
  const location: {
    pathname: any;
    state: any;
    search: any;
  } = useLocation();

  const changeRoleEventHandler = (event: any) => {
    setRole(event.target.value);
  };

  useEffect(() => {
    const filterDealers = () => {
      const tempArray = [] as any;
      const url = config["baseHost_backend"] + "/drm-create-user";
      let body = {
        selectType: {
          ID: "",
          type: "corporate",
          dealerID: dealerID,
          corporateID: corporateID,
        },
      };
      axios
        .post(url, body, {
          headers: {
            Authorization: authToken,
          },
        })
        .then((resp) => {
          let temp = [] as any;
          resp?.data?.body?.dealers.forEach((obj) => {
            temp.push(obj?._id);
          });
          console.log("temp", temp);

          setFilteredDealers(
            temp?.sort((a: any, b: any) =>
              a.dealer_name.trim().toLowerCase() >
              b.dealer_name.trim().toLowerCase()
                ? 1
                : -1
            )
          );
          // return resp?.data?.body?.dealers
        })

        .catch((err) => {});
    };
    filterDealers();
  }, []);

  const handleCreate = () => {
    if (!first_name) {
      return toastify("failure", "First Name cannot be empty");
    }
    if (!email) {
      return toastify("failure", "Email cannot be empty");
    }
    if (type !== "dealer" && (!selectedType || selectedType === "default")) {
      return toastify("failure", "Please select the user type");
    }
    if (type !== "dealer" && !selectedCompanyName) {
      return toastify("failure", "Please select the company");
    }
    if (!role || role === "null") {
      return toastify("failure", "Please select the role");
    }

    setLoading(true);
    const options = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      APItype: "creatUser",
      type: type === "dealer" ? "dealer" : selectedType,
      dealerID:
        selectedType === "dealer"
          ? selectedDealerOrCorporateID
          : type === "dealer"
          ? dealerID
          : null,
      corporateID:
        selectedType === "corporate" ? selectedDealerOrCorporateID : null,
      company:
        type === "cognitgo" && selectedType === "cognitgo"
          ? "cognitgo"
          : type === "dealer"
          ? company
          : selectedCompanyName,
      is_active: true,
      role: role,
      title,
      contactdetails: {
        cell_number: cell_number,
        work_number: work_number,
        communication_address: communication_address,
      },
      shortName,
      aliasName,
    };

    console.log("options", options);

    createUserApi(authToken, options).then((res) => {
      if (res.statusCode === 400) {
        setLoading(false);
        toastify("failure", res?.body?.message);
      } else {
        toastify("success", "User created successfully.");
        sendMainforProfileUpdate();
        setLoading(false);
        handleBack();
      }
    });
  };

  const sendMainforProfileUpdate = async () => {
    const originUrl = window.location.origin;
    const url = config["baseHost_backend"] + "/forgot-passowrd";
    axios
      .post(url, { email, type: "creatUser", user_email:email,WEBSITE:originUrl })
      .then((response) => {
        if (
          response?.data?.statusCode === 200 ||
          response?.data?.StatusCode === 200
        ) {
          // setShowReset(true)
        } else {
          toastify("failure", "User does not exist");
        }
      })
      .catch((err) => {});
  };

  const handleUpdate = () => {
    const options = {
      API_TYPE: "usermodule",
      first_name: first_name,
      last_name: last_name,
      email: email,
      title,
      // timezone:timezone,
      id: location.state._id,
      // password: password,
      active_status: status,
      type: selectedType,
      dealerID: selectedType === "dealer" ? selectedDealerOrCorporateID : null,
      corporateID:
        selectedType === "corporate" ? selectedDealerOrCorporateID : null,
      company:
        type === "cognitgo" && selectedType === "cognitgo"
          ? "cognitgo"
          : selectedCompanyName,
      is_active: status,
      role: role,
      contactdetails: {
        cell_number: cell_number,
        work_number: work_number,
        communication_address: communication_address,
      },
      shortName,
      aliasName,
    };

    if (!first_name) {
      return toastify("failure", "First Name cannot be empty");
    }
    if (!email) {
      return toastify("failure", "Email cannot be empty");
    }

    if (!selectedType || selectedType === "default") {
      return toastify("failure", "User Type cannot be empty");
    }
    if (!role || role === "null") {
      return toastify("failure", "Role cannot be empty");
    }
    console.log("options", options);
    setLoading(true);

    const token: any = localStorage.getItem("$AuthToken");
    axios
      .put(`${url}/update-user`, options, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res?.data?.statusCode !== 200) {
          setLoading(false);
          toastify("failure", `${res?.data?.body?.message}`);
        } else {
          setLoading(false);
          handleBack();
          toastify("success", `User Information updated successfully.`);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastify(
          "failure",
          error.response.data.message.length > 0
            ? error.response.data.message
            : "Something went wrong"
        );
      });
  };

  const handleBack = () => {
    history.push({
      pathname: "/users",
      state: {
        dataChanged: true,
      },
    });
  };

  const forgotPassword = () => {
    const url = config["baseHost_backend"] + "/forgot-passowrd";
    setLoading(true);
    const type = "forgotPassword";
    axios
      .post(url, { email, type })
      .then((response) => {
        if (
          response?.data?.statusCode === 200 ||
          response?.data?.StatusCode === 200
        ) {
          setLoading(false);
          setShowReset(true);
        } else {
          setLoading(false);
          toastify("failure", "User does not exist");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    roles?.filter((obj: any) => {
      if (_.toLower(obj.type) === _.toLower(selectedType)) {
        type === "corporate" && selectedType === "dealer"
          ? setFilteredRoles(obj.roles)
          : setFilteredRoles(obj.roles);
        // setFilteredRoles(obj.roles)
        return obj.roles;
      }
    });

    if (type === "dealer") {
      roles?.filter((obj: any) => {
        if (_.toLower(obj.type) === "dealer") {
          setFilteredRoles(obj.roles);
          // setFilteredRoles(obj.roles)
          return obj.roles;
        }
      });
    }

    if (selectedType === "corporate" || selectedType === "cognitgo") {
      setLoading(true);
      corporateListApi(localStorage.getItem("$AuthToken"), {
        allCorporate: true,
      })
        .then((response: any) => {
          console.log("response", response);

          setCompanyName(
            response?.body?.corporateList?.sort((a, b) =>
              a.corporateName.trim().toLowerCase() >
              b.corporateName.trim().toLowerCase()
                ? 1
                : -1
            )
          );
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
      return;
    }

    if (selectedType === "dealer") {
      setLoading(true);
      // dealerListApi(localStorage.getItem("$AuthToken"),{getAll: true})
      //     .then((response: any) => {
      //         setCompanyName(response?.body?.data?.sort((a, b) => a.dealer_name
      //             .trim().toLowerCase() > b.dealer_name
      //                 .trim().toLowerCase() ? 1 : -1));
      //         setLoading(false)
      //     })
      //     .catch((err: any) => {
      //         setLoading(false)
      //     });

      console.log("filteredDealers", filteredDealers);
      setCompanyName(filteredDealers);
      setLoading(false);
      return;
    }
  }, [selectedType, filteredDealers]);

  useEffect(() => {
    optionRenderer();
  }, [selectedType, companyName]);

  const optionRenderer = () => {
    console.log("companyName", companyName);

    if (selectedType === "cognitgo") {
      console.log("cond 1");

      return <option value="cognitgo">Cognitgo</option>;
    }
    if (
      type === "cognitgo" &&
      (selectedType === "corporate" || selectedType === "dealer")
    ) {
      console.log("cond 2", companyName);

      return (
        companyName &&
        companyName?.map((obj: any, index: any) => (
          <option data-object={JSON.stringify(obj)}>
            {selectedType === "corporate"
              ? obj?.corporateName
              : obj?.dealer_name}
          </option>
        ))
      );
    }
    if (type === "corporate" && selectedType === "corporate") {
      let cid: any = corporateID;
      console.log("cond 3");

      return <option value={cid}>{company}</option>;
    }
    if (type === "corporate" && selectedType === "dealer") {
      console.log("cond 4");

      return (
        filteredDealers &&
        filteredDealers?.map((obj: any, index: any) => (
          <option data-object={JSON.stringify(obj)}>{obj?.dealer_name}</option>
        ))
      );
    } else if (type === "corporate" || type === "dealer") {
      let comp: any = company;
      return <option value={comp}>{comp}</option>;
    } else {
      console.log("cond 5");

      let did: any = dealerID;

      //   return <option value={comp}>{comp}</option>;
      return "";
    }
  };

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      const state: any = history.location.state;
      console.log("state", state);

      setFirstName(state?.first_name);
      setLastName(state?.last_name);
      setEmail(state?.email);
      // setTimezone(state?.timezone)
      setWorkNumber(state?.contactdetails?.work_number);
      setCellNumber(state?.contactdetails?.cell_number);
      setCommunicationAddress(state?.contactdetails?.communication_address);
      setStatus(state?.is_active);
      setTitle(state?.title);
      setShortName(state?.shortName);
      setAliasName(state?.aliasName);
      setSelectedType(state?.type);
      setSelectedCompanyName(state?.company);
      setRole(state?.role);
      setSelectedDealerOrCorporateID(state?.dealerID || state?.corporateID);
    }
  }, [location, history]);

  const handleClose = () => {
    setShowReset(false);
  };

  const handleTypeChange = (e: any) => {
    setSelectedType(e.target.value);
    setSelectedCompanyName(null);
    setRole("null");
    if (e.target.value === "cognitgo") {
      setSelectedCompanyName("cognitgo");
      return;
    }
    if (type === "corporate" && e.target.value === "corporate") {
      // setSelectedCompanyName(name)
      setSelectedCompanyName(company);
      setSelectedDealerOrCorporateID(dealerID || corporateID);
      return;
    }
    if (type === "dealer" && e.target.value === "dealer") {
      // setSelectedCompanyName(name)
      setSelectedCompanyName(company);
      setSelectedDealerOrCorporateID(dealerID || corporateID);
      return;
    } else {
      console.log("elseee", e.target.value);
    }
  };

  const handleCompanyName = (e: any) => {
    const selectedOption = e.target.selectedOptions[0];
    const objectString = selectedOption.getAttribute("data-object");
    const object = JSON.parse(objectString);
    console.log("selected type", type);

    console.log("object", object);

    if (selectedType === "dealer") {
      setShortName(object?.shortName);
      setAliasName(object?.aliasName);
      setSelectedCompanyName(object?.dealer_name?.trim());
      console.log("object?.dealer_name", object?.dealer_name);

      setSelectedDealerOrCorporateID(object?.dealer_id || object?.dealerID);
      return;
    }
    setSelectedCompanyName(object?.corporateName);
    setSelectedDealerOrCorporateID(object?.ID);
  };

  return (
    <div className="containerBox">
      <LoadingBar isActive={loading} />
      {/* Topbar Code*/}
      <Row className="spacing-2">
        <Col className="button-col">
          <div className="spacing-1-1">
            <div>
              <Image
                onClick={() => {
                  handleBack();
                }}
                className="pointer"
                src={leftArrow}
              />
            </div>
            <div className="pad-button text-name">
              {location.pathname.includes("create")
                ? "Create User"
                : "Update User"}
            </div>
          </div>
        </Col>
      </Row>
      <hr className="divider-line" />
      <Row className="spacing-1">
        <div className="form-container">
          <span className="">
            Required fields are indicated with "
            <span className="text-danger h6 font-weight-bold">*</span>"
          </span>
          <Form className="mt-3">
            <Row className="mb-3">
              {/* Name Field */}
              <Form.Group
                size="sm"
                as={Col}
                lg="6"
                md="6"
                controlId="formGridEmail"
              >
                <Form.Label className="text">
                  First Name
                  <span className="text-danger h6 font-weight-bold">*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="form-input"
                    size="sm"
                    type="input"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Enter name"
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group
                size="sm"
                as={Col}
                lg="6"
                md="6"
                controlId="formGridEmail"
              >
                <Form.Label className="text">Last Name</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="form-input"
                    size="sm"
                    type="input"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Enter name"
                  />
                </InputGroup>
              </Form.Group>

              {/* Email Field */}
              <Form.Group
                size="sm"
                lg="6"
                md="6"
                as={Col}
                controlId="formGridEmail"
              >
                <Form.Label className="text">
                  Email
                  <span className="text-danger h6 font-weight-bold">*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="form-input"
                    size="sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter email"
                    disabled={
                      location.pathname.includes("create") ? false : true
                    }
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group
                size="sm"
                lg="6"
                md="6"
                as={Col}
                controlId="formGridEmail"
              >
                <Form.Label className="text">Title</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="form-input"
                    size="sm"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    placeholder="Enter title"
                  />
                </InputGroup>
              </Form.Group>

              {type !== "dealer" && (
                <Form.Group as={Col} md="6" lg="6" controlId="formGridState">
                  <Form.Label className="text">
                    Type
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <Form.Select
                    value={selectedType}
                    onChange={handleTypeChange}
                    // defaultValue='default'
                    className="mb-3 select-field select-field-sm"
                  >
                    {type !== "dealer" && (
                      <option value="default">Select User Type</option>
                    )}
                    {type === "cognitgo" ? (
                      types?.map((obj: any, index: any) => (
                        <option value={obj.value} key={index}>
                          {obj.label}
                        </option>
                      ))
                    ) : type === "corporate" ? (
                      types?.slice(1, 3).map((obj: any, index: any) => (
                        <option value={obj.value} key={index}>
                          {obj.label}
                        </option>
                      ))
                    ) : (
                      <option value={"dealer"} key={index}>
                        {"Dealer"}
                      </option>
                    )}
                  </Form.Select>
                </Form.Group>
              )}

              {type !== "dealer" && (
                <Form.Group as={Col} md="6" lg="6" controlId="formGridState">
                  <Form.Label className="text">
                    Company
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <Form.Select
                    value={selectedCompanyName}
                    onChange={handleCompanyName}
                    className="mb-3 select-field select-field-sm"
                  >
                    {type === "cognitgo" && selectedType !== "cognitgo" && (
                      <option value="null">Select company</option>
                    )}
                    {optionRenderer()}
                  </Form.Select>
                </Form.Group>
              )}

              <Form.Group as={Col} md="6" lg="6" controlId="formGridState">
                <Form.Label className="text">
                  Role<span className="text-danger h6 font-weight-bold">*</span>
                </Form.Label>
                <Form.Select
                  value={role}
                  onChange={changeRoleEventHandler}
                  className="mb-3 select-field select-field-sm"
                >
                  <option value={"null"}>Select Role</option>

                  {filteredRoles?.map((obj: any) => (
                    <option value={obj.value}>{obj.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group
                size="sm"
                as={Col}
                lg="6"
                md="6"
                controlId="formGridEmail"
              >
                <Form.Label className="text">Cell Number</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="form-input"
                    size="sm"
                    type="input"
                    value={cell_number}
                    onChange={(e) => setCellNumber(e.target.value)}
                    placeholder="Enter Cell Number"
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group
                size="sm"
                as={Col}
                lg="6"
                md="6"
                controlId="formGridEmail"
              >
                <Form.Label className="text">Work Number</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="form-input"
                    size="sm"
                    type="input"
                    value={work_number}
                    onChange={(e) => setWorkNumber(e.target.value)}
                    placeholder="Enter Work Number"
                  />
                </InputGroup>
              </Form.Group>

              {location.pathname.includes("edit") && (
                <>
                  <Form.Group
                    size="sm"
                    as={Col}
                    mb="2"
                    lg="2"
                    controlId="formGridEmail"
                  >
                    <Form.Label className="text pb-0 mb-0">Status</Form.Label>
                    <ToggleSwitch
                      label={status}
                      handleStatus={() => setStatus(!status)}
                    />
                  </Form.Group>
                  <Form.Group
                    size="sm"
                    as={Col}
                    mb="4"
                    lg="4"
                    controlId="formGridEmail"
                  >
                    <Form.Label className="text pb-0 mb-0">
                      Reset Password
                    </Form.Label>
                    <span
                      className="my-0 text-primary d-block"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        forgotPassword();
                      }}
                    >
                      Click here to send email notification to user to reset the
                      password.
                    </span>
                  </Form.Group>
                </>
              )}
            </Row>
          </Form>
          <hr />
          <div className="button-container">
            <button
            className="btn pbuttonbgcolor"
              onClick={
                location.pathname.includes("create")
                  ? handleCreate
                  : handleUpdate
              }
              type="submit"
            >
              {location.pathname.includes("create")
                ? "+ Create User"
                : "Update User"}
            </button>
          </div>
        </div>
      </Row>
      <Modal centered show={showReset}>
        <Modal.Header>
          <Modal.Title>Email sent successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <span className="" style={{ fontWeight: "normal" }}>
              Reset Password email is successfully sent to{" "}
              <span className="font-weight-bold">{email}</span>
            </span>
            {/* <span className='' style={{ fontWeight: 'normal' }}>We just sent you an email with a password reset link, please click the link to reset your password.</span> */}
          </div>
          <div className="mt-3">
            <span className="h6">
              *Note: Please inform the user to check the Spam folder.
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm pbuttonbgcolor" onClick={handleClose}>
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Usercreate;
