import React, { useEffect, useRef, useState } from "react";
import { Form, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import leftArrow from "../../assets/images/leftArrow.svg";
import LoadingBar from "../common/LoadingBar";
import axios from "axios";
import config from "../../config.json";
import ReactQuill from "react-quill";
import ibtn from "../../assets/images/ibtn.png";
import Quill from "quill";
import {
  convertToRaw,
  EditorState,
  RichUtils,
  ContentState,
  convertFromHTML,
  Modifier,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import _ from "lodash";
import { toastify } from "../common/notification";
import "./style.css";
import { emailTemplateNames } from "../../controller";

function Createemailtemplate() {
  const history = useHistory();
  const location: any = useLocation();
  const [loading, setLoading] = useState<any>(false);
  const [subject, setSubject] = useState<any>("");
  const [enteredTemplateCategory, setEnteredTemplateCategory] =
    useState<any>("");
  // Quill.register("modules/Clipboard", true);
  const [tempName, setTempName] = useState<any>("");
  let reactQuillref: any = useRef<any>(null);
  let reactDraftref: any = useRef<any>(null);
  const [emailBody, setEmailBody] = useState<any>("");
  const textArea = useRef<any>(null);
  const [text, setText] = useState();

  const [editorState, setEditorState] = useState(
    !location?.state?.edit
      ? EditorState.createEmpty()
      : EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(location?.state?.editData?.emailBody)
          )
        )
  );

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    const { blocks } = convertToRaw(editorState.getCurrentContent());
    /*let text = blocks.reduce((acc, item) => {
      acc = acc + item.text;
      return acc;
    }, "");*/
    let text = editorState.getCurrentContent().getPlainText("\u0001");
    setText(text);
  };

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    // You can convert contentState to raw or other formats here if needed
    console.log("contentState", contentState);
  }, [editorState]);

  console.log(
    "edit body",
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(`<a href="dsdsd">hello</a>`)
      )
    )
  );

  const [mf, setMf] = useState<any>([]);
  let token: any = localStorage.getItem("$AuthToken");
  const handleBack = () => {
    history.push({
      pathname: "/template",
      state: {
        dataChanged: true,
      },
    });
  };

  useEffect(() => {
    const fetchMergeFields = async () => {
      let body = {
        function: "fetchAll",
      };

      let resp = await axios.post(
        config.baseHost_backend + "/mergefield",
        body,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("merge fields api", resp?.data?.body);
      setMf(resp?.data?.body);
    };
    fetchMergeFields();
  }, []);

  // const handleReturn = (e) => {
  //   if (!e.shiftKey) {
  //     setEditorState(
  //       EditorState.push(
  //         editorState,
  //         Modifier.insertText(
  //           editorState.getCurrentContent(),
  //           editorState.getSelection(),
  //           "\n", // Insert a line break on Enter key press
  //         ),
  //         "insert-characters",
  //       ),
  //     );
  //     return "handled";
  //   }
  //   return "not-handled";
  // };
  const handleReturn = (e) => {
    if (!e.shiftKey) {
      setEditorState(
        EditorState.push(
          editorState,
          Modifier.insertText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            "\n" // Insert a line break on Enter key press
          ),
          "insert-characters"
        )
      );
      return "handled";
    }
    return "not-handled";
  };

  const handleSave = (e) => {
    e.preventDefault();

    let payload: any = {};

    if (location?.state?.edit) {
      payload = {
        type: "update",
        subject: `${subject}`,
        emailBody: `${draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        ).replace(/(\r\n|\n|\r)/gm, "")}`,
        _id: location?.state?.editData?._id,
      };

      if (!subject) {
        return toastify("failure", "Subject connot be empty");
      }
      if (!payload.emailBody) {
        return toastify("failure", "Email body connot be empty");
      }
    } else {
      payload = {
        type: "create",
        tempName: tempName,
        isActive: true,
        subject: `${subject}`,
        // emailBody: `${emailBody}`,
        emailBody: `${draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        ).replace(/(\r\n|\n|\r)/gm, "")}`,
      };
      if (!tempName) {
        return toastify("failure", "Category connot be empty");
      }
      if (!subject) {
        return toastify("failure", "Subject connot be empty");
      }
      if (!payload.emailBody) {
        return toastify("failure", "Email body connot be empty");
      }
    }

    setLoading(true);
    axios
      .post(config.baseHost_backend + "/email-template", payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("res", res);
        if (res?.data?.statusCode === 200) {
          toastify(
            "success",
            location?.state?.edit
              ? "Email template updated successfully."
              : "Email template created successfully."
          );
        }
        history.push({
          pathname: "/template",
          state: {
            dataChanged: true,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
    console.log("payload", payload);
  };

  // const handleChange = (e) => {
  //   // setEmailBody((prev)=>prev+`{{${e.target.value}}}`)}
  //   if (e.target.value !== "null") {
  //     const selectedOption = e.target.selectedOptions[0];
  //     const objectString = selectedOption.getAttribute("data-object");
  //     const object = JSON.parse(objectString);
  //     // const textarea = textArea.current;
  //     // const { selectionStart, selectionEnd } = textarea;
  //     const fieldValue = "${data." + `${object?.value}` + "}";
  //     //   setEmailBody(
  //     //     (prevValue) =>
  //     //       prevValue.slice(0, selectionStart) +
  //     //       fieldValue +
  //     //       prevValue.slice(selectionEnd)
  //     //   );

  //     //   textarea.focus();
  //     //   textarea.setSelectionRange(
  //     //     selectionStart + fieldValue.length,
  //     //     selectionStart + fieldValue.length
  //     //   );
  //     // }

  //     if (reactQuillref.current) {
  //       const quill = reactQuillref.current.getEditor();
  //       const selection = quill.getSelection();
  //       if (selection) {
  //         const { index } = selection;
  //         quill.insertText(index, fieldValue, "user");
  //         quill.setSelection(index + fieldValue.length);
  //       }
  //     }
  //     // setEmailBody(emailBody+fieldValue)
  //   }
  // };

  // ------------------------------------------------------
  useEffect(() => {
    if (reactQuillref.current) {
      const editor: any = new Quill(reactQuillref.current, {
        theme: "snow",
        modules: {
          clipboard: {
            matchVisual: false,
          },
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                handler: () => true,
              },
            },
          },
        },
        formats: [
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "link",
          "image",
          "video",
          "list",
          "bullet",
          "span", // Add custom 'span' format
        ],
      });

      // editor.keyboard.addBinding({
      //   key: "Enter",
      //   handler: function (range) {
      //     const cursorPosition = range.index;
      //     editor.insertText(cursorPosition, "<br>", "user");
      //     // editor.setSelection(cursorPosition + 1, "silent");
      //     return false;
      //   },
      // });

      editor.on("text-change", handleChanget);

      reactQuillref.current = editor;
    }
  }, []);

  const handleMergeFieldChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    const objectString = selectedOption.getAttribute("data-object");
    const object = JSON.parse(objectString);
    // const textarea = textArea.current;
    // const { selectionStart, selectionEnd } = textarea;
    const fieldValue = "#" + `${object?.value}` + "#";

    handleMergeFieldInsertion(fieldValue);
  };

  const insertMergeField = (selectedMergeField) => {
    if (reactDraftref.current) {
      const editor = reactDraftref.current;
      const selection = editor.getSelection();
      if (selection) {
        const { index } = selection;
        editor.insertText(index, selectedMergeField, "user");
        editor.setSelection(index + selectedMergeField.length);
      }
    }
  };

  const handleMergeFieldInsertion = (mergeField) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const contentWithMergeField = Modifier.insertText(
      contentState,
      selection,
      mergeField
    );

    const newEditorState = EditorState.push(
      editorState,
      contentWithMergeField,
      "insert-characters"
    );

    setEditorState(EditorState.moveFocusToEnd(newEditorState));
  };

  const handleChanget = (value) => {
    if (reactQuillref.current) {
      setEmailBody(reactQuillref.current.root.innerHTML);
    }
  };

  useEffect(() => {
    if (location?.state?.edit) {
      console.log("location?.state", location?.state);
      setSubject(location?.state?.editData?.subject);
      setEmailBody(location?.state?.editData?.emailBody);
      // setEditorState(location?.state?.editData?.emailBody)
      if (reactQuillref.current) {
        reactQuillref.current.root.innerHTML =
          location?.state?.editData?.emailBody;
      }
    }
  }, [location?.state]);
  return (
    <React.Fragment>
      <div className="p-3 d-flex justify-content-between">
        <div>
          <Image
            onClick={() => {
              handleBack();
            }}
            className="pointer"
            src={leftArrow}
          />
          <span className="ml-2">
            {location?.state?.edit ? "Edit" : "Create"} Email template
          </span>
        </div>
        <div className="mt-auto mb-auto">
          <Form.Group>
            <button
              type="submit"
              className="btn btn-sm pbuttonbgcolor"
              onClick={handleSave}
            >
              {location?.state?.edit ? "Update template" : "Save template"}
            </button>
          </Form.Group>
        </div>
      </div>
      <hr className="m-0 p-0" />
      {<LoadingBar isActive={loading} />}
      <div className="p-3">
        <Form action="">
          <Row>
            {!location?.state?.edit && (
              <Form.Group>
                <Form.Label className="text">
                  {tempName !== "new"
                    ? "Select template category"
                    : "Enter template category"}
                  <span className="text-danger h6 font-weight-bold">*</span>
                </Form.Label>

                <select
                  name=""
                  id=""
                  className="form-select mt-auto mb-auto bg-light p-3"
                  onChange={(e) => {
                    setTempName(e.target.value);
                  }}
                >
                  <option value="null">Select template category</option>
                  {emailTemplateNames?.map((cat: any) => (
                    <option value={cat}>{cat}</option>
                  ))}
                </select>

                {/* {tempName === "new" && (
                  <input
                    type="text"
                    className="form-control p-3 bg-light"
                    placeholder="Enter template category"
                    onChange={(e) => {
                      setEnteredTemplateCategory(e.target.value);
                    }}
                  />
                )} */}
              </Form.Group>
            )}
            {/* <Form.Group className="mt-2">
              <Form.Label className="text">
                Template name
                <span className="text-danger h6 font-weight-bold">*</span>
              </Form.Label>
              <input
                value={tempName}
                type="text"
                className="form-control p-3 bg-light"
                placeholder="Enter template name"
                onChange={(e) => {
                  setTempName(e.target.value);
                }}
              />
            </Form.Group> */}
            <Form.Group className="mt-2">
              <Form.Label className="text">
                Subject
                <span className="text-danger h6 font-weight-bold">*</span>
              </Form.Label>
              <input
                value={subject}
                type="text"
                className="form-control p-3 bg-light"
                placeholder="Enter subject"
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mt-4">
              {/* <div className='d-flex justify-content-between mb-1'> */}
              {/* <Form.Label className='text mt-auto'>Message body<span className='text-danger h6 font-weight-bold'>*</span></Form.Label> */}
              <select
                name=""
                id=""
                className="form-select mt-auto mb-auto w-25"
                onChange={handleMergeFieldChange}
                style={{ float: "right" }}
              >
                <option value="null">Select merge field</option>
                {mf &&
                  mf
                    ?.sort((a, b) =>
                      a?.label?.toLocaleLowerCase() >
                      b?.label?.toLocaleLowerCase()
                        ? 1
                        : -1
                    )
                    ?.map((mf) => (
                      <option
                        value={mf?.value}
                        data-object={JSON.stringify(mf)}
                      >
                        {mf?.label}
                      </option>
                    ))}
              </select>
              <p><OverlayTrigger
                placement="right"
                delay={{ show: 0, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">
                    Use #website# as link target for creating links
                  </Tooltip>
                }
              >
                <img
                  src={ibtn}
                  alt="i btn"
                  className="ml-2"
                  height={15}
                  width={15}
                />
              </OverlayTrigger></p>
              {/* </div> */}
              {/* <textarea
                ref={textArea}
                className="form-control bg-light p-2 m-0"
                rows={10}
                value={emailBody}
                onChange={(e) => {
                  setEmailBody(e.target.value);
                }}
              /> */}
              <div style={{ fontWeight: "normal", height: "400px" }}>
                {/* <style>{customCss}</style> */}
                {/* <ReactQuill
                  ref={(el) => {
                    reactQuillref = el;
                  }}
                  modules={modules}
                  value={emailBody}
                  onChange={setEmailBody}
                  placeholder="Type here..."
                  theme="snow"
                  // sanitize={sanitizeHtml}
                /> */}
                {/* <div ref={reactQuillref} /> */}
                <div className="text-editor">
                  <div className="editor-container">
                    <Editor
                      ref={reactDraftref}
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                      handleReturn={handleReturn}
                      editorStyle={{
                        minHeight: "200px",
                        border: "1px solid #ccc",
                        padding: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
}

export default Createemailtemplate;