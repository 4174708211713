/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Select from "react-select";
import { UserContext } from "../common/UserContext";
import csv from "../../assets/images/csv.svg";
import { CSVLink } from "react-csv";
import config from "../../config.json";
import axios from "axios";
import info from "../../assets/images/info.svg";
import ibtn from "../../assets/images/ibtn.png";
export const FilterLeads = ({
  dealerList,
  setPage,
  userList,
  filterLeads,
  statusList,
  toggle,
  setReset,
  filterQuery,
  setLoading,
  setUser,
  setResetForm,
  resetForm,
  totalLeads,
  reset,
}: any) => {
  const [values, setValues] = useState<any>([]);
  const [custRespDatevalues, setCustRespDatevalues] = useState<any>([]);
  const [taskDateValues, setTaskDateValues] = useState<any>([]);
  const [leadProcessedDatevalues, setLeadProcessedDatevalues] = useState<any>(
    []
  );
  const [selectedFilters, setSelectedFilters] = useState({
    status: "",
    dealer: "",
    owner: "",
    sdate: "",
    edate: "",
    customer_name: "",
    phone_no: "",
    reset: false,
  });
  const [csvData, setcsvData] = useState<any>(null);
  const [disableExportbtn, setDisableResetBtn] = useState<any>(true);
  const [filteredStatusList, setFilteredStatusList] = useState<any>(null);
  const [filteredDealersList, setFilteredDealersList] = useState<any>(null);
  const [filteredUserList, setFilteredUserList] = useState<any>(null);
  const [singleDateSelected, setSingleDateSelected] = useState<any>(null);
  const [custRespSingleDateSelected, setCustRespSingleDateSelected] =
    useState<any>(null);
  const [leadProcessedSingleDateSelected, setLeadProcessedSingleDateSelected] =
    useState<any>(null);
  const [selectedstatus, setSelectedstatus] = useState<any>([]);
  // const [selectedstatusLabel, setSelectedstatusLabel] = useState<any>("")
  const [selecteddealers, setSelecteddealers] = useState<any>("");
  const [selecteddealersName, setSelecteddealerName] = useState<any>("");
  const [selectedowners, setSelectedowners] = useState<any>("");
  const [selectedFromDate, setSelectedFromDate] = useState<any>("");
  const [selectedToDate, setSelectedToDate] = useState<any>("");
  const [selectedCustRespFromDate, setSelectedCustRespFromDate] =
    useState<any>("");
  const [selectedCustRespToDate, setSelectedCustRespToDate] = useState<any>("");
  const [selectedCustName, setSelectedCustName] = useState<any>("");
  const [selectedPhone, setSelectedPhone] = useState<any>("");
  const [selectedLeadProcessedFromDate, setSelectedLeadProcessedFromDate] =
    useState<any>("");
  const [selectedLeadProcessedToDate, setSelectedLeadProcessedToDate] =
    useState<any>("");

    const [taskFromDate,setTaskFromDate]=useState<any>("");
    const [taskToDate,setTaskToDate]=useState<any>("");
    const [taskSingleDateSelected,setTaskSingleDateSelected]=useState<any>(null);

  const statusRef = useRef<any>(null);
  const dealerRef = useRef<any>(null);
  const ownerRef = useRef<any>(null);
  const nameRef = useRef<any>(null);
  const { type } = useContext(UserContext);

  useEffect(() => {
    let loc_data: any = localStorage.getItem("FILTERS_OBJ");
    if (!loc_data) {
      setDisableResetBtn(true);
    } else {
      setDisableResetBtn(false);
    }
  }, []);

  const re = () => {
    resetFilters();
  };

  useEffect(() => {
    if (resetForm) {
      resetFilters();
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    const tempArray = [{ label: "All", value: "All" }] as any;
    console.log("statusList", statusList);

    if (statusList) {
      statusList?.forEach((obj: any) => {
        if (obj?.options) {
          tempArray.push({ label: obj?.label, options: obj?.options });
        } else {
          tempArray.push({ label: obj.label, value: obj.value });
        }
      });
      let newArray = [] as any;
      let newOptions = [] as any;
      console.log("Oggg", statusList);
      let filterd = statusList?.filter((ss) => ss.label === "Opportunities");
      console.log("Oggg1", filterd);
      filterd[0]?.options?.forEach((ss) => {
        newOptions.push(ss);
      });
      newArray.push({ label: "Opportunities", options: newOptions });
      console.log("newOptions", newOptions);
      console.log("newArray", newArray);
      let exceptData = tempArray?.filter((ss) => ss.label !== "Opportunities");
      setFilteredStatusList([...exceptData, ...newArray]);
    }
  }, [statusList]);

  useEffect(() => {
    const tempArray = [] as any;
    const cc = [{ label: "All", value: "All" }] as any;
    if (dealerList) {
      dealerList?.forEach((obj: any) => {
        tempArray.push({ label: obj.value, value: obj.key });
      });
      tempArray.sort((a: any, b: any) =>
        _.toLower(a.label).trim() > _.toLower(b.label).trim() ? 1 : -1
      );

      let combined = [...cc, ...tempArray];
      setFilteredDealersList(combined);
    }
  }, [dealerList]);

  useEffect(() => {
    const tempArray = [] as any;
    if (userList) {
      userList?.forEach((obj: any) => {
        tempArray.push({ label: obj.value, value: obj.key });
      });
      setFilteredUserList(
        tempArray.sort((a: any, b: any) =>
          _.toLower(a.label).trim() > _.toLower(b.label).trim() ? 1 : -1
        )
      );
    }
  }, [userList]);

  const handleTaskDateChange = (dates: DateObject[]) => {
    let length: any = dates?.length;
    localStorage.setItem("TASK_DATE_LENGTH", length);
    if (dates.length === 1) {
      setTaskFromDate(dates[0].toString());
  
      let a: any = dates[0];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      setTaskToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      setTaskSingleDateSelected(true);

    }
    if (dates.length === 2) {
      setTaskFromDate(dates[0].toString());
      let a: any = dates[1];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      
      setTaskToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      setTaskSingleDateSelected(false);
    }
    if (dates.length === 0) {
      setTaskFromDate(null);
      setTaskToDate(null);
      setTaskSingleDateSelected(null);
    }
    setTaskDateValues(dates);
  };

  const handleDateChange = (dates: DateObject[]) => {
    let length: any = dates?.length;
    localStorage.setItem("DATE_LENGTH", length);
    if (dates.length === 1) {
      setSelectedFromDate(dates[0].toString());
  
      let a: any = dates[0];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      setSelectedToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      setSingleDateSelected(true);

    }
    if (dates.length === 2) {
      setSelectedFromDate(dates[0].toString());
      let a: any = dates[1];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      
      setSelectedToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      setSingleDateSelected(false);
    }
    if (dates.length === 0) {
      setSelectedFromDate(null);
      setSelectedToDate(null);
      setSingleDateSelected(null);
    }
    setValues(dates);
  };

  const handleCustRespDateChange = (dates: DateObject[]) => {
    let length: any = dates?.length;
    localStorage.setItem("CUST_RESP_DATE_LENGTH", length);
    if (dates.length === 1) {
      setSelectedCustRespFromDate(dates[0].toString());
      // setSelectedToDate(moment(dates[0].toString()).add(1, 'days').toString())

      let a: any = dates[0];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      setSelectedCustRespToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );

      setCustRespSingleDateSelected(true);

      // converting to backend timeformat(isO UTC)

      // let isoConverted_s = new Date(dates[0].toString()).toISOString();
      // let isoConverted_e = new Date(moment(dates[0].toString()).add(1, 'days').toString()).toISOString()

      //

      // setSelectedFromDate(isoConverted_s)
      // setSelectedToDate(isoConverted_e)
    }
    if (dates.length === 2) {
      setSelectedCustRespFromDate(dates[0].toString());
      let a: any = dates[1];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      // setSelectedToDate(`${stamp.getFullYear()}/${stamp.getMonth()+1}/${stamp.getDate()}`)

      setSelectedCustRespToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      // setSelectedToDate(moment(dates[1].toString()).add(1, 'days').toString())
      setCustRespSingleDateSelected(false);
      // converting to backend timeformat(UTC)
      // let isoConverted_s = new Date(dates[0].toString()).toISOString();
      // let isoConverted_e = new Date(moment(dates[1].toString()).add(1, 'days').toString()).toISOString()

      // setSelectedFromDate(isoConverted_s)
      // setSelectedToDate(isoConverted_e)
    }
    if (dates.length === 0) {
      setSelectedCustRespFromDate(null);
      setSelectedCustRespToDate(null);
      setCustRespSingleDateSelected(null);
    }
    setCustRespDatevalues(dates);
  };

  const handleLeadProcessedDate = (dates: DateObject[]) => {
    let length: any = dates?.length;
    localStorage.setItem("LEAD_PROCESSED_DATE_LENGTH", length);
    if (dates.length === 1) {
      setSelectedLeadProcessedFromDate(dates[0].toString());
      // setSelectedToDate(moment(dates[0].toString()).add(1, 'days').toString())

      let a: any = dates[0];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      setSelectedLeadProcessedToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );

      setLeadProcessedSingleDateSelected(true);

      // converting to backend timeformat(isO UTC)

      // let isoConverted_s = new Date(dates[0].toString()).toISOString();
      // let isoConverted_e = new Date(moment(dates[0].toString()).add(1, 'days').toString()).toISOString()

      //

      // setSelectedFromDate(isoConverted_s)
      // setSelectedToDate(isoConverted_e)
    }
    if (dates.length === 2) {
      setSelectedLeadProcessedFromDate(dates[0].toString());
      let a: any = dates[1];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      // setSelectedToDate(`${stamp.getFullYear()}/${stamp.getMonth()+1}/${stamp.getDate()}`)

      setSelectedLeadProcessedToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      // setSelectedToDate(moment(dates[1].toString()).add(1, 'days').toString())
      setLeadProcessedSingleDateSelected(false);
      // converting to backend timeformat(UTC)
      // let isoConverted_s = new Date(dates[0].toString()).toISOString();
      // let isoConverted_e = new Date(moment(dates[1].toString()).add(1, 'days').toString()).toISOString()

      // setSelectedFromDate(isoConverted_s)
      // setSelectedToDate(isoConverted_e)
    }
    if (dates.length === 0) {
      setSelectedLeadProcessedFromDate(null);
      setSelectedLeadProcessedToDate(null);
      setLeadProcessedSingleDateSelected(null);
    }
    setLeadProcessedDatevalues(dates);
  };

  useEffect(() => {
    let loc_data: any = localStorage.getItem("FILTERS_OBJ");
    let pared = JSON.parse(loc_data);
    if (pared) {
      console.log("pared2", pared);
      let tArr = [] as any;
      setSelectedCustName(pared?.customer_name);
      setSelectedPhone(pared?.phone_no);
      setSelecteddealers(pared?.dealer);
      // setSelectedstatus(pared?.status)
      // setSelectedstatusLabel(pared?.statusLabel)
      setSelecteddealerName(pared?.dealer_name);

      // if(pared?.statusForDefault){
      //     pared?.statusForDefault?.forEach((ds)=>{
      //         tArr.push(ds.value)
      //     })
      // }
      console.log("Tarr", tArr);
      setSelectedstatus(pared?.statusForDefault);

      //convert into yyyy/mm/dd
      new Date().toISOString().slice(0, 10);

      // setSelectedFromDate(new Date(pared?.sdate).toISOString().slice(0, 10));
      // setSelectedToDate(new Date(pared?.edate).toISOString().slice(0, 10))
      let dateLength: any = localStorage.getItem("DATE_LENGTH");
      let task_date_length:any=localStorage.getItem("TASK_DATE_LENGTH");
      let custResdateLength: any = localStorage.getItem(
        "CUST_RESP_DATE_LENGTH"
      );
      let leadproceeseddateLength: any = localStorage.getItem(
        "LEAD_PROCESSED_DATE_LENGTH"
      );

      let splitted_e: any = new Date(pared?.edate).toLocaleDateString();
      splitted_e = splitted_e?.split("/");

      let ed = new Date(pared?.sdate);
      let splited_s = new Date(ed).toLocaleDateString().split("/");

      let custReEd = new Date(pared?.custRespSdate);
      let cusTsplited_s = new Date(custReEd).toLocaleDateString().split("/");

      let custRepD_e: any = new Date(pared?.custRespEdate).toLocaleDateString();
      custRepD_e = custRepD_e?.split("/");

      // -------
      let leadProcsplitted_e: any = new Date(
        pared?.leadProcessedEdate
      ).toLocaleDateString();
      leadProcsplitted_e = leadProcsplitted_e?.split("/");

      let leadProcsplitted_s: any = new Date(pared?.leadProcessedSdate);
      leadProcsplitted_s = new Date(leadProcsplitted_s)
        .toLocaleDateString()
        .split("/");

        ///
        let task_e_date:any=new Date(pared?.task_edate).toLocaleDateString();
        task_e_date = task_e_date?.split("/");
        let task_s_date:any=new Date(pared?.task_sdate).toLocaleDateString();
        task_s_date = task_s_date?.split("/");

        //

      if (pared?.sdate) {
        setSelectedFromDate(pared?.sdate);
        setSelectedToDate(pared?.edate);

        if (dateLength === 1 || dateLength === "1") {
          setValues([
            `${splited_s[0] + "/" + splited_s[1] + "/" + splited_s[2]}`,
          ]);
        } else {
          setValues([
            `${splited_s[0] + "/" + splited_s[1] + "/" + splited_s[2]}`,
            new DateObject(
              `${splitted_e[2] + "/" + splitted_e[0] + "/" + splitted_e[1]}`
            ).subtract(1, "days"),
          ]);
        }
      }
      if (pared?.custRespSdate) {
        setSelectedCustRespFromDate(pared?.custRespSdate);
        setSelectedCustRespToDate(pared?.custRespEdate);

        if (custResdateLength === 1 || custResdateLength === "1") {
          setCustRespDatevalues([
            `${
              cusTsplited_s[0] + "/" + cusTsplited_s[1] + "/" + cusTsplited_s[2]
            }`,
          ]);
        } else {
          setCustRespDatevalues([
            `${
              cusTsplited_s[0] + "/" + cusTsplited_s[1] + "/" + cusTsplited_s[2]
            }`,
            new DateObject(
              `${custRepD_e[2] + "/" + custRepD_e[0] + "/" + custRepD_e[1]}`
            ).subtract(1, "days"),
          ]);
        }
      }
      if(pared?.task_sdate){
        setTaskFromDate(pared?.task_sdate);
        setTaskToDate(pared?.task_edate);

        if (task_date_length === 1 || task_date_length === "1") {
          setTaskDateValues([
            `${
              task_s_date[0] + "/" + task_s_date[1] + "/" + task_s_date[2]
            }`,
          ]);
        } else {
          setTaskDateValues([
            `${
              task_s_date[0] + "/" + task_s_date[1] + "/" + task_s_date[2]
            }`,
            new DateObject(
              `${task_e_date[2] + "/" + task_e_date[0] + "/" + task_e_date[1]}`
            ).subtract(1, "days"),
          ]);
        }
      }
      if (pared?.leadProcessedSdate) {
        setSelectedLeadProcessedFromDate(pared?.leadProcessedSdate);
        setSelectedLeadProcessedToDate(pared?.leadProcessedEdate);

        if (leadproceeseddateLength === 1 || leadproceeseddateLength === "1") {
          setLeadProcessedDatevalues([
            `${
              leadProcsplitted_s[0] +
              "/" +
              leadProcsplitted_s[1] +
              "/" +
              leadProcsplitted_s[2]
            }`,
          ]);
        } else {
          setLeadProcessedDatevalues([
            `${
              leadProcsplitted_s[0] +
              "/" +
              leadProcsplitted_s[1] +
              "/" +
              leadProcsplitted_s[2]
            }`,
            new DateObject(
              `${
                leadProcsplitted_e[2] +
                "/" +
                leadProcsplitted_e[0] +
                "/" +
                leadProcsplitted_e[1]
              }`
            ).subtract(1, "days"),
          ]);
        }
      }
    }
  }, []);

  const submitFilters = (e: any) => {
    e.preventDefault();
    setPage(1);
    setReset(false);
    let arr = [] as any;
    selectedstatus && selectedstatus?.forEach((a: any) => arr.push(a.value));
    console.log("array", arr);
    const filtersObj: any = {
      customer_name: selectedCustName.trim(),
      dealer: selecteddealers,
      edate: selectedToDate,
      custRespEdate: selectedCustRespToDate,
      owner: selectedowners,
      phone_no: selectedPhone.trim(),
      reset: false,
      sdate: selectedFromDate,
      custRespSdate: selectedCustRespFromDate,
      task_sdate:taskFromDate,
      task_edate:taskToDate,
      taskSingleDateSelected,
      status: arr,
      singleDateSelected,
      custRespSingleDateSelected,
      dealer_name: selecteddealersName,
      // statusLabel: selectedstatusLabel,
      // lead_status: toggle === "opportunities" ? "open_opp" : "open_leads",
      leadProcessedSdate: selectedLeadProcessedFromDate,
      leadProcessedEdate: selectedLeadProcessedToDate,
      leadProcessedSingleDateSelected: leadProcessedSingleDateSelected,
    };
    console.log("filtersObj", filtersObj);
    filtersObj.statusForDefault = selectedstatus;
    filterLeads(filtersObj);
    // let dealersNameadded={...filtersObj, dealer_name:selecteddealersName, statusLabel:selectedstatusLabel}
    localStorage.setItem("FILTERS_OBJ", JSON.stringify(filtersObj));
    localStorage.removeItem("CRM_PAGE");
  };

  const resetFilters = () => {
    setSingleDateSelected(null);
    setCustRespSingleDateSelected(null);
    setPage(1);
    setReset(true);
    setSelectedstatus("");
    setSelecteddealers("");
    setSelectedowners("");
    setSelectedCustName("");
    setSelecteddealerName("");
    // setSelectedstatusLabel("")
    setSelectedPhone("");
    setSelectedFromDate("");
    setSelectedToDate("");
    setSelectedCustRespFromDate("");
    setSelectedCustRespToDate("");
    setSelectedLeadProcessedFromDate("");
    setSelectedLeadProcessedToDate("");

    setValues([]);
    setCustRespDatevalues([]);
    setLeadProcessedDatevalues([]);
    setTaskDateValues([]);
    setTaskFromDate("");
    setTaskToDate("");
    setTaskSingleDateSelected(null)
    const filtersObj = {
      status: toggle === "opportunities" ? "open_opp" : "open_leads",
      type: type,
    };
    
    filterLeads(filtersObj);
    localStorage.removeItem("filterQuery");
    localStorage.removeItem("toggle");
    localStorage.removeItem("FILTERS_OBJ");
    localStorage.removeItem("DATE_LENGTH");
    localStorage.removeItem("CUST_RESP_DATE_LENGTH");
    localStorage.removeItem("TASK_DATE_LENGTH");
    localStorage.removeItem("LEAD_PROCESSED_DATE_LENGTH");
    localStorage.removeItem("sr");
    localStorage.removeItem("CRDsort");
    localStorage.removeItem("LCDsort");
    localStorage.removeItem("CRM_PAGE")
    // localStorage.removeItem("TOGGLE")

    //when toggle is opportunities, then when u hit the reset bt, it should be in opportunities page
    if (localStorage.getItem("TOGGLE") === "opportunities") {
      let initialLoad = {
        status: "open_opp",
        reset: true,
      };
      localStorage.setItem("FILTERS_OBJ", JSON.stringify(initialLoad));
    }
    statusRef.current.state.value = "";
    if (type !== "dealer") {
      dealerRef.current.state.value = "";
      ownerRef.current.state.value = "";
    }
    setUser((prev: any) => ({ ...prev, sort: null }));
    setUser((prev: any) => ({ ...prev, CRDsort: null }));
    setUser((prev: any) => ({ ...prev, LCDsort: null }));
  };

  let loc_data: any = localStorage.getItem("FILTERS_OBJ");
  let pared = JSON.parse(loc_data);
  console.log("pared, pared", pared);

  //Setting default delaer
  const defaultDealer = {
    label: pared?.dealer_name || "Select",
    value: pared?.dealer || null,
  };

  const exporttoCsv = async () => {
    document.body.style.cursor = "wait";
    let resp = await axios.post(config.baseHost_backend + "/download-file", {
      lead: totalLeads,
      functionType: "leadDownload",
    });
    let binary = "";

    // eslint-disable-next-line array-callback-return
    resp?.data?.body?.map((obj: any) => {
      const bytes = new Uint8Array(obj?.Body?.data);

      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const file = window.btoa(binary);
      const url = `data:application/xlsx;base64,` + file;
      const a = document.createElement("a");
      a.href = url;
      a.download = "excel.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      binary = "";
    });
    document.body.style.cursor = "default";
  };

  const handleStatusChange = (value, event) => {
    console.log("value", value);
    console.log("event", event);
    console.log("filteredStatusList", filteredStatusList);
    // // console.log("event.option.value",event.option.value);

    if (
      event.action === "select-option" &&
      (event.option.value === "All" || event.option.value === "opportunities")
    ) {
      //  SetSelected(opt);
      if (event.option.value === "All") {
        setSelectedstatus([filteredStatusList[0]]);
      } else {
        setSelectedstatus([
          {
            value: "opportunities",
            label: "All",
          },
        ]);
      }

      console.log("c1");
    } else if (
      event.action === "select-option" &&
      (event.option.value !== "All" || event.option.value !== "opportunities")
    ) {
      setSelectedstatus(
        value?.filter((o) => o.value !== "All" && o.value !== "opportunities")
      );
      console.log("c2");
    } else if (
      event.action === "deselect-option" &&
      (event.option.value === "All" || event.option.value === "opportunities")
    ) {
      setSelectedstatus([]);
      console.log("c3");
    } else if (event.action === "deselect-option") {
      setSelectedstatus(value.filter((o) => o.value !== "All"));
      console.log("c4");
    } else if (value?.length === filteredStatusList.length - 1) {
      setSelectedstatus(filteredStatusList[0]);
      console.log("c5");
    } else {
      setSelectedstatus(value);
      console.log("else 6");
    }
  };

  return (
    <div className="py-1">
      <Card className="py-3">
        <Card.Body>
          <Form onSubmit={submitFilters} onReset={resetFilters}>
            <Form.Group className="row gap-4">
              <Grid container gap={3}>
                <Grid item lg={2.6} md={5} sm={12} xs={12} className="col-md-3">
                  <Form.Label>Status</Form.Label>
                  <Select
                    closeOnSelect={true}
                    isMulti
                    value={selectedstatus}
                    ref={statusRef}
                    name="status"
                    isSearchable={true}
                    // defaultValue={{label:"1234","value":"678"}}
                    options={filteredStatusList && filteredStatusList}
                    // onChange={(e: any) => {
                    //     setSelectedstatus(e); console.log("status", e);
                    //     // setSelectedstatusLabel(e.label)
                    // }}
                    onChange={handleStatusChange}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  ></Select>
                </Grid>
                {type !== "dealer" && (
                  <Grid
                    item
                    lg={2.6}
                    md={5}
                    sm={12}
                    xs={12}
                    className="col-md-3"
                  >
                    <Form.Label>Dealership</Form.Label>
                    <Select
                      closeOnSelect={true}
                      ref={dealerRef}
                      // defaultInputValue={{label:"Ramesh",value:0}}
                      name="dealer"
                      isSearchable={true}
                      defaultValue={defaultDealer}
                      // options={[{ label: 'All', value: "All"}] ,...filteredDealersList}
                      options={filteredDealersList}
                      onChange={(e: any) => {
                        setSelecteddealers(e.value);
                        setSelecteddealerName(e.label);
                      }}
                    ></Select>
                  </Grid>
                )}
                {type !== "dealer" && (
                  <Grid
                    item
                    lg={2.6}
                    md={5}
                    sm={12}
                    xs={12}
                    className="col-md-3"
                  >
                    <Form.Label>Owner</Form.Label>
                    <Select
                      closeOnSelect={true}
                      ref={ownerRef}
                      name="owner"
                      isSearchable={true}
                      defaultValue="All"
                      options={filteredUserList && filteredUserList}
                      onChange={(e: any) => {
                        setSelectedowners(e.value);
                      }}
                    ></Select>
                  </Grid>
                )}
                {selectedstatus !== "Filtered_Leads" && (
                  <Grid
                    item
                    lg={2.6}
                    md={5}
                    sm={12}
                    xs={12}
                    className="col-md-3 "
                  >
                    <div className="d-flex flex-column">
                      {/* <Form.Label>Message sent date</Form.Label> */}
                      <Form.Label>Lead processed date</Form.Label>
                      <DatePicker
                        // currentDate={new DateObject("2023/01/02 11:13:11")}
                        format="MM/DD/YYYY"
                        style={{ padding: "20px" }}
                        // value={values}
                        // currentDate={new DateObject(defaultDate)}
                        value={values}
                        // currentDate={new DateObject("2022/04/21")}
                        placeholder="Select Date"
                        onChange={handleDateChange}
                        range
                        plugins={[<DatePanel />]}
                      />
                    </div>
                  </Grid>
                )}
                {selectedstatus !== "Filtered_Leads" && (
                  <Grid
                    item
                    lg={2.6}
                    md={5}
                    sm={12}
                    xs={12}
                    className="col-md-3 "
                  >
                    <div className="d-flex flex-column">
                      <Form.Label>Customer response date</Form.Label>
                      <DatePicker
                        // currentDate={new DateObject("2023/01/02 11:13:11")}
                        format="MM/DD/YYYY"
                        style={{ padding: "20px" }}
                        // value={values}
                        // currentDate={new DateObject(defaultDate)}
                        value={custRespDatevalues}
                        // currentDate={new DateObject("2022/04/21")}
                        placeholder="Select Date"
                        onChange={handleCustRespDateChange}
                        range
                        plugins={[<DatePanel />]}
                      />
                    </div>
                  </Grid>
                )}

                <Grid
                  item
                  lg={2.6}
                  md={5}
                  sm={12}
                  xs={12}
                  className="col-md-3 mt-auto"
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      className="bg-white"
                      value={selectedCustName}
                      ref={nameRef}
                      type="text"
                      name="customer_name"
                      placeholder="Enter customer name"
                      onChange={(e: any) => {
                        setSelectedCustName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Grid>
                <Grid
                  item
                  lg={2.6}
                  md={5}
                  sm={12}
                  xs={12}
                  className="col-md-3 mt-auto"
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone No</Form.Label>
                    <Form.Control
                      className="bg-white"
                      value={selectedPhone}
                      type="text"
                      name="phone_no"
                      onChange={(e: any) => {
                        setSelectedPhone(e.target.value);
                      }}
                      placeholder="Enter customer phone no"
                    />
                  </Form.Group>
                </Grid>
                <Grid
                  item
                  lg={2.6}
                  md={5}
                  sm={12}
                  xs={12}
                  className="col-md-3 "
                >
                  <div className="d-flex flex-column">
                    {/* <Form.Label>Message sent date</Form.Label> */}
                    <Form.Label>Task date</Form.Label>
                    <DatePicker
                      // currentDate={new DateObject("2023/01/02 11:13:11")}
                      format="MM/DD/YYYY"
                      style={{ padding: "20px" }}
                      // value={values}
                      // currentDate={new DateObject(defaultDate)}
                      value={taskDateValues}
                      // currentDate={new DateObject("2022/04/21")}
                      placeholder="Select Date"
                      onChange={handleTaskDateChange}
                      range
                      plugins={[<DatePanel />]}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container gap={3}>
                <Grid
                  item
                  lg={2.6}
                  md={5}
                  sm={12}
                  xs={12}
                  className="col-md-3 mt-auto"
                >
                  <button className="w-100 pbuttonbgcolor btn" type="submit">
                    SEARCH
                  </button>
                </Grid>

                {!!Object.entries(selectedFilters).filter(([_, v]) => v !== "")
                  .length && (
                  <Grid
                    item
                    lg={2.9}
                    md={5}
                    sm={12}
                    xs={12}
                    className="col-md-2 mt-auto"
                  >
                    <button
                      className="w-20 sbuttonbgcolor btn"
                      type="reset"
                      title="Reset the filters"
                    >
                      Reset filters
                    </button>
                    {/* disabled={reset || totalLeads<1} */}
                    <button
                      className="w-20 ml-3 btn tbuttonbgcolor"
                      title="Export to CSV"
                      onClick={exporttoCsv}
                      disabled={!localStorage.getItem("FILTERS_OBJ")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-filetype-csv mr-1"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                        />
                      </svg>
                      Export
                    </button>
                    {/* <button className='btn' data-toggle="tooltip" data-placement="top" title="Tooltip on top">></button> */}

                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 0, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Upto 10,000 records can be exported
                        </Tooltip>
                      }
                    >
                      <img
                        src={ibtn}
                        alt="i btn"
                        className="ml-2"
                        height={15}
                        width={15}
                      />
                    </OverlayTrigger>
                  </Grid>
                )}
              </Grid>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};
