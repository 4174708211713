import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import React, { useEffect } from 'react'
// import data from './sample'
import './style.css'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Error } from '@material-ui/icons';
import { Warning } from '@material-ui/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { useQuery } from 'react-query';
import config from '../../config.json'
import axios from 'axios';
import moment from 'moment';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import Filter from './Filter';
import ReactPaginate from 'react-paginate';
import _ from 'lodash'
import { toastify } from '../common/notification';
import { error } from 'console';
import Bottable from './Bottable';
function Bothealthmonitor() {
  const [values, setValues] = React.useState<DateObject | DateObject[] | null>([]);
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10);
  let token: any = localStorage.getItem("$AuthToken")
  const [bots, setBots] = React.useState<any>(null);
  const [status, setStatus] = React.useState<any>(null);
  const [selectedBot, setSelectedBot] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [selectedStatus, setSelectedStatus] = React.useState<any>(null);
  const [selectedFromDate, setSelectedFromDate] = React.useState<any>(null);
  const [selectedToDate, setSelectedToDate] = React.useState<any>(null);
  const [reset, setReset] = React.useState<any>(false);
  const [filterApplied, setFilterApplied] = React.useState<any>(false);
  const [singleDateSelected, setSingleDateSelected] = React.useState<any>(false);
  // const [data, setData] = React.useState<any>(null);
  const [filteredData, setFilteredData] = React.useState<any>(null);

  const dateRef = React.useRef<any>(null)
  const botRef = React.useRef<any>(null)
  const statusRef = React.useRef<any>(null)

  const handleDateChange = (dates: DateObject[]) => {
    if (dates.length === 1) {
      setSelectedFromDate(dates[0].toString());
      let a: any = dates[0]
      let od: any = new Date(a)
      let stamp = new Date(od.setDate(od.getDate() + 1))
      setSelectedToDate(`${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`)
      setSingleDateSelected(true)
    }
    if (dates.length === 2) {
      setSelectedFromDate(dates[0].toString());
      let a: any = dates[1]
      let od: any = new Date(a)
      let stamp = new Date(od.setDate(od.getDate() + 1))
      setSelectedToDate(`${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`)
      setSingleDateSelected(false)

    }
    if (dates.length === 0) {
      setSelectedFromDate("");
      setSelectedToDate("")
      setSingleDateSelected(null)
    }
    setValues(dates);
  }

  const fetchData = async () => {
    let resp = await axios.get(config.baseHost_backend + "/fetch-all-bot-monitoring", {
      headers: {
        Authorization: token
      },
      params: {
        bot: selectedBot,
        fromDate: selectedFromDate,
        toDate: selectedToDate,
        status: selectedStatus,
        page,
        limit,
        page_to_skip: page - 1,
        reset
      }
    });

    return resp
  }

  //on page load
  const { data, isLoading, refetch, isRefetching, isFetching }: any = useQuery(['bot-health-monotor', page, reset], () =>
    fetchData(), { refetchOnWindowFocus: false }
  );


  //set dropdown values
  React.useEffect(() => {
    if (data) {

      const tempA = [{ label: "All", value: 'All' }] as any;
      const tempB = [{ label: "All", value: 'All' }] as any;

      data?.data?.body?.allBots?.filter((a) => a !== "All")?.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)?.forEach((obj) => {
        tempA.push({ label: obj, value: obj });
      })
      data?.data?.body?.allStatus?.filter((a) => a !== "All")?.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)?.forEach((obj) => {
        tempB.push({ label: obj, value: obj });
      })



      setBots(tempA)
      setStatus(tempB)
    }

  }, [data])


  const handlePageClick = async (Selected) => {
    setPage(Selected.selected + 1)
  }

  const filter = async (e) => {
    e.preventDefault();
    setPage(1)
    setReset(false)

    refetch()
  }

  const resetFilter = (e) => {
    e.preventDefault();
    setReset(true)
    setPage(1)
    setFilteredData(null)
    if (selectedBot) {
      botRef.current.state.value = ""
    }
    if (selectedStatus) {
      statusRef.current.state.value = ""
    }
    setValues([])
    setSelectedFromDate(null);
    setSelectedToDate(null)
    setSelectedBot(null);
    setSelectedStatus(null)
  }

  return (
    <div >

      <form className='d-flex flex-row gap-3 justify-content-between py-3 px-3 bhmheader shadow-sm' onReset={reset} onSubmit={filter}>
        <Filter
          status={status}
          bots={bots}
          setSelectedBot={setSelectedBot}
          setSelectedStatus={setSelectedStatus}
          setSelectedDate={handleDateChange}
          filter={filter}
          resetFilter={resetFilter}
          selectedBot={selectedBot}
          selectedStatus={selectedStatus}
          reset={reset}
          dateRef={dateRef}
          botRef={botRef}
          statusRef={statusRef}
          values={values}
        />
        <div className='d-flex gap-3 align-items-center'>
          <div className='d-flex'>
            <CheckCircleIcon style={{ color: 'green' }} />
            <span style={{ fontWeight: 'lighter' }}>Success</span>
          </div>
          <div className='d-flex'>
            <Warning style={{ color: 'tomato' }} />
            <span style={{ fontWeight: 'lighter' }}>Warning</span>
          </div>
          <div className='d-flex'>
            <Error style={{ color: 'red' }} />
            <span style={{ fontWeight: 'lighter' }}>Error</span>
          </div>
        </div>
      </form>
      {isFetching || isLoading ? <Loader /> : (!isFetching || !isLoading) && !data?.data?.body?.allBotMonitoring?.length ? <div style={{paddingTop:'150px'}}><NoDataFound height={""} width={""} message1='No results Found' message2='Try different filters or reset the filters' img="" /></div> : <><Bottable page={page} data={filteredData || data} /> <ReactPaginate
        nextLabel=">>"
        onPageChange={handlePageClick}
        pageCount={(filteredData?.data?.body?.totalCount?.[0]?.count / limit || data?.data?.body?.totalCount?.[0]?.count / limit) || 0}
        previousLabel="<<"
        containerClassName='pagination'
        pageClassName={'pagination'}
        pageLinkClassName='page-link'
        previousClassName='page-item'
        previousLinkClassName='page-link'
        nextClassName='page-item'
        nextLinkClassName='page-link'
        breakLabel='...'
        breakLinkClassName='page-link'
        activeClassName='active'
        breakClassName='page-item'
        initialPage={page - 1}

      /></>}


    </div>
  )
}

export default Bothealthmonitor