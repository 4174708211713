import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { useHistory, useParams } from 'react-router-dom'
import { toastify } from '../common/notification'
import LeftPanel from "./LeftPanel"
import './resetpass.css'
import config from '../../config.json';
const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState<any>(null)
    const [conformPassword, setConformPassword] = useState<any>(null)
    const cont1 = useRef<any>(null)
    const { id } = useParams<any>();
    const history = useHistory();
    
    const changePassword = async () => {
        const url = config['baseHost_backend'] + '/reset-password';
        if (!newPassword) {
            return toastify('failure', 'Please enter the new password');
        }
        if (!conformPassword) {
            return toastify('failure', 'Please enter the confirm password');
        }
        if (newPassword !== conformPassword) {
            return toastify('failure', 'Both passwords are not matching');
        }

        const body = {
            password: newPassword,
            token: id,
        }
        

        axios
            .post(url, body)
            .then((response) => {
                

                if (response?.data?.statusCode === 200) {
                    toastify('success', `${response?.data?.body?.message}`)
                    history.push('/login')
                } else {
                    toastify('failure', `${response?.data?.body?.message}`)
                }

            }).catch((err) => {
                
            })


    }

    useEffect(() => {
        cont1.current.focus()
    }, [])
    return (
        <>
            <Container fluid>
                <Row className='rowContainer'>
                    <Col className='leftside'>
                        <LeftPanel />
                    </Col>
                    <Col className='rightside'>
                        <div className='w-100 bg-light rounded shadow-sm p-5'>
                            <div>
                                <h1 className='text-center reset-head1 pb-4'>Reset Password</h1>
                            </div>
                            <div className='grp-1'>
                                <div>
                                    <span className='label-reset'>Create new password</span>
                                    <InputGroup className='input w-100' >
                                        <FormControl
                                            ref={cont1}
                                            className="bg-white"
                                            // placeholder='New Password'
                                            aria-label='New Password'
                                            aria-describedby='basic-addon1'
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            name='username'
                                            id='username'
                                        />
                                    </InputGroup>
                                </div>
                                <div className='mt-3'>
                                    <span className='label-reset'>Confirm your password</span>
                                    <InputGroup className='input w-100'>
                                        <FormControl
                                            className="bg-white"
                                            // placeholder='Confirm Password'
                                            aria-label='Confirm Password'
                                            aria-describedby='basic-addon1'
                                            onChange={(e) => setConformPassword(e.target.value)}
                                            name='username'
                                            id='username'
                                        />
                                    </InputGroup>
                                </div>
                                <div>
                                    <Button className='w-100 mt-4 p-2' onClick={changePassword}>Reset Password</Button>
                                </div>
                                <div className='w-100 d-flex'>
                                    <div className='w-100 mt-4 p-2 btn-link text-center login-forget-pass-txt ' style={{cursor:'pointer'}}  onClick={()=>{history.push("/login")}}>Back to login</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default ResetPassword