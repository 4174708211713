import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { toastify } from '../common/notification';


import { Col, Row, Button, Image, InputGroup, Form } from 'react-bootstrap';

import leftArrow from '../../assets/images/leftArrow.svg';

import { UserContext } from '../common/UserContext';
import ProfileUpdatedalert from './Profile-updated-alert';
import Loader from '../common/Loader';
import { createCorporateApi, createDealerApi } from '../../controllers/dealers';
import states1 from '../common/states'
import profile from '../../../src/assets/images/user_3.png'
import axios from 'axios';
import config from '../../config.json';
import './index.css'
import AlertModal from './AlertModal';
import LoadingBar from './LoadingBar';
import * as CryptoJS from 'crypto-js';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare, } from "@fortawesome/free-solid-svg-icons";
const EditProfile = ({ setUser }: any) => {
    const [firstName, setFirstName] = useState<any>("")
    const [lastName, setLastName] = useState<any>("")
    // const [timezone, setTimezone] = React.useState<any>('UTC');
    const [email, setEmail] = useState<any>("")
    const [phone, setPhone] = useState<any>("")
    const [oldpassword, setOldPassword] = useState<any>("")
    const [newPassword, setnewPassword] = useState<any>("")
    const [changePassword, setchangePassword] = useState<any>(false)

    const [linkedin, setLinkedin] = useState<any>("")
    const [facebook, setFacebook] = useState<any>("")
    const [tiktok, setTiktok] = useState<any>("")
    const [instagram, setInstagram] = useState<any>("")
    const [twitter, setTwitter] = useState<any>("")

    const [workNumber, setworkNumber] = useState<any>("")
    // const [communicationAddress, setcommunicationAddress] = useState<any>("")
    const data = useContext<any>(UserContext);
    const history = useHistory();
    const fileUpload = useRef<any>(null)
    const [imgUrl, setImgUrl] = useState<any>(null)
    const [file, setFile] = useState<any>(null)
    const [uploaded, setUploaded] = useState<any>(false)
    const [showReset, setShowReset] = useState<any>(false)
    const [showModal, setShowModal] = useState<any>(false)
    const [company, setCompany] = useState<any>(null)
    const url = config['baseHost_backend'];
    const [loading, setLoading] = useState<any>(false)
    const [cID, setCID] = useState<any>(null)
    const handleToggleChange = (e: any) => {
        setchangePassword(e.target.checked)
    }
    const { id } = useParams<any>();
    
    const location = useLocation();
    useEffect(() => {
        
        if (!location.pathname.includes('update-profile')) {
            setFirstName(data?.user_first_name);
            setLastName(data?.user_last_name);
            setEmail(data?.email)
            setPhone(data?.phone)
            setLinkedin(data?.linkedin)
            setImgUrl(data?.imageUrl)
            setworkNumber(data?.workNumber);
            // setcommunicationAddress(data?.communicationAddress)
            setCompany(data?.company)
            // setTimezone(data?.timezone)
            setFacebook(data?.facebook)
            setInstagram(data?.instagram)
            setTwitter(data?.twitter)
            setTiktok(data?.tiktok)
        }
        if (location.pathname.includes('update-profile')) {
            
            if (id !== "123") {
                const coverted = decodeURIComponent(id)
                let decoded = CryptoJS.AES.decrypt(coverted, "rap@123")
                let originalText = decoded.toString(CryptoJS.enc.Utf8);
                decoded = JSON.parse(originalText);
                console.log("decoded",decoded);
                
                setFirstName(decoded?.first_name);
                setLastName(decoded?.last_name);
                setEmail(decoded?.email)
                setPhone(decoded?.contactdetails?.cell_number)
                setLinkedin(decoded?.linkedin)
                setImgUrl(decoded?.imageUrl)
                setworkNumber(decoded?.contactdetails?.work_number);
                // setcommunicationAddress(decoded?.contactdetails?.communication_address)
                setCompany(decoded?.company)
                setCID(decoded._id)
                // setTimezone(decoded?.timezone)
                setFacebook(decoded?.facebook)
                setInstagram(decoded?.instagram)
                setTwitter(decoded?.twitter)
                setTiktok(decoded?.tiktok)
            }
        }



    }, [data?.first_name, data?.last_name, data?.email, data?.phone, data?.linkedin, data?.imageUrl, data?.workNumber, data, location.pathname, id])

    const updateProfile = async () => {
        if (!firstName) {
            return toastify('failure', "Please enter First Name");
        }
        if (location.pathname.includes('update-profile') && !lastName) {
            return toastify('failure', "Please enter Last Name");
        }
        if (!email) {
            return toastify('failure', "Please enter Email");
        }
        if (!phone) {
            return toastify('failure', "Please enter cell number");
        }
        // if (location.pathname.includes('update-profile') && !workNumber) {
        //     return toastify('failure', "Please enter work number");
        // }
        if (changePassword && (!oldpassword)) {
            return toastify('failure', "Please enter Old password");
        }
        if (changePassword && (!newPassword)) {
            return toastify('failure', "Please enter New password");
        }
        // if (location.pathname.includes('update-profile') && !communicationAddress) {
        //     return toastify('failure', "Please enter Communication Address");
        // }
        const payload = {
            API_TYPE: "profile",
            first_name: firstName,
            last_name: lastName,
            email,
            linkedin,
            facebook,
            instagram,
            twitter,
            // timezone: timezone,
            tiktok,
            changePassword,
            id: location.pathname.includes('update-profile') ? cID : data.id,
            contactdetails: {
                cell_number: phone,
                work_number: workNumber,
                // communication_address: communicationAddress,
            }
        }
        

        setLoading(true)
        axios
            .put(`${url}/update-user`, payload, {
                headers: {
                    Authorization: data.authToken,
                },
            })
            .then((res) => {
                

                if (res?.data?.statusCode !== 200) {
                    setLoading(false)
                    toastify(
                        'failure', `${res?.data?.body?.message}`
                    )
                }
                else {
                    setLoading(false)
                    if (location.pathname.includes('update-profile')) {
                        // setShowModal(true)
                        forgotPassword();
                    } else {
                        toastify(
                            'success', `Profile updated successfully.`
                        )
                    }
                }

                
            })
            .catch((error) => {
                setLoading(false)
                

                toastify(
                    'failure',
                    error.response.data.message.length > 0
                        ? error.response.data.message
                        : 'Something went wrong'
                )
            }
            );
        // setUser((prev:any)=>({...prev,timezone:timezone}))

    }


    const handleFileChange = (e: any) => {

        
        var reader = new FileReader();
        var url = reader.readAsDataURL(e.target.files[0]);

        const imgSize = e.target.files[0].size / 1000 / 1000

        if (e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "image/png" && e.target.files[0].type !== "image/jpg") {
            return toastify(
                'failure', `Unsupported file format (Supported formats: png, jpeg, jpg)`
            )
        }
        if (imgSize >= 2) {
            return toastify(
                'failure', `The file is ${imgSize.toFixed(2)}MB exceeding the maximum file size limit of 2MB`
            )
        }
        setFile(e.target.files[0])
        reader.onloadend = function (e) {
            setImgUrl(reader.result)
        }
    }


    const uploadprofilePic = () => {
        const config = {
            id: location.pathname.includes('update-profile') ? cID : data.id,
            imgBase64: imgUrl
        }
        setLoading(true)
        axios
            .put(`${url}/update-user`, config, {
                headers: {
                    Authorization: data.authToken,
                },
            })
            .then((res) => {
                if (res?.data?.statusCode !== 200) {
                    setLoading(false)
                    toastify(
                        'failure', `${res?.data?.body?.message}`
                    )
                }
                else {
                    setLoading(false)
                    setUploaded(true);
                    setFile(null)
                    toastify(
                        'success', `Profile uploaded successfully.`
                    )
                }

            })
            .catch((error) => {
                setLoading(false)
                toastify(
                    'failure',
                    error.response.data.message.length > 0
                        ? error.response.data.message
                        : 'Something went wrong'
                )
            }
            );
    }

    const handleClose = () => {
        setShowReset(false)
    }
    const handleModalClose = () => {
        setShowModal(false)
    }

    const forgotPassword = () => {
        const originUrl = window.location.origin;
        const url = config['baseHost_backend'] + '/forgot-passowrd';
        setLoading(true)
        const type = location.pathname.includes('update-profile') ? "updateUser" : "forgotPassword"
        axios
            .post(url, { email, type,user_email:email,WEBSITE:originUrl })
            .then((response) => {
                
                
                if (response?.data?.statusCode === 200 || response?.data?.StatusCode === 200) {
                    setLoading(false)
                    // 
                    // location.pathname.split("/").includes('update-profile') ? setShowModal(true) : setShowReset(true)
                    location.pathname.includes('update-profile') ? setShowModal(true) : setShowReset(true)
                }
                else {
                    setLoading(false)
                    toastify('failure', 'User does not exist')
                }

            }).catch((err) => {
                setLoading(false)
                
            })
    }

    return (
        <div className=' p-3 bg-light'>
            <LoadingBar isActive={loading} />
            <Grid container spacing={0} px={3} className="bg-white p-3">
                <Grid item lg={6} className="d-flex flex-column w-100 justify-content-ce">
                    <div className='ml-auto mr-auto'>
                        <div className="profilepic">
                            <img className="profilepic__image ml-auto mr-auto" src={!imgUrl ? profile : imgUrl} alt="Profibild" height="250px" width="250px" />
                            <div className="profilepic__content" onClick={() => { file ? uploadprofilePic() : fileUpload.current.click() }}>
                                <span className="profilepic__icon">{file ? <i className="fas fa-upload"></i> : <i className="fas fa-camera"></i>}</span>
                                <span className="profilepic__text">{file ? 'Upload' : 'Edit Profile'}</span>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <input type="file" name="" id="" ref={fileUpload} onChange={handleFileChange} hidden accept="image/x-png,image/gif,image/jpeg" />
                            <span className='text-center'>{firstName + ' ' + lastName}</span>
                            <span className='text-center h6 pb-0'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                            </svg> {email}</span>
                            <div className="form-group mt-3 w-100">
                                <div className="input-group d-flex ml-auto mr-auto">
                                    <Grid container className="ml-auto mr-auto" spacing={2} >
                                        <Grid item lg={5} md={5} sm={12} xs={12}>
                                            <div className='d-flex'>
                                                <div className="input-group-addon bg-dark rounded">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor" className="mt-1 bi p-1 text-white bi-tiktok" viewBox="0 0 16 16">
                                                        <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                                                    </svg>
                                                </div>
                                                <Form.Control
                                                    value={tiktok}
                                                    onChange={(e: any) => { setTiktok(e.target.value) }}
                                                    className='form-input p-2'
                                                    size='sm'
                                                    type='input'
                                                    placeholder='Enter TikTok Profile'
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={12} xs={12}>
                                            <div className='d-flex'>
                                                <div className="input-group-addon bg-dark rounded">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor" className="mt-1 text-white p-1 bi bi-facebook" viewBox="0 0 16 16">
                                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                    </svg>
                                                </div>
                                                <Form.Control
                                                    value={facebook}
                                                    onChange={(e: any) => { setFacebook(e.target.value) }}
                                                    className='form-input p-2'
                                                    size='sm'
                                                    type='input'
                                                    placeholder='Enter Facebook Profile'
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={12} xs={12}>
                                            <div className='d-flex'>
                                                <div className="input-group-addon bg-dark rounded">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor" className="mt-1 p-1 text-white bi bi-twitter" viewBox="0 0 16 16">
                                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                    </svg>
                                                </div>
                                                <Form.Control
                                                    value={twitter}
                                                    onChange={(e: any) => { setTwitter(e.target.value) }}
                                                    className='form-input p-2'
                                                    size='sm'
                                                    type='input'
                                                    placeholder='Enter Twitter Profile'
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={12} xs={12}>
                                            <div className='d-flex'>
                                                <div className="input-group-addon bg-dark rounded">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor" className="mt-1 text-white p-1 bi bi-instagram" viewBox="0 0 16 16">
                                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                                    </svg>
                                                </div>
                                                <Form.Control
                                                    value={instagram}
                                                    onChange={(e: any) => { setInstagram(e.target.value) }}
                                                    className='form-input p-2'
                                                    size='sm'
                                                    type='input'
                                                    placeholder='Enter Instagram Profile'
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={12} xs={12}>
                                            <div className='d-flex'>
                                                <div className="input-group-addon rounded">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" className="bi bi-linkedin " viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                    </svg>
                                                </div>
                                                <Form.Control
                                                    value={linkedin}
                                                    onChange={(e: any) => { setLinkedin(e.target.value) }}
                                                    className='form-input p-2'
                                                    size='sm'
                                                    type='input'
                                                    placeholder='Enter Linkedin Profile'
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Row>
                        <h3 className='py-3 font-weight-bold'>Edit Personal Information</h3>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group >
                                <Form.Label className='text required'>First Name<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        required
                                        className='form-input cust-padding'
                                        size='sm'
                                        type='input'
                                        value={firstName}
                                        onChange={(e: any) => setFirstName(e.target.value)}
                                        placeholder='Enter name'
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group >
                                <Form.Label className='text'>Last Name{location.pathname.includes('update-profile') && <span className='text-danger h6 font-weight-bold'>*</span>}</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input cust-padding'
                                        size='sm'
                                        type='input'
                                        value={lastName}
                                        onChange={(e: any) => setLastName(e.target.value)}
                                        placeholder='Enter name'
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group >
                                <Form.Label className='text'>Email<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        disabled
                                        value={email}
                                        onChange={(e: any) => setEmail(e.target.value)}
                                        placeholder='Enter Email'
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group >
                            <Form.Label className='text'>Company<span className='text-danger h6 font-weight-bold'>*</span></Form.Label>
                            <InputGroup className='mb-3'>
                                <Form.Control
                                    className='form-input'
                                    size='sm'
                                    type='input'
                                    disabled
                                    value={company}
                                    onChange={(e: any) => setCompany(e.target.value)}
                                    placeholder='Company Name'
                                />
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label className='text'>Cell Number{location.pathname.includes('update-profile') && <span className='text-danger h6 font-weight-bold'>*</span>}</Form.Label>
                            <InputGroup className='mb-3'>
                                <Form.Control
                                    className='form-input'
                                    size='sm'
                                    type='input'
                                    value={phone}
                                    onChange={(e: any) => setPhone(e.target.value)}
                                    placeholder='Enter Cell Number'
                                />
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label className='text'>Work Number</Form.Label>
                            <InputGroup className='mb-3'>
                                <Form.Control
                                    className='form-input'
                                    size='sm'
                                    type='input'
                                    value={workNumber}
                                    onChange={(e: any) => setworkNumber(e.target.value)}
                                    placeholder='Enter Work Number'
                                />
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    {/* //removing bellow on stanly request */}
                    {/* <Row>
                        <Form.Group>
                            <Form.Label className='text'>Communication Address{location.pathname.includes('update-profile') && <span className='text-danger h6 font-weight-bold'>*</span>}</Form.Label>
                            <InputGroup className='mb-3'>
                                <Form.Control
                                    className='form-input'
                                    size='sm'
                                    type='input'
                                    value={communicationAddress}
                                    onChange={(e: any) => setcommunicationAddress(e.target.value)}
                                    placeholder='Enter Communication Address'
                                />
                            </InputGroup>
                        </Form.Group>
                    </Row> */}
                    {!location.pathname.includes('update-profile') && <Row>
                        <span className='my-0 text-danger reset-txt' onClick={() => { forgotPassword() }}>Reset Password</span>
                    </Row>}
                    <Row>
                        <Form.Group>
                        </Form.Group>
                    </Row>
                    <Form.Group>
                        <button className='btn-sm my-3 btn pbuttonbgcolor' onClick={updateProfile}>
                            Update Profile
                        </button>
                    </Form.Group>

                </Grid>
                <AlertModal show={showReset} handleClose={handleClose} email={email} />
                <ProfileUpdatedalert show={showModal} handleClose={handleModalClose} email={email} />
            </Grid>
        </div>
    );
};

export default EditProfile;
