/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useState ,useEffect, useRef} from "react";
import { UserContext } from "../common/UserContext";
import "./index.css";
import { useLocation } from "react-router-dom";
import DropdownSelect from "./select";
import logout from "../../assets/images/logout.svg";
// import logo from "../../assets/images/Cognitgo_Logo-Dark.png";
import logo from '../../assets/images/Cognitgo_Logo_new.png'
import { useHistory } from "react-router";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./drawer.css";
import rapicon from "../../assets/images/rapIcon.png";
import dashboard from "../../assets/images/dashboard.svg";
import _ from "lodash";
import calender from '../../assets/icons/calender.svg'
import notoficationBell from '../../assets/images/notificationbell.svg';
import {
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
  Dropdown ,
} from "react-bootstrap";
import { useQueryClient, useQuery } from "react-query";
import { userListApi } from "../../controllers/users";
import { Drawer } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import chartBar from "../../assets/images/chart-line-solid.svg";
import { accessRoles } from "../../controller";
import users from "../../assets/images/users.svg";
import books from "../../assets/images/books.svg";
import './sideBar.css'
import { useAppContext } from "../../AppContext";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Hidden } from "@material-ui/core";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import config from "../../config.json";
import axios from "axios";

import NotificationComp from "./Notificationpopup"

let token: any = localStorage.getItem("$AuthToken");

const useStyles: any = makeStyles({
  paper: {
    background: "black",
    color: "red",
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  item: any;
}


const Topbar = () => {
  const { name, role, type, authToken, setUser, company } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState<any>(false);
  const [openDrawer, setpenDrawer] = useState<any>(false);
  const app: any = useAppContext();
  let QueryClient = useQueryClient();

  


  // const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [boxvalue, setBoxValue] = React.useState(0);
  // const popupRef = useRef(null);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setBoxValue(newValue);
  // };

  // const smsNotiCount = unReadMessagesCount.smsNotificationCount
  // const tenSmsNoti = unReadMessagesCount.first10SmsNotifications
  // const priceNotiCount = unReadMessagesCount.priceNotificationCount
  // const tenPriceNoti = unReadMessagesCount.first10PriceNotiNotifications




  
  const logoutHandler = () => {
    localStorage.clear();
    QueryClient.clear();
    setUser("");
    // const { data: userList, isLoading: isLeadsLoading } = useQuery('users', () =>
    // userListApi(authToken), { staleTime: 3600000 }
    // );

    history.push("/login");
  };
  let word: string = name
    ?.split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "")
    .toUpperCase();
  word = word.length === 1 ? name.substring(0, 2).toUpperCase() : word;

  const dynamicList = [
    { id: 1, label: 'Item 1' },
    { id: 2, label: 'Item 2' },
    { id: 3, label: 'Item 3' },
    // Add more items as needed
  ];

  const array = [
    {
      name: role,
    },
    {
      name: "Logout",
      image: logout,
      clickEvent: logoutHandler,
    },
  ];

  

  function handleClick() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  function handlelogout() {
    localStorage.clear();

    history.push("/login");
    // app.signOut!();
    setOpen(false);
    // app.signOut!();
    window.location.reload()
  }

  const styles = useStyles();
  const pathCheck = location.pathname.replace("/", "").split("/");



  // const handleClickOutside = (event) => {
  //   // if (popupRef.current && !popupRef.current.contains(event.target)) {
  //   //   // Click outside the popup, hide it
  //   //   setDropdownVisible(false);
  //   // }
  // };
  
  // // Add a click event listener when the component mounts
  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);
  
  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  // function handleNotiClick(linkToOpen: any ){
  //   // const linkToOpen = '/your-link';
  //   console.log("opening link -- ",linkToOpen)
  //   // Open link in a new tab
  //   window.open(linkToOpen, '_blank');

  //   // Optionally, push the link to history if you want to maintain routing state
  //   history.push(linkToOpen);
  // }

  return (
    <div className="topbar-height shadow-sm d-flex align-items-center justify-content-between">
      <div className="top-bar-container menu-icon-resp">
        <i
          className="fas fa-bars"
          style={{ fontSize: "30px" }}
          onClick={() => {
            setpenDrawer(true);
          }}
        ></i>
      </div>
      <div className="top-bar-container logo-margin">
        <div>
          {/* <p style={{ marginBottom: '0rem', fontSize: '1.125rem' }}>Cognitgo</p> */}
          <img src={logo} alt="cognitgo logo" width="170" />
        </div>
      </div>



      <div className="d-flex flex-row w-25  justify-content-right justify-content-end" style={{ gap: '45px' }}>
        {!location.pathname.includes("update-profile") && < NotificationComp />}
    
        {!location.pathname.includes("update-profile") && (
          <button
            className="user-logo-length rounded-circle  margin_c mt-auto mb-auto"
            type="button"
            onClick={handleClick}
          >
            {name.split(" ")[0][0].toUpperCase()}
          </button>
        )}

      </div>



      {/* Drawer for mobile devices starts */}
      <Drawer
        open={openDrawer}
        PaperProps={{
          sx: {
            backgroundColor: "#30346b",
            color: "white",
            width: "100%",
            justifyContent: "left",
          },
        }}
      >
        <MenuItem
          style={{ height: "70px" }}
          className="bg-dark d-flex  align-items-center justify-content-between"
        >
          <div className="">
            <Image className="logo" src={rapicon} />
          </div>
          <div
            onClick={() => {
              setpenDrawer(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </div>
        </MenuItem>
        <MenuItem className="d-flex  align-items-center justify-content-left">
          <div
            className={
              pathCheck[0] === "dashboard" ? "sdr_item sdr_active" : "sdr_item"
            }
            onClick={() => {
              setpenDrawer(false);
              history.push(`/dashboard`);
            }}
          >
            <img
              src={chartBar}
              alt={"Dashboard"}
              width={"20px"}
              style={{ marginRight: "10px" }}
            />
            <span className="pr-2">Dashboard</span>
          </div>
        </MenuItem>

        <MenuItem>
          {accessRoles
            ?.filter(
              (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
            )[0]
            ?.permissions?.includes("allowLeadsandOpportunities") && (
              <div
                className={
                  pathCheck[0] === "leads" ? "sdr_item sdr_active" : "sdr_item"
                }
                onClick={() => {
                  setpenDrawer(false);
                  history.push(`/leads`);
                }}
              >
                <img
                  src={books}
                  alt={"leads"}
                  width={"20px"}
                  style={{ marginRight: "10px" }}
                />
                <span className="pr-2">Leads and Opportunities</span>
              </div>
            )}
        </MenuItem>
        <MenuItem>
          {accessRoles
            ?.filter(
              (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
            )[0]
            ?.permissions?.includes("allowDealersandCorporate") && (
              <div
                className={
                  pathCheck[0] === "dealers" ? "sdr_item sdr_active" : "sdr_item"
                }
                onClick={() => {
                  setpenDrawer(false);
                  history.push(`/dealers`);
                }}
              >
                <img
                  src={dashboard}
                  alt={"dealers"}
                  width={"20px"}
                  style={{ marginRight: "10px" }}
                />
                <span className="pr-2">Dealers</span>
              </div>
            )}
        </MenuItem>
        <MenuItem>
          {accessRoles
            ?.filter(
              (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
            )[0]
            ?.permissions?.includes("allowUsers") && (
              <div
                className={
                  pathCheck[0] === "users" ? "sdr_item sdr_active" : "sdr_item"
                }
                onClick={() => {
                  setpenDrawer(false);
                  history.push(`/users`);
                }}
              >
                <img
                  src={users}
                  alt={"users"}
                  width={"20px"}
                  style={{ marginRight: "10px" }}
                />
                <span className="pr-2">Users</span>
              </div>
            )}
        </MenuItem>
      </Drawer>
      {/* Drawer for mobile devices end */}

      <Menu
        id="basic-menu"
        // anchorEl={open}
        open={open}
        style={{ marginTop: "40px" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-top",
        }}
      >
        <MenuItem onClick={handleClose} className="menu-parent">
          <div>
            <button
              className="user-logo-length rounded-circle p-1"
              type="button"
              //   style={{marginRight:'30px'}}
              onClick={handleClick}
            >
              {name.split(" ")[0][0]}
            </button>
          </div>
          <div className="d-flex flex-column">
            <span className="name_menu">{name.split(" ")[0]}</span>
            <div className="user-info-box">
              {type==="dealer" && <span className="badge text-dark bg-light pl-0 ">
                {company}
              </span>}
              <span className="badge text-dark bg-light pl-0 ">
                {type} | {role}
              </span>
            </div>
            {/* <span className="badge text-dark bg-white pl-0 ml-0" style={{textTransform:'capitalize'}}>
              
            </span> */}
          </div>
        </MenuItem>
        <hr />
        <MenuItem
          onClick={() => {
            history.push("/edit-profile");
            setOpen(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className=" bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fill-rule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            />
          </svg>
          <span className="action-label-manu">Edit Profile</span>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            history.push("/mycalendar");
            setOpen(false);
          }}
        >
          <img src={calender} alt="Calendar" />
          <span className="action-label-manu">My Calendar</span>
        </MenuItem> */}
        <MenuItem onClick={handlelogout}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className=" bi bi-box-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
            />
            <path
              fill-rule="evenodd"
              d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
            />
          </svg>
          <span className="action-label-manu">Logout</span>
        </MenuItem>
      </Menu>
    </div>
  );
};
export default Topbar;
