import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { toastify } from "../common/notification";

import {
  Col,
  Row,
  Button,
  Image,
  InputGroup,
  Form,
  Card,
  Table,
  Modal,
} from "react-bootstrap";
import plus from "../../assets/images/plus.svg";
import leftArrow from "../../assets/images/leftArrow.svg";
import { CSVLink } from "react-csv";
import { UserContext } from "../common/UserContext";
import _ from "lodash";
import "./dealerList.css";
import Select from "react-select";
import Loader from "../common/Loader";
import { corporateListApi, createDealerApi } from "../../controllers/dealers";
import states1 from "../common/states";
import LoadingBar from "../common/LoadingBar";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import Operationhours from "./Operationhours";
import moment from "moment";
import { accessRoles, dealerProcesses } from "../../controller";
import { userListApi } from "../../controllers/users";
import { useQuery } from "react-query";
import { agentTypes } from "../common/globalValues";
import Addcontacts from "./Addcontacts";
import deleteIcon from "../../assets/images/delete.svg";
import ToggleSwitch from "../common/toggleSwitch";
import { createOutlookAccount } from "../../GraphService";
import { useAppContext } from "../../AppContext";
import axios from "axios";
import config from "../../config.json";
import { F } from "ramda";
const CreateDealer = () => {
  // const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [dealerUsers, setDealerUsers] = React.useState<any>(null);
  const [filterdExcelData, setFilteredExcelData] = useState<any>(null);
  const [name1, setName] = React.useState<string>("");
  const [shortName, setShortName] = React.useState<any>("");
  const [address, setAddress] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [twilioPhone, setTwilioPhone] = React.useState<string>("");
  const [subscriptionFee, setSubscriptionFee] = React.useState<string>("");
  const [websiteUrl, setWebsiteUrl] = React.useState<string>("");
  const [timezone, setTimezone] = React.useState<string>("");
  const [crmName, setCrmName] = React.useState<string>("");
  const [emailVerified, setEmailVerified] = React.useState<any>(false);
  const [crmAdfMail, setCrmAdfMail] = React.useState<string>("");
  const [bdcEmail, setBdcEmail] = React.useState<string>("");
  const [inventoryTool, setInventoryTool] = useState<string>("");
  const [preferredBook, setPreferredBook] = useState<string>("");
  const [contactName, setContactName] = useState<string>("");
  const [dealershipGroup, setDealershipGroup] = useState<string>("");
  const [dealershipGroupName, setDealershipGroupName] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [dealType, setDealType] = React.useState("");
  const [workingHrs, setWorkingHrs] = useState<any>(null);
  const [status, setStatus] = React.useState(true);
  const [flat_houseno_building, setFlat_houseno_building] =
    useState<string>("");
  const [street_1, setStreet_1] = useState<string>("");
  const [aliasName, setAliasName] = useState<any>("");
  const [street_2, setStreet_2] = useState<string>("");
  const [area_sector, setArea_sector] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [tempEMail, setTempEmail] = React.useState<any>(null);
  const [state, setState] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [states, setStates] = useState<any>(null);
  const [corporates, setCorporates] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [imgUrl, setImgUrl] = useState<any>(null);
  const [s3Url,setS3Url]=useState<any>(null)
  const [loading, setLoading] = useState<any>(false);
  const [deleteContactID, setDeleteContactID] = useState<any>("");
  const [deleteContactEmail, setDeleteContactEmail] = useState<any>("");
  const [emailDisabled, setEmailDisabled] = useState<any>(true);
  const { authToken, role, type, dealerID, corporateID, name, email } =
    useContext(UserContext);
  const [values, setValues] = useState<DateObject | DateObject[] | null>([]);
  const [years, setYears] = useState<any>([]);
  const history = useHistory();
  const [defaultValues, setDefaultValues] = useState<any>(null);
  const [show, setShow] = useState<any>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false);
  const handleClose = () => setShow(false);
  const [csvData, setCsvData] = useState<any>(null);
  const [YearData, setYearData] = useState<any>(null);
  const [contacts, setContacts] = useState<any>([]);
  const [showContactModal, setShowContactModal] = useState<any>(false);
  const [selectedProcess, setSelectedProcess] = useState<any>([]);
  const app: any = useAppContext();
  const fileRef = useRef<any>(null);
  const location: {
    pathname: any;
    state: any;
    search: any;
  } = useLocation();
  const monthRef = useRef<any>(null);
  let re = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  // const { data: userList, isLoading: isLeadsLoading } = useQuery('users', () =>
  //     userListApi(authToken), { staleTime: 3600000 }
  // );

  const fetchData = async () => {
    if (location.pathname.includes("edit")) {
      let resp = await axios.get(
        config.baseHost_backend +
          "/fetch-dealer-detail/" +
          location.pathname.split("/")[3],
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      return resp?.data?.body;
    }
  };

  const { data, refetch, isLoading } = useQuery(
    `fetch-dealer-detail-${location.pathname.split("/")[3]}`,
    fetchData
  );

  // useEffect(()=>{

  //     const fetchData=async()=>{
  //         let resp=await axios.get(config.baseHost_backend+"/fetch-dealer-detail/"+location.pathname.split("/")[3],{
  //             headers:{
  //                 Authorization: authToken
  //             }
  //         })
  //         console.log("dealer",resp);

  //     }
  //     fetchData()
  // },[])

  // useEffect(() => {
  //     if (userList && location.pathname.includes("edit")) {
  //         const dealerUsers = userList?.body?.data?.filter((obj: any) =>
  //             obj.dealerID === location?.state?.obj?.dealer_id
  //         )

  //         setDealerUsers(dealerUsers)

  //     }
  // }, [location.pathname, location?.state?.obj?.dealer_id, userList])

  const handleCreate = (e) => {
    e.preventDefault();
    if (!name1) {
      return toastify("failure", "Dealer Name cannot be empty");
    }
    if (!shortName) {
      return toastify("failure", "Dealer short name cannot be empty");
    }
    if (!aliasName) {
      return toastify("failure", "Dealer alias name cannot be empty");
    }
    if (!phone) {
      return toastify("failure", "Phone number cannot be empty");
    }
    if (twilioPhone && !re.test(twilioPhone)) {
      return toastify(
        "failure",
        "Invalid twilio phone number, Please include country code with +"
      );
    }
    if (!websiteUrl) {
      return toastify("failure", "Website URL cannot be empty");
    }
    if (!timezone) {
      return toastify("failure", "Please select the timezone");
    }
    if (!crmName) {
      return toastify("failure", "CRM name cannot be empty");
    }
    if (!crmAdfMail) {
      return toastify("failure", "Email cannot be empty");
    }
    // if (!dealershipGroup || dealershipGroup === "null") {
    //   return toastify("failure", "Please select the corporate");
    // }
    if (!state) {
      return toastify("failure", "Please select the State");
    }
    if (!workingHrs) {
      return toastify(
        "failure",
        "Please select and configure the working hours"
      );
    }
    setLoading(true);
    const dealerData = {
      dealer_name: name1?.trim(),
      website: websiteUrl,
      timezone,
      contact_name: contactName,
      phone: phone,
      address,
      crm_name: crmName,
      adf_email: crmAdfMail,
      preferred_book: preferredBook,
      inventory_tool: inventoryTool,
      corporateID: dealershipGroup,
      corporateName: dealershipGroupName,
      notes: notes,
      subscriptionFee,
      deal_type: dealType,
      is_active: true,
      is_update: 0,
      flat_houseno_building,
      street_1,
      street_2,
      area_sector,
      city,
      state,
      pincode,
      twilioPhone: formatNumber(twilioPhone),
      workingHours: workingHrs,
      contacts: contacts,
      createdBy: {
        name,
        email,
        role,
      },
      bdcEmail,
      shortName,
      aliasName: aliasName.trim(),
      base64: imgUrl || null,
      logoExtension: file?.split(".").pop() || null,
      process: selectedProcess
        ?.filter((d) => d.value !== "All process")
        ?.map((sp) => sp.value),
    };

    console.log("payload", dealerData);

    createDealerApi(authToken, dealerData).then((res) => {
      console.log("res", res);

      if (res.statusCode === 400) {
        setLoading(false);
        toastify("failure", res?.body?.message);
      } else {
        setLoading(false);
        toastify("success", res?.message || res?.body?.message);

        handleBack();
      }
    });
  };

  const verifyEmail = (e) => {
    e.preventDefault();
    let body = {
      isEmail_validation: true,
      email: crmAdfMail,
    };
    createDealerApi(authToken, body).then((res) => {
      if (res.statusCode === 400) {
        setLoading(false);
        toastify("failure", res?.body?.message);
      } else {
        setLoading(false);
        setEmailVerified(true);
        toastify("success", res?.body?.message);

        // handleBack();
      }
    });
  };

  const formatNumber = (number) => {
    let splited = number?.split(" ");
    if (splited) {
      return splited.join("");
    }
  };

  const handleUpdate = (e, afterDelete, mesg) => {
    e.preventDefault();

    if (!name1) {
      return toastify("failure", "Dealer Name cannot be empty");
    }
    if (!shortName) {
      return toastify("failure", "Dealer short name cannot be empty");
    }
    if (!aliasName) {
      return toastify("failure", "Dealer alias name cannot be empty");
    }
    if (!phone) {
      return toastify("failure", "Phone number cannot be empty");
    }
    if (twilioPhone && !re.test(twilioPhone)) {
      return toastify(
        "failure",
        "Invalid twilio phone number, Please include country code with +"
      );
    }
    if (!websiteUrl) {
      return toastify("failure", "Website URL cannot be empty");
    }
    if (!timezone) {
      return toastify("failure", "Please select the timezone");
    }
    if (!crmAdfMail) {
      return toastify("failure", "Email cannot be empty");
    }
    // if (!dealershipGroup || dealershipGroup === "null") {
    //   return toastify("failure", "Please select the corporate");
    // }
    if (!state) {
      return toastify("failure", "Please select the State");
    }
    const dealerData = {
      id: location.state.obj._id,
      dealer_id: location.state.obj.dealer_id,
      dealer_name: name1?.trim(),
      website: websiteUrl,
      timezone,
      contact_name: contactName,
      phone: phone,
      address,
      subscriptionFee,
      crm_name: crmName,
      adf_email: crmAdfMail,
      preferred_book: preferredBook,
      inventory_tool: inventoryTool,
      corporateID: dealershipGroup,
      corporateName: dealershipGroupName,
      notes: notes,
      deal_type: dealType,
      is_active: status,
      is_update: 1,
      flat_houseno_building,
      street_1,
      street_2,
      area_sector,
      city,
      state,
      contacts: afterDelete || contacts,
      pincode,
      bdcEmail,
      twilioPhone: formatNumber(twilioPhone),
      shortName,
      workingHours: workingHrs,
      aliasName: aliasName.trim(),
      emailValidationRequired: tempEMail === crmAdfMail ? false : true,
      base64: imgUrl || null,
      logoExtension: file?.split(".").pop() || null,
      process: selectedProcess
        ?.filter((f) => f.value !== "All process")
        ?.map((sp) => sp.value),
    };
    console.log("upate payload", dealerData);
    
    setLoading(true);
    createDealerApi(authToken, dealerData).then((res) => {
      if (res.statusCode === 400) {
        setLoading(false);
        toastify("failure", res?.message);
      } else {
        setLoading(false);
        toastify("success", mesg || "Details updated successfully.");
        handleBack();
      }
    });
  };
  // useEffect(() => {
  //     corporateList();
  // }, [])

  const corporateList = () => {
    corporateListApi(localStorage.getItem("$AuthToken"), { getAll: true })
      .then((response: any) => {
        setCorporates(response?.body);
      })
      .catch((err: any) => {});
  };

  const handleBack = () => {
    if (location?.state?.navigateFrom === "leadDeatilsPage") {
      history.goBack();
      return;
    }
    history.push({
      pathname: "/dealers",
      state: {
        dataChanged: true,
      },
    });
  };
  useEffect(() => {
    setStates(states1);
    corporateList();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      const state: any = history.location.state;
      setName(data?.dealerInfo?.dealer_name);
      setShortName(data?.dealerInfo?.shortName);
      setAliasName(data?.dealerInfo?.aliasName);
      setAddress(data?.dealerInfo?.address);
      setWebsiteUrl(data?.dealerInfo?.website);
      setTimezone(data?.dealerInfo?.timezone);
      setCrmName(data?.dealerInfo?.crm_name);
      setStatus(data?.dealerInfo?.is_active);
      setCrmAdfMail(data?.dealerInfo?.adf_email);
      setBdcEmail(data?.dealerInfo?.bdcEmail);
      setTempEmail(data?.dealerInfo?.adf_email);
      setPreferredBook(data?.dealerInfo?.preferred_book);
      setInventoryTool(data?.dealerInfo?.inventory_tool);
      setDealershipGroup(data?.dealerInfo?.corporateID);
      setDealershipGroupName(data?.dealerInfo?.corporateName);
      setNotes(data?.dealerInfo?.notes);
      setDealType(data?.dealerInfo?.deal_type);
      setContactName(data?.dealerInfo?.contact_name);
      setPhone(data?.dealerInfo?.phone);
      setTwilioPhone(data?.dealerInfo?.twilioPhone);
      setContacts(
        data?.dealerInfo?.contacts ? data?.dealerInfo?.contacts : contacts
      );
      setFlat_houseno_building(data?.dealerInfo?.flat_houseno_building);
      setStreet_1(data?.dealerInfo?.street_1);
      setStreet_2(data?.dealerInfo?.street_2);
      setSubscriptionFee(data?.dealerInfo?.subscriptionFee);
      setArea_sector(data?.dealerInfo?.area_sector);
      setCity(data?.dealerInfo?.city);
      setState(data?.dealerInfo?.state);
      setPincode(data?.dealerInfo?.pincode);

      setDealerUsers(data?.userList);
      setDefaultValues(data?.dealerInfo?.workingHours);
      setWorkingHrs(data?.dealerInfo?.workingHours);
      setS3Url(data?.dealerLogo)
      // setFile(data?.dealerInfo?.dealerLogoFilename || null);
      setImgUrl(data?.dealerInfo?.dealerLogo);
      let temp = [] as any;
      data?.dealerInfo?.process?.forEach((ss) => {
        temp.push({ label: ss, value: ss });
      });
      setSelectedProcess(temp);
      console.log("aa", temp);

      const filterd = data?.roi;
      setFilteredExcelData(filterd);
      filterd?.forEach((obj: any) => {
        let year: any = new Date(obj["SoldDate"]).getFullYear();
        years.push(year);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, history, data]);

  if (isLoading) {
    return <Loader />;
  }

  const handleFromYearChange = (dates: any) => {
    if (dates.length === 1) {
      // setFromDate(dates[0].toString());
      // setToDate(moment(dates[0].toString()).add(1, 'days').toString())
    }
    if (dates.length === 2) {
      // setFromDate(dates[0].toString());
      // setToDate(moment(dates[1].toString()).add(1, 'days').toString())
    }
    if (dates.length === 0) {
      // setFromDate(null);
      // setToDate(null)
      // setFilters({ ...filters, fromDate: null, toDate: null })
    }
    setValues(dates);
  };
  // let YearData: any;
  const handleYearSelected = (e: any) => {
    if (e.target.value === "null") {
      monthRef.current.selectedIndex = 0;
    }
    let yearData = filterdExcelData?.filter((obj: any) => {
      let year: any = new Date(obj["SoldDate"]).getFullYear();

      return year.toString() === e.target.value.toString();
    });
    setYearData(yearData);
    setCsvData(structureData(yearData));
  };

  const handleMonthSelected = (e: any) => {
    if (e.target.value === "all") {
      return setCsvData(structureData(YearData));
    }

    const monthData = YearData?.filter((obj: any) => {
      let month = moment(new Date(obj["SoldDate"])).format("LL").split(" ")[0];

      return month === e.target.value;
    });

    setCsvData(structureData(monthData));
  };

  const download = (e) => {
    e.preventDefault();
  };

  const structureData = (data: any) => {
    const tempArrays = [] as any;
    // eslint-disable-next-line array-callback-return
    data?.map((obj: any) => {
      tempArrays.push({
        FirstName: obj["FirstName"],
        LastName: obj["LastName"],
        Email: obj["Email"],
        EvePhone: obj["EvePhone"],
        CellPhone: obj["CellPhone"],
        DayPhone: obj["DayPhone"],
        VehicleStockNumber: obj["VehicleStockNumber"],
        VehicleVIN: obj["VehicleVIN"],
        VehicleYear: obj["VehicleYear"],
        VehicleMake: obj["VehicleMake"],
        VehicleModel: obj["VehicleModel"],
        SoldDate: obj["SoldDate"],
        FrontGross: obj["FrontGross"],
        BackGross: obj["BackGross"],
        "Total Gross": obj["Total Gross"],
        SellingPrice: obj["SellingPrice"],
        "P/L": obj["P/L"],
        InventoryType: obj["InventoryType"],
        "Up Type": obj["Up Type"],
        LeadSource: obj["LeadSource"],
        SoldStatus: obj["SoldStatus"],
      });
    });
    return tempArrays;
  };

  const handleCorporateSelected = (event: any) => {
    const selectedOption = event.target.selectedOptions[0];
    const objectString = selectedOption.getAttribute("data-object");
    const object = JSON.parse(objectString);

    setDealershipGroup(object?.ID);
    setDealershipGroupName(object?.corporateName);
  };

  const handleDeletecontact = (e) => {
    let afterDelete;
    if (deleteContactEmail) {
      afterDelete = contacts?.filter(
        (cont) => cont.email !== deleteContactEmail
      );
    } else if (deleteContactID) {
      afterDelete = contacts?.filter((cont) => cont.id !== deleteContactID);
    }

    setDeleteContactEmail("");
    setDeleteContactID("");
    setContacts(afterDelete);
    setShowDeleteModal(false);
    handleUpdate(e, afterDelete, "Contact deleted successfully");
    // toastify('success', 'Contact deleted successfully')
  };

  const handleFilChange = (e) => {
    if (
      e.target.files[0].type !== "image/jpeg" &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpg"
    ) {
      return toastify(
        "failure",
        `Unsupported file format (Supported formats: png, jpeg, jpg)`
      );
    }
    var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);

    const imgSize = e.target.files[0].size / 1000 / 1000;

    if (imgSize >= 2) {
      return toastify(
        "failure",
        `The file is ${imgSize.toFixed(
          2
        )}MB exceeding the maximum file size limit of 2MB`
      );
    }
    setFile(e.target.files[0]?.name);
    console.log("file", e.target.files[0]);

    reader.onloadend = function (e) {
      setImgUrl(reader.result);
    };
  };

  const handleProcessChange = (value, event) => {
    if (
      event.action === "select-option" &&
      event.option.value === "All process"
    ) {
      setSelectedProcess(dealerProcesses);
      console.log(1);
    } else if (
      event.action === "select-option" &&
      value?.length === dealerProcesses.length - 1
    ) {
      setSelectedProcess(dealerProcesses);
      console.log(5);
    } else if (
      event.action === "select-option" &&
      event.option.value !== "All process"
    ) {
      setSelectedProcess(value?.filter((o) => o.value !== "All process"));
      console.log(2);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All process"
    ) {
      setSelectedProcess([]);
      console.log(3);
    } else if (event.action === "deselect-option") {
      setSelectedProcess(value.filter((o) => o.value !== "All process"));
      console.log(4);
    } else {
      setSelectedProcess(value);
      console.log(5);
    }
  };

  return (
    <div className="containerBox">
      <LoadingBar isActive={loading || isLoading} />
      {/* Topbar Code*/}
      <Row className="spacing-2">
        <Col className="button-col">
          <div className="spacing-1-1">
            <div>
              <Image
                onClick={() => {
                  handleBack();
                }}
                className="pointer"
                src={leftArrow}
              />
            </div>
            <div className="pad-button text-name">
              {location.pathname.includes("create")
                ? "Create Dealer"
                : "Update Dealer"}
            </div>
          </div>
        </Col>
      </Row>
      <hr className="divider-line" style={{ marginBottom: "0px" }} />

      <Row className="spacing-1">
        <Card>
          <div className="form-container">
            <span className="">
              Required fields are indicated with "
              <span className="text-danger h6 font-weight-bold">*</span>"
            </span>
            <Form className="mt-3">
              <Row className="">
                {/* Name Field */}
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Dealer Name
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={name1}
                      onChange={(e: any) => setName(e.target.value)}
                      placeholder="Enter name"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Dealer short name
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={shortName}
                      onChange={(e: any) => setShortName(e.target.value)}
                      placeholder="Enter dealer short name"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Dealer alias name
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      // disabled={location.pathname.includes('edit')}
                      className="form-input"
                      size="sm"
                      type="input"
                      value={aliasName}
                      onChange={(e: any) => setAliasName(e.target.value)}
                      placeholder="Enter alias name"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Phone
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={phone}
                      onChange={(e: any) => setPhone(e.target.value)}
                      placeholder="Enter phone"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Twilio phone [with country code]
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={twilioPhone}
                      onChange={(e: any) => setTwilioPhone(e.target.value)}
                      placeholder="Enter twilio phone"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Website
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={websiteUrl}
                      onChange={(e: any) => setWebsiteUrl(e.target.value)}
                      placeholder="Enter website url"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Timezone
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Select timezone"
                    value={timezone}
                    style={{
                      borderRadius: "4px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    onChange={(e: any) => setTimezone(e.target.value)}
                  >
                    <option>Select timezone</option>
                    <option value="PST">PST</option>
                    <option value="MST">MST</option>
                    <option value="CST">CST</option>
                    <option value="EST">EST</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    CRM Name
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={crmName}
                      disabled={
                        location.pathname.includes("edit") ? true : false
                      }
                      onChange={(e: any) => setCrmName(e.target.value)}
                      placeholder="Enter crm name"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    CRM ADF/XML Email
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      disabled={
                        !accessRoles
                          ?.filter(
                            (obj: any) =>
                              _.toLower(obj.role) === _.toLower(type + role)
                          )[0]
                          ?.permissions?.includes("editAdfXmlEmail")
                      }
                      value={crmAdfMail}
                      onChange={(e: any) => setCrmAdfMail(e.target.value)}
                      placeholder="Enter CRM ADF/XML Email"
                    />
                    {!location.pathname.includes("create") &&
                      tempEMail !== crmAdfMail && (
                        <button
                          className="btn btn-success"
                          onClick={verifyEmail}
                        >
                          {emailVerified ? (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-patch-check-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                              </svg>{" "}
                              <span>Validated</span>
                            </>
                          ) : (
                            "Validate"
                          )}
                        </button>
                      )}
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">BDC email</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={bdcEmail}
                      onChange={(e: any) => setBdcEmail(e.target.value)}
                      placeholder="Enter BDC email address"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">Inventory Tool</Form.Label>
                  <Form.Select
                    aria-label="Select inventory tool"
                    value={inventoryTool}
                    style={{
                      borderRadius: "4px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    onChange={(e: any) => setInventoryTool(e.target.value)}
                  >
                    <option>Select inventory tool</option>
                    <option value="inventory tool 1">inventory tool 1</option>
                    <option value="inventory tool 2">inventory tool 2</option>
                    <option value="inventory tool 3">inventory tool 3</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                  className="mb-2"
                >
                  <Form.Label className="text">Preferred Book</Form.Label>
                  <Form.Select
                    aria-label="Select preferred book"
                    className=""
                    style={{
                      borderRadius: "4px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    value={preferredBook}
                    onChange={(e: any) => setPreferredBook(e.target.value)}
                  >
                    <option>Select preferred book</option>
                    <option value="preffered book 1">preferred book 1</option>
                    <option value="preffered book 2">preferred book 2</option>
                    <option value="preffered book 3">preferred book 3</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">Contact Name</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={contactName}
                      onChange={(e: any) => setContactName(e.target.value)}
                      placeholder="Enter contact name"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">Subscription fee</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={subscriptionFee}
                      onChange={(e: any) => setSubscriptionFee(e.target.value)}
                      placeholder="Enter subscription fee"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Corporate
                    {/* {!location.pathname.includes("create") && <span className="text-danger h6 font-weight-bold">*</span>} */}
                  </Form.Label>
                  <Form.Select
                    className=""
                    style={{
                      borderRadius: "4px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    aria-label="Select corporate"
                    value={dealershipGroup}
                    // onChange={(e: any) =>{
                    onChange={(e: any) => {
                      handleCorporateSelected(e);
                    }}
                  >
                    <option value={"null"}>Select the corporate</option>
                    {type === "cognitgo" ? (
                      corporates?.map((obj: any) => (
                        <option
                          value={obj?.ID}
                          data-object={JSON.stringify(obj)}
                        >
                          {obj?.corporateName}
                        </option>
                      ))
                    ) : (
                      <option value={corporateID.toString()}>{name}</option>
                    )}
                  </Form.Select>
                </Form.Group>

                {/*new field */}
                {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>Flat, House no., Building, Company, Apartment</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={flat_houseno_building}
                                        onChange={(e: any) => setFlat_houseno_building(e.target.value)}
                                        placeholder='Flat, House no., Building, Company, Apartment'
                                    />
                                </InputGroup>
                            </Form.Group> */}
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">Street 1</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={street_1}
                      onChange={(e: any) => setStreet_1(e.target.value)}
                      placeholder="Enter Street 1"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">Street 2</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={street_2}
                      onChange={(e: any) => setStreet_2(e.target.value)}
                      placeholder="Enter Street 2"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">City</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={area_sector}
                      onChange={(e: any) => setArea_sector(e.target.value)}
                      placeholder="Enter City"
                    />
                  </InputGroup>
                </Form.Group>
                {/* <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                <Form.Label className='text'>City/Town</Form.Label>
                                <InputGroup className='mb-3'>
                                    <Form.Control
                                        className='form-input'
                                        size='sm'
                                        type='input'
                                        value={city}
                                        onChange={(e: any) => setCity(e.target.value)}
                                        placeholder='Enter City/Town'
                                    />
                                </InputGroup>
                            </Form.Group> */}
                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    State
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <Form.Select
                    className=""
                    style={{
                      borderRadius: "4px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    aria-label="Select inventory tool"
                    value={state}
                    onChange={(e: any) => setState(e.target.value)}
                  >
                    <option>Select State</option>
                    {states?.map((state: any) => (
                      <option value={state.name}>{state.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">Zip Code</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      type="input"
                      value={pincode}
                      onChange={(e: any) => setPincode(e.target.value)}
                      placeholder="Enter Zip Code"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} lg="6" md="6" controlId="formGridEmail">
                  <Form.Label className="text">Deal Type</Form.Label>
                  <Form.Select
                    className=""
                    aria-label="Select deal type"
                    style={{
                      borderRadius: "4px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    value={dealType}
                    onChange={(e: any) => setDealType(e.target.value)}
                  >
                    <option>Select deal type</option>
                    <option value="deal type 1">deal type 1</option>
                    <option value="deal type 2">deal type 2</option>
                    <option value="deal type 3">deal type 3</option>
                  </Form.Select>
                </Form.Group>

                {/*new field */}

                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">Notes</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-input"
                      size="sm"
                      as="textarea"
                      rows={1}
                      value={notes}
                      onChange={(e: any) => setNotes(e.target.value)}
                      placeholder="Type notes here"
                    />
                  </InputGroup>
                </Form.Group>
                {accessRoles
                  ?.filter(
                    (obj: any) => _.toLower(obj.role) === _.toLower(type + role)
                  )[0]
                  ?.permissions?.includes("allowAssignDealerProcess") && (
                  <Form.Group
                    size="sm"
                    as={Col}
                    lg="6"
                    md="6"
                    controlId=""
                  >
                    <Form.Label className="text">Process</Form.Label>
                    <Select
                     style={{zIndex:"99999999"}}
                      isMulti
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      closeOnSelect={true}
                      hideSelectedOptions={false}
                      options={dealerProcesses}
                      onChange={handleProcessChange}
                      value={
                        selectedProcess?.length === dealerProcesses.length
                          ? [{ label: "All process", value: "All process" }]
                          : selectedProcess
                      }
                    />
                  </Form.Group>
                )}

                {location.pathname.includes("edit") && (
                  <Form.Group
                    size="sm"
                    as={Col}
                    lg="6"
                    md="6"
                    controlId="formGridEmail"
                  >
                    <Form.Label className="text">
                      Download sold report
                    </Form.Label>
                    <InputGroup className="mb-3 d-flex flex-row gap-3">
                      <select
                        name=""
                        id=""
                        onChange={handleYearSelected}
                        className="form-select form-select-sm"
                        style={{
                          borderRadius: "4px",
                          fontSize: "16px",
                          padding: "10px",
                        }}
                      >
                        <option value={"null"}>Select year</option>
                        {_.uniq(years)?.map((yr: any) => (
                          <option value={yr}>{yr}</option>
                        ))}
                      </select>

                      <select
                        name=""
                        id=""
                        onChange={handleMonthSelected}
                        className="form-select form-select-sm"
                        ref={monthRef}
                        style={{
                          borderRadius: "4px",
                          fontSize: "16px",
                          padding: "10px",
                        }}
                      >
                        <option value="all">All</option>
                        <option value="January">Jan</option>
                        <option value="February">Feb</option>
                        <option value="March">Mar</option>
                        <option value="April">Apr</option>
                        <option value="May">May</option>
                        <option value="June">Jun</option>
                        <option value="July">Jul</option>
                        <option value="August">Aug</option>
                        <option value="September">Sep</option>
                        <option value="October">Oct</option>
                        <option value="November">Nov</option>
                        <option value="December">Dec</option>
                      </select>

                      {!csvData || csvData?.length === 0 ? (
                        <button disabled className="btn  btn-link m-auto">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-filetype-csv"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                            />
                          </svg>{" "}
                          Download
                        </button>
                      ) : (
                        <CSVLink data={csvData} filename={"Report"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-filetype-csv"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                            />
                          </svg>{" "}
                          Download {csvData?.length} record(s)
                        </CSVLink>
                      )}
                    </InputGroup>
                  </Form.Group>
                )}

                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">Contacts</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      style={{ textTransform: "capitalize" }}
                      disabled
                      className="form-input"
                      size="sm"
                      type="input"
                      value={
                        !contacts || !contacts?.length
                          ? "No contacts found!"
                          : contacts[0]?.name
                      }
                      onChange={(e: any) => setPincode(e.target.value)}
                      // placeholder='Keerthan and 2 more'
                    />
                    <button
                    style={{zIndex:"0"}}
                      className="btn tbuttonbgcolor"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowContactModal(true);
                      }}
                    >
                      <img src={plus} alt="plus" /> Add
                    </button>
                  </InputGroup>
                </Form.Group>

                {/* {location.pathname.includes('edit') && <Form.Group size='sm' as={Col} lg='6' md='6' controlId='formGridEmail'>
                                
                                </Form.Group>} */}

                <Form.Group
                  size="sm"
                  as={Col}
                  lg="6"
                  md="6"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text">
                    Hours of Operation
                    <span className="text-danger h6 font-weight-bold">*</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <button
                    className="btn tbuttonbgcolor"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(true);
                      }}
                    >
                      Select Hours of operation
                    </button>
                  </InputGroup>
                </Form.Group>
                {location.pathname.includes("edit") && (
                  <Form.Group
                    size="sm"
                    as={Col}
                    mb="6"
                    lg="6"
                    controlId="formGridEmail"
                  >
                    <Form.Label className="text pb-0 mb-0">Status</Form.Label>
                    <ToggleSwitch
                      label={status}
                      handleStatus={() => setStatus(!status)}
                    />
                  </Form.Group>
                )}
                <Form.Group
                  size="sm"
                  as={Col}
                  mb="6"
                  lg="6"
                  className="d-flex flex-column gap-1"
                  controlId="formGridEmail"
                >
                  <Form.Label className="text pb-0 mb-0">Logo</Form.Label>
                  <div className="d-flex">
                    {console.log("imgUrl",imgUrl)
                    }
                    {imgUrl && (
                      <div>
                        <img src={imgUrl} alt="logo" height={140} />
                      </div>
                    )}
                    <div
                      className={
                        imgUrl
                          ? "mt-auto mb-auto d-flex flex-column ml-2"
                          : "mt-auto mb-auto d-flex flex-column"
                      }
                    >
                      <span style={{ fontWeight: "normal" }} className="mb-2">
                        *Image dimension should be 500x300
                      </span>
                      <button
                        type="button"
                        onClick={() => {
                          fileRef.current.click();
                        }}
                        className="btn btn-sm mr-auto mt-auto mb-auto tbuttonbgcolor"
                      >
                        Choose file
                      </button>
                      <span style={{ fontWeight: "normal", fontSize:'12px' }}>{file}</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    name=""
                    ref={fileRef}
                    className="form-control-file"
                    style={{ display: "none" }}
                    id=""
                    onChange={handleFilChange}
                  />
                </Form.Group>
              </Row>
              <hr />
              <div className="mt-3">
                <button
                className="btn pbuttonbgcolor"
                  onClick={(e) => {
                    location.pathname.includes("create")
                      ? handleCreate(e)
                      : handleUpdate(e, null, null);
                  }}
                  type="submit"
                  disabled={
                    location.pathname.includes("create")
                      ? false
                      : tempEMail === crmAdfMail
                      ? false
                      : !emailVerified
                  }
                >
                  {location.pathname.includes("create")
                    ? "+ Create Dealer"
                    : "Update Dealer"}
                </button>
              </div>
            </Form>
            <Operationhours
              show={show}
              handleClose={handleClose}
              setWorkingHrs={setWorkingHrs}
              defaultValues={defaultValues}
              title="Configure the standard working hour"
              readOnly={false}
            />
          </div>
        </Card>
        {location.pathname.includes("edit") && (
          <Card className="px-0 my-3">
            <Card.Header>
              <Card.Title className="m-1">Users</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table bordered>
                <thead className="table-head">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Created At</th>
                    <th>Last Modified</th>
                  </tr>
                </thead>
                <tbody style={{ fontWeight: "lighter" }}>
                  {dealerUsers?.map((user, index) => (
                    <tr>
                      <th>{index + 1}</th>
                      <td>{user?.first_name + " " + user?.last_name}</td>
                      <td>{user?.email}</td>
                      <td>{agentTypes[_.toLower(user?.role)]}</td>
                      <td>{moment(user?.updatedAt).format("LLL")}</td>
                      <td>{moment(user?.updatedAt).format("LLL")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!dealerUsers?.length && (
                <h3 className="p-3 text-center">No users found</h3>
              )}
            </Card.Body>
          </Card>
        )}
        {location.pathname.includes("edit") && (
          <Card className="px-0  mb-3">
            <Card.Header>
              <Card.Title className="m-1">Contacts</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table bordered>
                <thead className="table-head">
                  <tr className="p-3">
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Title</th>
                    <th>Mobile phone</th>
                    <th>Work phone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody style={{ fontWeight: "lighter" }}>
                  {contacts?.map((contact, index) => (
                    <tr>
                      <th>{index + 1}</th>
                      <td>{contact?.name || "N/A"}</td>
                      <td>{contact?.email || "N/A"}</td>
                      <td>{contact?.title || "N/A"}</td>
                      <td>{contact?.mobilePhone || "N/A"}</td>
                      <td>{contact?.workPhone || "N/A"}</td>
                      <td style={{ verticalAlign: "middle" }} title="Delete">
                        <div
                          style={{ width: "auto", height: "auto" }}
                          className=" d-flex justify-content-center align-item-center"
                        >
                          <svg
                            onClick={() => {
                              setShowDeleteModal(true);
                              !contact.id &&
                                setDeleteContactEmail(contact?.email);
                              contact.id && setDeleteContactID(contact?.id);
                              // setDeleteContactID(!contact.id ? contact.email : contact.id)
                            }}
                            style={{ cursor: "pointer" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash text-danger"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg>
                          {/* <Image
                                                    onClick={() => {
                                                        setShowDeleteModal(true);
                                                        !contact.id && setDeleteContactEmail(contact?.email)
                                                        contact.id && setDeleteContactID(contact?.id)
                                                        // setDeleteContactID(!contact.id ? contact.email : contact.id)
                                                    }}
                                                    className='iconAction'
                                                    src={deleteIcon}
                                                /> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!contacts?.length && (
                <h3 className="p-3 text-center">No contacts found</h3>
              )}
            </Card.Body>
          </Card>
        )}
      </Row>

      <Addcontacts
        showContactModal={showContactModal}
        setShowContactModal={setShowContactModal}
        setContacts={setContacts}
        contacts={contacts}
        dealerUsers={dealerUsers}
      />
      <Modal centered show={showDeleteModal}>
        <Modal.Header>
          <Modal.Title>Delete contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this contact?</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm sbuttonbgcolor" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </button>
          <button className="btn btn-sm pbuttonbgcolor" onClick={handleDeletecontact}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateDealer;
