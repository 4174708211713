import React, { useContext, useEffect, useRef, useState } from "react";
import Userstable from "./Userstable";
import { useQuery } from "react-query";
import config from "../../config.json";
import axios from "axios";
import { UserContext } from "../common/UserContext";
import Filter from "./Filter";
import ReactPaginate from "react-paginate";
import LoadingBar from "../common/LoadingBar";
import NoDataFound from "../common/NoDataFound";
import { agentTypes } from "../common/globalValues";
import { DateObject } from "react-multi-date-picker";
import { Grid } from "@mui/material";
import _ from "lodash";
import {
  calculateAvgTime,
  calculateTotalTime,
  filterleadsbyselecteddate,
} from "../common/calculatetotaltime";
import moment from "moment";
function Users() {
  const csvRef = useRef<any>(null);
  const { authToken, type, dealerID, corporateID } = useContext(UserContext);
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10);
  const [types, setTypes] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [dealers, setDealers] = useState<any>([]);
  const [complany, setComplany] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = React.useState<DateObject | DateObject[] | null>(
    []
  );
  const Globalvalues: any = agentTypes;
  const [selectedFromDate, setSelectedFromDate] = React.useState<any>(null);
  const [selectedToDate, setSelectedToDate] = React.useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<any>("");
  const [selectedType, setSelectedType] = useState<any>("");
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [selectedDealer, setSelectedDealer] = useState<any>("");
  const [selectedompany, setSelectedompany] = useState<any>("");
  const [csvData, setCsvData] = useState<any>(null);
  const [selectedCompanyForUIpurpose, setSelectedCompanyForUIpurpose] =
    useState<any>([]);
  const [selectedUserForUIpurpose, setSelectedUserForUIpurpose] = useState<any>(
    []
  );
  const [sort, setSort] = useState<any>({
    type: null,
    key: null,
  });
  const [reset, setReset] = useState<any>(true);
  const compRef = useRef<any>(null);
  const roleRef = useRef<any>(null);
  const typeRef = useRef<any>(null);
  const userRef = useRef<any>(null);
  const dealerRef = useRef<any>(null);

  // console.log("selectedUser", selectedUser);

  let selectedCompanyArray = [] as any;
  let selectedCompanyAll = ["All"] as any;

  let user = {
    first_name: ["All"],
    last_name: ["All"],
  };

  selectedompany &&
    selectedompany?.forEach((d) => {
      selectedCompanyArray.push(d.value);
    });
  let body = {
    role: selectedRole,
    type: selectedType,
    company:
      selectedompany[0] !== "All" ? selectedCompanyArray : selectedompany,
    limit_per_page: 10,
    pages_to_skip: page - 1,
    // reset: true,
    reset,
    sort,
    logged_in_usertype: type,
    dealerID: dealerID,
    corporateID: corporateID,
    sdate: selectedFromDate,
    edate: selectedToDate,
    user: selectedUser === "" ? user : selectedUser,
    dealerId:  selectedDealer && selectedDealer?.map((sd) => sd.value),
  };

  const fetchUsers = async () => {
    let resp = await axios.post(
      config.baseHost_backend + "/fetch-all-user",
      body,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    console.log("resp", resp);
    return resp?.data;
  };

  const fetchOptions = async () => {
    let body = {
      function: "option",
      logged_in_usertype: type,
      dealerID: dealerID,
      corporateID: corporateID,
    };
    let resp = await axios.post(
      config.baseHost_backend + "/user-report",
      body,
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    console.log("options", resp);
    return resp?.data?.body;
  };

  const resetFilter = () => {
    setReset(true);
    if (type === "dealer") {
      roleRef.current.state.value = "";
    } else {
      compRef.current.state.value = "";
      roleRef.current.state.value = "";
      typeRef.current.state.value = "";
      dealerRef.current.state.value = "";
      userRef.current.state.value = "";
    }
    setSelectedRole("");
    setSelectedType("");
    setSelectedompany("");
    setPage(1);
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setValues([]);
    setSelectedDealer("");
    setSelectedompany("");
    setSelectedUser("");
    setSelectedCompanyForUIpurpose([]);
    setSelectedUserForUIpurpose([]);
  };

  const { data, isLoading, refetch } = useQuery(
    ["fetch-users-all", page, sort, reset],
    fetchUsers
  );
  const { data: options } = useQuery("fetch-users-options", fetchOptions);

  console.log("data", data);
  // useEffect(() => {
  //     let resp = fetchUsers()
  // }, [])

  useEffect(() => {
    if (options) {
      let temp1 = [] as any;
      let temp2 = [] as any;
      let temp3 = [] as any;
      let users = [] as any;
      let dealers = [] as any;
      let temp4 = [{ label: "All", value: "All" }];
      options?.roleFilter?.forEach((op, indx) => {
        temp1.push({ label: op?._id, value: op?._id });
      });
      options?.typeFilter?.forEach((op, indx) => {
        temp2.push({ label: op?._id, value: op?._id });
      });
      options?.companyFilter?.forEach((op, indx) => {
        temp3.push({ label: op?._id, value: op?._id });
      });
      options?.userNameFilter?.forEach((op, indx) => {
        users.push({
          label: op?.first_name + " " + op?.last_name,
          value: op?.first_name + " " + op?.last_name,
        });
      });
      options?.dealerFilter?.forEach((op, indx) => {
        dealers.push({ label: op?.dealer_name, value: op?.dealer_id });
      });
      setRoles([...temp4, ...temp1]);
      setTypes([...temp4, ...temp2]);
      setComplany([...temp4, ...temp3]);
      setUsers([...temp4, ...users]);
      setDealers([...temp4, ...dealers]);
    }
  }, [options]);

  const handlePageClick = async (Selected) => {
    setPage(Selected.selected + 1);
  };

  const handleDateChange = (dates: DateObject[]) => {
    if (dates.length === 1) {
      setSelectedFromDate(dates[0].toString());
      let a: any = dates[0];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      setSelectedToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      // setSingleDateSelected(true)
    }
    if (dates.length === 2) {
      setSelectedFromDate(dates[0].toString());
      let a: any = dates[1];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      setSelectedToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      // setSingleDateSelected(false)
    }
    if (dates.length === 0) {
      setSelectedFromDate("");
      setSelectedToDate("");
      // setSingleDateSelected(null)
    }
    setValues(dates);
  };

  const handleompanyChange = (val: any, event: any) => {
    console.log("val", val);
    console.log("event", event);

    if (event?.action === "select-option" && event?.option?.value === "All") {
      setSelectedompany(["All"]);
      setSelectedCompanyForUIpurpose({ label: "All", value: "All" });
    } else if (
      event?.action === "deselect-option" &&
      event?.option?.value === "All"
    ) {
      setSelectedompany("");
      setSelectedCompanyForUIpurpose([]);
    } else {
      setSelectedompany(val?.filter((a) => a.value !== "All"));
      setSelectedCompanyForUIpurpose(val?.filter((a) => a.value !== "All"));
    }
  };

  const handleUserChange = (val: any, event: any) => {
    if (event?.action === "select-option" && event?.option?.value === "All") {
      let user = {
        first_name: ["All"],
        last_name: ["All"],
      };
      setSelectedUser(user);
      setSelectedUserForUIpurpose({ label: "All", value: "All" });
    } else if (
      event?.action === "deselect-option" &&
      event?.option?.value === "All"
    ) {
      setSelectedUser("");
      setSelectedUserForUIpurpose([]);
    } else {
      let firstNames = [] as any;
      let lastNames = [] as any;

      val
        ?.filter((a) => a.value !== "All")
        ?.forEach((gf) => {
          console.log("gf", gf?.value?.split(" "));
          firstNames.push(gf?.value?.split(" ")[0]);
          lastNames.push(gf?.value?.split(" ")[1]);
        });
      let user = {
        first_name: firstNames,
        last_name: lastNames,
      };

      setSelectedUser(user);
      setSelectedUserForUIpurpose(val?.filter((a) => a.value !== "All"));
    }
  };

  const handleDealerChange = (val: any, event: any) => {
    // if (event?.action === "select-option" && event?.option?.value === "All") {
    //     let user = {
    //         first_name: ["All"],
    //         last_name: ["All"]
    //     }
    //     setSelectedUser(user)
    //     setSelectedUserForUIpurpose({ label: "All", value: "All" })
    // } else if (event?.action === "deselect-option" && event?.option?.value === "All") {
    //     setSelectedUser("")
    //     setSelectedUserForUIpurpose([])
    // }
    // else {

    //     let firstNames = [] as any;
    //     let lastNames = [] as any;

    //     val?.filter((a)=>a.value!=="All")?.forEach((gf) => {
    //         console.log("gf", gf?.value?.split(" "));
    //         firstNames.push(gf?.value?.split(" ")[0])
    //         lastNames.push(gf?.value?.split(" ")[1])
    //     })
    //     let user = {
    //         first_name: firstNames,
    //         last_name: lastNames
    //     }

    //     setSelectedUser(user)
    //     setSelectedUserForUIpurpose(val?.filter((a)=>a.value!=="All"))
    // }
    if (event?.action === "select-option" && event?.option?.value === "All") {
      setSelectedDealer(["All"]);
      // setSelectedCompanyForUIpurpose({ label: "All", value: "All" })
    } else if (
      event?.action === "deselect-option" &&
      event?.option?.value === "All"
    ) {
      setSelectedDealer("");
      // setSelecteddea([])
    } else {
      setSelectedDealer(val?.filter((a) => a.value !== "All"));
      // setSelectedCompanyForUIpurpose(val?.filter((a)=>a.value!=="All"))
    }
  };

  const handleExport = async () => {
    setLoading(true);
    const master = [] as any;
    let heading = [
      {
        A: "Name",
        B: "Email",
        C: "Company",
        D: "Type",
        E: "Role",
        F: "Created",
        G: "Modified",
        H: "Leads accessed",
        I: "Total time",
        J: "Avg time",
      },
    ] as any;
    let resp = await axios.post(
      config.baseHost_backend + "/download-file",
      { ...body, functionType: "userUpload" },
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    console.log("respp", resp);

    resp?.data?.body?.forEach((obj) => {
  
      let leadInfo = [
        {
          G: "Lead name",
          H: "Email",
          I: "Dealership",
          J: "Timer",
        },
      ] as any;

      heading.push({
        A: obj?.document?.first_name + " " + obj?.document?.last_name,
        B: obj?.document?.email,
        C: obj?.document?.company,
        D: obj?.document?.type,
        E: Globalvalues[_.toLower(obj?.document?.role)],
        F: moment(obj?.document?.createdAt).format("LLL"),
        G: moment(obj?.document?.updatedAt).format("LLL"),
        H:
          calculateTotalTime(
            obj?.document?.active_leads,
            selectedFromDate,
            selectedToDate
          )?.totalLeads || 0,

        I:
          calculateTotalTime(
            obj?.document?.active_leads,
            selectedFromDate,
            selectedToDate
          )?.hours +
          ":" +
          calculateTotalTime(
            obj?.document?.active_leads,
            selectedFromDate,
            selectedToDate
          )?.minutes +
          ":" +
          calculateTotalTime(
            obj?.document?.active_leads,
            selectedFromDate,
            selectedToDate
          )?.seconds,
        J: obj?.document?.active_leads
          ? calculateAvgTime(
              obj?.document?.active_leads,
              selectedFromDate,
              selectedToDate
            )?.hours +
            ":" +
            calculateAvgTime(
              obj?.document?.active_leads,
              selectedFromDate,
              selectedToDate
            )?.minutes +
            ":" +
            calculateAvgTime(
              obj?.document?.active_leads,
              selectedFromDate,
              selectedToDate
            )?.seconds
          : "00:00:00",
      });
      // console.log("active1",filterleadsbyselecteddate(obj?.document?.active_leads, selectedFromDate,selectedToDate) );
      // console.log("active2",filterleadsbyselecteddate(obj?.document?.active_leads, selectedFromDate,selectedToDate) );

      obj?.document?.active_leads &&
        filterleadsbyselecteddate(
          obj?.document?.active_leads,
          selectedFromDate,
          selectedToDate
        )?.forEach((h) => {
          leadInfo.push({
            G: h?.leadDetails?.first_name + " " + h?.leadDetails?.last_name,
            H: h?.leadDetails?.email,
            I: h?.dealerName,
            J:
              h?.timer?.hours +
              ":" +
              h?.timer?.minutes +
              ":" +
              h?.timer?.seconds,
          });
        });

      heading?.forEach((f) => {
        master.push(f);
      });

      obj?.document?.active_leads &&
        leadInfo?.forEach((lf) => {
          master.push(lf);
        });
      master.push({});
      console.log("length", leadInfo.length, leadInfo);
      heading=[];
    });
    console.log("master", master);

    setCsvData(master);
    csvRef.current.link.click();
    setLoading(false);
  };

  return (
    <div>
      <form
        className="d-flex flex-row gap-3 justify-content-between py-3 px-3 bhmheader shadow-sm"
        onReset={resetFilter}
        onSubmit={(e) => {
          e.preventDefault();
          setPage(1);
          setReset(false);
          refetch();
        }}
      >
        <Filter
          roles={roles}
          types={types}
          company={complany}
          setSelectedRole={setSelectedRole}
          setSelectedType={setSelectedType}
          typeRef={typeRef}
          roleRef={roleRef}
          userRef={userRef}
          companyRef={compRef}
          filter={refetch}
          setSelectedompany={handleompanyChange}
          values={values}
          setSelectedDate={handleDateChange}
          users={users}
          dealers={dealers}
          setSelectedUser={handleUserChange}
          setSelectedDealer={handleDealerChange}
          DealerRef={dealerRef}
          selectedCompanyForUIpurpose={selectedCompanyForUIpurpose}
          selectedUserForUIpurpose={selectedUserForUIpurpose}
          handleExport={handleExport}
          csvData={csvData && csvData}
          csvRef={csvRef}
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
        />
      </form>

      <div
        style={{ paddingTop: type === "dealer" ? "105px" : "185px" }}
        className="container-fluid"
      >
        <Userstable
          data={data && data?.body?.data}
          refetch={refetch}
          page={page}
          sort={sort}
          setSort={setSort}
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
        />
        <ReactPaginate
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageCount={data?.body?.count / limit || 0}
          previousLabel="<<"
          containerClassName="pagination"
          pageClassName={"pagination"}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakLinkClassName="page-link"
          activeClassName="active"
          breakClassName="page-item"
          initialPage={page - 1}
        />
      </div>
      {loading || isLoading ? (
        <LoadingBar isActive={true} />
      ) : !isLoading && !data?.body?.data?.length ? (
        <NoDataFound
          height={""}
          width={""}
          message1="No results Found"
          message2="Try different filters or reset the filters"
          img=""
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Users;
