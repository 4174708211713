import moment from 'moment';
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppContext } from '../../AppContext';
import { markReadStatus } from '../../GraphService';
import NoDataFound from '../common/NoDataFound';
import momenttz from 'moment-timezone'
function Inbox({ data, handleRefresh,dealerDetails, leadDetails }: any) {
    const app: any = useAppContext();
    const location: any = useLocation()

    const handleChageReadStatus = async (ob: any) => {
        history.push({ pathname: `/view/inbox/${ob.conversationId}/${location.pathname.includes('leads') ? dealerDetails?.dealer_id : null}`, state: { ob, type: 'inbox',leadDetails } })
    }

    useEffect(() => {
        if (location?.state?.dataChanged) {
            handleRefresh()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])


    const history = useHistory();
    return (
        <>
            {!data?.length && <NoDataFound height={""} width={""} message1='No messages' message2='' img=""/>}
            <table className="table mt-2">
                <tbody>
                    {data?.map((obj: any, index: any) => (
                        <tr
                            style={{ cursor: "pointer" }}
                            onClick={() => { handleChageReadStatus(obj) }}
                        >
                            {/* <td>{index + 1}</td> */}
                            <td>
                                <div className='d-flex flex-row p-2'>
                                    <div className='d-flex flex-column'>
                                        <span style={{ fontWeight: obj?.isRead ? 'lighter' : '800' }}>{obj?.from?.emailAddress?.name}</span>
                                        <span style={{ padding: "0px", color: obj?.isRead ? '#000' : '#0F6CBD', fontWeight: obj?.isRead ? 'lighter' : '800' }}>{obj?.subject}</span>
                                        <span style={{ padding: "0px", fontWeight: 'lighter' }}>{obj?.bodyPreview?.substring(0, 150).concat('...')}</span>

                                    </div>
                                    <div>
                                        <span style={{ position: 'absolute', right: '20px', fontWeight: obj?.isRead ? 'lighter' : '800' }}>{momenttz(obj?.receivedDateTime).tz('America/Los_Angeles').format("LLL")}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </>
    )
}

export default Inbox