import { Tab, Tabs, Box } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Smsnotification from "./SMS/Smsnotification";
import Pricenotification from "./Price/Notification";
import { useHistory, useLocation } from "react-router-dom";
import Filter from "./Filter";
import DatePicker, { DateObject } from "react-multi-date-picker";
import config from "../../config.json";
import _ from "lodash";
import axios from "axios";
import { useQuery } from "react-query";
function Notification() {
  const [tabs, setTabs] = React.useState<any>(0);
  const histoty = useHistory();
  const scrolRef = useRef(null);
  const location = useLocation();
  const dealerRef=useRef<any>(null)
  let token: any = localStorage.getItem("$AuthToken");
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10);
  const [dealerList, setDealersList] = useState<any>(null);
  const [selectedDealer, setSelectedDealer] = useState<any>(null);
  console.log("location", location.pathname.split("/")[2]);
  const [setelectedDate, setSelecteDate] = useState<any>();
  const [selectedFromDate, setSelectedFromDate] = useState<any>(null);
  const [selectedToDate, setSelectedToDate] = useState<any>(null);
  const [reset, setReset] = React.useState<any>(false);
  const [values, setValues] = React.useState<DateObject | DateObject[] | null>(
    []
  );


  

  const fetchSmsNotifications = async () => {
    let body = {
      limit_per_page: 10,
      pages_to_skip: page - 1,
      dealerName: selectedDealer,
      sdate: selectedFromDate,
      edate: selectedToDate,
      reset,
    };
    let url:any=config.baseHost_backend;

    let resp = await axios.post(
      tabs==0 ? url+"/notification/unread-messages" : url+"/notification/pricing",
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return resp?.data;
  };
  const { data, refetch, isLoading, isFetching } = useQuery(
    ["fetch-sms-notificatios", page, reset, tabs],
    fetchSmsNotifications
  );

  useEffect(() => {
    let tab = location.pathname.split("/")[2];
    if (tab === "sms") {
      setTabs(0);
    } else if (tab === "pricing") {
      setTabs(1);
    } else {
      setTabs(0);
    }
  }, [location.pathname]);

  const handleReset=(e)=>{
    e.preventDefault();
    setReset(true);
    dealerRef.current.state.value = "";
    setValues([])
    setSelectedFromDate(null);
    setSelectedToDate(null)
    setSelectedDealer(null);
    setPage(1);
    refetch();
  }

  const HandleFilter=(e)=>{
    e.preventDefault();
    setReset(false);
    setPage(1);
    refetch();
  }

  useEffect(() => {
    if (data) {
      console.log("un msg", data);

      const dealers: any = [];
      if(tabs===0){
        data?.body?.unReadMessagesLeads?.forEach((d: any) => {
          dealers.push(d?.dealer_name);
        });
      }else{
        data?.body?.pricingLeads?.forEach((d: any) => {
          dealers.push(d?.dealer_name);
        });
      }
     
      let UD: any = [];
      _.uniq(dealers).forEach((ud) => {
        UD.push({ label: ud, value: ud });
      });
      console.log("dealers",dealers);
      
      setDealersList(UD);
    }
  }, [data]);

  const handleDateChange = (dates: DateObject[]) => {
    if (dates.length === 1) {
      setSelectedFromDate(dates[0].toString());
      let a: any = dates[0];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      setSelectedToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      // setSingleDateSelected(true)
    }
    if (dates.length === 2) {
      setSelectedFromDate(dates[0].toString());
      let a: any = dates[1];
      let od: any = new Date(a);
      let stamp = new Date(od.setDate(od.getDate() + 1));
      setSelectedToDate(
        `${stamp.getFullYear()}/${stamp.getMonth() + 1}/${stamp.getDate()}`
      );
      // setSingleDateSelected(false)
    }
    if (dates.length === 0) {
      setSelectedFromDate("");
      setSelectedToDate("");
      // setSingleDateSelected(null)
    }
    setValues(dates);
  };

  return (
    <div ref={scrolRef}>
      <div className="d-flex">
        <div className="bg-light container-fluid">
          <Tabs
            value={tabs}
            onChange={(e, tab) => {
              setPage(1)
              setTabs(tab);
              console.log(tab);
            }}
          >
            <Tab
              onClick={() => {
                histoty.push("/notifications/sms");
              }}
              style={{
                backgroundColor: tabs === 0 ? "#343a4024" : "#ffff",
                color: "black",
              }}
              label="SMS notification"
            ></Tab>
            <Tab
              onClick={() => {
                histoty.push("/notifications/pricing");
              }}
              style={{
                backgroundColor: tabs === 1 ? "#343a4024" : "#ffff",
                color: "black",
              }}
              label="Pricing notification"
            ></Tab>
          </Tabs>
        </div>
        {/* <div>
          <Filter
            setSelectedDate={handleDateChange}
            dealersList={dealerList}
            values={values}
            setSelectedDealer={setSelectedDealer}
            handleFilter={HandleFilter}
            handleReset={handleReset}
            dealerRef={dealerRef}
          />
        </div> */}
      </div>
      {tabs === 0 && (
        <Smsnotification data={data} isLoading={isFetching || isLoading} page={page} setPage={setPage} limit={limit}/>
      )}
      {tabs === 1 && <Pricenotification data={data} isLoading={isFetching || isLoading}  page={page} setPage={setPage} limit={limit}/>}
    </div>
  );
}

export default Notification;
