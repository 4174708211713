import React from 'react'
import filter from '../../assets/images//filter.png'
function Applyfilter() {
    return (
        <div className='load-cont2'>
            <div className='d-flex flex-column '>
                <img src={filter} alt="Filter" height={170} className='ml-auto mr-auto' />
                <h2 className='spacing-1' style={{ fontWeight: 'bold' }}>Please select the filter to view the data.</h2>
            </div>
        </div>
    )
}

export default Applyfilter