import React, { useState } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import _ from 'lodash'
import Select from 'react-select';
function Filter({ data,
    status,
    bots,
    setSelectedBot,
    setSelectedStatus,
    setSelectedDate,
    filter,
    resetFilter,
    botRef,
    statusRef,
    values
}: any) {
    
    return (
        <div className='d-flex gap-3'>
            <div className='d-flex flex-column'>
                <span className='filter-control-lable'>Select date</span>
                {/* <input type="date" ref={dateRef} name="" id="" className='form-control bg-light m-0' style={{ width: '200px', padding: '6px' }} onChange={(e) => setSelectedDate(e.target.value)} /> */}
                <DatePicker
                    // style={{ width}}
                    style={{ padding: '18px' }}
                    value={values}
                    placeholder={'MM/DD/YYYY'}
                    format="MM/DD/YYYY"
                    onChange={setSelectedDate}
                    range
                    plugins={[<DatePanel />]}
                />
            </div>
            <div style={{ width: '200px' }}>
                <span className='filter-control-lable'>Select bot</span>
                <Select closeOnSelect={true}
                    isSearchable={true}
                    options={bots && bots}
                    defaultValue="All"
                    ref={botRef}
                    onChange={(op: any) => { setSelectedBot(op.value) }}
                ></Select>
            </div>
            <div style={{ width: '200px' }}>
                <span className='filter-control-lable'>Select status</span>
                <Select closeOnSelect={true}
                    isSearchable={true}
                    options={status && status}
                    defaultValue="All"
                    ref={statusRef}
                    onChange={(op: any) => setSelectedStatus(op.value)}
                ></Select>
            </div>
            <button className='btn pbuttonbgcolor mt-auto mb-1 btn-sm' onClick={filter} >Filter</button>
            <button className='btn sbuttonbgcolor mt-auto mb-1 btn-sm' onClick={resetFilter}>Reset filter</button>
        </div>
    )
}

export default Filter