import React, { useEffect, useState } from "react";
import "./greeting.css";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config.json";
import { toastify } from "./notification";
import hyundai from "../../assets/images/Hyundai.png";
import phoneicon from "../../assets/images/phoneicon.png";
import location from "../../assets/icons/location.svg";
import globe from "../../assets/icons/globe.svg";
import { leadDetailsApiWithoutToken } from "../../controllers/leads";
import states from "./states";
function Greetings() {
  let url = window.location.href;
  const { id } = useParams<any>();
  console.log("id", id);
  const history = useHistory<any>();
  const [data, setData] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [longAddress, setLongAddress] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const updateLead = async () => {
      const resp = await axios
        .put(config.baseHost_backend + "/update-lead-bot", {
          leadId: id,
          functionType: "byEmail",
          status:
            url.split("/")[3] === "email-interested"
              ? "new_by_email"
              : "Service_Interested_by_email",
        })
        .then((result) => {
          console.log("Success", result);
        })
        .catch((error) => {
          toastify("failure", error.message || "Something went wrong!");
        });
    };

    const fetchDataa = () => {
      setIsLoading(true);
      leadDetailsApiWithoutToken({}, id)
        .then((res) => {
          console.log("res", res);
          setData(res?.body);
          setIsLoading(false);
          if (res?.statusCode === 400) {
            setIsError(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };

    fetchDataa();
    updateLead();
  }, []);

  useEffect(() => {
    let street_1 = data?.dealerDetails?.street_1;
    let street_2 = data?.dealerDetails?.street_2;
    let area_sector = data?.dealerDetails?.area_sector;
    let state = states?.filter(
      (a) =>
        a.name.toLocaleLowerCase() ===
        data?.dealerDetails?.state?.toLocaleLowerCase()
    )[0]?.abbreviation;
    let pincode = data?.dealerDetails?.pincode;

    let arr = [street_1, street_2, area_sector, state, pincode];
    setLongAddress(arr?.filter((a) => a));
  }, [data]);
  console.log("DATA", data);

  return (
    <div className="">
      {!isError ? (
        <div className="greetingbox">
          <div className="greetings-header">
            <div className="ml-4">
              {isLoading ? (
                "Loading..."
              ) : (
                <img
                  src={data?.dealerDetails?.dealerLogo}
                  alt="Cognitgo"
                  height={80}
                />
              )}
            </div>
            <div style={{ display: "flex", gap: "80px" }} className="mx-4">
              <span
                style={{ color: "#043865" }}
                onClick={() => {
                  history.push(`/neuron-dialer/${data?.data?._id}`);
                }}
              >
                <img src={phoneicon} alt="phone" height={25} />{" "}
                {isLoading ? "Loading..." : data?.dealerDetails?.twilioPhone}{" "}
              </span>
              <span style={{ color: "#043865" }}>
                <img src={globe} alt="website" height={25} />
                <a href={data?.dealerDetails?.website} target="_blank">
                  {" "}
                  {isLoading ? "Loading..." : data?.dealerDetails?.website}
                </a>
              </span>
              <span style={{ color: "#043865" }} className="d-flex">
                <img src={location} alt="location" height={25} />{" "}
                {isLoading ? (
                  "Loading..."
                ) : !longAddress?.length ? (
                  <p>N/A</p>
                ) : (
                  <p> {longAddress?.map((add) => add + " ")}</p>
                )}
              </span>
            </div>
          </div>
          <div className="greetings-body">
            <h1 className="thankyou">THANK YOU!</h1>
            {url.split("/")[3] === "email-interested" ? (
              <p className="content-h">
                A team member will contact you soon to discuss your vehicle's
                value. If you're interested in trading in or selling your
                vehicle, we can schedule an appointment for you.
              </p>
            ) : (
              <p className="content-h">
                One of our team members will contact you soon to discuss our
                service protection plan options and assist you in finding the
                ideal coverage for your needs.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "20%",
          }}
          className="d-flex justify-content-center"
        >
          <div className="invalid-obj">
            <p className="">Something went wrong.</p>
            <p className="">
              URL may be broken. please try again with valid URL
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Greetings;
