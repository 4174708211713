import { Grid } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { Button, Modal } from 'react-bootstrap'
import './operationhrs.css'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { toastify } from '../common/notification'
function Operationhours({ defaultValues, handleClose, show, setWorkingHrs, title, readOnly }) {
    const timeRef = useRef<any>(null)
    const location: {
        pathname: any;
        state: any;
        search: any;
    } = useLocation();
    const [daysChecked, setDaysChecked] = React.useState<any>([
        {
            day: 'Monday',
            abbr: 'Mon',
            working: true,
            fromTime: "09:00",
            toTime: "19:00"
        },
        {
            day: 'Tuesday',
            abbr: 'Tue',
            working: true,
            fromTime: "09:00",
            toTime: "19:00"
        },
        {
            day: 'Wednesday',
            abbr: 'Wed',
            working: true,
            fromTime: "09:00",
            toTime: "19:00"
        },
        {
            day: 'Thursday',
            abbr: 'Thu',
            working: true,
            fromTime: "09:00",
            toTime: "19:00"
        },
        {
            day: 'Friday',
            abbr: 'Fri',
            working: true,
            fromTime: "09:00",
            toTime: "17:00"
        },
        {
            day: 'Saturday',
            abbr: 'Sat',
            working: true,
            fromTime: "11:00",
            toTime: "15:00"
        },
        {
            day: 'Sunday',
            abbr: 'Sun',
            working: false,
            fromTime: "",
            toTime: ""
        }
    ])

    useEffect(() => {
        if (defaultValues) {

            
            const tempArray = [] as any
            // defaultValues?.forEach((obj: any) => {
            //     tempArray.push(obj)
            // })

            
            Object.keys(defaultValues).forEach((key) => {
                
                tempArray.push(defaultValues[key])
            });

            (location.pathname.includes('edit')||location.pathname.includes('leads')) && show && tempArray.length > 0 && setDaysChecked(tempArray)
        }

    }, [defaultValues, location.pathname, show])

    // location.pathname.includes('edit') && setDaysChecked(defaultValues)

    const handleCheckbox = (e: any, day: any) => {
        const newArr = daysChecked?.map((obj: any) => {
            if (_.toLower(obj.abbr) === day) {
                if (!e.target.checked) {
                    return { ...obj, working: e.target.checked, fromTime: "", toTime: "" }
                } else {
                    return { ...obj, working: e.target.checked }
                }
            } else {
                return obj
            }
        })
        
        setDaysChecked(newArr)
    }

    useEffect(() => {
        
    }, [daysChecked])

    const handleTimeChange = (e: any, day: any, type: any) => {
        
        const newArr = daysChecked?.map((obj: any) => (
            _.toLower(obj.abbr) === day ?
                { ...obj, [type]: e.target.value } : obj
        ))
        setDaysChecked(newArr)
        
    }

    const handleSave = () => {
        
        let num = 0


        setWorkingHrs(daysChecked)
        handleClose()

    }


    return (
        <Modal centered show={show} onHide={handleClose} className="modal-md" style={{zIndex:'9999999999999'}}>
            <Modal.Header>
                <h2 className='bolder pb-0 mb-0'>{title}</h2>
            </Modal.Header>
            <Modal.Body >
                <Grid container>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        Monday
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <input checked={daysChecked[0].working} type="checkbox" disabled={readOnly} className="zoom-check" onChange={(e: any) => { handleCheckbox(e, 'mon') }} name="" id="" />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} className="mb-2 mt-0 pt-0">
                        <div className='d-flex flex-row w-100 gap-2 gap-2'>
                            <span className=''>from: </span><input value={daysChecked[0].fromTime} type="time" name="" id="" disabled={readOnly || !daysChecked[0].working} onChange={(e) => { handleTimeChange(e, "mon", "fromTime") }} />
                            <span className=''>To: </span><input value={daysChecked[0].toTime} type="time" name="" id="" disabled={readOnly || !daysChecked[0].working} onChange={(e) => { handleTimeChange(e, "mon", "toTime") }} />
                        </div>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        Tuesday
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <input type="checkbox" disabled={readOnly} checked={daysChecked[1].working} className="zoom-check" onChange={(e: any) => { handleCheckbox(e, 'tue') }} name="" id="" />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} className="mb-2">
                        <div className='d-flex flex-row w-100 gap-2'>
                            <span className=''>from: </span><input value={daysChecked[1].fromTime} type="time" name="" id="" disabled={readOnly || !daysChecked[1].working} onChange={(e) => { handleTimeChange(e, "tue", "fromTime") }} />
                            <span className=''>To: </span><input value={daysChecked[1].toTime} type="time" name="" id="" disabled={readOnly || !daysChecked[1].working} onChange={(e) => { handleTimeChange(e, "tue", "toTime") }} />
                        </div>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        Wednesday
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <input type="checkbox" disabled={readOnly} checked={daysChecked[2].working} className="zoom-check" onChange={(e: any) => { handleCheckbox(e, 'wed') }} name="" id="" />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} className="mb-2">
                        <div className='d-flex flex-row w-100 gap-2'>
                            <span className=''>from: </span><input value={daysChecked[2].fromTime} type="time" name="" id="" disabled={readOnly || !daysChecked[2].working} onChange={(e) => { handleTimeChange(e, "wed", "fromTime") }} />
                            <span className=''>To: </span><input value={daysChecked[2].toTime} type="time" name="" id="" disabled={readOnly || !daysChecked[2].working} onChange={(e) => { handleTimeChange(e, "wed", "toTime") }} />
                        </div>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        Thursday
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <input type="checkbox" disabled={readOnly} checked={daysChecked[3].working} className="zoom-check" onChange={(e: any) => { handleCheckbox(e, 'thu') }} name="" id="" />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} className="mb-2">
                        <div className='d-flex flex-row w-100 gap-2'>
                            <span className=''>from: </span><input value={daysChecked[3].fromTime} type="time" name="" id="" disabled={readOnly || !daysChecked[3].working} onChange={(e) => { handleTimeChange(e, "thu", "fromTime") }} />
                            <span className=''>To: </span><input value={daysChecked[3].toTime} type="time" name="" id="" disabled={readOnly || !daysChecked[3].working} onChange={(e) => { handleTimeChange(e, "thu", "toTime") }} />
                        </div>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        Friday
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <input type="checkbox" disabled={readOnly} checked={daysChecked[4].working} className="zoom-check" onChange={(e: any) => { handleCheckbox(e, 'fri') }} name="" id="" />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} className="mb-2">
                        <div className='d-flex flex-row w-100 gap-2'>
                            <span className=''>from: </span><input value={daysChecked[4].fromTime} type="time" name="" id="" disabled={readOnly || !daysChecked[4].working} onChange={(e) => { handleTimeChange(e, "fri", "fromTime") }} />
                            <span className=''>To: </span><input value={daysChecked[4].toTime} type="time" name="" id="" disabled={readOnly || !daysChecked[4].working} onChange={(e) => { handleTimeChange(e, "fri", "toTime") }} />
                        </div>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        Saturday
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <input type="checkbox" disabled={readOnly} checked={daysChecked[5].working} className="zoom-check" onChange={(e: any) => { handleCheckbox(e, 'sat') }} name="" id="" />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} className="mb-2">
                        <div className='d-flex flex-row w-100 gap-2'>
                            <span className=''>from: </span><input value={daysChecked[5].fromTime} type="time" name="" id="" disabled={readOnly || !daysChecked[5].working} onChange={(e) => { handleTimeChange(e, "sat", "fromTime") }} />
                            <span className=''>To: </span><input value={daysChecked[5].toTime} type="time" name="" id="" disabled={readOnly || !daysChecked[5].working} onChange={(e) => { handleTimeChange(e, "sat", "toTime") }} />
                        </div>
                    </Grid>


                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        Sunday
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <input type="checkbox" disabled={readOnly} checked={daysChecked[6].working} className="zoom-check" onChange={(e: any) => { handleCheckbox(e, 'sun') }} name="" id="" />
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} className="">
                        <div className='d-flex flex-row w-100 gap-2'>
                            <span className=''>from: </span><input value={daysChecked[6].fromTime} type="time" name="" id="" disabled={readOnly || !daysChecked[6].working} ref={timeRef} onChange={(e) => { handleTimeChange(e, "sun", "fromTime") }} />
                            <span className=''>To: </span><input value={daysChecked[6].toTime} type="time" name="" id="" disabled={readOnly || !daysChecked[6].working} onChange={(e) => { handleTimeChange(e, "sun", "toTime") }} />
                        </div>
                    </Grid>
                </Grid>

            </Modal.Body>
            {!readOnly && <Modal.Footer>
                <button
                    className='btn btn-sm sbuttonbgcolor'
                    onClick={handleClose}
                >
                    Cancel
                </button>
                <button className='btn btn-sm pbuttonbgcolor' onClick={handleSave}>
                    Save
                </button>
            </Modal.Footer>}
            {readOnly && <Modal.Footer>
                <button
                    className='btn btn-sm sbuttonbgcolor'
                    onClick={handleClose}
                >
                    Close
                </button>
            </Modal.Footer>}
        </Modal>
    )
}

export default Operationhours

