const daysConfig=[
    {
        num:0,
        day:'Sunday'
    },
    {
        num:1,
        day:'Monday'
    },
    {
        num:2,
        day:'Tuesday'
    },
    {
        num:3,
        day:'Wednesday'
    },
    {
        num:4,
        day:'Thursday'
    },
    {
        num:5,
        day:'Friday'
    },
    {
        num:6,
        day:'Saturday'
    }
]
module.exports = daysConfig