/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
// import { ModelsData } from '../Data'
import { Chart as chartJS, registerables } from "chart.js";
import { dashboard } from "../../../controllers/dashboard";
import { useQuery } from "react-query";
import { UserContext } from "../../common/UserContext";
import { allLeadsApi } from "../../../controllers/leads";
import { Col, Form, Row } from "react-bootstrap";
import Loader from "../../common/Loader";
import { toastify } from "../../common/notification";
import axios from "axios";
import config from "../../../config.json";
import ReactPaginate from "react-paginate";
import { any } from "ramda";
import { statusExtractor } from "./globalStatus";
import { Grid } from "@mui/material";
const url = config["baseHost_backend"];
chartJS.register(...registerables);
function Report({ bdcStatsData, setEnableExportBtn }: any) {
  const { authToken, soldvalue } = useContext(UserContext);
  const [myData, setmyData] = useState<any>(null);
  const { data: datas } = useQuery(
    "drm-dashboard",
    () => dashboard(authToken, {}),
    { staleTime: 3600000 }
  );
  const [filter, setFilter] = useState<any>("All");
  const [rowPerPage, setRowperPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [scrollMin, setScrollMin] = useState(0);
  const [scrollMax, setScrollMax] = useState(9);
  const [displayData, setDisplayData] = useState<any>(null);
  const pageVisited = pageNumber * rowPerPage;
  const [data, setData] = useState<any>(null);
  const { data: dashboardData, isLoading: isLeadsLoading } = useQuery(
    "dashboard",
    () => dashboard(authToken, {})
  );
  const [loading, setLoading] = useState<boolean>(false);
 
  const [status, setStatus] = useState<any>([
    {
      text: "appointments",
      ctaText: "Appointments",
      value: 0,
    },
    // {
    //   text: "showed",
    //   ctaText: "Showed",
    //   value: 0,
    // },
    // {
    //   text: "no_show",
    //   ctaText: "Did Not Show",
    //   value: 0,
    // },
    // {
    //   text: "opportunities",
    //   ctaText: "Working/Opportunities",
    //   value: 0,
    // },
    {
      text: "bot_msg_sent",
      ctaText: "No Response",
      value: 0,
    },
    {
      text: "not_interested",
      ctaText: "Not Interested",
      value: 0,
    },
  
    // {
    //   text: "interested",
    //   ctaText: "New-Value",
    //   value: 0,
    // },
    // {
    //   text: "interestedService",
    //   ctaText: "New-Service Protection",
    //   value: 0,
    // },
    // {
    //   text: "sold",
    //   ctaText: "Sold",
    //   value: 0,
    // },
    {
      text: "working_followup",
      ctaText: "Working/Follow-up",
      value: 0,
    },
  ]);

  
  useEffect(() => {
    if (bdcStatsData) {
      console.log("bdcStatsData",bdcStatsData);
      
      setData(bdcStatsData);
    
      setStatus(
        statusExtractor(bdcStatsData, status)
      );
      setEnableExportBtn(true);
      //   setLoading(false);
      //   
    }
  }, [soldvalue, bdcStatsData]);

  useEffect(() => {
    if (data && displayData) {
      setmyData({
        labels:
          filter === "All"
            ? status?.map((status: any) => status.ctaText)
            : displayData?.map((status: any) => status.ctaText),
        datasets: [
          {
            label: "Total",
            data:
              filter === "All"
                ? status?.map((status: any) => status.value)
                : displayData?.map((status: any) => Math.ceil(status.count)),
            backgroundColor: ["blue"],
            barThickness: 40,
          },
        ],
      });
    }
  }, [data, displayData, pageNumber, filter, scrollMax, scrollMin]);

  useEffect(() => {
    let filteredData;
    let tempArray = [] as any;
    if (data) {
      if (filter === "All") {
        data
          ?.sort((a: any, b: any) => (a.count > b.count ? -1 : 1))
          .slice(pageVisited, pageVisited + rowPerPage)
          .map((status: any) => {
            tempArray.push({
              _id: status._id,
              percent: status.percent,
              count: status.count,
            });
          });
        setDisplayData(tempArray);
      } else {
        data
          ?.filter((status: any) => status._id === filter)
          .sort((a: any, b: any) => (a.count > b.count ? -1 : 1))
          .slice(pageVisited, pageVisited + rowPerPage)
          .map((status: any) => {
            tempArray.push({
              _id: status._id,
              percent: status.percent,
              count: status.count,
            });
          });
        setDisplayData(tempArray);
      }
    }
  }, [data, filter, pageNumber]);

  // let pageCount = Math.ceil(
  //   dashboardData && dashboardData?.body?.data?.status?.length / rowPerPage
  // );

  return loading ? (
    <Loader />
  ) : (
    <Grid container
      style={{ width: "500px" }}
      className="container row w-100  d-flex justify-content-center m-auto bg-light shadow-sm py-3"
    >
      <h2 className="font-weight-bold py-0 my-2">BDC Stats</h2>
      <Grid item lg={6} className="spacing-1">
        <table className="table">
          <thead className="thead-dark bg-dark text-white">
            <tr>
              <th scope="col">BDC Breakdown</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {status?.map((status: any, index: any) => (
              <tr>
                <td>{status.ctaText}</td>
                <td>{status.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
      <Grid item lg={6}
        className={
          !myData?.datasets[0]?.data[0]
            ? "spacing-1 d-flex mt-5 justify-content-center"
            : "spacing-1"
        }
      >
        {!myData?.datasets[0]?.data[0] ? (
          <div>
            <h3>No Data Found</h3>
          </div>
        ) : (
          <>
            <div style={{ height: "400px" }}>
              {myData && (
                <Bar
                  data={myData}
                  width="100px"
                  height="100px"
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      xAxes: {
                        grid: {
                          display: false,
                        },
                        ticks: { autoSkip: false },
                        min: scrollMin,
                        max: scrollMax,
                      },
                      yAxes: {
                        grid: {
                          display: false,
                        },
                        ticks: { stepSize: 500 },
                      },
                    },
                  }}
                />
              )}
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-light shadow-lg"
                disabled={true}
                onClick={() => {
                  setScrollMin(scrollMin - 10);
                  setScrollMax(scrollMax - 10);
                }}
              >
                Prev
              </button>
              <button
                className="btn btn-light shadow-lg"
                disabled={true}
                onClick={() => {
                  setScrollMin(scrollMin + 10);
                  setScrollMax(scrollMax + 10);
                }}
              >
                Next
              </button>
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Report;
