export const accessRoles = [
  {
    role: "cognitgoAdmin",
    permissions: [
      "allowDashboard",
      "viewDashboard",
      "editDashboard",
      "addDashboard",

      "allowLeadsandOpportunities",
      "viewLeadsandOpportunities",
      "editLeadsandOpportunities",
      "addLeadsandOpportunities",
      "allowSeeCustomerResponseDate",
      "allowSeeMesgSentDate",
      "allowSeeLastContactedDatetime",
      "allowSeeLeadProcessedDate",
      "createDemoLead",

      "allowDealersandCorporate",
      "viewDealersandCorporate",
      "editDealersandCorporate",
      "addDealersandCorporate",
      "editAdfXmlEmail",

      "allowUsers",
      "viewUsers",
      "editUsers",
      "addUsers",

      "allowBotHealthMonitoring",
      "allowDealerInbox",
      "allowTemplates",
      "allowAllCalllogs",

      "allowNotifications",
      "allowAllcallLogs",
      "allowUserReporingScreen",
      "allowSelectDealerProcessFilter",
      "allowAssignDealerProcess"
    ],
  },
  {
    role: "cognitgoSales",
    permissions: [
      "allowDashboard",
      "viewDashboard",
      "editDashboard",
      "addDashboard",

      "allowLeadsandOpportunities",
      // "viewLeadsandOpportunities",
      // "editLeadsandOpportunities",
      // "addLeadsandOpportunities",
      "allowSeeCustomerResponseDate",
      "allowSeeMesgSentDate",
      // "allowDealersandCorporate",
      "viewDealersandCorporate",
      "allowSeeCustomerResponseDate",
      "allowSeeMesgSentDate",
      "allowSeeLastContactedDatetime",
      "allowSeeLeadProcessedDate",
      // "editDealersandCorporate",
      // "addDealersandCorporate",

      // "allowUsers",
      // "viewUsers",
      // "editUsers",
      // "addUsers"
    ],
  },
  {
    role: "cognitgoBdcManager",
    permissions: [
      "allowDashboard",
      "viewDashboard",
      "editDashboard",
      "addDashboard",

      "allowLeadsandOpportunities",
      "viewLeadsandOpportunities",
      "editLeadsandOpportunities",
      "addLeadsandOpportunities",
      "allowSeeLastContactedDatetime",
      "allowSeeLeadProcessedDate",

      "allowDealersandCorporate",
      // "viewDealersandCorporate",
      "editDealersandCorporate",
      // "addDealersandCorporate",

      "allowUsers",
      "editUsers",
      // "addUsers",
      "editOnlyDealerAndCorporateInfo",
      // "editOnly_Dealer_And_Corporate_Info"
      // "editOnlyDealerInfo",
      "allowNotifications",
      "allowAllcallLogs",
      "allowUserReporingScreen",
    ],
  },
  {
    role: "cognitgoBDC",
    permissions: [
      "allowDashboard",
      "viewDashboard",
      "editDashboard",
      "addDashboard",

      "allowLeadsandOpportunities",
      "viewLeadsandOpportunities",
      "editLeadsandOpportunities",
      "addLeadsandOpportunities",
      "allowSeeLastContactedDatetime",

      "allowDealersandCorporate",
      "viewDealersandCorporate",
      // "editDealersandCorporate",
      // "addDealersandCorporate",

      "allowUsers",
      // "viewUsers",
      // "editUsers",
      // "addUsers"
      "allowNotifications",
      "allowAllcallLogs",
      "allowUserReporingScreen",
    ],
  },
  {
    role: "corporateAdmin",
    permissions: [
      "allowDashboard",
      "viewDashboard",
      "editDashboard",
      "addDashboard",

      "allowLeadsandOpportunities",
      "viewLeadsandOpportunities",
      "editLeadsandOpportunities",
      "addLeadsandOpportunities",
      "allowSeeCustomerResponseDate",
      "allowSeeMesgSentDate",
      "allowSeeLastContactedDatetime",
      "allowSeeLeadProcessedDate",

      "allowDealersandCorporate",
      "viewDealersandCorporate",
      "editDealersandCorporate",
      "addDealersandCorporate",

      "allowUsers",
      "viewUsers",
      "editUsers",
      "addUsers",
    ],
  },
  {
    role: "corporateUser",
    permissions: [
      "allowDashboard",
      "viewDashboard",
      "editDashboard",
      "addDashboard",

      "allowLeadsandOpportunities",
      // "viewLeadsandOpportunities",
      // "editLeadsandOpportunities",
      // "addLeadsandOpportunities",

      "allowDealersandCorporate",
      "viewDealersandCorporate",
      "allowSeeMesgSentDate",
      "allowSeeLastContactedDatetime",
      "allowSeeLeadProcessedDate",
      "allowSeeCustomerResponseDate",
      // "editDealersandCorporate",
      // "addDealersandCorporate",

      // "allowUsers",
      // "viewUsers",
      // "editUsers",
      // "addUsers"
    ],
  },
  {
    role: "dealerAdmin",
    permissions: [
      "allowDashboard",
      "viewDashboard",
      "editDashboard",
      "addDashboard",

      "allowLeadsandOpportunities",
      "viewLeadsandOpportunities",
      "editLeadsandOpportunities",
      "addLeadsandOpportunities",
      "allowSeeCustomerResponseDate",
      "allowSeeMesgSentDate",
      "allowSeeLastContactedDatetime",
      "allowSeeLeadProcessedDate",

      // "allowDealersandCorporate",
      // "viewDealersandCorporate",
      // "editDealersandCorporate",
      // "addDealersandCorporate",

      "allowUsers",
      "viewUsers",
      "editUsers",
      "addUsers",
      "allowDealerInbox",
    ],
  },
  {
    role: "dealerUser",
    permissions: [
      "allowDashboard",
      "viewDashboard",
      "editDashboard",
      "addDashboard",

      "allowLeadsandOpportunities",
      // "viewLeadsandOpportunities",
      // "editLeadsandOpportunities",
      // "addLeadsandOpportunities",
      "allowSeeCustomerResponseDate",
      "allowSeeMesgSentDate",
      "allowSeeLastContactedDatetime",
      "allowSeeLeadProcessedDate",

      // "allowDealersandCorporate",
      // "viewDealersandCorporate",
      // "editDealersandCorporate",
      // "addDealersandCorporate",

      // "allowUsers",
      // "viewUsers",
      // "editUsers",
      // "addUsers"
      "allowDealerInbox",
    ],
  },
];

export const types = [
  {
    label: "Cognitigo",
    value: "cognitgo",
  },
  {
    label: "Corporate",
    value: "corporate",
  },
  {
    label: "Dealer",
    value: "dealer",
  },
];

export const roles = [
  {
    type: "cognitgo",
    roles: [
      {
        label: "Admin",
        value: "admin",
      },
      {
        label: "BDC Manager",
        value: "bdcmanager",
      },
      {
        label: "Sales",
        value: "sales",
      },
      {
        label: "BDC",
        value: "bdc",
      },
    ],
  },
  {
    type: "corporate",
    roles: [
      {
        label: "Admin",
        value: "admin",
      },
      {
        label: "User",
        value: "user",
      },
    ],
  },
  {
    type: "dealer",
    roles: [
      {
        label: "Admin",
        value: "admin",
      },
      {
        label: "User",
        value: "user",
      },
    ],
  },
];

export const merger_fields = [
  {
    label: "Lead first name",
    value: "first_name",
  },
  {
    label: "Lead last name",
    value: "last_name",
  },
  {
    label: "User first name",
    value: "user_first_name",
  },
  {
    label: "User last name",
    value: "user_last_name",
  },
  {
    label: "Dealer name",
    value: "dealer_name",
  },
  {
    label: "Dealer address",
    value: "address",
  },
  {
    label: "Dealer short name",
    value: "shortName",
  },
  {
    label: "Appointment date and time",
    value: "appointment_date",
  },
  {
    label: "Year",
    value: "year",
  },
  {
    label: "Make",
    value: "make",
  },
  {
    label: "Model",
    value: "model",
  },
];

export const dealerProcesses = [
  {
    label: "All process",
    value: "All process",
  },
  {
    label: "Regular plan",
    value: "Regular plan",
  },
  {
    label: "Service protection Plan",
    value: "Service protection Plan",
  },
  {
    label: "Service appointment plan",
    value: "Service appointment plan",
  }
];

export const MAX_BOOKING_PER_SLOT = 5;

export const emailTemplateNames = [
 "Neuron Account Creation",
 "Cognitgo-Neuron Account Created-Credentials",
 "Reset Password",
 "Customer Appointment Created",
 "Customer Rescheduled Appointment",
 "Customer Appointment Canceled",
 "Note Created for lead"
]
