import React from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Error } from '@material-ui/icons';
import { Warning } from '@material-ui/icons';
function Bottable({ data, page }) {
    const [show, setShow] = React.useState<any>(false)
    const [content, setContent] = React.useState<any>("")

    const stepStyle = {
        // boxShadow: 2,  
        // backgroundColor: "rgba(0,0,0,0.1)",
        padding: 2,
        "& .Mui-active": {
            "&.MuiStepIcon-root": {
                color: "secondary.main",
                fontSize: "2rem"
            }
        },
        "& .Mui-completed": {
            "&.MuiStepIcon-root": {
                color: "success.main",
                fontSize: "2rem"
            }
        },
        // "& .MuiStepConnector-line": {
        //   borderTopWidth: "4px",
        // },
        // "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
        //   borderColor: "green",
        // },
        // "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
        //   borderColor: "green",
        // },
    }
    const styles = {

    }
    return (
        <div className='container-fluid mb-3' style={{ paddingTop: '100px' }}>
            <table className='table table-bordered table-sm '>
                <thead className='table-head'>
                    <tr>
                        <th className='p-3' scope="col">#</th>
                        <th className='p-3' scope="col">Date</th>
                        <th className='p-3' scope="col">Bot name</th>
                        <th className='p-3' scope="col">Working stage</th>
                        <th className='p-3' scope="col">Status</th>
                    </tr>
                </thead>
                <tbody style={{ fontWeight: 'normal' }}>
                    {data && data?.data?.body?.allBotMonitoring
                        ?.sort((a, b) => a?.datetime > b?.datetime ? -1 : 1)
                        ?.map((obj, index) => (
                            <tr>
                                <th scope="row" style={{ verticalAlign: 'middle' }}>{(index + 1) + page * 10 - 10}</th>
                                <td style={{ verticalAlign: 'middle' }}>{!obj?.datetime ? "N/A" : moment(obj?.datetime).format('LLL') + "[" + obj?.time_zone + "]"}</td>
                                <td style={{ verticalAlign: 'middle' }}>{obj?.Dealer_Name}</td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <Box sx={{ width: '100%' }}>
                                        <Stepper alternativeLabel activeStep={0} sx={stepStyle}>
                                            {obj?.check_points?.map((inObj, index) => (
                                                inObj?.Reason ? <Step
                                                    onClick={() => { setContent(inObj?.Traceback?.exception_message); setShow(true) }}
                                                    key={index} style={{ cursor: 'pointer', color: inObj?.Status === "Success" ? "green" : inObj?.Status === "Error" ? 'red' : 'tomato' }}>
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip>
                                                                <strong>{inObj?.Reason}</strong>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <StepLabel sx={styles} StepIconComponent={inObj?.Status === "Success" ? CheckCircleIcon : inObj?.Status === "Error" ? Error : Warning}>{inObj?.Action_name}</StepLabel>
                                                    </OverlayTrigger>
                                                </Step> : <Step key={index} style={{ color: inObj?.Status === "Success" ? "green" : inObj?.Status === "Error" ? 'red' : 'tomato' }}>
                                                    <StepLabel sx={styles} StepIconComponent={inObj?.Status === "Success" ? CheckCircleIcon : inObj?.Status === "Error" ? Error : Warning}>{inObj?.Action_name}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Box>
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    {/* {checkStatus(obj)} */}
                                    {!obj?.final_status ? <div className='center-status'>N/A</div> :
                                        <div className={obj?.final_status === "Success" ? 'bot_status_green center-status' : obj?.final_status === "Warning" ? 'bot_status_yellow center-status' : 'bot_status_red center-status'}></div>}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <Modal show={show} onHide={() => { setContent(""); setShow(false) }}>
                <Modal.Body>
                    <div style={{ fontWeight: 'normal' }}> {content}</div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn pbuttonbgcolor btn-sm' onClick={() => { setContent(""); setShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Bottable