import { Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
// import DatePicker, { DateObject, Calendar } from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import DatePicker from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import UpcommingAppointments from './UpcommingAppointments';
import PreviousAppoinemnts from './PreviousAppoinemnts';
import './appoinemtnet.css'
import { dateformater, timeformater } from '../common/dateFormat';
import moment from 'moment';
import { Button } from '@mui/material';
import daysConfig from './daysconfig.js'
import Previewappointment from './Previewappointment';
import { MAX_BOOKING_PER_SLOT } from './../../controller'
function Appointment({ lead, dealerDetails, refetch, setLoading }: any) {

    const dateRef = useRef<any>(null)
    const [timeSlots, setTimeSlots] = useState<any>(null)
    const [startTime, setstartTime] = useState<any>(null)
    const [isReshedule, SetIsReshedule] = useState<any>(false)
    const [endTime, setendTime] = useState<any>(null)
    const [disableDays, setDisableDays] = useState<any>([])
    const [selectedTimeSlot, setSelectedTimeSlots] = useState<any>([])
    const [selectedAppoiintmentDate, setSelectedAppoiintmentDate] = useState<any>(null)
    const [toggle, setToggle] = useState<any>("date-select");
    const [bookingPayload, setBookingPayload] = useState<any>(null)
    const [upcommingApp, setUpcommingApp] = useState<any>(null)
    const [priviousApp, setpriviousApp] = useState<any>(null)
    const [btnDisabled, setBtndisabled] = useState(true)
    const findNonWorkingDay = () => {
        const filtered = dealerDetails?.workingHours?.filter((obj: any) => (
            obj.working === false
        ));

        const temp = [] as any;
        filtered?.forEach((cont: any) => {
            const daysConfig11 = daysConfig?.filter((obj: any) => (
                obj.day === cont.day
            ))
            temp.push(daysConfig11[0].num)

        })
        setDisableDays(temp)
    }
    // return current.day() !== temp.forEach((obj:any)=>obj);
    const yesterday = moment().subtract(1, 'day');
    const disableWeekends = (current) => {
        const temp = [0, 3] as any
      
        
        //IF APPOINTMENT IS ALREADY BOOKED, disabling calender
        // if (!isReshedule) {
        //     let can=lead?.appointments?.filter((obj)=>{
        //         return obj?.appointment_status==="Booked" || obj?.appointment_status==="Rescheduled" || obj?.appointment_status==="Rescheduled" ||
           
        //     })
            
        //     if(can && can?.length){
        //         return false //disable the whole the calender
        //     }
        //     // return false
           
        // }

        if (!dealerDetails?.workingHours) {
            return disableDays.includes(current.day());
        }
        // return !disableDays.includes(current.day()) && current.isAfter(yesterday);
        return !disableDays.includes(current.day())
        // && current.isAfter(yesterday);
    };

    //get from and to time based on day user selected in calender
    const handleDateChange = (date: any) => {
        setSelectedTimeSlots("")
        setSelectedAppoiintmentDate(moment(date).format('ll'))
        const filtered = dealerDetails?.workingHours.filter((obj: any) => (
            obj.day === moment(date).format('LLLL').split(',')[0]
        ))
        setstartTime(filtered[0].fromTime);
        setendTime(filtered[0].toTime);

    }

    const clearForm = () => {
        // setTimeSlots(null)
        setstartTime(null)
        setendTime(null)
        // setSelectedTimeSlots(null)
        setBtndisabled(true)
    }


    useEffect(() => {
        findNonWorkingDay()
        function parseTime(s) {
            var c = s.split(':');
            return parseInt(c[0]) * 60 + parseInt(c[1]);
        }

        function convertHours(mins: any) {
            var hour = Math.floor(mins / 60);
            mins = mins % 60;
            var converted = pad(hour, 2) + ':' + pad(mins, 2);
            return converted;
        }

        function pad(str, max) {
            str = str.toString();
            return str.length < max ? pad("0" + str, max) : str;
        }

        function calculate_time_slot(start_time, end_time, interval = "30") {
            var i, formatted_time: any
            var time_slots = [] as any;
            for (i = start_time; i <= end_time; i = i + interval) {
                formatted_time = convertHours(i);
                time_slots.push(formatted_time);
            }
            return time_slots;
        }

        var start_time = startTime && endTime && parseTime(startTime),
            end_time = startTime && endTime && parseTime(endTime),
            interval: any = 30;

        var times_ara = startTime && endTime && calculate_time_slot(start_time, end_time, interval);

        const temp = [] as any;
        times_ara?.forEach((val: any) => {
            temp.push({ slot: val, disabled: false })
        })
        disableTimeslot(selectedAppoiintmentDate, temp)
        // setTimeSlots(times_ara)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lead, startTime, endTime, selectedAppoiintmentDate])

    const handleSlotSelected = (e: any, slot: any) => {
        setBtndisabled(false)
        setSelectedTimeSlots(slot.slot)
    }



    const disableTimeslot = (date: any, slots: any) => {
        const updatedSlots = [] as any;
        slots?.forEach((obj1: any) => {
            dealerDetails?.bookedSlots?.forEach((obj2: any) => {
                if (selectedAppoiintmentDate === obj2.date && obj1.slot === obj2.timeSlot && obj2.count >= MAX_BOOKING_PER_SLOT) {
                    obj1.disabled = true
                }
            })
            updatedSlots.push(obj1)
        })
        setTimeSlots(updatedSlots)
    }


    // useEffect(() => {
    //     disableTimeslot(selectedAppoiintmentDate,timeSlots)
    // }, [selectedAppoiintmentDate, timeSlots])

    //calculate future and prior appointments
    useEffect(() => {
        const upcommingAppointments = [] as any
        const previousAppointments = [] as any
        if (lead) {
            let date1 = new Date(Date.now());
            let date2;
            lead?.appointments?.forEach((obj: any) => {
                date2 = new Date(obj?.appointment_date);
                if (date1 > date2) {
                    previousAppointments.push(obj)
                } else {
                    upcommingAppointments.push(obj)
                }
            })
            setpriviousApp(previousAppointments);
            setUpcommingApp(upcommingAppointments)

        }
    }, [lead])

    const confirmTime = (op: any) => {
        const payload = {
            leadInfo: {
                _id: lead?._id,
                email: lead?.customer_details.email,
                first_name: lead?.customer_details.first_name,
                last_name: lead?.customer_details.last_name,
                mobile_number: lead?.customer_details.mobile_number
            },
            dealer_info: {
                dealer_id: dealerDetails?.dealer_id,
                dealer_name: dealerDetails?.dealer_name,
                dealer_email: dealerDetails?.adf_email,
                dealer_phone: dealerDetails?.phone,
                dealer_address: dealerDetails?.address
            },
            appointment_date: selectedAppoiintmentDate,
            appointment_time: selectedTimeSlot,
            appointment_status: "Booked",
            timeZone: dealerDetails?.timezone
        }
        setBookingPayload(payload)
        setToggle("booking-section")
        clearForm()
    }
    // style={{ backgroundColor: "#ccd2f3" }}
    return (
        <div>
            <Grid container>
                <Grid item lg={6}>
                    <div >
                        <div>
                            <UpcommingAppointments
                                selectedAppoiintmentDate={selectedAppoiintmentDate}
                                data={upcommingApp && upcommingApp}
                                refetch={refetch}
                                setLoading={setLoading}
                                disableWeekends={disableWeekends}
                                handleDateChange={handleDateChange}
                                timeSlots={timeSlots}
                                selectedTimeSlot={selectedTimeSlot}
                                handleSlotSelected={handleSlotSelected}
                                confirmTime={() => confirmTime("reschedule")}
                                clearForm={clearForm}
                                SetIsReshedule={SetIsReshedule}
                                isReshedule={isReshedule}
                                dealerDetails={dealerDetails}
                            // btnDisabled={btnDisabled}

                            />
                        </div>
                        <div>
                            <PreviousAppoinemnts data={priviousApp && priviousApp} refetch={refetch} setLoading={setLoading} />
                        </div>
                    </div>
                </Grid>
                <Grid item lg={6} className="px-2">
                    {toggle === "date-select" ?
                        <>
                            <div className='px-4'>
                                <h3 className='font-weight-bold py-3' style={{ color: "#00158d", }}>Book the appointment</h3>
                                <DatePicker
                                    input={false}
                                    open={true}
                                    timeFormat={false}
                                    //Enabled the past dates on stanley/client request.
                                    isValidDate={disableWeekends}
                                    onChange={handleDateChange}
                                />
                            </div>
                            {timeSlots && <div className='px-4 my-2'>
                                <Grid container id='slot-container'>
                                    {timeSlots?.map((times: any) => (
                                        <span className={times.disabled ? `p-2 m-2 disable-slot` : `p-2 app-date-card m-2 ${selectedTimeSlot.includes(times.slot) && 'active-slot'}`} style={{ fontSize: '12px', borderRadius: '6px' }} onClick={(e) => !times.disabled && handleSlotSelected(e, times)}>
                                            {moment(times?.slot, ["HH:mm a"]).format("hh:mm A")}
                                        </span>

                                    ))}
                                </Grid>
                            </div>}
                            <div className="timezone-note d-flex justify-content-center my-3">
                                <span className='text-dark'>The above mentioned slots are for "{dealerDetails?.timezone}" timezone</span>
                            </div>
                            <div className='d-flex justify-content-center my-3'>
                                <Button variant="contained" className='text-center' onClick={() => confirmTime("general")} disabled={!selectedTimeSlot?.length || btnDisabled}>Confirm Time</Button>
                            </div>
                        </> :
                        <Previewappointment
                            bookingPayload={bookingPayload}
                            setToggle={setToggle}
                            refetch={refetch}
                            setLoading={setLoading}
                            setTimeSlots={setTimeSlots}
                            clearForm={clearForm}
                            lead={lead}
                            dealerDetails={dealerDetails}
                        />
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default Appointment