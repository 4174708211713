import React, { createContext } from 'react';

export const UserContext = createContext<{
    id: string;
    name: string;
    role: string;
    email: string;
    authToken: string;
    soldvalue: string;
    fromDate: string;
    toDate: string;
    user_first_name: String,
    user_last_name: String,
    phone: String,
    imageUrl: String,
    linkedin: String,
    facebook: String,
    instagram: String,
    twitter: String,
    tiktok: String,
    workNumber: String,
    type: String,
    company: String,
    communicationAddress: String,
    dealerID: String,
    corporateID: String,
    subscriptionFee:any;
    sort:any
    CRDsort:any
    LCDsort:any
    loading:any
    timezone:any
    shortName:any
    aliasName:any
    setUser: Function;
    // unReadMessagesCount:any
}>({
    id: '',
    name: '',
    role: '',
    email: '',
    authToken: '',
    soldvalue: '0',
    fromDate: '',
    toDate: '',
    user_first_name: '',
    user_last_name: '',
    phone: '',
    imageUrl: '',
    linkedin: '',
    facebook: '',
    instagram: '',
    twitter: '',
    tiktok: '',
    type: '',
    workNumber: "",
    subscriptionFee:"",
    company: '',
    communicationAddress: "",
    dealerID: "",
    corporateID: "",
    loading:false,
    sort:null,
    CRDsort:null,
    LCDsort:null,
    timezone:"",
    shortName:"",
    aliasName:"",
    // unReadMessagesCount:0,
    setUser: () => { },
});
