import React, { useContext, useEffect, useState } from 'react';
import Sidebar from './sidebar';
import Topbar from './topbar';
import './index.css';
import _ from 'lodash'
import { useLocation } from 'react-router-dom';
import { accessRoles } from '../../controller';
import { UserContext } from './UserContext';
import Accessdenied from './Accessdenied';

const WithBars = ({ render }: { render: Function }) => {
    const { type, role } = useContext<any>(UserContext)
    const [auth, setAuth] = useState<any>("loading")
    const location = useLocation();
    
    

    useEffect(() => {
        let path = window.location.hash.split("/")[1];

        if (path === "leads") {
            return setAuth(accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowLeadsandOpportunities"))
        }
        if (path === "dealers") {
            return setAuth(accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowDealersandCorporate"))
        }
        if (path === "users") {
            return setAuth(accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowUsers"))
        }
        if (path === "bot-health-monitor") {
            return setAuth(accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowBotHealthMonitoring"))
        }
        if (path === "mails") {
            return setAuth(accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowDealerInbox"))
        }
        if (path === "notifications") {
            return setAuth(accessRoles?.filter((obj: any) => _.toLower(obj.role) === _.toLower(type + role))[0]?.permissions?.includes("allowNotifications"))
        }
        else {
            setAuth(true)
        }

    }, [location.pathname, role, type])
    return (
        <div className={'container-div d-flex'}>
            {!location.pathname.includes('update-profile') && <div className='sidebar'>
                <Sidebar />
            </div>}
            <Topbar />
            <div className='content'>{auth === "loading" ? "Loading" : auth ? render() : <Accessdenied/>}</div>
        </div>

    );
};

export default WithBars;


// <div className={'container-div d-flex'}>
// {!location.pathname.includes('update-profile') && <div className='sidebar'>
//     <Sidebar />
// </div>}
// <Topbar />
// <div className='content'>{render()}</div>
// </div>
