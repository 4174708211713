import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import config from '../../../config.json'
import moment from 'moment'
import { Button, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Loader from '../../common/Loader'
import _ from 'lodash'
import './style.css'
import downArrow from '../../../assets/images/downarrow.svg'
import upArrow from '../../../assets/images/arrowup.svg'
import LoadingBar from '../../common/LoadingBar'
import ReactPaginate from 'react-paginate'
import { UserContext } from '../../common/UserContext'
import NoDataFound from '../../common/NoDataFound'
import notificationBell from '../../../assets/images/nonotification.png'
import {deleteNotification} from "../../common/Notificationpopup"
function Smsnotification({data,isLoading, page, setPage, limit}: any) {
  // let { unReadMessagesCount } = useContext(UserContext)
  let token: any = localStorage.getItem("$AuthToken")

  const [scrollToBottom, setscrollToBottom] = useState<boolean>(true)
  const [custPage, setCustPage] = React.useState<any>("");
  const history = useHistory();
  const bottonRef = React.useRef<any>(null)



  
// [
//   {
//     id:'1234',
//     messages:[
//       {
//         time:"08-30-2023 15:14:05",
//         msg:"hello"
//       },
//       {
//         time:"08-30-2023, 16:18:20",
//         msg:"My msg"
//       },
//     ]
//   }
// ]

  // const fetchSmsNotifications = async () => {
  //   let body = {
  //     limit_per_page: 10,
  //     pages_to_skip: page - 1,
  //   }
  //   let resp = await axios.post(config.baseHost_backend + "/notification/unread-messages", body, {
  //     headers: {
  //       Authorization: token
  //     }
  //   })
  //   return resp?.data
  // }
  // const { data, refetch, isLoading } = useQuery(['fetch-sms-notificatios', page], fetchSmsNotifications)

  const handlePageClick = async (Selected) => {
    setPage(Selected.selected + 1)
    setscrollToBottom(true)
  }

  // const handleScroll = () => {
  //   let refVar: any
  //   if (scrollToBottom) {
  //     bottonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  //   } else {
  //     scrolRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  //   }
  //   setscrollToBottom(!scrollToBottom)
  // }

  
  console.log("lead:",data)

  return (
    <>
      {isLoading ? <LoadingBar isActive={true} /> : !isLoading && !data?.body?.unReadMessagesLeads?.length ? <NoDataFound height={""} width={""} img={notificationBell} message1='No notifications yet' message2="When you get notifications, they'll show up here"/> :
        <div className='container-fluid bg-light' style={{ paddingTop: '10px' }} ref={bottonRef}>
          {/* <button disabled={isLoading} onClick={() => {
            // handleScroll()
          }} className='scroll-dwn btn'><img src={scrollToBottom ? downArrow : upArrow} alt="scroll button" title={scrollToBottom ? "Scroll to bottom" : " Scroll to top"} height={30} /></button> */}
          <div className="accordion my-2" id="accordionExample" >
            {data && data?.body?.unReadMessagesLeads?.map((lead, indx) => (
              <div className="accordion-item open  shadow-sm mb-4" aria-expanded="true"  >
                <div className="accordion-header my-0 py-0" id={'heading' + indx}>
                  <button className="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target={'#' + 'collapse' + indx} aria-expanded="false" aria-controls={'collapse' + indx}>
                    <div style={{ width: '100%' }} className='d-flex justify-content-between'>
                      <span style={{ fontWeight: 'bold' }} className='mt-auto mb-auto'>{lead?.customer_details?.first_name + " " + lead?.customer_details?.last_name} - {lead?.dealer_name}</span>
                      <button className='btn pbuttonbgcolor btn-sm mr-5' onClick={() => {
                        deleteNotification(lead?._id)
                        console.log("opening lead and delete notification id: "+lead?._id)
                        history.push(`/leads/${lead?.lead_id}`)
                      }}>Open lead details</button>
                    </div>
                  </button>
                </div>
                <div id={'collapse' + indx} className="accordion-collapse collapse show" aria-labelledby={'heading' + indx} data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>Sender</th>
                          <th>Text</th>
                          <th>Time</th>
                          <th>Created by</th>
                        </tr>
                      </thead>

                      <tbody style={{ fontWeight: 'normal' }}>
                        {lead?.messages?.map(
                          (
                            message: any,
                            index: any
                          ) => (
                            <tr key={index}>

                              <td>{message.sender_type}</td>
                              <td>{message.text}</td>
                              <td>{moment(message.time).format('LLL') + "(" + lead?.timezone + ")"}</td>
                              <td>
                                {
                                  message.sender_type === "BOT" ? "BOT" :
                                    message.sender_type === "CUSTOMER" ? lead?.customer_details?.first_name + " " + lead?.customer_details?.last_name :
                                      message.byName || "N/A"
                                }
                              </td>
                            </tr>
                          )
                        ).reverse()}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='d-flex flex-row gap-3'>
            <div className='mt-3'>
              <ReactPaginate
                nextLabel=">>"
                onPageChange={handlePageClick}
                pageCount={data?.body?.unReadMessagesLeads.length / limit || 0}
                previousLabel="<<"
                containerClassName='pagination'
                pageClassName={'pagination'}
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakLinkClassName='page-link'
                activeClassName='active'
                breakClassName='page-item'
                initialPage={page - 1}
              />
            </div>
            <div className='d-flex mt-auto mb-auto'>
              <input type="number" name="" id="" value={custPage} style={{ width: '60px' }} className="p-1 form-control cust-page-input bg-light" placeholder='##' onChange={(e) => { setCustPage(e.target.value) }} />
              <button className='cust-page-btn btn btn-sm pbuttonbgcolor' onClick={(e) => { custPage && setPage(Number(custPage)); setCustPage("") }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg></button>
            </div>
          </div>
        </div >
      }
    </>
  )
}

export default Smsnotification