import moment from 'moment';
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import NoDataFound from '../common/NoDataFound';
import momenttz from 'moment-timezone'
function Sentitems({ data,dealerDetails,leadDetails }: any) {
    const location: any = useLocation();
    const history = useHistory();
    return (
        <>
            {!data?.length && <NoDataFound height={""} width={""} message1='No messages' message2='' img=""/>}
            <table className="table mt-2">
                <tbody>
                    {data?.map((obj: any, index: any) => (
                        <tr
                            style={{ cursor: "pointer" }}
                            onClick={() => { history.push({ pathname: `/view/sentItems/${obj.conversationId}/${location.pathname.includes('leads') ? dealerDetails?.dealer_id : null}`, state: { ob: obj, type: 'sentItems',leadDetails } });  }}
                        >
                            {/* <td>{index + 1}</td> */}
                            <td>
                                <div className='d-flex flex-row p-2'>
                                    <div className='d-flex flex-column'>
                                        {/* <span style={{ fontWeight: 'lighter' }}>{obj?.from?.emailAddress?.name}</span> */}
                                        <span style={{ fontWeight: 'lighter' }}>{obj?.toRecipients[0]?.emailAddress?.address}</span>
                                        <span style={{ padding: "0px", fontWeight: 'lighter' }}>{obj?.subject}</span>
                                        <span style={{ padding: "0px", fontWeight: 'lighter' }}>{obj?.bodyPreview?.substring(0, 150).concat('...')}</span>

                                    </div>
                                    <div>
                                        <span style={{ position: 'absolute', right: '20px', fontWeight: 'lighter' }}>{momenttz(obj?.sentDateTime).tz('America/Los_Angeles').format("LLL")}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </>
    )
}

export default Sentitems