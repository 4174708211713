import React, { useState } from 'react'
import { Modal, Row } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import Flows from './Flows'
import axios from 'axios'
import config from '../../config.json'
import { useQuery } from 'react-query'
function Adminflow({data,refetch,isLoading}) {
    const [flowName, setFlowName] = useState<any>("")
    const [show, setShow] = useState<boolean>(false)
    const token:any=localStorage.getItem("$AuthToken");

    const handleSave =async (e) => {
        e.preventDefault();
        let body={
            function: "createList",
             name: flowName
        }
        let resp=await axios.post(config.baseHost_backend+"/automation",body,{
            headers:{
                Authorization:token
            }
        });
        console.log("resp",resp);
        
        if(resp?.data?.statusCode===200){
            setShow(true);
            refetch();
        } 
    }



 
    
    return (
        <div >
            <Form className='container-fluid my-3'>
                <Row>
                    <Form.Group>
                        <Form.Label className='mb-0'>Enter flow name</Form.Label>
                        <input autoFocus type="text" required value={flowName} className='form-control form-control-sm p-3 bg-white' onChange={(e) => { setFlowName(e.target.value) }} />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <button className='btn pbuttonbgcolor' type='submit' disabled={!flowName} onClick={handleSave}>Save</button>
                    </Form.Group>
                </Row>
            </Form>
            <Flows data={data} isLoading={isLoading}/>
            <Modal show={show}>
                <Modal.Header>
                    <div className='d-flex gap-2'>
                        <Modal.Title><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="mt-auto mb-auto text-success bi bi-check2-circle" viewBox="0 0 16 16">
                            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                        </svg></Modal.Title>
                        <h2 className='mt-auto mb-auto'>Contact list created successfully</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ fontWeight: 'normal' }}>
                        <a href="https://app.brevo.com/automation/automations" target='_blank' className='btn btn-link px-0 mt-auto' onClick={()=>{setShow(false)}}>Click here</a> to navigate to Brevo to create the email automation flow
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Adminflow