import React, { useContext } from 'react'
import { Card } from 'react-bootstrap'
import { UserContext } from '../../common/UserContext'
import './style.css'
function Sms() {
  return (
    <Card>
      <Card.Header>
        <Card.Title className='text-dark mt-1'>SMS</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="chat">
          <div className="mine messages" style={{ fontWeight: 'lighter' }} >
            <div className="message last mineMsgborder" >
              <div className='w-100 d-flex justify-content-between'>
                <span>Sales team</span>
                <span>12:15 PM</span>
              </div>
              <div className="py-2">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi accusantium nemo, ipsa blanditiis unde nesciunt nobis aibus in!
              </div>
            </div>
          </div>

          <div className="yours messages">
            <div className="message yourMsgborder py-2" style={{ fontWeight: 'lighter' }}>
              <div className='w-100 d-flex justify-content-between'>
                <span>Keerthan</span>
                <span>03:31 PM</span>
              </div>
              <div style={{ fontWeight: 'lighter' }} className="py-2">
                Lorem ipsum daliquid voluperendis, accusantiure non laboriosam neque sint assumenda?
              </div>
            </div>
          </div>

          <div className="mine messages" style={{ fontWeight: 'lighter' }} >
            <div className="message last mineMsgborder" >
              <div className='w-100 d-flex justify-content-between'>
                <span>Sales team</span>
                <span>12:15 PM</span>
              </div>
              <div className="py-2">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi accusantium nemo, ipsa blanditiis unde nesciunt nobis aibus in!
              </div>
            </div>
          </div>

          <div className="yours messages">
            <div className="message yourMsgborder py-2" style={{ fontWeight: 'lighter' }}>
              <div className='w-100 d-flex justify-content-between'>
                <span>Keerthan</span>
                <span>03:31 PM</span>
              </div>
              <div style={{ fontWeight: 'lighter' }} className="py-2">
                Lorem ipsum daliquid voluperendis, accusantiure non laboriosam neque sint assumenda?
              </div>
            </div>
          </div>

        </div>
        <div className='d-flex chat-comtrols mt-2'>
          <input type="text" className='form-control form-control-sm' placeholder='Enter your message...' />
          <button className='btn btn-primary'>Send</button>
        </div>
      </Card.Body>
    </Card>
  )
}

export default Sms